import { useLayoutEffect, useState } from 'react';

import axios from 'axios';

import { isProductionEnvironment } from '../../common/helpers/misc';
import packageJson from '../../../package.json';

const useVersioning = () => {
    const [appLoading, setAppLoading] = useState(true);

    // ============================================
    // METHODS
    // ============================================

    const refreshCacheAndReload = () => {
        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(async function(names) {
                await Promise.all(names.map((name) => caches.delete(name)));
            });
        }
        // delete browser cache and hard reload
        window.location.reload(true);
    };

    // version from response - first param, local version second param
    const semverGreaterThan = (versionA, versionB) => {
        const versionsA = versionA.split(/\./g);

        const versionsB = versionB.split(/\./g);
        while (versionsA.length || versionsB.length) {
            const a = Number(versionsA.shift());
            const b = Number(versionsB.shift());
            if (a === b) continue;
            return a > b || isNaN(b);
        }
        return false;
    };

    // ============================================
    // HOOKS
    // ============================================

    useLayoutEffect(() => {
        axios.get('/meta.json', { headers: { cache: 'no-cache' } }).then((response) => {
            const latestVersion = response.data.version;
            const currentVersion = packageJson.version;
            if (!isProductionEnvironment()) {
                console.log('latestVersion: ', latestVersion);
                console.log('currentVersion: ', currentVersion);
            }

            const isNewVersionAvailable = semverGreaterThan(latestVersion, currentVersion);
            if (isNewVersionAvailable) {
                // notification.open({
                //     message: 'New update is available! Click here to upgrade to it!',
                //     onClick: refreshCacheAndReload,
                //     placement: 'topRight',
                // });
                refreshCacheAndReload();
            } else {
                setAppLoading(false);
                console.log(
                    `You already have the latest version - ${latestVersion}. No cache refresh needed.`,
                );
            }
        });
    }, []);

    return [appLoading];
};

export default useVersioning;
