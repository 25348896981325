import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';

import {
    ACTIONS,
    addBankCard,
    addBankCardFailure,
    addBankCardSuccess,
    addEWallet,
    addEWalletFailure,
    addEWalletSuccess,
    changeFinPin,
    changeFinPinFailure,
    changeFinPinSuccess,
    changePassword,
    changePasswordFailure,
    changePasswordSuccess,
    claimPasscode,
    claimPasscodeFailure,
    claimPasscodeSuccess,
    deleteBankCard,
    deleteBankCardFailure,
    deleteBankCardSuccess,
    deleteEWallet,
    deleteEWalletFailure,
    deleteEWalletSuccess,
    getAffiliateStatus,
    getAffiliateStatusFailure,
    getAffiliateStatusSuccess,
    getGameAccess,
    getGameAccessFailure,
    getGameAccessSuccess,
    getPlaytechMemberInfo,
    getPlaytechMemberInfoFailure,
    getPlaytechMemberInfoSuccess,
    getReferral,
    getReferralFailure,
    getReferralSuccess,
    getSocialPlatformAccess,
    getSocialPlatformAccessFailure,
    getSocialPlatformAccessSuccess,
    getUserDetails,
    getUserDetailsFailure,
    getUserDetailsSuccess,
    getUserVipLevelDetails,
    getUserVipLevelDetailsFailure,
    getUserVipLevelDetailsSuccess,
    openOnBoardingModal,
    regCodeRequest,
    regCodeRequestFailure,
    registerAffiliate,
    registerAffiliateFailure,
    registerUser,
    registerUserFailure,
    resetPassword,
    resetPasswordFailure,
    resetPasswordSuccess,
    updateAppLanguage,
    updateAppLanguageFailure,
    updateAppLanguageSuccess,
    updateUserDetails,
    updateUserDetailsFailure,
    updateUserDetailsSuccess,
    userLogin,
    userLoginFailure,
    userLoginSuccess,
    userLogout,
    verifyAccount,
    verifyAccountFailure,
    verifyAccountSuccess,
    verifyFinPin,
    verifyFinPinFailure,
    verifyFinPinSuccess,
} from '../common/actions/userActions';
import { API } from '../common/constants/api';
import { LOGIN_TYPE } from '../common/constants/misc';
import {
    claimPasscodeOperation,
    regCodeRequestOperation,
    signUpOperation,
} from '../common/actions/operationsActions';
import { clearError } from '../common/actions/errorActions';
import { clearRewardBanner } from '../components/rewards/rewardsActions';
import { clearWalletInfo } from '../components/wallet/walletActions';
import { convertPhoneNumber, getStateProp } from '../common/helpers/misc';
import {
    deleteStorageItem,
    getCurrentUser,
    getOperationId,
    getStorageItem,
    isUserLoggedIn,
    setStorageItem,
} from '../common/helpers/api';
import {
    geoLocationGet,
    geoLocationGetFailure,
    geoLocationGetSuccess,
} from '../components/internationalisation/actions';
import { getTranslation } from '../common/helpers/locale';
import { isChinaCurrencyHelper } from '../common/helpers/currency';
import { useDispatch, useStore } from '../store/StateProvider';
import { useOperationAsyncEndpoint } from './operationsService';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import packageJson from '../../package.json';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const {
    BANK_CARD,
    CHANGE_PASSWORD,
    GEOLOCATION,
    PLAYERS,
    VERSION,
    REQUEST_BETFILIATE_OTP,
    REQUEST_FIN_PIN_OTP,
    IDENTITY,
    REGISTER_USER_V2,
    SIGN_IN,
    OTP_SIGN_IN,
    CHANGE_FIN_PIN,
    RESET_PASSWORD,
    REQUEST_OTP,
    VERIFY_FIN_PIN,
    REGISTER_AFFILIATE,
    REFERRAL,
    PLAYTECH,
    VERIFY_ACCOUNT,
    GAME_ACCESS,
    AFFILIATE_STATUS,
    PLAYERS_APP_LANGUAGE,
    PLAYERS_VIP_DETAILS,
    SOCIAL_PLATFORM_ACCESS,
    EWALLET,
} = API;

export function useGetUserAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');

    const { error, loading, response } = useApi({
        forceDispatchEffect: () => !!userId,
        method: 'GET',
        trigger: userId,
        url: `${VERSION.V1}${PLAYERS}/${userId}`,
    });

    useEffect(() => {
        if (userId) getUserDetails(dispatch);
    }, [dispatch, userId]);

    useEffect(() => {
        if (response) {
            try {
                const user = response.data.data;
                clearError(dispatch);
                getUserDetailsSuccess(
                    dispatch,
                    _.pickBy(
                        {
                            ..._.merge(user, user.monetary),
                            ..._.merge(user, user.profile),
                        },
                        (value, key) => !_.includes(['profile', 'monetary'], key),
                    ),
                );
            } catch (error) {}
        }
    }, [dispatch, response]);

    const message = useError(error, getUserDetailsFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useUpdateUserDetailsAsyncEndpoint(updatedInfo) {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    const formattedInfo = _.filter(
        _.map(Object.keys(updatedInfo), (key) => {
            if (updatedInfo[key]) {
                return {
                    op: 'replace',
                    path: `/${key}`,
                    value: updatedInfo[key],
                };
            }
        }),
        (item) => item,
    );

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateUserDetails = () => {
        updateUserDetails(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PATCH',
        options: {
            data: formattedInfo,
        },
        trigger,
        url: `${VERSION.V1}${PLAYERS}/${userId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateUserDetailsSuccess(dispatch, updatedInfo);
        }
    }, [dispatch, response, updatedInfo]);

    useToasterSuccessMessage(response, getTranslation('User information successfully updated!'));

    const errorMsg = useError(error, updateUserDetailsFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleUpdateUserDetails];
}

export function useVerificationNumberAsyncEndpoint(formValues, initialCurrencyCode) {
    const [state, dispatch] = useStore();
    const { mobileNo } = getStateProp(state, 'user', {});
    const [requestProcessing, setRequestProcessing] = useState(false);
    // ============================================
    // METHODS
    // ============================================

    const getCurrencyCode = () => {
        return !_.isEmpty(formValues) ? formValues.currencyCode : initialCurrencyCode.currencyCode;
    };

    const handleRequestCode = () => {
        regCodeRequest(dispatch);
        setRequestProcessing(true);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                currencyCode: getCurrencyCode(),
                mobileNo: convertPhoneNumber(mobileNo),
            },
        },
        timeout: 60000,
        url: `${VERSION.V1}${IDENTITY}${REQUEST_OTP.NEW_MOBILE_NO}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            regCodeRequestOperation(dispatch, getOperationId(response));
        }
    }, [dispatch, response]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'claimVerificationCode',
        ACTIONS.USER_REG_CODE_REQUEST_SUCCESS,
        ACTIONS.USER_REG_CODE_REQUEST_FAILURE,
        'Verification code sent to you via SMS',
        response,
    );

    useEffect(() => {
        if (operationComplete || operationError || error) {
            setRequestProcessing(false);
        }
    }, [error, operationComplete, operationError]);

    const message = useError(error, regCodeRequestFailure);

    useToasterErrorMessage(error, message, 4);

    return [requestProcessing, operationComplete, handleRequestCode];
}

export function useVerificationNumberLoginAsyncEndpoint(formValues, initialCurrencyCode) {
    const [state, dispatch] = useStore();
    const { mobileNo } = getStateProp(state, 'user', {});
    const [requestProcessing, setRequestProcessing] = useState(false);
    // ============================================
    // METHODS
    // ============================================

    const getCurrencyCode = () => {
        return !_.isEmpty(formValues) ? formValues.currencyCode : initialCurrencyCode.currencyCode;
    };

    const handleRequestCode = () => {
        regCodeRequest(dispatch);
        setRequestProcessing(true);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                currencyCode: getCurrencyCode(),
                mobileNo: convertPhoneNumber(mobileNo),
            },
        },
        url: `${VERSION.V1}${IDENTITY}${REQUEST_OTP.NEW_MOBILE_NO}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            regCodeRequestOperation(dispatch, getOperationId(response));
        }
    }, [dispatch, response]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'claimVerificationCode',
        ACTIONS.USER_REG_CODE_REQUEST_SUCCESS,
        ACTIONS.USER_REG_CODE_REQUEST_FAILURE,
        'Verification code sent to you via SMS',
        response,
    );

    useEffect(() => {
        if (operationComplete || operationError || error) {
            setRequestProcessing(false);
        }
    }, [error, operationComplete, operationError]);

    const message = useError(error, regCodeRequestFailure);

    useToasterErrorMessage(error, message, 4);

    return [requestProcessing, operationComplete, handleRequestCode];
}

export function useUserRegistrationAsyncEndpoint(executeRedirect, formData) {
    const [state, dispatch] = useStore();
    const [requestProcessing, setRequestProcessing] = useState(false);
    const [loginData, setLoginData] = useState({});
    const mobileNo = getStateProp(state, 'user.mobileNo', {});
    const countryCode = getStateProp(state, 'country.code', {});
    const affiliateAndRefInfo = getStorageItem('affiliateAndRefInfo');
    const affiliateToken = getStateProp(affiliateAndRefInfo, 'affiliateToken', null);
    const { userName } = formData;

    const getUserRegistrationData = () => {
        const { confirmPassword, ...restFormValues } = formData;
        return {
            ...restFormValues,
            affiliateToken,
            countryCode,
            mobileNo,
        };
    };

    // ============================================
    // METHODS
    // ============================================

    const signUpUser = () => {
        registerUser(dispatch);
        setRequestProcessing(true);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: getUserRegistrationData(),
        },
        url: `${VERSION.V1}${REGISTER_USER_V2}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            signUpOperation(dispatch, getOperationId(response));
            setLoginData({ contextId: getOperationId(response), userName });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, response]);

    const [operationComplete, operationError, operationSuccess] = useOperationAsyncEndpoint(
        'signUp',
        ACTIONS.USER_REGISTER_SUCCESS,
        ACTIONS.USER_REGISTER_FAILURE,
        'Congratulations! You have succefully registered your account',
        response,
    );

    const [loggingIn, handleLogin] = useUserLoginAsyncEndpoint(null, loginData, LOGIN_TYPE.OTP);

    useEffect(() => {
        if (operationSuccess) {
            handleLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationSuccess]);

    useEffect(() => {
        if (operationComplete || operationError || error || !loggingIn) {
            setRequestProcessing(false);
        }
    }, [error, loggingIn, operationComplete, operationError]);

    const message = useError(error, registerUserFailure);

    useToasterErrorMessage(error, message);

    return [requestProcessing || loggingIn, signUpUser];
}

export function useUserLoginAsyncEndpoint(fireRedirect, loginInfo, loginMethod) {
    const dispatch = useDispatch();
    const hideWelcomeScreenOnLogin = getStorageItem('hideWelcomeScreen') || false;
    const appVersion = getStorageItem('appVersion');
    const loginType = loginMethod === LOGIN_TYPE.PASSWORD ? SIGN_IN : OTP_SIGN_IN;

    // ============================================
    // METHODS
    // ============================================

    const handleLogin = () => {
        userLogin(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: loginInfo,
        },
        url: `${VERSION.V1}${loginType}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            setStorageItem('user', response.data);
            userLoginSuccess(dispatch, response.data);
        }
    }, [response, dispatch, hideWelcomeScreenOnLogin, fireRedirect]);

    useEffect(() => {
        if (response) {
            if (
                !hideWelcomeScreenOnLogin ||
                (appVersion !== packageJson.version &&
                    getStateProp(response, 'data.claims.currencyCode', '') === 'MYR')
            ) {
                deleteStorageItem('hideWelcomeScreen');
                openOnBoardingModal(dispatch);
            }
            clearRewardBanner(dispatch);
            fireRedirect && fireRedirect(true);
        }
    }, [appVersion, dispatch, fireRedirect, hideWelcomeScreenOnLogin, response]);

    const message = useError(error, userLoginFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation("You've successfully logged in, welcome!"));

    return [loading, handleLogin];
}

export function useForgotPasswordAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const userName = getStateProp(state, 'user.userName', null);
    const mobileNo = getStateProp(state, 'user.mobileNo', {});
    const [requestProcessing, setRequestProcessing] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const handlePasscodeClaimRequest = () => {
        claimPasscode(dispatch);
        setRequestProcessing(true);
        executeRequest();
    };
    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                mobileNo: convertPhoneNumber(mobileNo),
                userName,
            },
        },
        url: `${VERSION.V1}${IDENTITY}${REQUEST_OTP.RESET_PASSWORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            claimPasscodeOperation(dispatch, getOperationId(response));
        }
    }, [response, dispatch]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'claimPasscode',
        ACTIONS.USER_CLAIM_PASS_CODE_SUCCESS,
        ACTIONS.USER_CLAIM_PASS_CODE_FAILURE,
        'Passcode sent to your mobile',
        response,
    );

    useEffect(() => {
        if (operationComplete || operationError || error) setRequestProcessing(false);
    }, [error, operationComplete, operationError]);

    const message = useError(error, claimPasscodeFailure);

    useToasterErrorMessage(error, message);

    return [requestProcessing, operationComplete, handlePasscodeClaimRequest];
}
export function useLoginVerificationAsyncEndpoint(formValues) {
    const dispatch = useDispatch();
    const { userName } = formValues;
    const [requestProcessing, setRequestProcessing] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const handlePasscodeClaimRequest = () => {
        claimPasscode(dispatch);
        setRequestProcessing(true);
        executeRequest();
    };
    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                userName,
            },
        },
        url: `${VERSION.V1}${IDENTITY}${REQUEST_OTP.LOGIN}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            claimPasscodeOperation(dispatch, getOperationId(response));
        }
    }, [response, dispatch]);

    const [operationComplete, operationError] = useOperationAsyncEndpoint(
        'claimPasscode',
        ACTIONS.USER_CLAIM_PASS_CODE_SUCCESS,
        ACTIONS.USER_CLAIM_PASS_CODE_FAILURE,
        'Passcode sent to your mobile',
        response,
    );

    useEffect(() => {
        if (operationComplete || operationError || error) setRequestProcessing(false);
    }, [error, operationComplete, operationError]);

    const message = useError(error, claimPasscodeFailure);

    useToasterErrorMessage(error, message);

    return [requestProcessing, operationComplete, handlePasscodeClaimRequest];
}

export function useResetPasswordAsyncEndpoint(otp, newPassword, executeRedirect) {
    const [state, dispatch] = useStore();

    const mobileNo = getStateProp(state, 'user.mobileNo');
    const userName = getStateProp(state, 'user.userName');

    // ============================================
    // METHODS
    // ============================================

    const handlePasswordResetRequest = () => {
        resetPassword(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                mobileNo: convertPhoneNumber(mobileNo),
                newPassword,
                otp,
                userName,
            },
        },
        url: `${VERSION.V1}${IDENTITY}${RESET_PASSWORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            resetPasswordSuccess(dispatch);
            deleteStorageItem('user');
            userLogout(dispatch);
            clearWalletInfo(dispatch);
            executeRedirect(true);
        }
    }, [response, dispatch, executeRedirect]);

    const message = useError(error, resetPasswordFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('Password successfully changed'));

    return [loading, handlePasswordResetRequest];
}

export function useChangePasswordAsyncEndpoint(currentPassword, newPassword, executeRedirect) {
    const [state, dispatch] = useStore();

    const userName = getStateProp(state, 'user.userName');

    // ============================================
    // METHODS
    // ============================================

    const handlePasswordChangeRequest = () => {
        changePassword(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'PUT',
        options: {
            data: {
                currentPassword,
                newPassword,
                userName,
            },
        },
        url: `${VERSION.V1}${CHANGE_PASSWORD}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            changePasswordSuccess(dispatch);
            deleteStorageItem('user');
            userLogout(dispatch);
            clearWalletInfo(dispatch);
            executeRedirect(true);
        }
    }, [response, dispatch, executeRedirect]);

    const message = useError(error, changePasswordFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('Password successfully changed'));

    return [loading, handlePasswordChangeRequest];
}

export function useRequestFinPinOtpAsyncEndpoint() {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const handlePasscodeClaimRequest = () => {
        claimPasscode(dispatch);
        executeRequest();
    };
    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        url: `${VERSION.V1}${IDENTITY}${REQUEST_FIN_PIN_OTP}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            claimPasscodeSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, claimPasscodeFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('Passcode sent to your mobile'));

    return [loading, handlePasscodeClaimRequest];
}

export function useChangeFinPinAsyncEndpoint(otp, newFinancialPin) {
    const [state, dispatch] = useStore();

    const userId = getStateProp(state, 'user.id');

    // ============================================
    // METHODS
    // ============================================

    const handleFinPinChangeRequest = () => {
        changeFinPin(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'PUT',
        options: {
            data: {
                newFinancialPin,
                otp,
                userId,
            },
        },
        url: `${VERSION.V1}${CHANGE_FIN_PIN}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            changeFinPinSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, changeFinPinFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('Financial Pin successfully set!'));

    return [loading, handleFinPinChangeRequest, response];
}

export function useVerifyFinPinAsyncEndpoint(financialPin) {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const handleFinPinVerifyRequest = () => {
        verifyFinPin(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                financialPin,
            },
        },
        url: `${VERSION.V1}${VERIFY_FIN_PIN}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            verifyFinPinSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, verifyFinPinFailure);

    useToasterErrorMessage(error, message);

    return [loading, response, handleFinPinVerifyRequest];
}

export function useAddBankCardAsyncEndpoint(cardData) {
    const [state, dispatch] = useStore();

    const bankType = isChinaCurrencyHelper(state)
        ? getTranslation('Bank Card')
        : getTranslation('Bank account');

    // ============================================
    // METHODS
    // ============================================

    const handleAddBankCard = () => {
        addBankCard(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: cardData,
        },
        url: `${VERSION.V1}${BANK_CARD.ROOT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addBankCardSuccess(dispatch, response.data);
        }
    }, [response, dispatch]);

    const message = useError(error, addBankCardFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(
        response,
        getTranslation('Bank Card Add Success Message', { bankType }),
    );

    return [loading, response, handleAddBankCard];
}

export function useDeleteBankCardAsyncEndpoint(cardId) {
    const [state, dispatch] = useStore();

    const bankType = isChinaCurrencyHelper(state)
        ? getTranslation('Bank Card')
        : getTranslation('Bank account');

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteBankCard = () => {
        deleteBankCard(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'DELETE',
        url: `${VERSION.V1}${BANK_CARD.ROOT}/${cardId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deleteBankCardSuccess(dispatch, cardId);
        }
    }, [response, dispatch, cardId]);

    const message = useError(error, deleteBankCardFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(
        response,
        getTranslation('Bank Card Delete Success Message', { bankType }),
    );

    return [loading, response, handleDeleteBankCard];
}

export function useAffiliateRegistrationAsyncEndpoint(affiliateInfo) {
    const [state, dispatch] = useStore();
    const getAffiliateStatus = getStateProp(state, 'user.getAffiliateStatus', () => {});

    // ============================================
    // METHODS
    // ============================================

    const signUpAffiliate = () => {
        registerAffiliate(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                ...affiliateInfo,
                marketUs: affiliateInfo.marketUs ? affiliateInfo.marketUs : 'none',
                program: 'Betfiliate',
            },
        },
        url: `${VERSION.V1}${REGISTER_AFFILIATE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getAffiliateStatus();
        }
    }, [dispatch, getAffiliateStatus, response]);

    const message = useError(error, registerAffiliateFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(
        response,
        getTranslation('Congratulations! You have succefully apply an affiliate'),
    );

    return [loading, signUpAffiliate];
}

export function useRequestBetfiliateOtpAsyncEndpoint() {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const handlePasscodeClaimRequest = () => {
        claimPasscode(dispatch);
        executeRequest();
    };
    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        url: `${VERSION.V1}${IDENTITY}${REQUEST_BETFILIATE_OTP}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            claimPasscodeSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, claimPasscodeFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('Passcode sent to your mobile'));

    return [loading, handlePasscodeClaimRequest];
}

export function useGetReferralAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetReferral = useCallback(() => {
        getReferral(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${REFERRAL}`,
    });

    useEffect(() => {
        if (userId) handleGetReferral();
    }, [handleGetReferral, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getReferralSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getReferralFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetPlaytechMemberInfoAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const userId = getStateProp(getCurrentUser(), 'id');

    // ============================================
    // METHODS
    // ============================================

    const getPlayTechMemberInfo = useCallback(() => {
        getPlaytechMemberInfo(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${PLAYTECH.MEMBER_INFO}`,
    });

    useEffect(() => {
        if (userId) getPlayTechMemberInfo();
    }, [getPlayTechMemberInfo, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getPlaytechMemberInfoSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getPlaytechMemberInfoFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}

export function useVerifyUserAccountAsyncEndpoint(userInfo) {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleVerifyUserAccount = () => {
        verifyAccount(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                id: userId,
                ...userInfo,
            },
        },
        trigger,
        url: `${VERSION.V1}${VERIFY_ACCOUNT}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            verifyAccountSuccess(dispatch, userInfo);
        }
    }, [dispatch, response, userInfo]);

    useToasterSuccessMessage(response, getTranslation('User account successfully verified!'));

    const errorMsg = useError(error, verifyAccountFailure);
    useToasterErrorMessage(error, errorMsg);

    return [loading, response, handleVerifyUserAccount];
}

export function useGetGeoLocationAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const geolocation = getStateProp(state, 'country.geolocation');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetGeoLocation = useCallback(() => {
        geoLocationGet(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${GEOLOCATION}`,
    });

    useEffect(() => {
        if (!geolocation) handleGetGeoLocation();
    }, [geolocation, handleGetGeoLocation]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            geoLocationGetSuccess(dispatch, { geolocation: response.data.data });
        }
    }, [dispatch, response]);

    const message = useError(error, geoLocationGetFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetGameAccessAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetGameAccess = useCallback(() => {
        getGameAccess(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && isUserLoggedIn(),
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${GAME_ACCESS}`,
    });

    useEffect(() => {
        if (isUserLoggedIn()) handleGetGameAccess();
    }, [handleGetGameAccess]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getGameAccessSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getGameAccessFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetAffiliateStatusAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetAffiliateStatus = useCallback(() => {
        getAffiliateStatus(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${AFFILIATE_STATUS}`,
    });

    useEffect(() => {
        if (userId) handleGetAffiliateStatus();
    }, [handleGetAffiliateStatus, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getAffiliateStatusSuccess(dispatch, {
                affiliateStatus: response.data.data,
                getAffiliateStatus: handleGetAffiliateStatus,
            });
        }
    }, [response, dispatch, handleGetAffiliateStatus]);

    const message = useError(error, getAffiliateStatusFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}

export function useUpdateAppLanguageAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const langCode = getStateProp(state, 'country.language');

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateAppLanguage = useCallback(() => {
        updateAppLanguage(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PATCH',
        trigger,
        url: `${VERSION.V1}${PLAYERS_APP_LANGUAGE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateAppLanguageSuccess(dispatch);
        }
    }, [response, dispatch]);

    useEffect(() => {
        if (langCode && isUserLoggedIn()) {
            handleUpdateAppLanguage();
        }
    }, [handleUpdateAppLanguage, langCode]);

    const message = useError(error, updateAppLanguageFailure);
    useToasterErrorMessage(error, message);

    return [handleUpdateAppLanguage];
}

export function useGetVipLevelDetailsAsyncEndpoint() {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetVipLevelDetails = useCallback(() => {
        getUserVipLevelDetails(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        trigger,
        url: `${VERSION.V1}${PLAYERS_VIP_DETAILS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getUserVipLevelDetailsSuccess(dispatch, {
                ...response.data.data,
            });
        }
    }, [response, dispatch]);

    useEffect(() => {
        if (isUserLoggedIn()) {
            handleGetVipLevelDetails();
        }
    }, [handleGetVipLevelDetails]);

    const message = useError(error, getUserVipLevelDetailsFailure);
    useToasterErrorMessage(error, message);

    return [handleGetVipLevelDetails];
}

export function useNavigateSocialPlatformEndpoint() {
    const dispatch = useDispatch();
    /*    const language = getStateProp(state, 'country.language', getCurrentLanguage());
    const userName = getStateProp(state, 'user.userName', '');
    const fullName = getStateProp(state, 'user.realName', '');
    const shortId = getStateProp(state, 'user.shortId', ''); */

    // ============================================
    // METHODS
    // ============================================

    const handleNavigateSocialPlatform = () => {
        getSocialPlatformAccess(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {},
        },
        url: `${VERSION.V1}${SOCIAL_PLATFORM_ACCESS}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getSocialPlatformAccessSuccess(dispatch);
            try {
                const WindowRedirect = window.open(response.data.data);
                if (
                    !WindowRedirect ||
                    WindowRedirect.closed ||
                    typeof WindowRedirect.closed == 'undefined'
                ) {
                }
            } catch (error) {}
        }
    }, [dispatch, response]);

    const message = useError(error, getSocialPlatformAccessFailure);

    useToasterErrorMessage(error, message);

    return [handleNavigateSocialPlatform];
}

export function useAddEWalletAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [eWallet, setEWallet] = useState({});
    // ============================================
    // METHODS
    // ============================================

    const handleAddEWallet = (eWallet) => {
        setEWallet(eWallet);
        addEWallet(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: { ...eWallet, isOperator: false, userId },
        },
        url: `${VERSION.V1}${EWALLET.EWALLET_ACC}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            addEWalletSuccess(dispatch);
        }
    }, [response, dispatch]);

    const message = useError(error, addEWalletFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('E-Wallet successfully added!'));

    return [loading, response, handleAddEWallet];
}

export function useDeleteEWalletAsyncEndpoint(id) {
    const dispatch = useDispatch();

    // ============================================
    // METHODS
    // ============================================

    const handleDeleteEWallet = () => {
        deleteEWallet(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'DELETE',
        url: `${VERSION.V1}${EWALLET.EWALLET_ACC}/${id}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            deleteEWalletSuccess(dispatch, id);
        }
    }, [response, dispatch, id]);

    const message = useError(error, deleteEWalletFailure);

    useToasterErrorMessage(error, message);
    useToasterSuccessMessage(response, getTranslation('E-Wallet successfully deleted!'));

    return [loading, response, handleDeleteEWallet];
}
