export const ACTIONS = {
    GET_LOGIN_BONUS: 'GET_LOGIN_BONUS',
    GET_LOGIN_BONUS_FAILURE: 'GET_LOGIN_BONUS_FAILURE:',
    GET_LOGIN_BONUS_SUCCESS: 'GET_LOGIN_BONUS_SUCCESS',
    PAGINATION: {
        PAGE_CHANGE: 'REWARDS_PAGE_CHANGE',
        PAGE_SIZE_CHANGE: 'REWARDS_PAGE_SIZE_CHANGE',
    },
    REWARDS_BANNER_CLEAR: 'REWARDS_BANNER_CLEAR',
    REWARDS_BANNER_GET: 'REWARDS_BANNER_GET',
    REWARDS_BANNER_GET_FAILURE: 'REWARDS_BANNER_GET_FAILURE',
    REWARDS_BANNER_GET_SUCCESS: 'REWARDS_BANNER_GET_SUCCESS',
    REWARDS_CLEAR: 'REWARDS_CLEAR',
    REWARDS_GET: 'REWARDS_GET',
    REWARDS_GET_CATEGORY: 'REWARDS_GET_CATEGORY',
    REWARDS_GET_CATEGORY_FAILURE: 'REWARDS_GET_CATEGORY_FAILURE',
    REWARDS_GET_CATEGORY_SUCCESS: 'REWARDS_GET_CATEGORY_SUCCESS',
    REWARDS_GET_DEPOSIT: 'REWARDS_GET_DEPOSIT',
    REWARDS_GET_DEPOSIT_FAILURE: 'REWARDS_GET_DEPOSIT_FAILURE',
    REWARDS_GET_DEPOSIT_SUCCESS: 'REWARDS_GET_DEPOSIT_SUCCESS',
    REWARDS_GET_DETAILS: 'REWARDS_GET_DETAILS',
    REWARDS_GET_DETAILS_FAILURE: 'REWARDS_GET_DETAILS_FAILURE',
    REWARDS_GET_DETAILS_SUCCESS: 'REWARDS_GET_DETAILS_SUCCESS',
    REWARDS_GET_FAILURE: 'REWARDS_GET_FAILURE',
    REWARDS_GET_SUCCESS: 'REWARDS_GET_SUCCESS',
    REWARDS_JOIN: 'REWARD_JOIN',
    REWARDS_JOIN_FAILURE: 'REWARD_JOIN_FAILURE',
    REWARDS_JOIN_SUCCESS: 'REWARD_JOIN_SUCCESS',
    REWARDS_MODAL_CLOSE: 'REWARDS_MODAL_CLOSE',
    REWARDS_MODAL_OPEN: 'REWARD_MODAL_OPEN',
    REWARDS_SET_BONUS_CODE: 'REWARDS_SET_BONUS_CODE',
    REWARDS_SET_DEPOSIT_TASK_NO: 'REWARDS_SET_DEPOSIT_TASK_NO',
    REWARDS_SET_SELECTED_CATEGORY: 'REWARDS_SET_SELECTED_CATEGORY',
    REWARDS_STATIC_BANNER_GET: 'REWARDS_STATIC_BANNER_GET',
    REWARDS_STATIC_BANNER_GET_FAILURE: 'REWARDS_STATIC_BANNER_GET_FAILURE',
    REWARDS_STATIC_BANNER_GET_SUCCESS: 'REWARDS_STATIC_BANNER_GET_SUCCESS',
    UPDATE_LOGIN_BONUS: 'UPDATE_LOGIN_BONUS',
    UPDATE_LOGIN_BONUS_FAILURE: 'UPDATE_LOGIN_BONUS_FAILURE:',
    UPDATE_LOGIN_BONUS_SUCCESS: 'UPDATE_LOGIN_BONUS_SUCCESS',
};

/**
 * Get rewards init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewards = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_GET,
    });

/**
 * Get rewards success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: Reward[], paging: Pagination}} payload Action payload
 */
export const getRewardsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_SUCCESS,
    });

/**
 * Get rewards failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_FAILURE,
    });

/**
 * Change page number action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page number
 */
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_CHANGE,
    });

/**
 * Change page size action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page size
 */
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_SIZE_CHANGE,
    });

/**
 * Get reward details init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewardDetails = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_GET_DETAILS,
    });

/**
 * Get reward details success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {RewardDetails} payload Action payload
 */
export const getRewardDetailsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_DETAILS_SUCCESS,
    });

/**
 * Get reward details failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardDetailsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_DETAILS_FAILURE,
    });

/**
 * Clear reward info action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearReward = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_CLEAR,
    });

/**
 * Join reward init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const joinReward = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_JOIN,
    });
/**
 * Join reward success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Reward id
 */
export const joinRewardSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_JOIN_SUCCESS,
    });

/**
 * Join reward failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const joinRewardFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_JOIN_FAILURE,
    });

/**
 * Open reward modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const openRewardModal = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_MODAL_OPEN,
    });

/**
 * Close reward modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const closeRewardModal = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_MODAL_CLOSE,
    });

/**
 * Get reward categories init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewardCategory = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_GET_CATEGORY,
    });

/**
 * Get reward categories success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {RewardCategory[]} payload Action payload
 */
export const getRewardCategorySuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_CATEGORY_SUCCESS,
    });

/**
 * Get reward categories failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardCategoryFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_CATEGORY_FAILURE,
    });

/**
 * Set reward category action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Reward category id
 */
export const setSelectedRewardCategory = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_SET_SELECTED_CATEGORY,
    });

/**
 * Set reward bonus code action
 * @param {Function} dispatch Action dispatcher from store
 * @param {BonusCode} payload Bonus code
 */
export const setBonusCode = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_SET_BONUS_CODE,
    });

/**
 * Set reward deposit task no action
 * @param {Function} dispatch Action dispatcher from store
 * @param {BonusCode} payload deposit task no
 */
export const setDepositTaskNo = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_SET_DEPOSIT_TASK_NO,
    });

/**
 * Get reward banners init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewardBanners = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_BANNER_GET,
    });

/**
 * Get reward banners success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: Reward[], paging: Pagination}} payload Action payload
 */
export const getRewardBannersSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_BANNER_GET_SUCCESS,
    });

/**
 * Get reward banners failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardBannersFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_BANNER_GET_FAILURE,
    });

/**
 * Clear reward banner info action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearRewardBanner = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_BANNER_CLEAR,
    });

/**
 * Get daily login init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getLoginBonus = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_LOGIN_BONUS,
    });

/**
 * Get daily login success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{dailyLoginInfo: DailyLoginPlayerInfo, handleGetDailyLogin: Function}} payload Action payload
 */
export const getLoginBonusSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_LOGIN_BONUS_SUCCESS,
    });

/**
 * Get daily login failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getLoginBonusFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_LOGIN_BONUS_FAILURE,
    });

/**
 * Update daily login init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateLoginBonus = (dispatch) =>
    dispatch({
        type: ACTIONS.UPDATE_LOGIN_BONUS,
    });

/**
 * update daily login success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateLoginBonusSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.UPDATE_LOGIN_BONUS_SUCCESS,
    });

/**
 * Update daily login failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const updateLoginBonusFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPDATE_LOGIN_BONUS_FAILURE,
    });

/**
 * Get reward deposit init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewardsDeposit = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_GET_DEPOSIT,
    });

/**
 * Get reward deposit success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {RewardCategory[]} payload Action payload
 */
export const getRewardsDepositSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_DEPOSIT_SUCCESS,
    });

/**
 * Get reward deposit failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardsDepositFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_GET_DEPOSIT_FAILURE,
    });

/**
 * Get reward static banner init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getRewardsStaticBanner = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_STATIC_BANNER_GET,
    });

/**
 * Get reward static banner success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {RewardCategory[]} payload Action payload
 */
export const getRewardsStaticBannerSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.REWARDS_STATIC_BANNER_GET_SUCCESS,
    });

/**
 * Get reward static banner failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getRewardsStaticBannerFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REWARDS_STATIC_BANNER_GET_FAILURE,
    });
