import { ACTIONS } from './walletActions';

export default function walletReducer(wallet, { type, payload = null }) {
    switch (type) {
        case ACTIONS.PAYMENT_DEPOSIT_REQUEST_SUCCESS:
        case ACTIONS.PAYMENT_GATEWAYS_GET_SUCCESS:
        case ACTIONS.PAYMENT_GATEWAY_CHANGE:
        case ACTIONS.PAYMENT_PROVIDER_CHANGE:
        case ACTIONS.CRYPTOCURRENCY_CHANGE:
        case ACTIONS.WEALTHPAYCHANNEL_CHANGE:
        case ACTIONS.CRYPTOCURRENCY_RATES_GET_SUCCESS:
        case ACTIONS.CRYPTOCURRENCY_LIST_GET_SUCCESS:
        case ACTIONS.CHECK_ELIGIBLE_DEPOSIT_SUCCESS:
        case ACTIONS.WALLETS_BALANCE_GET_SUCCESS:
        case ACTIONS.WALLET_SUMMARY_GET_SUCCESS:
        case ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_SUCCESS:
        case ACTIONS.TRANSACTION_AMOUNT_EWALLET_SETTING_GET_SUCCESS:
        case ACTIONS.PAYMENT_E_WALLET_DEPOSIT_REQUEST_SUCCESS:
            return {
                ...wallet,
                ...payload,
            };
        case ACTIONS.UPLOAD_RECEIPT_SUCCESS:
            return {
                ...wallet,
                fileUploaded: payload,
            };
        case ACTIONS.CLEAR_UPLOAD_FILE_CONTENT:
            return {
                ...wallet,
                fileUploaded: null,
            };
        case ACTIONS.GET_DEPOSIT_BANK_LIST_SUCCESS:
            return {
                ...wallet,
                depositBankList: payload,
            };
        case ACTIONS.GET_BANK_CARDS_SUCCESS:
            return {
                ...wallet,
                bankCards: payload,
            };
        case ACTIONS.GET_E_WALLET_LIST_SUCCESS:
            return {
                ...wallet,
                eWalletList: payload,
            };
        case ACTIONS.CLEAR_WALLET_INFO:
            return undefined;
        case ACTIONS.PAYMENT_DEPOSIT_REQUEST:
        case ACTIONS.CRYPTOCURRENCY_RATES_GET:
        case ACTIONS.PAYMENT_GATEWAYS_GET:
        case ACTIONS.PAYMENT_TRANSFER_REQUEST:
        case ACTIONS.PAYMENT_TRANSFER_REQUEST_SUCCESS:
        case ACTIONS.PAYMENT_WITHDRAWAL_REQUEST:
        case ACTIONS.PAYMENT_WITHDRAWAL_REQUEST_SUCCESS:
        case ACTIONS.E_WALLET_WITHDRAWAL_REQUEST:
        case ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_SUCCESS:
        case ACTIONS.GET_DEPOSIT_BANK_LIST:
        case ACTIONS.CRYPTOCURRENCY_LIST_GET:
        case ACTIONS.CHECK_ELIGIBLE_DEPOSIT:
        case ACTIONS.WALLETS_BALANCE_GET:
        case ACTIONS.WALLET_SUMMARY_GET:
        case ACTIONS.UPLOAD_RECEIPT:
        case ACTIONS.TRANSACTION_AMOUNT_SETTING_GET:
        case ACTIONS.TRANSACTION_AMOUNT_EWALLET_SETTING_GET:
        case ACTIONS.GET_BANK_CARDS:
        case ACTIONS.GET_E_WALLET_LIST:
        case ACTIONS.PAYMENT_E_WALLET_DEPOSIT_REQUEST:
        default: {
            return wallet;
        }
    }
}
