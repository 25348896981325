export const ACTIONS = {
    MESSAGE_GET: 'MESSAGE_GET',
    MESSAGE_GET_FAILURE: 'MESSAGE_GET_FAILURE',
    MESSAGE_GET_SUCCESS: 'MESSAGE_GET_SUCCESS',
    PAGINATION: {
        PAGE_CHANGE: 'MESSAGE_PAGE_CHANGE',
        PAGE_SIZE_CHANGE: 'MESSAGE_PAGE_SIZE_CHANGE',
    },
    READ_COUNTER_GET: 'READ_COUNTER_GET',
    READ_COUNTER_GET_FAILURE: 'READ_COUNTER_GET_FAILURE',
    READ_COUNTER_GET_SUCCESS: 'READ_COUNTER_GET_SUCCESS',
    READ_COUNTER_UPDATE: 'READ_COUNTER_UPDATE',
    READ_COUNTER_UPDATE_FAILURE: 'READ_COUNTER_UPDATE_FAILURE',
    READ_COUNTER_UPDATE_SUCCESS: 'READ_COUNTER_UPDATE_SUCCESS',
};

/**
 * Change page number action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page number
 */
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_CHANGE,
    });

/**
 * Change page size action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page size
 */
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_SIZE_CHANGE,
    });

/**
 * Get personal messages init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getMessageList = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_GET,
    });

/**
 * Get personal messages success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: Message[], paging: Pagination}} payload Action payload
 */
export const getMessageListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_GET_SUCCESS,
    });

/**
 * Get personal messages failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getMessageListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_GET_FAILURE,
    });

/**
 * Get personal messages read counter init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getReadCounter = (dispatch) =>
    dispatch({
        type: ACTIONS.READ_COUNTER_GET,
    });

/**
 * Get personal messages read counter success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number}  payload Read counter
 */
export const getReadCounterSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.READ_COUNTER_GET_SUCCESS,
    });

/**
 * Get personal messages failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getReadCounterFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.READ_COUNTER_GET_FAILURE,
    });

/**
 * Update personal messages read counter init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateReadCounter = (dispatch) =>
    dispatch({
        type: ACTIONS.READ_COUNTER_UPDATE,
    });

/**
 * Update personal messages read countersuccess action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Action payload
 */
export const updateReadCounterSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.READ_COUNTER_UPDATE_SUCCESS,
    });

/**
 * Update personal messages read counter failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const updateReadCounterFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.READ_COUNTER_UPDATE_FAILURE,
    });
