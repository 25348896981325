import { useHistory } from 'react-router-dom';
import React from 'react';

import { RightOutlined } from '@ant-design/icons';
import { Row } from 'antd';

import './SideBar.less';
import { ROUTES } from '../../../common/constants/routing';
import { getTranslation } from '../../../common/helpers/locale';
import { isUserLoggedIn } from '../../../common/helpers/api';

const SideBarLink = ({ link, onClose }) => {
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const navigateToPage = (route, isLoginRequired = false) => {
        if (isLoginRequired) {
            if (isUserLoggedIn()) history.push(route);
            else {
                history.push(ROUTES.LOGIN, { redirectTo: route });
            }
        } else history.push(route);
        onClose();
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row
            align="middle"
            justify="space-between"
            className="side-bar__item"
            onClick={() => navigateToPage(link.path, link.isAuthRequired)}
        >
            <span className="side-bar__item__title">{getTranslation(link.title)}</span>
            <RightOutlined className="side-bar__item__arrow" />
        </Row>
    );
};

export default SideBarLink;
