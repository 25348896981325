import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { message } from 'antd';

import { ROUTES } from '../../common/constants/routing';
import { clearWalletInfo } from '../../components/wallet/walletActions';
import { deleteStorageItem, getCurrentUser } from '../../common/helpers/api';
import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import { useDispatch } from '../../store/StateProvider';
import { userLogout } from '../../common/actions/userActions';

const useHandleMultiLogin = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const refreshToken = getStateProp(getCurrentUser(), 'refreshToken', null);
    const [newRefreshToken, setNewRefreshToken] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleSignalREvent = (event) => setNewRefreshToken(event.refreshToken);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (refreshToken && newRefreshToken && newRefreshToken !== refreshToken) {
            deleteStorageItem('user');
            userLogout(dispatch);
            clearWalletInfo(dispatch);
            history.push(ROUTES.LOGIN, { redirectTo: location.pathname });
            message.warning(getTranslation('You were logged in from another device'));
            setNewRefreshToken(null);
        }
    }, [dispatch, history, location.pathname, newRefreshToken, refreshToken]);

    return [handleSignalREvent];
};

export default useHandleMultiLogin;
