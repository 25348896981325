export const LANGUAGES = [
    {
        code: 'cn',
        name: '简体中文',
        value: 'zh-CN',
    },
    {
        code: 'us',
        name: 'English',
        value: 'en-US',
    },
    {
        code: 'id',
        name: 'Indonesian',
        value: 'id-ID',
    },
    {
        code: 'th',
        name: 'ภาษาไทย',
        value: 'tr-TR', // instead of 'th-TH' fix for backend thai language issue
    },
    {
        code: 'vn',
        name: 'Tiếng Việt',
        value: 'vi-VN',
    },
    // {
    //     code: 'tw',
    //     name: '繁體中文',
    //     value: 'zh-TW',
    // },
    // {
    //     code: 'jp',
    //     name: '日本語',
    //     value: 'ja-JP',
    // },
    // {
    //     code: 'kr',
    //     name: '한국어',
    //     value: 'ko-KR',
    // },
];

export const BASE_LANGUAGE = {
    code: 'us',
    name: 'English',
    value: 'en-US',
};

export const CURRENCY_SETTINGS = new Map([
    [
        'CNY',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'CNY',
            thousand: ',',
            withdrawalMax: 300000,
            withdrawalMin: 50,
        },
    ],
    [
        'JPY',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'JPY',
            thousand: ',',
            withdrawalMax: 4500000,
            withdrawalMin: 2000,
        },
    ],
    [
        'KRW',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'KRW',
            thousand: ',',
            withdrawalMax: 50000000,
            withdrawalMin: 20000,
        },
    ],
    [
        'MYR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'MYR',
            thousand: ',',
            withdrawalMax: 200000,
            withdrawalMin: 30,
        },
    ],
    [
        'THB',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'THB',
            thousand: ',',
            withdrawalMax: 1500000,
            withdrawalMin: 300,
        },
    ],
    [
        'IDR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'IDR',
            thousand: ',',
            withdrawalMax: 20000,
            withdrawalMin: 100,
        },
    ],
    [
        'VND',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'VND',
            thousand: ',',
            withdrawalMax: 30000,
            withdrawalMin: 300,
        },
    ],
    [
        'USD',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'USD',
            thousand: ',',
            withdrawalMax: 50000,
            withdrawalMin: 30,
        },
    ],
    [
        'EUR',
        {
            decimal: '.',
            format: '%s %v',
            precision: 2,
            symbol: 'EUR',
            thousand: ',',
            withdrawalMax: 50000,
            withdrawalMin: 20,
        },
    ],
]);

export const SUPPORTED_CURRENCIES = ['CNY', 'VND', 'IDR', 'THB', 'MYR'];

export const BET_LIST_TIMEZONE = {
    LOCATION: 'Brazil/West',
    OFFSET: -4,
};
