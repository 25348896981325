import { useCallback, useEffect } from 'react';

import _ from 'lodash';

import { BET_TYPE_KEYS, TF_GAMING_BET_TYPES } from './constants';
import { ES_PLATFORM } from '../../common/constants/misc';
import { getStateProp } from '../../common/helpers/misc';
import { updateBet } from './betsActions';
import { useStore } from '../../store/StateProvider';

export function useUpdateSelectedBetOdds(page, pageData, platform) {
    const [state, dispatch] = useStore();

    const selectedBet = getStateProp(state, 'bets.selectedBet');
    const selectedBetPrice = _.get(selectedBet, 'price');
    const selectedBetPoint = _.get(selectedBet, 'point');
    const selectedBetKey = _.get(selectedBet, 'key');
    const selectedBetId = _.get(selectedBet, 'id');
    const index = _.get(selectedBet, 'index', 0);
    const isTFGamingPlatform = platform === ES_PLATFORM.TF_GAMING;

    const updateBetOdds = useCallback(() => {
        const findUpdatedOddForSelectedBet =
            page === 'bets'
                ? () => {
                      const lastUpdatedBet = _.find(
                          _.flatMap(pageData, (group) => group.odds),
                          (odd) => odd.marketId === selectedBetId,
                      );

                      if (
                          _.includes(
                              [
                                  BET_TYPE_KEYS.HOME,
                                  BET_TYPE_KEYS.OVER,
                                  BET_TYPE_KEYS.YES,
                                  TF_GAMING_BET_TYPES.HOME,
                              ],
                              selectedBetKey,
                          )
                      )
                          return {
                              updatedPoint: isTFGamingPlatform
                                  ? lastUpdatedBet.selections[0].point
                                  : lastUpdatedBet.home[index].point,
                              updatedPrice: isTFGamingPlatform
                                  ? lastUpdatedBet.selections[0].price
                                  : lastUpdatedBet.home[index].price,
                          };
                      else if (
                          _.includes(
                              [
                                  BET_TYPE_KEYS.AWAY,
                                  BET_TYPE_KEYS.EVEN,
                                  BET_TYPE_KEYS.UNDER,
                                  BET_TYPE_KEYS.NO,
                                  TF_GAMING_BET_TYPES.AWAY,
                              ],
                              selectedBetKey,
                          )
                      )
                          return {
                              updatedPoint: isTFGamingPlatform
                                  ? lastUpdatedBet.selections[lastUpdatedBet.selections.length - 1]
                                        .point
                                  : lastUpdatedBet.away[index].point,
                              updatedPrice: isTFGamingPlatform
                                  ? lastUpdatedBet.selections[lastUpdatedBet.selections.length - 1]
                                        .price
                                  : lastUpdatedBet.away[index].price,
                          };
                      else
                          return {
                              updatedPoint: selectedBetPoint,
                              updatedPrice: selectedBetPrice,
                          };
                  }
                : () => {
                      const lastUpdatedEvent = _.find(
                          pageData,
                          (event) => event.moneyLineMarketId === selectedBetId,
                      );

                      if (lastUpdatedEvent) {
                          if (
                              selectedBetKey === BET_TYPE_KEYS.HOME ||
                              selectedBetKey === TF_GAMING_BET_TYPES.HOME
                          )
                              return {
                                  updatedPoint: 0,
                                  updatedPrice: lastUpdatedEvent.moneyLineHomeWinPrice,
                              };
                          else if (
                              selectedBetKey === BET_TYPE_KEYS.AWAY ||
                              selectedBetKey === TF_GAMING_BET_TYPES.AWAY
                          )
                              return {
                                  updatedPoint: 0,
                                  updatedPrice: lastUpdatedEvent.moneyLineAwayWinPrice,
                              };
                      } else
                          return {
                              updatedPoint: selectedBetPoint,
                              updatedPrice: selectedBetPrice,
                          };
                  };

        const { updatedPrice, updatedPoint } = findUpdatedOddForSelectedBet();
        if (selectedBetPrice !== updatedPrice || selectedBetPoint !== updatedPoint) {
            updateBet(dispatch, {
                change: {
                    event: +new Date(),
                    trend: selectedBetPrice < updatedPrice ? 'up' : 'down',
                },
                point: updatedPoint,
                price: updatedPrice,
            });
        }
    }, [
        page,
        selectedBetPrice,
        selectedBetPoint,
        pageData,
        selectedBetId,
        selectedBetKey,
        isTFGamingPlatform,
        index,
        dispatch,
    ]);

    useEffect(() => {
        if (pageData.length > 0) {
            updateBetOdds();
        }
    }, [dispatch, pageData, updateBetOdds]);
}
