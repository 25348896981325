export const NEWS_CARD_TYPES = {
    DETAILS: 'News details',
    LIST_ITEM: 'News list item',
};

export const NEWS_CATEGORIES = [
    {
        id: 1,
        name: 'Dota 2',
    },
    {
        id: 2,
        name: 'League of Legends',
    },
    {
        id: 3,
        name: 'CS:GO',
    },
    {
        id: 4,
        name: 'King of Glory',
    },
    {
        id: 5,
        name: 'Valorant',
    },
    {
        id: 6,
        name: 'StarCraft 2',
    },
    {
        id: 7,
        name: 'Call of Duty',
    },
    {
        id: 8,
        name: 'Arena of Valor',
    },
    {
        id: 9,
        name: 'Overwatch',
    },
    {
        id: 10,
        name: 'PUBG',
    },
    {
        id: 11,
        name: 'Rainbow Six',
    },
    {
        id: 12,
        name: 'Starcraft',
    },
    {
        id: 13,
        name: 'Street Fighter 5',
    },
    {
        id: 14,
        name: 'WarCraft 3',
    },
    {
        id: 15,
        name: 'Rocket League',
    },
    {
        id: 16,
        name: 'Hearthstone',
    },
    {
        id: 17,
        name: 'Artifact',
    },
    {
        id: 18,
        name: 'Gaming',
    },
    {
        id: 19,
        name: 'Mobile Legend',
    },
    {
        id: 20,
        name: 'FIFA',
    },
    {
        id: 21,
        name: 'NBA 2K18',
    },
    {
        id: 22,
        name: 'Heroes of the Storm',
    },
    {
        id: 23,
        name: 'Fortnite',
    },
    {
        id: 24,
        name: 'Game for Peace',
    },
    {
        id: 25,
        name: 'Quake',
    },
];

export const NEWS_PAGE_SIZE_DEFAULT = 5;
export const NEWS_PAGE_NO_DEFAULT = 1;

export const NEWS_ID_SALT = 'what is up doc';
