import { getStateProp } from './misc';
import _ from 'lodash';

export const getStorageItem = (name) => JSON.parse(window.localStorage.getItem(name));

export const setStorageItem = (name, value) =>
    window.localStorage.setItem(name, JSON.stringify(value));

export const deleteStorageItem = (name) => window.localStorage.removeItem(name);

//#region User
export const getCurrentUser = () => getStorageItem('user');

export const getCurrentUserInfo = () => getStateProp(getCurrentUser(), 'claims');

export const isUserTokenExpired = () => {
    const user = getCurrentUser();
    if (!user) return false;

    const expires = user.expires;
    const now = +new Date();

    return expires - now <= 10000;
};

export const getOrDeleteStorageItemByExpDate = (name) => {
    const localStorageInfo = getStorageItem(name);
    const now = new Date();
    const expires = localStorageInfo && new Date(localStorageInfo.expiration_date);
    if (expires && expires - now <= 0) {
        deleteStorageItem(name);
        return null;
    } else {
        return localStorageInfo;
    }
};

export const isUserLoggedIn = () => !!getStorageItem('user');

//#region Language
export const getCurrentLanguage = () => getStorageItem('lang');

export const isChineseLanguage = () => getCurrentLanguage() === 'zh-CN';

export const setLangStorageItem = (value) => setStorageItem('lang', value);

//#region Other
export const getResponseBodyProp = (response, propName, defaultValue) =>
    _.get(response, `data.data.${propName}`, defaultValue);

export const getOperationId = (res) => res.headers['x-operation'].replace('operations/', '');

export const getErrorStatus = (error) => getStateProp(error, 'response.status');

export const getErrorMessage = (error) => {
    const messageInResponseData = getStateProp(error, 'response.data.error.message', null);
    const messageInResponseStatusText = getStateProp(error, 'response.statusText', null);
    return messageInResponseData
        ? messageInResponseData
        : messageInResponseStatusText
        ? messageInResponseStatusText
        : getStateProp(error, 'message');
};

export const isListNotEmpty = (apiResponse, stateValue, path = 'data.data') =>
    !_.isEmpty(getStateProp(apiResponse, path, [])) || !_.isEmpty(stateValue);

export const getPushyToken = () => {
    const ggbookUserAgentIndex = window.navigator.userAgent.indexOf('GGbookWebViewToken/');
    if (ggbookUserAgentIndex > 0) {
        const tokenIndex = ggbookUserAgentIndex + 'GGbookWebViewToken/'.length;
        const webviewTokenString = window.navigator.userAgent.substring(tokenIndex);
        const tokenString = webviewTokenString.substring(0, webviewTokenString.indexOf('/'));
        return tokenString.length > 20 ? tokenString : '';
    } else {
        return '';
    }
};
