import { useCallback, useEffect, useState } from 'react';

import { API } from '../common/constants/api';
import {
    NOTIFICATIONS_PAGE_NO_DEFAULT,
    NOTIFICATIONS_PAGE_SIZE_DEFAULT,
} from '../components/notifications/constants';
import { clearError } from '../common/actions/errorActions';
import {
    getAnnouncementList,
    getAnnouncementListFailure,
    getAnnouncementListSuccess,
} from '../components/notifications/announcements/announcementsActions';
import { getCurrentUser, isListNotEmpty } from '../common/helpers/api';
import {
    getIsEmptySelectedMessage,
    getPersonalMessageItemIdbyId,
} from '../components/notifications/messages/helpers';
import {
    getMessageList,
    getMessageListFailure,
    getMessageListSuccess,
    getReadCounter,
    getReadCounterFailure,
    getReadCounterSuccess,
    updateReadCounter,
    updateReadCounterFailure,
    updateReadCounterSuccess,
} from '../components/notifications/messages/messagesActions';
import { getStateProp } from '../common/helpers/misc';
import { getUserCurrency } from '../common/helpers/currency';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, NOTIFICATIONS } = API;

export function useGetAnnouncementsAsyncEndpoint(announcementType) {
    const [state, dispatch] = useStore();

    const [trigger, setTrigger] = useState(null);
    const langCode = getStateProp(state, 'country.language');
    const { pageNo, pageSize } = announcementType
        ? getStateProp(state, 'notifications.announcements.paging')
        : {
              pageNo: NOTIFICATIONS_PAGE_NO_DEFAULT,
              pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
          };
    const announcementList = getStateProp(state, 'notifications.announcements.list', []);
    const currency = getUserCurrency(state);

    // ============================================
    // METHODS
    // ============================================

    const handleGetAnnouncement = useCallback(() => {
        getAnnouncementList(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: {
                announcementType,
                currency,
                pageNo,
                pageSize,
            },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATIONS.ANNOUNCEMENTS}/${langCode}`,
    });

    useEffect(() => {
        if (currency) handleGetAnnouncement();
    }, [dispatch, announcementType, pageNo, pageSize, handleGetAnnouncement, langCode, currency]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getAnnouncementListSuccess(dispatch, response.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getAnnouncementListFailure);
    useToasterErrorMessage(error, message);

    const isNotEmpty = isListNotEmpty(response, announcementList);

    return [loading, handleGetAnnouncement, isNotEmpty];
}

export function useGetMessagesAsyncEndpoint() {
    const [state, dispatch] = useStore();

    const [trigger, setTrigger] = useState(null);
    const { pageNo, pageSize } = getStateProp(state, 'notifications.messages.paging');
    const messageList = getStateProp(state, 'notifications.messages.list', []);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, response, error } = useApi({
        method: 'GET',
        options: {
            params: { pageNo, pageSize },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATIONS.MESSAGES}`,
    });

    useEffect(() => {
        getMessageList(dispatch);
        setTrigger(+new Date());
    }, [dispatch, pageNo, pageSize]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getMessageListSuccess(dispatch, response.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getMessageListFailure);
    useToasterErrorMessage(error, message);

    const isNotEmpty = isListNotEmpty(response, messageList);

    return [loading, isNotEmpty];
}

export function useGetReadCounterAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetReadCounter = useCallback(() => {
        getReadCounter(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${NOTIFICATIONS.READ_COUNTER}`,
    });

    useEffect(() => {
        if (userId) handleGetReadCounter();
    }, [handleGetReadCounter, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getReadCounterSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getReadCounterFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}

export function useUpdateReadCounterAsyncEndpoint(id) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const selectedMessage = getPersonalMessageItemIdbyId(state, id);
    const isEmpty = getIsEmptySelectedMessage(selectedMessage);
    const isRead = !isEmpty && selectedMessage.isRead;
    const personalMessageItemId = !isEmpty && selectedMessage.personalMessageItemId;

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateReadCounter = useCallback(() => {
        updateReadCounter(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !isRead && !isEmpty,
        method: 'PATCH',
        options: {
            data: {
                personalMessageItemId: personalMessageItemId,
            },
        },
        trigger,
        url: `${VERSION.V1}${NOTIFICATIONS.READ_COUNTER}`,
    });

    useEffect(() => {
        if (!isRead && !isEmpty) handleUpdateReadCounter();
    }, [handleUpdateReadCounter, isRead, isEmpty]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateReadCounterSuccess(dispatch, id);
        }
    }, [dispatch, response, id]);

    const message = useError(error, updateReadCounterFailure);
    useToasterErrorMessage(error, message);

    return [loading];
}
