export const ACTIONS = {
    ANNOUNNCEMENT_FILTER_SET: 'ANNOUNNCEMENT_FILTER_SET',
    ANNOUNNCEMENT_GET: 'ANNOUNNCEMENT_GET',
    ANNOUNNCEMENT_GET_FAILURE: 'ANNOUNNCEMENT_GET_FAILURE',
    ANNOUNNCEMENT_GET_SUCCESS: 'ANNOUNNCEMENT_GET_SUCCESS',
    PAGINATION: {
        PAGE_CHANGE: 'ANNOUNNCEMENT_PAGE_CHANGE',
        PAGE_SIZE_CHANGE: 'ANNOUNNCEMENT_PAGE_SIZE_CHANGE',
    },
};

/**
 * Get announcements init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getAnnouncementList = (dispatch) =>
    dispatch({
        type: ACTIONS.ANNOUNNCEMENT_GET,
    });

/**
 * Get announcements success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: Announcement[], paging: Pagination}} payload Action payload
 */
export const getAnnouncementListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNNCEMENT_GET_SUCCESS,
    });

/**
 * Get announcements failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getAnnouncementListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNNCEMENT_GET_FAILURE,
    });

/**
 * Change page number action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page number
 */
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_CHANGE,
    });

/**
 * Change page size action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page size
 */
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_SIZE_CHANGE,
    });

export const announcementFilterSet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ANNOUNNCEMENT_FILTER_SET,
    });
