import _ from 'lodash';

import { ACTIONS } from '../actions/userActions';
import { ODD_TYPES } from '../../components/bets/constants';
import { getCurrentUserInfo, setStorageItem } from '../helpers/api';

export default function userReducer(
    user = {
        ...getCurrentUserInfo(),
        oddsType: ODD_TYPES.DECIMAL,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.USER_LOGIN_SUCCESS:
        case ACTIONS.USER_REG_CODE_REQUEST:
        case ACTIONS.USER_DETAILS_SET:
        case ACTIONS.USER_DETAILS_GET_SUCCESS:
        case ACTIONS.USER_DETAILS_UPDATE_SUCCESS:
        case ACTIONS.BET_SETTINGS_UPDATE_SUCCESS:
        case ACTIONS.USER_VERIFY_SUCCESS:
        case ACTIONS.AFFILIATE_STATUS_SUCCESS:
            return {
                ...user,
                ...payload,
            };
        case ACTIONS.REFERRAL_GET_SUCCESS:
            return {
                ...user,
                referralInfo: payload,
            };
        case ACTIONS.USER_LOGOUT:
            return undefined;
        case ACTIONS.LOGIN_PROMPT_MODAL_OPEN:
            return {
                ...user,
                showLoginPrompt: true,
            };
        case ACTIONS.LOGIN_PROMPT_MODAL_CLOSE:
            return {
                ...user,
                showLoginPrompt: false,
            };
        case ACTIONS.USER_ONBOARDING_MODAL_OPEN:
            return {
                ...user,
                showOnboardingModal: true,
            };
        case ACTIONS.USER_ONBOARDING_MODAL_CLOSE:
            return {
                ...user,
                showOnboardingModal: false,
            };
        case ACTIONS.USER_ONBOARDING_MODAL_VISIBILITY_TRIGGER:
            setStorageItem('hideWelcomeScreen', payload.visible);
            setStorageItem('appVersion', payload.version);
            return user;
        case ACTIONS.FIN_PIN_MODAL_OPEN:
            return {
                ...user,
                finPinModalVisible: true,
            };
        case ACTIONS.FIN_PIN_MODAL_CLOSE:
            return {
                ...user,
                finPinModalVisible: false,
            };
        case ACTIONS.E_WALLET_DELETE_SUCCESS:
            return {
                ...user,
                eWalletAccounts: user.eWalletAccounts.filter((e) => e.id !== payload),
            };
        case ACTIONS.USER_BANK_CARD_DELETE_SUCCESS:
            return {
                ...user,
                bankCards: user.bankCards.filter((card) => card.id !== payload),
            };
        case ACTIONS.SET_ODDS_TYPE: {
            return {
                ...user,
                oddsType: payload,
            };
        }
        case ACTIONS.USER_CLEAR_INFO: {
            let newUserInfo = _.pickBy(user, function(value, key) {
                return key === 'oddsType';
            });
            return {
                ...newUserInfo,
            };
        }
        case ACTIONS.PLAYTECH_MEMBER_INFO_GET_SUCCESS: {
            return {
                ...user,
                playtechUserInfo: payload,
            };
        }
        case ACTIONS.USER_MAINTENANCE_MODAL_VISIBLE:
            return {
                ...user,
                maintenanceModalVisible: false,
            };
        case ACTIONS.USER_CLOSE_DOWNLOAD_ALERT:
            return {
                ...user,
                isDownloadAlertClose: true,
            };
        case ACTIONS.GAME_ACCESS_GET_SUCCESS:
            return {
                ...user,
                gameAccess: payload,
            };
        case ACTIONS.USER_VIP_LEVEL_DETAILS_GET_SUCCESS:
            return {
                ...user,
                vipDetails: payload,
            };
        case ACTIONS.CRASH_WALLET_SETUP_GET_SUCCESS:
            return {
                ...user,
                isDireGamingWallet: payload,
            };
        case ACTIONS.GET_SLOT88_GAMES_LIST_SUCCESS:
            return { ...user, slot88: payload };
        case ACTIONS.USER_VIP_LEVEL_DETAILS_GET:
        case ACTIONS.AFFILIATE_STATUS:
        case ACTIONS.GAME_ACCESS_GET:
        case ACTIONS.PLAYTECH_MEMBER_INFO_GET:
        case ACTIONS.BET_SETTINGS_UPDATE:
        case ACTIONS.E_WALLET_ADD:
        case ACTIONS.E_WALLET_ADD_SUCCESS:
        case ACTIONS.USER_BANK_CARD_ADD:
        case ACTIONS.USER_BANK_CARD_ADD_SUCCESS:
        case ACTIONS.USER_BANK_CARD_DELETE:
        case ACTIONS.USER_LOGIN:
        case ACTIONS.USER_DETAILS_GET:
        case ACTIONS.USER_DETAILS_UPDATE:
        case ACTIONS.USER_REG_CODE_REQUEST_SUCCESS:
        case ACTIONS.USER_REGISTER:
        case ACTIONS.USER_REGISTER_SUCCESS:
        case ACTIONS.USER_CLAIM_PASS_CODE:
        case ACTIONS.USER_CLAIM_PASS_CODE_SUCCESS:
        case ACTIONS.USER_CHANGE_FIN_PIN:
        case ACTIONS.USER_CHANGE_FIN_PIN_SUCCESS:
        case ACTIONS.USER_CHANGE_PASSWORD:
        case ACTIONS.USER_CHANGE_PASSWORD_SUCCESS:
        case ACTIONS.USER_RESET_PASSWORD:
        case ACTIONS.USER_RESET_PASSWORD_SUCCESS:
        case ACTIONS.USER_VERIFY_FIN_PIN:
        case ACTIONS.USER_VERIFY_FIN_PIN_SUCCESS:
        case ACTIONS.USER_VERIFY:
        case ACTIONS.SABA_REDIRECT_INIT:
        case ACTIONS.RNG_REDIRECT_INIT:
        case ACTIONS.APP_LANGUAGE_UPDATE:
        case ACTIONS.APP_LANGUAGE_UPDATE_SUCCESS:
        case ACTIONS.GET_SLOT88_GAMES_LIST:
        default:
            return user;
    }
}
