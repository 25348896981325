export const ACTIONS = {
    CURRENT_COUNTRY_SET: 'CURRENT_COUNTRY_SET',
    CURRENT_LANGUAGE_SET: 'CURRENT_LANGUAGE_SET',
    GEOLOCATION_GET: 'GEOLOCATION_GET',
    GEOLOCATION_GET_FAILURE: 'GEOLOCATION_GET_FAILURE',
    GEOLOCATION_GET_SUCCESS: 'GEOLOCATION_GET_SUCCESS',
};

/**
 * Set current country action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Country code
 */
export const setCurrentCountry = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENT_COUNTRY_SET,
    });

/**
 * Set current language action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Language code
 */
export const setCurrentLanguage = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CURRENT_LANGUAGE_SET,
    });

export const geoLocationGet = (dispatch) =>
    dispatch({
        type: ACTIONS.GEOLOCATION_GET,
    });

export const geoLocationGetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GEOLOCATION_GET_SUCCESS,
    });

export const geoLocationGetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GEOLOCATION_GET_FAILURE,
    });
