export const ACTIONS = {
    GAME_RESULTS_APPLY_FILTER: 'GAME_RESULTS_APPLY_FILTER',
    GAME_RESULTS_CLEAR: 'GAME_RESULTS_CLEAR',
    GAME_RESULTS_DROP_FILTER: 'GAME_RESULTS_DROP_FILTER',
    GAME_RESULTS_GET: 'GAME_RESULTS_GET',
    GAME_RESULTS_GET_FAILURE: 'GAME_RESULTS_GET_FAILURE',
    GAME_RESULTS_GET_SUCCESS: 'GAME_RESULTS_GET_SUCCESS',
    GAME_RESULTS_PERIOD_CHANGE: 'GAME_RESULTS_PERIOD_CHANGE',
};

/**
 * Get game results init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getResults = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_RESULTS_GET,
    });

/**
 * Get game results success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{totalEventsByMarketType: {early: Number, today: Number, live: Number}, results: GameResult[]}} payload Action payload
 */
export const getResultsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_RESULTS_GET_SUCCESS,
    });

/**
 * Get game results failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getResultsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_RESULTS_GET_FAILURE,
    });

/**
 * Game results filter action (backend filter by game name)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Game name
 */
export const applyResultsFilter = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_RESULTS_APPLY_FILTER,
    });

/**
 * Drop game results filter action
 * @param {Function} dispatch Action dispatcher from store
 */
export const dropResultsFilter = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_RESULTS_DROP_FILTER,
    });

/**
 * Game results filter action (backend filter by game date)
 * @param {Function} dispatch Action dispatcher from store
 * @param {{dateFrom: String, dateTo: String}} payload Action payload
 */
export const changeResultsPeriod = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_RESULTS_PERIOD_CHANGE,
    });

/**
 * Clear game results action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearResults = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_RESULTS_CLEAR,
    });
