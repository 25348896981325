/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import * as defaultAxios from 'axios';

import { ACTIONS, initialResponse, responseReducer } from './apiReducer';
import { DEFAULT_TIMEOUT } from '../../common/constants/api';
import { ERROR_REDIRECT_STATUSES } from '../../common/constants/misc';
import { ROUTES } from '../../common/constants/routing';
import {
    getCurrentUser,
    getPushyToken,
    getStorageItem,
    isUserLoggedIn,
} from '../../common/helpers/api';
import { isNullOrEmptyString } from '../../common/helpers/misc';
import { refreshTokenInterceptor } from './interceptors';

/**
 * Params
 * @param  {AxiosInstance} [axios] - (optional) The custom axios instance
 * @param  {String} url - The request URL
 * @param  {('GET'|'POST'|'PUT'|'DELETE'|'HEAD'|'OPTIONS'|'PATCH')} method - The request method
 * @param  {Object} [options] - (optional) The config options of Axios.js (https://goo.gl/UPLqaK)
 * @param  {Object|String} trigger - (optional) The conditions for AUTO RUN, refer the concepts of [conditions](https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect) of useEffect, but ONLY support string and plain object. If the value is a constant, it'll trigger ONLY once at the begining
 * @param  {Function} [forceDispatchEffect] - (optional) Trigger filter function, only AUTO RUN when get `true`, leave it unset unless you don't want AUTO RUN by all updates of trigger
 * @param  {Function} [customHandler] - (optional) Custom handler callback, NOTE: `error` and `response` will be set to `null` before request
 */

/**
 * Returns
 * @param  {Object} response - The response of Axios.js (https://goo.gl/dJ6QcV)
 * @param  {Object} error - HTTP error
 * @param  {Boolean} loading - The loading status
 * @param  {Function} executeRequest - MANUAL RUN trigger function for making a request manually
 */

const { CancelToken } = defaultAxios;

const useAPi = ({
    axios = defaultAxios,
    url,
    method = 'GET',
    options = {},
    trigger,
    forceDispatchEffect,
    customHandler,
    isProtected = false,
    timeout = DEFAULT_TIMEOUT,
} = {}) => {
    const [results, dispatch] = useReducer(responseReducer, initialResponse);
    const [innerTrigger, setInnerTrigger] = useState(0);
    const history = useHistory();
    const location = useLocation();
    const outerTrigger = trigger;
    const dispatchEffect = forceDispatchEffect || (() => true);

    const composeHeaders = () => {
        const headers = {
            'Accept-Language': getStorageItem('lang') || 'en-US',
            'Content-Encoding': 'gzip',
            'Content-Type': 'application/json',
            'Pushy-Device-Token': getPushyToken(),
        };
        if (isUserLoggedIn())
            return {
                ...headers,
                Authorization: `Bearer ${getCurrentUser().accessToken}`,
            };
        else if (isProtected) {
            return {
                ...headers,
                Authorization: `Bearer ${process.env.REACT_APP_SECRET_KEY}`,
            };
        } else return headers;
    };

    axios.default.interceptors.request.use(refreshTokenInterceptor);

    useEffect(() => {
        const handler = (error, response) => {
            if (customHandler) {
                customHandler(error, response);
            }
        };

        if (!url || !dispatchEffect()) return;

        if (typeof outerTrigger === 'undefined' && !innerTrigger) return;

        handler(null, null);
        dispatch({
            type: ACTIONS.INIT,
        });

        const source = CancelToken.source();

        const axiosApiCall = () =>
            axios({
                cancelToken: source.token,
                method,
                ...{
                    ...options,
                    headers: composeHeaders(),
                },
                timeout,
                url,
            })
                .then((response) => {
                    if (response.status === 204 && isNullOrEmptyString(response.data)) {
                        handler({ message: 'Something went wrong!' }, null);
                        dispatch({
                            payload: { message: 'Something went wrong!' },
                            type: ACTIONS.FAILURE,
                        });
                    } else {
                        handler(null, response);
                        dispatch({
                            payload: response,
                            type: ACTIONS.SUCCESS,
                        });
                    }
                })
                .catch((error) => {
                    if (error.message === `timeout of ${timeout}ms exceeded`) {
                        axiosApiCall();
                    } else if (error.response) {
                        if (error.response.status === 451) {
                            history.replace({
                                pathname: ROUTES.ERROR,
                                state: {
                                    country: error.response.data.Data.CountryName,
                                    ipAddress: error.response.data.Data.IpAddress,
                                    status: error.response.status,
                                },
                            });
                        } else if (error.response.status === 401) {
                            history.push(ROUTES.LOGIN, { redirectTo: location.pathname });
                        } else if (ERROR_REDIRECT_STATUSES.indexOf(error.response.status) !== -1) {
                            history.push({
                                pathname: ROUTES.ERROR,
                                state: {
                                    status: error.response.status,
                                },
                            });
                        } else {
                            handler(error, null);
                            if (!defaultAxios.isCancel(error)) {
                                dispatch({
                                    payload: error,
                                    type: ACTIONS.FAILURE,
                                });
                            }
                        }
                    }
                });

        axiosApiCall();

        return () => {
            source.cancel();
        };
    }, [innerTrigger, outerTrigger]);

    return {
        ...results,
        executeRequest: () => {
            setInnerTrigger(+new Date());
        },
    };
};

export const axios = defaultAxios;

export default useAPi;
