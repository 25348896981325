import React from 'react';

import { Button } from 'antd';
import classNames from 'classnames';

import './Buttons.less';

const ButtonBlue = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__blue', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonGray = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__gray', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

const ButtonGreen = ({ label, className, children, ...rest }) => {
    return (
        <Button className={classNames('btn__green', className)} {...rest}>
            {label}
            {children}
        </Button>
    );
};

export { ButtonBlue, ButtonGray, ButtonGreen };
