import React, { useEffect, useLayoutEffect, useState } from 'react';

import { Modal as AntModal } from 'antd';

const Modal = ({ visible, onCancel, maskClosable, children, maskListener = true, ...rest }) => {
    const [mask, setMask] = useState(null);

    useLayoutEffect(() => {
        if (visible) {
            setMask(document.getElementsByClassName('ant-modal-wrap')[0]);
        }
    }, [visible, mask]);

    useEffect(() => {
        if (mask && maskClosable !== false) {
            mask.addEventListener('click', (e) => {
                if (
                    maskListener &&
                    e.target.className &&
                    typeof e.target.className === 'string' &&
                    e.target.className.indexOf('ant-modal-wrap') !== -1
                ) {
                    onCancel();
                }
            });
        }
    }, [mask, maskClosable, maskListener, onCancel]);

    return (
        <AntModal visible={visible} onCancel={onCancel} {...rest}>
            {children}
        </AntModal>
    );
};

export default Modal;
