import { ACTIONS } from './newsActions';
import { NEWS_PAGE_NO_DEFAULT, NEWS_PAGE_SIZE_DEFAULT } from './constants';

import _ from 'lodash';

export default function newsReducer(
    news = {
        isPageChanging: false,
        list: [],
        paging: {
            pageNo: NEWS_PAGE_NO_DEFAULT,
            pageSize: NEWS_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.NEWS_GET_SUCCESS:
            return {
                ...news,
                isPageChanging: false,
                list: _.unionBy(news.list, payload.data, 'id'),
                paging: {
                    ...news.paging,
                    ...payload.paging,
                },
            };
        case ACTIONS.NEWS_DETAILS_GET_SUCCESS:
            const { newsDetails } = payload;
            return {
                ...news,
                newsDetails,
            };

        case ACTIONS.NEWS_APPLY_GAME_FILTER:
            return {
                ...news,
                list: [],
                newsCategoryId: payload,
                paging: {
                    pageNo: NEWS_PAGE_NO_DEFAULT,
                    pageSize: NEWS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
            };
        case ACTIONS.NEWS_DROP_GAME_FILTER:
            return {
                ...news,
                list: [],
                newsCategoryId: null,
                paging: {
                    pageNo: NEWS_PAGE_NO_DEFAULT,
                    pageSize: NEWS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
            };

        case ACTIONS.PAGINATION.PAGE_CHANGE:
            return {
                ...news,
                isPageChanging: true,
                paging: {
                    ...news.paging,
                    pageNo: payload > news.paging.pageCount ? news.paging.pageCount : payload,
                },
            };
        case ACTIONS.PAGINATION.PAGE_SIZE_CHANGE:
            return {
                ...news,
                paging: {
                    ...news.paging,
                    pageNo: 1,
                    pageSize: payload < 1 ? NEWS_PAGE_SIZE_DEFAULT : payload,
                },
            };

        case ACTIONS.NEWS_GET:
        case ACTIONS.NEWS_DETAILS_GET:
        default:
            return news;
    }
}
