import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Col, ConfigProvider, DatePicker, Row } from 'antd';
import classNames from 'classnames';
import moment from 'moment';

import './SingleDatePicker.less';
import { ButtonBlue } from './Buttons';
import { getAntDLocaleByCountry, getTranslation } from '../helpers/locale';
import { getCurrentLanguage } from '../helpers/api';

const TODAY = moment()
    .startOf('day')
    .format();

export const QUICK_DATES = [
    {
        key: 'Today',
        value: {
            dateFrom: moment()
                .startOf('day')
                .format(),
            dateTo: moment()
                .endOf('day')
                .format(),
        },
    },
    {
        key: 'Yesterday',
        value: {
            dateFrom: moment()
                .subtract(1, 'd')
                .startOf('day')
                .format(),
            dateTo: moment()
                .subtract(1, 'd')
                .endOf('day')
                .format(),
        },
    },
];

const SingleDatePicker = ({ date, onPeriodChange, className, antPickerProps, withQuickDates }) => {
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const selectDateFrom = (m) => onPeriodChange({ dateFrom: m.format(), dateTo: m.format() });

    const quickDateSelect = (period) => {
        onPeriodChange(period);
        setIsDatePickerOpen(false);
    };

    // ============================================
    // RENDER
    // ============================================

    const QuickDateSelectionButton = () => (
        <Row align="middle" justify="space-around">
            {QUICK_DATES.map((quickDate) => (
                <ButtonBlue
                    key={quickDate.key}
                    label={getTranslation(quickDate.key)}
                    onClick={() => quickDateSelect(quickDate.value)}
                    className="date-picker__btn-quick"
                />
            ))}
        </Row>
    );

    return (
        <Col
            align="middle"
            justify="space-around"
            className={classNames(['date-picker', className])}
        >
            <ConfigProvider locale={getAntDLocaleByCountry(getCurrentLanguage())}>
                <DatePicker
                    {...antPickerProps}
                    onChange={selectDateFrom}
                    onOpenChange={(open) => setIsDatePickerOpen(open)}
                    open={isDatePickerOpen}
                    value={date && moment(date)}
                    showToday={false}
                    renderExtraFooter={withQuickDates ? QuickDateSelectionButton : null}
                />
            </ConfigProvider>
        </Col>
    );
};

SingleDatePicker.defaultProps = {
    antPickerProps: {
        allowClear: false,
        format: 'DD/MM/YYYY',
    },
    date: TODAY,
    withQuickDates: true,
};

SingleDatePicker.propTypes = {
    antPickerProps: PropTypes.object, //refer to https://ant.design/components/date-picker
    date: PropTypes.string.isRequired,
    onPeriodChange: PropTypes.func.isRequired,
    withQuickDates: PropTypes.bool,
};

export default SingleDatePicker;
