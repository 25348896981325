import { ACTIONS } from './bankActions';

export default function bankReducer(bank, { type, payload = null }) {
    switch (type) {
        case ACTIONS.AREAS_GET_SUCCESS:
        case ACTIONS.CITIES_GET_SUCCESS:
        case ACTIONS.PROVINCES_GET_SUCCESS:
            return {
                ...bank,
                ...payload,
            };
        case ACTIONS.AREAS_GET:
        case ACTIONS.CITIES_GET:
        case ACTIONS.PROVINCES_GET:
        default:
            return bank;
    }
}
