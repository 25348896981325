import { ACTIONS } from '../actions/operationsActions';

export default function operationsReducer(operation, { type, payload = null }) {
    switch (type) {
        case ACTIONS.OPERATIONS_SIGNUP:
            return {
                ...operation,
                signUp: payload,
            };
        case ACTIONS.OPERATIONS_CLAIM_PASSCODE:
            return {
                ...operation,
                claimPasscode: payload,
            };
        case ACTIONS.OPERATIONS_REG_CODE_REQUEST:
            return {
                ...operation,
                claimVerificationCode: payload,
            };
        case ACTIONS.OPERATIONS_CLEAR:
            return null;
        default:
            return operation;
    }
}
