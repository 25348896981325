import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Layout as AntLayout, BackTop, Col, message } from 'antd';
import IdleTimer from 'react-idle-timer';
import classNames from 'classnames';

import './Layout.less';
import { ICONS } from '../../../common/constants/icons';
import { IDLE_TIMEOUT } from '../../../common/constants/misc';
import { ROUTES } from '../../../common/constants/routing';
import { clearWalletInfo } from '../../wallet/walletActions';
import { deleteStorageItem, isUserLoggedIn } from '../../../common/helpers/api';
import { detectIphonePWA, getClassNameForPWA } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useDispatch } from '../../../store/StateProvider';
import { userLogout } from '../../../common/actions/userActions';
import ConfirmModal from '../../../common/components/confirm-modal/ConfirmModal';
import HeaderWithBackButton from './HeaderWithBackButton';
import NavBar from './NavBar';
import SideBar from './SideBar';
import UserNavBar from './UserNavBar';

const { Content, Footer } = AntLayout;

const Layout = ({
    backgroundClassName,
    backToHeader = null,
    customHeader: CustomHeader = null,
    customHeaderProps,
    customFooter: CustomFooter = null,
    customFooterProps,
    children,
    defaultFooter = true,
    overflowYAuto = true,
}) => {
    const dispatch = useDispatch();

    const history = useHistory();
    const location = useLocation();

    const [startTimer, setStartTimer] = useState(false);
    const idleTimer = useRef(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [timedOut, setIsTimedOut] = useState(false);
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const showDrawer = () => setDrawerVisible(!drawerVisible);

    const onClose = () => setDrawerVisible(false);

    const onAction = () => setIsTimedOut(false);

    const onActive = () => setIsTimedOut(false);

    const onIdle = () => {
        if (isUserLoggedIn() && timedOut) {
            setTimeLeft(IDLE_TIMEOUT.MODAL_LOGOUT_TIMER);
            setLogoutModalVisible(true);
            setStartTimer(true);
            setIsTimedOut(false);
        } else {
            setIsTimedOut(true);
            idleTimer.current.reset();
        }
    };

    const logout = useCallback(() => {
        setLogoutModalVisible(false);
        setStartTimer(false);
        deleteStorageItem('user');
        userLogout(dispatch);
        clearWalletInfo(dispatch);
        history.push(ROUTES.LOGIN, { redirectTo: location.pathname });
        message.warning(getTranslation("You've been logged out"));
    }, [dispatch, history, location.pathname]);

    const closeLogoutModal = () => {
        setStartTimer(false);
        setLogoutModalVisible(false);
    };

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (!timeLeft && startTimer) {
            logout();
        }
        if (startTimer) {
            const intervalId = setInterval(() => {
                setTimeLeft(timeLeft - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        }
    }, [logout, startTimer, timeLeft]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <AntLayout className={defaultFooter ? 'layout' : 'layout__no-footer'}>
            <IdleTimer
                ref={idleTimer}
                element={document}
                onIdle={onIdle}
                onAction={onAction}
                onActive={onActive}
                debounce={250}
                timeout={IDLE_TIMEOUT.IDLE_TIMER}
            />
            <SideBar visible={drawerVisible} onClose={onClose} />
            <Col className="layout__header">
                {CustomHeader ? (
                    <CustomHeader {...customHeaderProps} />
                ) : backToHeader ? (
                    <HeaderWithBackButton data={backToHeader} />
                ) : (
                    <NavBar onShowDrawer={showDrawer} />
                )}
            </Col>
            <Content
                id="content"
                className={classNames(
                    overflowYAuto
                        ? defaultFooter || CustomFooter
                            ? getClassNameForPWA('layout__content-with-footer-scroll')
                            : getClassNameForPWA('layout__content')
                        : getClassNameForPWA('layout__content-with-footer'),
                    backgroundClassName,
                )}
            >
                {children}
                <BackTop
                    target={() => document.getElementById('content')}
                    visibilityHeight={240}
                    style={{ bottom: detectIphonePWA() ? 93 : 71, right: 15 }}
                />
            </Content>
            {CustomFooter ? (
                <Footer
                    className="layout__footer"
                    style={detectIphonePWA() ? { height: 60 } : null}
                >
                    <CustomFooter {...customFooterProps} />
                </Footer>
            ) : (
                defaultFooter && (
                    <Footer
                        className="layout__footer"
                        style={detectIphonePWA() ? { height: 60 } : null}
                    >
                        <UserNavBar />
                    </Footer>
                )
            )}
            <ConfirmModal
                onCancel={closeLogoutModal}
                visible={logoutModalVisible}
                withAction={true}
                confirmAction={logout}
                closable={false}
                image={ICONS.MODAL.INFO}
                cancelText={getTranslation('Stay Logged In')}
                actionText={getTranslation('Logout')}
                text={getTranslation('You have been Inactive', {
                    timeLeft,
                })}
            />
        </AntLayout>
    );
};

export default Layout;
