export const SABA_BETS_STATUSES = {
    CLOSED: 'closed',
    CLOSED_PRICE: 'closePrice',
    LOOSER: 'looser',
    RUNNING: 'running',
    SUSPEND: 'suspend',
    WINNER: 'winner',
};

export const TF_GAMING_BETS_STATUSES = {
    OPEN: 'open',
    PAUSE: 'pause',
    RESULTING: 'resulting',
};

export const BETS_SLIP_STATUSES = {
    PENDING: 'Pending',
    RUNNING: 'Running',
    STATEMENTS: 'Statements',
};

export const BETS_SUMMARY_TABS = {
    BETTING_SUMMARY: 'BettingSummary',
    ESPORTS_BETTING: 'EsportsBetting',
};

export const BET_PLUS_VALUES = [100, 500, 1000];

export const BET_SIDES = {
    AWAY: 'away',
    HOME: 'home',
};

export const BET_TYPE_KEYS = {
    AWAY: 'a',
    EVEN: 'e',
    HOME: 'h',
    NO: 'n',
    OVER: 'o',
    UNDER: 'u',
    YES: 'y',
};

export const BET_TRANSACTION_STATUSES = {
    FAILED: 'Failed',
    SUCCESS: 'Success',
};

export const ODD_TYPES_MAP = new Map([
    ['china', 'HK'],
    ['malay', 'MY'],
    ['decimal', 'DEC'],
    ['indo', 'IND'],
]);

export const ODD_TYPES = {
    CHINA: 'china',
    DECIMAL: 'decimal',
    INDO: 'indo',
    // MALAY: 'malay',
};

export const TF_GAMING_BET_TYPES = {
    '1X2': '1X2',
    AH: 'AH',
    AWAY: 'away',
    HOME: 'home',
    OE: 'OE',
    OR: 'OR',
    OU: 'OU',
    SP1X2: 'SP1X2',
    SPHA: 'SPHA',
    SPOE: 'SPOE',
    SPOR: 'SPOR',
    SPOU: 'SPOU',
    SPWINMAP: 'SPWINMAP',
    SPXX: 'SPXX',
    SPYN: 'SPYN',
    WIN: 'WIN',
    WINMAP: 'WINMAP',
};

export const PLATFORM_FILTER_VALUES = {
    ALL: 'DIREESPORTS,GGBOOK',
    SABA: 'DIREESPORTS',
    TF_GAMING: 'GGBOOK',
};

export const BETTING_PLATFORM_FILTERS = [
    {
        name: 'All',
        value: PLATFORM_FILTER_VALUES.ALL,
    },
    {
        name: 'Dire Esports',
        value: PLATFORM_FILTER_VALUES.SABA,
    },
    {
        name: 'GGBook Esports',
        value: PLATFORM_FILTER_VALUES.TF_GAMING,
    },
];

export const platformNamesMap = new Map([
    [PLATFORM_FILTER_VALUES.SABA, 'Dire Esports'],
    [PLATFORM_FILTER_VALUES.TF_GAMING, 'GGBook Esports'],
]);

export const GAMES_BETTING_PLATFORM_CODE_VALUES = {
    ADVANTPLAY: 'ADVANTPLAY',
    AG: 'AG',
    BTI: 'BTi',
    DIREESPORTS: 'DIREESPORTS',
    DIREGAMING: 'DIREGAMING',
    GD: 'GD',
    GGBOOK: 'GGBOOK',
    MG: 'MG',
    PGSOFT: 'PGSOFT',
    PT: 'PT',
    SABA: 'SABA',
};

export const GAMES_BETTING_PRODUCT_CODE_VALUES = {
    CRASH: 'CRASH',
    LIVECASINO: 'LIVECASINO',
    LOTTERY: 'LOTTERY',
    MINIGAME: 'MINIGAME',
    NUMBERGAME: 'NUMBERGAME',
    RNG: 'RNG',
    SMART: 'SMART',
    SPORT: 'SPORT',
    VIRTUALGAME: 'VIRTUALGAME',
    VIRTUALSPORT: 'VIRTUALSPORT',
};

export const GAMES_BETTING_PLATFORM_CODE_FILTERS = [
    { name: 'All', value: '' },
    { name: 'Advant Play', value: GAMES_BETTING_PLATFORM_CODE_VALUES.ADVANTPLAY },
    { name: 'AG', value: GAMES_BETTING_PLATFORM_CODE_VALUES.AG },
    { name: 'BTI', value: GAMES_BETTING_PLATFORM_CODE_VALUES.BTI },
    { name: 'Dire Gaming', value: GAMES_BETTING_PLATFORM_CODE_VALUES.DIREGAMING },
    { name: 'GGBOOK', value: GAMES_BETTING_PLATFORM_CODE_VALUES.GGBOOK },
    { name: 'MG', value: GAMES_BETTING_PLATFORM_CODE_VALUES.MG },
    { name: 'PT', value: GAMES_BETTING_PLATFORM_CODE_VALUES.PT },
    { name: 'SABA', value: GAMES_BETTING_PLATFORM_CODE_VALUES.SABA },
    { name: 'PG Soft', value: GAMES_BETTING_PLATFORM_CODE_VALUES.PGSOFT },
];

export const GAMES_BETTING_PRODUCT_CODE_FILTERS = [
    { name: 'All', value: '' },
    { name: 'Crash', value: GAMES_BETTING_PRODUCT_CODE_VALUES.CRASH },
    { name: 'Esport', value: GAMES_BETTING_PRODUCT_CODE_VALUES.ESPORT },
    { name: 'Live Casino', value: GAMES_BETTING_PRODUCT_CODE_VALUES.LIVECASINO },
    { name: 'Lottery', value: GAMES_BETTING_PRODUCT_CODE_VALUES.LOTTERY },
    { name: 'Mini Game', value: GAMES_BETTING_PRODUCT_CODE_VALUES.MINIGAME },
    { name: 'Number Game', value: GAMES_BETTING_PRODUCT_CODE_VALUES.NUMBERGAME },
    { name: 'RNG', value: GAMES_BETTING_PRODUCT_CODE_VALUES.RNG },
    { name: 'Smart', value: GAMES_BETTING_PRODUCT_CODE_VALUES.SMART },
    { name: 'Sport', value: GAMES_BETTING_PRODUCT_CODE_VALUES.SPORT },
    { name: 'Virtual Game', value: GAMES_BETTING_PRODUCT_CODE_VALUES.VIRTUALGAME },
    { name: 'Virtual Sport', value: GAMES_BETTING_PRODUCT_CODE_VALUES.VIRTUALSPORT },
];

export const BETS_PAGE_SIZE_DEFAULT = 20;
export const BETS_PAGE_NO_DEFAULT = 1;
