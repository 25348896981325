import _ from 'lodash';

import { ACTIONS } from './announcementsActions';
import { NOTIFICATIONS_PAGE_NO_DEFAULT, NOTIFICATIONS_PAGE_SIZE_DEFAULT } from '../constants';

export default function announcementsReducer(
    announcements = {
        isPageChanging: false,
        list: [],
        paging: {
            pageNo: NOTIFICATIONS_PAGE_NO_DEFAULT,
            pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.ANNOUNNCEMENT_GET_SUCCESS:
            const isLanguageChanged =
                payload.data[0] && payload.data[0].announcementItems[0]
                    ? announcements.list[0] &&
                      announcements.list[0].announcementItems[0] &&
                      (announcements.list[0].announcementItems[0].languageCode !==
                          payload.data[0].announcementItems[0].languageCode ||
                          announcements.list[0].announcementItems[0].currency !==
                              payload.data[0].announcementItems[0].currency)
                    : true;

            return {
                ...announcements,
                isPageChanging: false,
                list: isLanguageChanged
                    ? payload.data
                    : _.unionBy(announcements.list, payload.data, 'id'),
                paging: {
                    ...announcements.paging,
                    ...payload.paging,
                },
            };
        case ACTIONS.PAGINATION.PAGE_CHANGE:
            return {
                ...announcements,
                isPageChanging: true,
                paging: {
                    ...announcements.paging,
                    pageNo:
                        payload > announcements.paging.pageCount
                            ? announcements.paging.pageCount
                            : payload,
                },
            };
        case ACTIONS.PAGINATION.PAGE_SIZE_CHANGE:
            return {
                ...announcements,
                paging: {
                    ...announcements.paging,
                    pageNo: 1,
                    pageSize: payload < 1 ? NOTIFICATIONS_PAGE_SIZE_DEFAULT : payload,
                },
            };
        case ACTIONS.ANNOUNNCEMENT_FILTER_SET:
            return {
                ...announcements,
                filter: payload,
                list: [],
                paging: {
                    pageNo: NOTIFICATIONS_PAGE_NO_DEFAULT,
                    pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
            };
        case ACTIONS.ANNOUNNCEMENT_GET:
        default:
            return announcements;
    }
}
