import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'antd';

import './ConfirmModal.less';
import { getTranslation } from '../../helpers/locale';

const ActionButton = ({ actionText, actionPending, confirmAction, justify }) => {
    return (
        <Button
            loading={actionPending}
            onClick={confirmAction}
            className={
                justify === 'left'
                    ? 'confirm-modal__btn confirm-modal__btn--action-left'
                    : 'confirm-modal__btn confirm-modal__btn--action-right'
            }
        >
            {getTranslation(actionText)}
        </Button>
    );
};

ActionButton.defaultProps = {
    actionText: 'Ok',
};

ActionButton.propTypes = {
    actionPending: PropTypes.bool,
    actionText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmAction: PropTypes.func,
};

export default ActionButton;
