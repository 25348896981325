export const ACTIONS = {
    GET_ADVANTPLAY_JACKPOT: 'GET_ADVANTPLAY_JACKPOT',
    GET_ADVANTPLAY_JACKPOT_FAILURE: 'GET_ADVANTPLAY_JACKPOT_FAILURE',
    GET_ADVANTPLAY_JACKPOT_SUCCESS: 'GET_ADVANTPLAY_JACKPOT_SUCCESS',
};

export const getAdvantplayJackpot = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_ADVANTPLAY_JACKPOT,
    });

export const getAdvantplayJackpotSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_ADVANTPLAY_JACKPOT_SUCCESS,
    });

export const getAdvantplayJackpotFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_ADVANTPLAY_JACKPOT_FAILURE,
    });
