import React from 'react';

import { Col, Row } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import './Fallback.less';
import { ButtonGray } from '../common/components/Buttons';
import { ICONS } from '../common/constants/icons';
import { getCurrentLanguage } from '../common/helpers/api';
import { getImageByLanguage } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import ImgIcon from '../common/components/ImgIcon';
import SupportButton from '../common/components/SupportButton';

const Fallback = () => {
    const language = getCurrentLanguage();
    const logo = getImageByLanguage(ICONS.LOGOS, language);
    // ============================================
    // METHODS
    // ============================================

    const goToHomePage = () => (window.location.pathname = '/');

    // ============================================
    // RENDER
    // ============================================

    return (
        <Col className="fallback-page">
            <Row justify="space-between" align="middle" className="fallback-page__navbar">
                <Col onClick={goToHomePage}>
                    <HomeOutlined className="navbar__icon" alt="home" />
                </Col>
                <Col onClick={goToHomePage}>
                    <ImgIcon icon={logo} width={110} alt="logo" />
                </Col>
                <Col>
                    <SupportButton />
                </Col>
            </Row>
            <Row justify="center" className="fallback-page__title">
                <span>{getTranslation('Oops, something went wrong!').toLocaleUpperCase()}</span>
            </Row>
            <Row justify="center" className="fallback-page__image">
                <ImgIcon icon={ICONS.ERROR.ERROR_5XX} width={320} />
            </Row>
            <ButtonGray onClick={goToHomePage} className="fallback-page__btn">
                {getTranslation('Go to home').toLocaleUpperCase()}
            </ButtonGray>
        </Col>
    );
};

export default Fallback;
