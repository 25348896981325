import { ICONS } from './icons';

export const WINNER_VALUES = {
    AWAY: 'away',
    DRAW: 'draw',
    EMPTY: '',
    HOME: 'home',
    PENDING: null,
    REFUND: 'refund',
    UNRESOLVED: 'nil',
};

export const TF_GAMING_RESULT_VALUES = {
    LOST: 'lost',
    PENDING: 'Pending',
    WON: 'won',
};

export const ADDITIONAL_FILTER_NAMES = {
    FAVOURITES: 'favourites',
    RELATED: 'related',
};

export const MARKETS = {
    EARLY: 'e',
    LIVE: 'l',
    TODAY: 't',
};

export const ESPORTS_GAMES = [
    {
        displayName: 'Dota 2',
        icon: ICONS.GAME_ICONS.DOTA2,
        searchName: 'Dota 2',
        thumbnail: ICONS.GAME_THUMBNAILS.DOTA2,
    },
    {
        displayName: 'CS:GO',
        icon: ICONS.GAME_ICONS.CS_GO,
        searchName: 'CS:GO',
        thumbnail: ICONS.GAME_THUMBNAILS.CS_GO,
    },
    {
        displayName: 'LOL',
        icon: ICONS.GAME_ICONS.LOL,
        searchName: 'League of Legends',
        thumbnail: ICONS.GAME_THUMBNAILS.LOL,
    },
    {
        displayName: 'KOG',
        icon: ICONS.GAME_ICONS.KOG,
        searchName: 'King of Glory',
        thumbnail: ICONS.GAME_THUMBNAILS.KOG,
    },
    {
        displayName: 'Valorant',
        icon: ICONS.GAME_ICONS.VALORANT,
        searchName: 'Valorant',
    },
    {
        displayName: 'Starcraft 2',
        icon: ICONS.GAME_ICONS.STARCRAFT2,
        searchName: 'StarCraft 2',
        thumbnail: ICONS.GAME_THUMBNAILS.STARCRAFT2,
    },
    {
        displayName: 'COD',
        icon: ICONS.GAME_ICONS.COD,
        searchName: 'Call of Duty',
        thumbnail: ICONS.GAME_THUMBNAILS.COD,
    },
    {
        displayName: 'Hearthstone',
        icon: ICONS.GAME_ICONS.HEARTHSTONE,
        searchName: 'Hearthstone',
        thumbnail: ICONS.GAME_THUMBNAILS.HEARTHSTONE,
    },
    {
        displayName: 'Overwatch',
        icon: ICONS.GAME_ICONS.OVERWATCH,
        searchName: 'Overwatch',
        thumbnail: ICONS.GAME_THUMBNAILS.OVERWATCH,
    },
    {
        displayName: 'AOV',
        icon: ICONS.GAME_ICONS.AOV,
        searchName: 'Arena of Valor',
        thumbnail: ICONS.GAME_THUMBNAILS.AOV,
    },
    {
        displayName: 'PUBG',
        icon: ICONS.GAME_ICONS.PUBG,
        searchName: 'PUBG',
        thumbnail: ICONS.GAME_THUMBNAILS.PUBG,
    },
    {
        displayName: 'Rainbow 6',
        icon: ICONS.GAME_ICONS.RBOW6,
        searchName: 'Rainbow Six',
        thumbnail: ICONS.GAME_THUMBNAILS.RBOW6,
    },
    {
        displayName: 'Rocket League',
        icon: ICONS.GAME_ICONS.RLEAGUE,
        searchName: 'Rocket League',
        thumbnail: ICONS.GAME_THUMBNAILS.RLEAGUE,
    },
    {
        displayName: 'Starcraft',
        icon: ICONS.GAME_ICONS.STARCRAFT,
        searchName: 'Starcraft',
        thumbnail: ICONS.GAME_THUMBNAILS.STARCRAFT,
    },
    {
        displayName: 'SF 5',
        icon: ICONS.GAME_ICONS.STREETF5,
        searchName: 'Street Fighter 5',
        thumbnail: ICONS.GAME_THUMBNAILS.STREETF5,
    },
    {
        displayName: 'WarCraft 3',
        icon: ICONS.GAME_ICONS.WARCRAFT3,
        searchName: 'WarCraft 3',
        thumbnail: ICONS.GAME_THUMBNAILS.WARCRAFT3,
    },
    {
        displayName: 'Artifact',
        icon: ICONS.GAME_ICONS.ARTIFACT,
        searchName: 'Artifact',
        thumbnail: ICONS.GAME_THUMBNAILS.ARTIFACT,
    },
    {
        displayName: 'Gaming',
        icon: ICONS.GAME_ICONS.GAMING,
        searchName: 'Gaming',
        thumbnail: '',
    },
];

export const TF_GAMING_GAMES = [
    {
        displayName: 'LOL',
        icon: ICONS.GAME_ICONS.LOL,
        searchName: 'League of Legends',
    },
    {
        displayName: 'Dota 2',
        icon: ICONS.GAME_ICONS.DOTA2,
        searchName: 'Dota 2',
    },
    {
        displayName: 'CS:GO',
        icon: ICONS.GAME_ICONS.CS_GO,
        searchName: 'CS:GO',
    },
    {
        displayName: 'KOG',
        icon: ICONS.GAME_ICONS.KOG,
        searchName: 'King of Glory',
    },
    {
        displayName: 'AOV',
        icon: ICONS.GAME_ICONS.AOV,
        searchName: 'Arena of Valor',
    },
    {
        displayName: 'Mobile Legend',
        icon: ICONS.GAME_ICONS.MOBILE_LEGENDS,
        searchName: 'Mobile Legend',
    },
    {
        displayName: 'FIFA',
        icon: ICONS.GAME_ICONS.FIFA,
        searchName: 'FIFA',
    },
    {
        displayName: 'Starcraft 2',
        icon: ICONS.GAME_ICONS.STARCRAFT2,
        searchName: 'StarCraft 2',
    },
    {
        displayName: 'Valorant',
        icon: ICONS.GAME_ICONS.VALORANT,
        searchName: 'Valorant',
    },
    {
        displayName: 'PUBG',
        icon: ICONS.GAME_ICONS.PUBG,
        searchName: 'PUBG',
    },
    {
        displayName: 'Rainbow 6',
        icon: ICONS.GAME_ICONS.RBOW6,
        searchName: 'Rainbow Six',
    },
    {
        displayName: 'Overwatch',
        icon: ICONS.GAME_ICONS.OVERWATCH,
        searchName: 'Overwatch',
    },
    {
        displayName: 'NBA 2K18',
        icon: ICONS.GAME_ICONS.NBA2K18,
        searchName: 'NBA 2K18',
    },
    {
        displayName: 'SF 5',
        icon: ICONS.GAME_ICONS.STREETF5,
        searchName: 'Street Fighter 5',
    },
    {
        displayName: 'Hearthstone',
        icon: ICONS.GAME_ICONS.HEARTHSTONE,
        searchName: 'Hearthstone',
    },
    {
        displayName: 'Heroes Of The Storm',
        icon: ICONS.GAME_ICONS.HOTS,
        searchName: 'Heroes of the Storm',
    },
    {
        displayName: 'Starcraft',
        icon: ICONS.GAME_ICONS.STARCRAFT,
        searchName: 'Starcraft',
    },
    {
        displayName: 'COD',
        icon: ICONS.GAME_ICONS.COD,
        searchName: 'Call of Duty',
    },
    {
        displayName: 'WarCraft 3',
        icon: ICONS.GAME_ICONS.WARCRAFT3,
        searchName: 'WarCraft 3',
    },
    // {
    //     displayName: 'Basketball',
    //     icon: ICONS.GAME_ICONS.BASKETBALL,
    //     searchName: 'Basketball',
    // },
    {
        displayName: 'Rocket League',
        icon: ICONS.GAME_ICONS.RLEAGUE,
        searchName: 'Rocket League',
    },
    {
        displayName: 'Fortnite',
        icon: ICONS.GAME_ICONS.FORTNITE,
        searchName: 'Fortnite',
    },
    {
        displayName: 'Game For Peace',
        icon: ICONS.GAME_ICONS.GFP,
        searchName: 'Game for Peace',
    },
    {
        displayName: 'Quake',
        icon: ICONS.GAME_ICONS.QUAKE,
        searchName: 'Quake',
    },
];

export const FEATURED_GAMES = [
    {
        name: 'AG',
        thumbnail: ICONS.FEATURED_GAMES.AG,
    },
    {
        name: 'SexyGaming',
        thumbnail: ICONS.FEATURED_GAMES.SEXY_GAMING,
    },
    {
        name: 'Allbet',
        thumbnail: ICONS.FEATURED_GAMES.ALLBET,
    },
    {
        name: 'VirtualLeagues',
        thumbnail: ICONS.FEATURED_GAMES.EURO_VIRTUAL_LEAGUES,
    },
    {
        name: 'UEFAChampionCup',
        thumbnail: ICONS.FEATURED_GAMES.UEFA_CHAMPIONS_CUP,
    },
    {
        name: 'Basketball',
        thumbnail: ICONS.FEATURED_GAMES.NBA,
    },
    {
        name: 'VirtualGames',
        thumbnail: ICONS.FEATURED_GAMES.VIRTUAL_GAME,
    },
];

export const SABA_SPORT_GAMES = [
    {
        name: 'Soccer',
        thumbnail: ICONS.SPORT_GAMES.SOCCER,
    },
    {
        name: 'Basketball',
        thumbnail: ICONS.SPORT_GAMES.BASKETBALL,
    },
    {
        name: 'Cricket',
        thumbnail: ICONS.SPORT_GAMES.CRICKET,
    },
    {
        name: 'Tennis',
        thumbnail: ICONS.SPORT_GAMES.TENNIS,
    },
    {
        name: 'Baseball',
        thumbnail: ICONS.SPORT_GAMES.BASEBALL,
    },
    {
        name: 'IceHockey',
        thumbnail: ICONS.SPORT_GAMES.ICE_HOCKEY,
    },
    {
        name: 'Badminton',
        thumbnail: ICONS.SPORT_GAMES.BADMINTON,
    },
    {
        name: 'Football',
        thumbnail: ICONS.SPORT_GAMES.FOOTBALL,
    },
    {
        name: 'Volleyball',
        thumbnail: ICONS.SPORT_GAMES.VOLLEYBALL,
    },
    {
        name: 'Golf',
        thumbnail: ICONS.SPORT_GAMES.GOLF,
    },
    {
        name: 'Motorsports',
        thumbnail: ICONS.SPORT_GAMES.FORMULA1,
    },
    {
        name: 'Billiards',
        thumbnail: ICONS.SPORT_GAMES.SNOOKER,
    },
    {
        name: 'TableTennis',
        thumbnail: ICONS.SPORT_GAMES.TABLE_TENNIS,
    },
    {
        name: 'Boxing',
        thumbnail: ICONS.SPORT_GAMES.BOXING,
    },
    {
        name: 'MoreGames',
        thumbnail: ICONS.SPORT_GAMES.MORE_GAMES,
    },
];

export const SABA_VIRTUAL_SPORT_GAMES = [
    {
        displayName: 'Soccer Euro Cup',
        name: 'VirtualSoccerEuroCup',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER_EURO_CUP,
    },
    {
        displayName: 'Soccer Champion Cup',
        name: 'VirtualSoccerChampionsCup',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER_CHAMPIONS_CUP,
    },
    {
        displayName: 'Soccer Asian Cup',
        name: 'VirtualSoccerAsianCup',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER_ASIAN_CUP,
    },
    {
        displayName: 'Soccer League',
        name: 'VirtualSoccerLeague',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER_LEAGUE,
    },
    {
        displayName: 'Soccer Nation',
        name: 'VirtualSoccerNation',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER_NATION,
    },
    {
        displayName: 'Soccer',
        name: 'VirtualSoccer',
        thumbnail: ICONS.VIRTUAL_SPORTS.SOCCER,
    },
    {
        displayName: 'Basketball',
        name: 'VirtualBasketball',
        thumbnail: ICONS.VIRTUAL_SPORTS.BASKETBALL,
    },
    {
        displayName: 'Horse Racing',
        name: 'VirtualHorseRacing',
        thumbnail: ICONS.VIRTUAL_SPORTS.HORSE_RACING,
    },
    {
        displayName: 'Greyhound',
        name: 'VirtualGreyhound',
        thumbnail: ICONS.VIRTUAL_SPORTS.GREYHOUND,
    },
    {
        displayName: 'Speedway',
        name: 'VirtualSpeedway',
        thumbnail: ICONS.VIRTUAL_SPORTS.SPEEDWAY,
    },
    {
        displayName: 'Cycling',
        name: 'VirtualCycling',
        thumbnail: ICONS.VIRTUAL_SPORTS.CYCLING,
    },
    {
        displayName: 'Motorsport',
        name: 'VirtualF1',
        thumbnail: ICONS.VIRTUAL_SPORTS.MOTORSPORT,
    },
    {
        displayName: 'Tennis',
        name: 'VirtualTennis',
        thumbnail: ICONS.VIRTUAL_SPORTS.TENNIS,
    },
];

export const SABA_OTHER_GAMES = [
    {
        displayName: 'Gold Deluxe',
        name: 'GoldDeluxe',
        thumbnail: ICONS.OTHER_GAMES.GOLD_DELUXE,
    },
    {
        displayName: 'Big/Small',
        name: 'Taixiu',
        thumbnail: ICONS.OTHER_GAMES.BIG_SMALL,
    },
    {
        displayName: 'Lottery',
        name: 'KenoLottery',
        thumbnail: ICONS.OTHER_GAMES.LOTTERY,
    },
    {
        displayName: 'Number Games',
        name: 'NumberGames',
        thumbnail: ICONS.OTHER_GAMES.NUMBER,
    },
    {
        displayName: 'Keno',
        name: 'KENO',
        thumbnail: ICONS.OTHER_GAMES.KENO,
    },
];

export const ERROR_REDIRECT_STATUSES = [401, 403, 451, 502, 503, 504];

export const errorStatusesMap = new Map([
    [
        '400',
        {
            image: ICONS.ERROR.ERROR_4XX,
            text: '400 error text',
            title: 'Bad request',
        },
    ],
    [
        '401',
        {
            image: ICONS.ERROR.ERROR_4XX,
            text: '401 error text',
            title: 'Unauthorized',
        },
    ],
    [
        '403',
        {
            image: ICONS.ERROR.ERROR_4XX,
            text: '403 error text',
            title: 'Forbidden',
        },
    ],
    [
        '404',
        {
            image: ICONS.ERROR.ERROR_4XX,
            text: '404 error text',
            title: 'Page not found',
        },
    ],
    [
        '451',
        {
            image: ICONS.ERROR.ERROR_4XX,
            text: 'Blocked country text',
            title: 'Sorry, your IP is prohibited',
        },
    ],
    [
        '500',
        {
            image: ICONS.ERROR.ERROR_5XX,
            text: '500 error text',
            title: 'Internal server error',
        },
    ],
    [
        '502',
        {
            image: ICONS.ERROR.ERROR_5XX,
            text: '502 error text',
            title: 'Bad gateway',
        },
    ],
    [
        '503',
        {
            image: ICONS.ERROR.ERROR_5XX,
            text: '503 error text',
            title: 'Service unavailable',
        },
    ],
    [
        '504',
        {
            image: ICONS.ERROR.ERROR_5XX,
            text: '504 error text',
            title: 'Gateway timeout',
        },
    ],
]);

export const DEFAULT_ERROR_INFO = {
    image: ICONS.ERROR.ERROR_4XX,
    text: 'Something went wrong',
    title: 'Error',
};

export const welcomeGreetingMap = new Map([
    ['Morning', 'Good morning!'],
    ['Afternoon', 'Good afternoon!'],
    ['Evening', 'Good evening!'],
]);

export const BET_STATEMENT_STATUS = {
    DRAW: 'draw',
    HALF_LOSE: 'half lose',
    HALF_WON: 'half won',
    LOSE: 'lose',
    REFUND: 'refund',
    REJECT: 'reject',
    RUNNING: 'running',
    VOID: 'void',
    WAITING: 'waiting',
    WON: 'won',
};

export const IDLE_TIMEOUT = {
    IDLE_TIMER: 1800000,
    MODAL_LOGOUT_TIMER: 10,
};

export const KEYBOARD_MAX_VALUE = 1000000;

export const COMMUNICATION_CHANNELS = [
    { id: 1, name: 'QQ' },
    { id: 2, name: 'Skype' },
    { id: 3, name: 'Telegram' },
    { id: 4, name: 'WeChat' },
];

export const LOGIN_TYPE = {
    OTP: 'otp',
    PASSWORD: 'password',
};

export const MOBILE_WEBVIEW = {
    ANDROID: {
        GGBAO: 'https://ggbao.oss-cn-beijing.aliyuncs.com/android/ggbao.apk',
        GGBOOK: 'https://storage.googleapis.com/ggbook-app-19289235/android/ggbook.apk',
    },
    IOS: {
        GGBBAO: 'https://www.aso008.com/FJOS28',
        GGBOOK: 'https://www.aso008.com/FJOS28',
    },
    VERSION: '1.9',
};

export const MOBILE_PLATFORM = {
    ANDROID: 'Android',
    IOS: 'Ios',
};

export const ES_PLATFORM = {
    SABA: '/ggbook',
    TF_GAMING: '/ggbook/0',
};

export const LIVECHAT_GROUPS = {
    ALL: 5,
    GENERAL: 0,
    GGBAO: 2,
    GGTH: 4,
    TESTING: 3,
};

export const LIVECHAT_GROUPS_MAP = new Map([
    ['my', LIVECHAT_GROUPS.ALL],
    ['id', LIVECHAT_GROUPS.ALL],
    ['vn', LIVECHAT_GROUPS.ALL],
    ['th', LIVECHAT_GROUPS.GGTH],
]);

export const TESTING_URL = [
    'app.dev.ggbook.org',
    'app.sit.ggbook.org',
    'app.stg.dota2book.com',
    'localhost',
];

export const vipLevelMapping = new Map([
    [0, 'Beginner'],
    [1, 'Silver 1'],
    [2, 'Silver 2'],
    [3, 'Silver 3'],
    [4, 'Gold 1'],
    [5, 'Gold 2'],
    [6, 'Gold 3'],
    [7, 'Platinum 1'],
    [8, 'Platinum 2'],
    [9, 'Platinum 3'],
    [10, 'Diamond'],
]);

export const GAMES = {
    CSGO: 'CS:GO',
    DOTA: 'Dota 2',
    LOL: 'League of Legends',
};

export const CSGO_MAP = [
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.DUST2,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.DUST2,
        name: 'dust2',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.INFERNO,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.INFERNO,
        name: 'inferno',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.MIRAGE,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.MIRAGE,
        name: 'mirage',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.NUKE,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.NUKE,
        name: 'nuke',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.OVERPASS,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.OVERPASS,
        name: 'overpass',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.TRAIN,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.TRAIN,
        name: 'train',
    },
    {
        background: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_BG.VERTIGO,
        icon: ICONS.STATS.HEAD_TO_HEAD.DRAFT.CSGO.MAP_ICON.VERTIGO,
        name: 'vertigo',
    },
];

export const DANGER_LEVEL_ELIGIBILITY = {
    BANK_TRANSFER_STATUS: 'bankTransferStatus',
    CRYPTO_CURRENCY_STATUS: 'crytoCurrencyStatus',
    WEALTHPAY_STATUS: 'wealthPayStatus',
    EWALLET_STATUS: 'eWalletStatus',
    ONLINE_BANKING_STATUS: 'onlineBankingStatus',
    WITHDRAWAL_STATUS: 'withdrawalStatus',
};
