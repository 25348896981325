export const ACTIONS = {
    MESSAGE_CARD_DESELECTED: 'MESSAGE_CARD_DESELECTED',
    MESSAGE_CARD_SELECTED: 'MESSAGE_CARD_SELECTED',
};

/**
 * Set active selected card
 * @param {Function} dispatch Action dispatcher from store
 * @param {MessageCard} payload Action payload
 */
export const setSelectedCard = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.MESSAGE_CARD_SELECTED,
    });

/**
 * Drop selected card
 * @param {Function} dispatch Action dispatcher from store
 */
export const dropSelectedCard = (dispatch) =>
    dispatch({
        type: ACTIONS.MESSAGE_CARD_DESELECTED,
    });
