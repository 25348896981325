import { ACTIONS } from './resultsActions';
import { QUICK_DATES } from '../../../common/components/SingleDatePicker';

export default function resultsReducer(
    results = {
        period: {
            ...QUICK_DATES[0].value,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.GAME_RESULTS_GET_SUCCESS:
            return {
                ...results,
                list: payload.results,
                totalEvents: payload.totalEventsByMarketType,
            };
        case ACTIONS.GAME_RESULTS_APPLY_FILTER:
            return {
                ...results,
                list: [],
                selected: payload,
            };
        case ACTIONS.GAME_RESULTS_DROP_FILTER:
            return {
                ...results,
                list: [],
                selected: null,
            };
        case ACTIONS.GAME_RESULTS_PERIOD_CHANGE:
            return {
                ...results,
                list: [],
                period: payload,
            };
        case ACTIONS.GAME_RESULTS_CLEAR:
            return {
                ...results,
                list: [],
            };
        case ACTIONS.GAME_RESULTS_GET:
        default:
            return results;
    }
}
