export const ACTIONS = {
    CHECK_ELIGIBLE_DEPOSIT: 'CHECK_ELIGIBLE_DEPOSIT',
    CHECK_ELIGIBLE_DEPOSIT_FAILURE: 'CHECK_ELIGIBLE_DEPOSIT_FAILURE',
    CHECK_ELIGIBLE_DEPOSIT_SUCCESS: 'CHECK_ELIGIBLE_DEPOSIT_SUCCESS',
    CLEAR_UPLOAD_FILE_CONTENT: 'CLEAR_UPLOAD_FILE_CONTENT',
    CLEAR_WALLET_INFO: 'CLEAR_WALLET_INFO',
    CRYPTOCURRENCY_CHANGE: 'CRYPTOCURRENCY_CHANGE',
    WEALTHPAYCHANNEL_CHANGE: 'WEALTHPAYCHANNEL_CHANGE',
    CRYPTOCURRENCY_LIST_GET: 'CRYPTOCURRENCY_LIST_GET',
    CRYPTOCURRENCY_LIST_GET_FAILURE: 'CRYPTOCURRENCY_LIST_GET_FAILURE',
    CRYPTOCURRENCY_LIST_GET_SUCCESS: 'CRYPTOCURRENCY_LIST_GET_SUCCESS',
    CRYPTOCURRENCY_RATES_GET: 'CRYPTOCURRENCY_RATES_GET',
    CRYPTOCURRENCY_RATES_GET_FAILURE: 'CRYPTOCURRENCY_RATES_GET_FAILURE',
    CRYPTOCURRENCY_RATES_GET_SUCCESS: 'CRYPTOCURRENCY_RATES_GET_SUCCESS',
    E_WALLET_WITHDRAWAL_REQUEST: 'E_WALLET_WITHDRAWAL_REQUEST',
    E_WALLET_WITHDRAWAL_REQUEST_FAILURE: 'E_WALLET_WITHDRAWAL_REQUEST_FAILURE',
    E_WALLET_WITHDRAWAL_REQUEST_SUCCESS: 'E_WALLET_WITHDRAWAL_REQUEST_SUCCESS',
    GET_BANK_CARDS: 'GET_BANK_CARDS',
    GET_BANK_CARDS_FAILURE: 'GET_BANK_CARDS_FAILURE',
    GET_BANK_CARDS_SUCCESS: 'GET_BANK_CARDS_SUCCESS',
    GET_DEPOSIT_BANK_LIST: 'GET_DEPOSIT_BANK_LIST',
    GET_DEPOSIT_BANK_LIST_FAILURE: 'GET_DEPOSIT_BANK_LIST_FAILURE',
    GET_DEPOSIT_BANK_LIST_SUCCESS: 'GET_DEPOSIT_BANK_LIST_SUCCESS',
    GET_E_WALLET_LIST: 'GET_E_WALLET_LIST',
    GET_E_WALLET_LIST_FAILURE: 'GET_E_WALLET_LIST_FAILURE',
    GET_E_WALLET_LIST_SUCCESS: 'GET_E_WALLET_LIST_SUCCESS',
    PAYMENT_DEPOSIT_REQUEST: 'PAYMENT_DEPOSIT_REQUEST',
    PAYMENT_DEPOSIT_REQUEST_FAILURE: 'PAYMENT_DEPOSIT_REQUEST_FAILURE',
    PAYMENT_DEPOSIT_REQUEST_SUCCESS: 'PAYMENT_DEPOSIT_REQUEST_SUCCESS',
    PAYMENT_E_WALLET_DEPOSIT_REQUEST: 'PAYMENT_E_WALLET_DEPOSIT_REQUEST',
    PAYMENT_E_WALLET_DEPOSIT_REQUEST_FAILURE: 'PAYMENT_E_WALLET_DEPOSIT_REQUEST_FAILURE',
    PAYMENT_E_WALLET_DEPOSIT_REQUEST_SUCCESS: 'PAYMENT_E_WALLET_DEPOSIT_REQUEST_SUCCESS',
    PAYMENT_GATEWAYS_GET: 'PAYMENT_GATEWAYS_GET',
    PAYMENT_GATEWAYS_GET_FAILURE: 'PAYMENT_GATEWAYS_GET_FAILURE',
    PAYMENT_GATEWAYS_GET_SUCCESS: 'PAYMENT_GATEWAYS_GET_SUCCESS',
    PAYMENT_GATEWAY_CHANGE: 'PAYMENT_GATEWAY_CHANGE',
    PAYMENT_PROVIDER_CHANGE: 'PAYMENT_PROVIDER_CHANGE',
    PAYMENT_TRANSFER_REQUEST: 'PAYMENT_TRANSFER_REQUEST',
    PAYMENT_TRANSFER_REQUEST_FAILURE: 'PAYMENT_TRANSFER_REQUEST_FAILURE',
    PAYMENT_TRANSFER_REQUEST_SUCCESS: 'PAYMENT_TRANSFER_REQUEST_SUCCESS',
    PAYMENT_WITHDRAWAL_REQUEST: 'PAYMENT_WITHDRAWAL_REQUEST',
    PAYMENT_WITHDRAWAL_REQUEST_FAILURE: 'PAYMENT_WITHDRAWAL_REQUEST_FAILURE',
    PAYMENT_WITHDRAWAL_REQUEST_SUCCESS: 'PAYMENT_WITHDRAWAL_REQUEST_SUCCESS',
    TRANSACTION_AMOUNT_EWALLET_SETTING_GET: 'TRANSACTION_AMOUNT_EWALLET_SETTING_GET',
    TRANSACTION_AMOUNT_EWALLET_SETTING_GET_FAILURE:
        'TRANSACTION_AMOUNT_EWALLET_SETTING_GET_FAILURE',
    TRANSACTION_AMOUNT_EWALLET_SETTING_GET_SUCCESS:
        'TRANSACTION_AMOUNT_EWALLET_SETTING_GET_SUCCESS',
    TRANSACTION_AMOUNT_SETTING_GET: 'TRANSACTION_AMOUNT_SETTING_GET',
    TRANSACTION_AMOUNT_SETTING_GET_FAILURE: 'TRANSACTION_AMOUNT_SETTING_GET_FAILURE',
    TRANSACTION_AMOUNT_SETTING_GET_SUCCESS: 'TRANSACTION_AMOUNT_SETTING_GET_SUCCESS',
    UPLOAD_RECEIPT: 'UPLOAD_RECEIPT',
    UPLOAD_RECEIPT_FAILURE: 'UPLOAD_RECEIPT_FAILURE',
    UPLOAD_RECEIPT_SUCCESS: 'UPLOAD_RECEIPT_SUCCESS',
    WALLETS_BALANCE_GET: 'WALLETS_BALANCE_GET',
    WALLETS_BALANCE_GET_FAILURE: 'WALLETS_BALANCE_GET_FAILURE',
    WALLETS_BALANCE_GET_SUCCESS: 'WALLETS_BALANCE_GET_SUCCESS',
    WALLET_SUMMARY_GET: 'WALLET_SUMMARY_GET',
    WALLET_SUMMARY_GET_FAILURE: 'WALLET_SUMMARY_GET_FAILURE',
    WALLET_SUMMARY_GET_SUCCESS: 'WALLET_SUMMARY_GET_SUCCESS',
};

/**
 * Send deposit request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const depositRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_DEPOSIT_REQUEST,
    });

/**
 * Send deposit request success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{bankTransferResponse?: BankTransferResponse}} payload Action payload
 */
export const depositRequestSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_DEPOSIT_REQUEST_SUCCESS,
    });

/**
 * Send deposit request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const depositRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_DEPOSIT_REQUEST_FAILURE,
    });

/**
 * Get payment gateways init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPaymentGateways = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_GATEWAYS_GET,
    });

/**
 * Get payment gateways success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentPaymentGateway: PaymentGateway, paymentGateways: PaymentGateway[]}} payload Action payload
 */
export const getPaymentGatewaysSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_GATEWAYS_GET_SUCCESS,
    });

/**
 * Get payment gateways failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPaymentGatewaysFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_GATEWAYS_GET_FAILURE,
    });

/**
 * Get list of available cryptocurrencies init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getCryptocurrencyRates = (dispatch) =>
    dispatch({
        type: ACTIONS.CRYPTOCURRENCY_RATES_GET,
    });

/**
 * Get list of available cryptocurrencies success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentCryptocurrency: Cryptocurrency, cryptocurrencies: Cryptocurrency[]}} payload Action payload
 */
export const getCryptocurrencyRatesSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTOCURRENCY_RATES_GET_SUCCESS,
    });

/**
 * Get list of available cryptocurrencies failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getCryptocurrencyRatesFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTOCURRENCY_RATES_GET_FAILURE,
    });

/**
 * Change current cryptocurrency action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentCryptocurrency: Cryptocurrency}} payload Action payload
 */
export const changeCryptocurrency = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTOCURRENCY_CHANGE,
    });

/**
 * Change current wealthpay channel action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentChannel: Cryptocurrency}} payload Action payload
 */
export const changeWealthPayChannel = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WEALTHPAYCHANNEL_CHANGE,
    });

/**
 * Change current payment gateway action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentPaymentGateway: PaymentGateway}} payload Action payload
 */
export const changePaymentGateway = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_GATEWAY_CHANGE,
    });

/**
 * Change current payment provider action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{currentPaymentProvider: Provider}} payload Action payload
 */
export const changePaymentProvider = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_PROVIDER_CHANGE,
    });
/**
 * Send transfer request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const transferRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_TRANSFER_REQUEST,
    });

/**
 * Send transfer request success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const transferRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_TRANSFER_REQUEST_SUCCESS,
    });

/**
 * Send transfer request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const transferRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_TRANSFER_REQUEST_FAILURE,
    });

/**
 * Send withdrawal request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const withdrawalRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_WITHDRAWAL_REQUEST,
    });

/**
 * Send withdrawal request success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const withdrawalRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_WITHDRAWAL_REQUEST_SUCCESS,
    });

/**
 * Send withdrawal request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const withdrawalRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_WITHDRAWAL_REQUEST_FAILURE,
    });

/**
 * Get CryptoCurrency List init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getCryptoCurrencyList = (dispatch) => {
    dispatch({
        type: ACTIONS.CRYPTOCURRENCY_LIST_GET,
    });
};

/**
 * Get CryptoCurrency List success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {CryptoInfo} payload Action payload
 */
export const getCryptoCurrencyListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTOCURRENCY_LIST_GET_SUCCESS,
    });

/**
 * Get CryptoCurrency List failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getCryptoCurrencyListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRYPTOCURRENCY_LIST_GET_FAILURE,
    });

/**
 * Clear wallet info action (on user logout)
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearWalletInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.CLEAR_WALLET_INFO,
    });

/**
 * Check Eligible deposit init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const checkEligibleDeposit = (dispatch) => {
    dispatch({
        type: ACTIONS.CHECK_ELIGIBLE_DEPOSIT,
    });
};

/**
 * Check Eligible deposit success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{isEligible: Boolean}} payload Action payload
 */
export const checkEligibleDepositSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CHECK_ELIGIBLE_DEPOSIT_SUCCESS,
    });

/**
 * Check Eligible deposit failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const checkEligibleDepositFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CHECK_ELIGIBLE_DEPOSIT_FAILURE,
    });

/**
 * Get wallets init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getWalletsBalance = (dispatch) =>
    dispatch({
        type: ACTIONS.WALLETS_BALANCE_GET,
    });

/**
 * Get wallets success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{externalWallets: Wallet[], refreshExternalWallets: Function}} payload Array of all wallets except GGBook wallet
 */
export const getWalletsBalanceSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLETS_BALANCE_GET_SUCCESS,
    });

/**
 * Get wallets failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getWalletsBalanceFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLETS_BALANCE_GET_FAILURE,
    });

/**
 * Get wallet summary init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getWalletSummary = (dispatch) =>
    dispatch({
        type: ACTIONS.WALLET_SUMMARY_GET,
    });

/**
 * Get wallet summary success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{refresh: Function, totalBalance: Number, ggBookWalletBalance: Number, sabaWalletBalance: Number}} payload Action payload
 */
export const getWalletSummarySuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_SUMMARY_GET_SUCCESS,
    });

/**
 * Get wallet summary failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getWalletSummaryFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.WALLET_SUMMARY_GET_FAILURE,
    });

/**
 * Upload receipt init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const uploadReceipt = (dispatch) =>
    dispatch({
        type: ACTIONS.UPLOAD_RECEIPT,
    });

/**
 * Upload receipt success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{file: *, fileName: String}} payload file is a base64 Blob
 */
export const uploadReceiptSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPLOAD_RECEIPT_SUCCESS,
    });

/**
 * Upload receipt failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const uploadReceiptFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.UPLOAD_RECEIPT_FAILURE,
    });

/**
 * Clear upload content init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearUploadContent = (dispatch) =>
    dispatch({
        type: ACTIONS.CLEAR_UPLOAD_FILE_CONTENT,
    });

/**
 * Get Eligible bank card init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getDepositBankList = (dispatch) => {
    dispatch({
        type: ACTIONS.GET_DEPOSIT_BANK_LIST,
    });
};

/**
 * Get Eligible bank card success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Bank[]} payload Action payload
 */
export const getDepositBankListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_DEPOSIT_BANK_LIST_SUCCESS,
    });

/**
 * Get Eligible bank card failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getDepositBankListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_DEPOSIT_BANK_LIST_FAILURE,
    });

/**
 * Get User transaction amount range init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getTransactionAmountSetting = (dispatch) => {
    dispatch({
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET,
    });
};

/**
 * Get User transaction amount range success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {TransactionRange} payload Action payload
 */
export const getTransactionAmountSettingSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_SUCCESS,
    });

/**
 * Get User transaction amount range failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getTransactionAmountSettingFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_SETTING_GET_FAILURE,
    });

/**
 * Get User transaction amount  Ewallet range init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getTransactionAmountEwalletSetting = (dispatch) => {
    dispatch({
        type: ACTIONS.TRANSACTION_AMOUNT_EWALLET_SETTING_GET,
    });
};

/**
 * Get User transaction amount Ewallet range success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {TransactionRange} payload Action payload
 */
export const getTransactionAmountEwalletSettingSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_EWALLET_SETTING_GET_SUCCESS,
    });

/**
 * Get User transaction amount Ewallet range failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getTransactionAmountEwalletSettingFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTION_AMOUNT_EWALLET_SETTING_GET_FAILURE,
    });

/**
 * Get Bank Cards  init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getBankCards = (dispatch) => {
    dispatch({
        type: ACTIONS.GET_BANK_CARDS,
    });
};

/**
 * Get Bank Cards  success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Bank[]} payload Action payload
 */
export const getBankCardsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BANK_CARDS_SUCCESS,
    });

/**
 * Get Bank Cards failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getBankCardsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_BANK_CARDS_FAILURE,
    });

/**
 * Get Eligible e wallet init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getEWalletList = (dispatch) => {
    dispatch({
        type: ACTIONS.GET_E_WALLET_LIST,
    });
};

/**
 * Get Eligible e wallet success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Bank[]} payload Action payload
 */
export const getEWalletListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_E_WALLET_LIST_SUCCESS,
    });

/**
 * Get Eligible e wallet failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getEWalletListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_E_WALLET_LIST_FAILURE,
    });

/**
 * Send e-wallet deposit request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const eWalletDepositRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.PAYMENT_E_WALLET_DEPOSIT_REQUEST,
    });

/**
 * Send wallet deposit request success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{bankTransferResponse?: BankTransferResponse}} payload Action payload
 */
export const eWalletDepositRequestSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_E_WALLET_DEPOSIT_REQUEST_SUCCESS,
    });

/**
 * Send wallet deposit request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const eWalletDepositRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAYMENT_E_WALLET_DEPOSIT_REQUEST_FAILURE,
    });

/**
 * Send withdrawal request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const eWalletWithdrawalRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST,
    });

/**
 * Send withdrawal request success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const eWalletWithdrawalRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_SUCCESS,
    });

/**
 * Send withdrawal request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const eWalletWithdrawalRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_WITHDRAWAL_REQUEST_FAILURE,
    });
