import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Alert, Col, Row } from 'antd';

import './NavBar.less';
import { ButtonGreen } from '../../../common/components/Buttons';
import { ICONS } from '../../../common/constants/icons';
import { ROUTES } from '../../../common/constants/routing';
import {
    checkWebViewForUpdate,
    detectMobile,
    detectWebView,
    getAppDownloadLink,
    getStateProp,
    isChinaDomain,
} from '../../../common/helpers/misc';
import { closeDownloadAlert } from '../../../common/actions/userActions';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import ImgIcon from '../../../common/components/ImgIcon';
import Logo from '../../../common/components/Logo';
import SupportButton from '../../../common/components/SupportButton';

const NavBar = ({ onShowDrawer }) => {
    const location = useLocation();
    const [state, dispatch] = useStore();
    const isDownloadAlertClose = getStateProp(state, 'user.isDownloadAlertClose', false);
    const showMessage =
        (detectMobile() && !detectWebView()) || (detectWebView() && checkWebViewForUpdate());
    const webViewHasUpdate = detectWebView() ? checkWebViewForUpdate() : false;
    const isHomePage = location.pathname === ROUTES.HOME;
    const ggbookTitle = isChinaDomain() ? '吉吉宝' : 'GGBOOK';

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (showMessage && isHomePage) {
            const timer = setTimeout(() => {
                if (!isDownloadAlertClose) closeDownloadAlert(dispatch);
            }, 6000);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [dispatch, isDownloadAlertClose, isHomePage, showMessage]);

    // ============================================
    // RENDER
    // ============================================

    const AlertMessage = () => (
        <Row className="alert-banner" align="middle">
            <Col span={4} className="alert-banner-logo">
                <ImgIcon icon={ICONS.NAV.GGBOOK} size={40} lazy={false} />
            </Col>
            <Col span={14}>
                <Row>
                    <span className="alert-banner-title-title">
                        {webViewHasUpdate
                            ? getTranslation('App Update Available', { ggbookTitle })
                            : getTranslation('Download App Now', { ggbookTitle })}
                    </span>
                </Row>
                <Row>
                    <span className="alert-banner-title-text">
                        {getTranslation('The best esports platform!')}
                    </span>
                </Row>
            </Col>
            <Col span={5}>
                <ButtonGreen
                    className="alert-banner-download-button"
                    label={getTranslation('Download')}
                    href={getAppDownloadLink()}
                />
            </Col>
        </Row>
    );

    return (
        <>
            <Row justify="space-between" align="middle" className="navbar__container">
                <Col span={7}>
                    <ImgIcon icon={ICONS.HEADER.BURGER_MENU} onClick={onShowDrawer} width={'50%'} />
                </Col>
                <Col span={10} style={{ textAlign: 'center' }}>
                    <Link to={ROUTES.HOME}>
                        <Logo size="small" />
                    </Link>
                </Col>
                <Col span={7} style={{ textAlign: 'right' }}>
                    <SupportButton />
                </Col>
            </Row>
            {showMessage && !isDownloadAlertClose && isHomePage && (
                <Alert
                    className="alert-style-absolute"
                    message={<AlertMessage />}
                    banner
                    showIcon={false}
                    closable
                    onClose={() => closeDownloadAlert(dispatch)}
                    style={{ position: 'absolute', top: '0', width: '100%' }}
                />
            )}
        </>
    );
};

export default NavBar;
