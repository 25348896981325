import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

import './DateRangeQuickPick.less';
import { getTranslation } from '../helpers/locale';

const getPeriodQuickPicks = (utcOffset = 0) => ({
    LAST_WEEK: {
        key: 'Last Week',
        value: {
            dateFrom: moment()
                .subtract(1, 'w')
                .startOf('isoWeek')
                .utcOffset(utcOffset)
                .utc()
                .format(),
            dateTo: moment()
                .subtract(1, 'w')
                .endOf('isoWeek')
                .utcOffset(utcOffset)
                .utc()
                .format(),
        },
    },
    THIS_WEEK: {
        key: 'This Week',
        value: {
            dateFrom: moment()
                .startOf('isoWeek')
                .utcOffset(utcOffset)
                .utc()
                .format(),
            dateTo: moment()
                .endOf('isoWeek')
                .utcOffset(utcOffset)
                .utc()
                .format(),
        },
    },
    YESTERDAY: {
        key: 'Yesterday',
        value: {
            dateFrom: moment()
                .subtract(1, 'd')
                .startOf('day')
                .utcOffset(utcOffset)
                .utc()
                .format(),
            dateTo: moment()
                .subtract(1, 'd')
                .endOf('day')
                .utcOffset(utcOffset)
                .utc()
                .format(),
        },
    },
});

const DateRangeQuickPick = ({ onPeriodChange, utcOffset, location = '' }) => {
    const [selected, setSelected] = useState(getPeriodQuickPicks(utcOffset).THIS_WEEK.key);

    momentTimeZone.tz.setDefault(location);
    // ============================================
    // METHODS
    // ============================================

    const selectPeriod = (type) => {
        setSelected(type.key);
        onPeriodChange(type.value);
    };

    useEffect(() => {
        onPeriodChange(getPeriodQuickPicks(utcOffset).THIS_WEEK.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // ============================================
    // RENDER
    // ============================================

    const QuickPickBtn = ({ type }) => (
        <Col
            span={8}
            className={
                selected === type.key
                    ? 'date-range-quick-pick__btn--selected'
                    : 'date-range-quick-pick__btn'
            }
            onClick={() => selectPeriod(type)}
        >
            {getTranslation(type.key)}
        </Col>
    );

    return (
        <Row align="middle" justify="space-around" className="date-range-quick-pick">
            <QuickPickBtn type={getPeriodQuickPicks(utcOffset).YESTERDAY} />
            <QuickPickBtn type={getPeriodQuickPicks(utcOffset).THIS_WEEK} />
            <QuickPickBtn type={getPeriodQuickPicks(utcOffset).LAST_WEEK} />
        </Row>
    );
};

export { DateRangeQuickPick, getPeriodQuickPicks };
