import _ from 'lodash';

import { ACTIONS } from './messagesActions';
import { NOTIFICATIONS_PAGE_NO_DEFAULT, NOTIFICATIONS_PAGE_SIZE_DEFAULT } from '../constants';

export default function messagesReducer(
    messages = {
        isPageChanging: false,
        list: [],
        paging: {
            pageNo: NOTIFICATIONS_PAGE_NO_DEFAULT,
            pageSize: NOTIFICATIONS_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.MESSAGE_GET_SUCCESS:
            return {
                ...messages,
                isPageChanging: false,
                list: _.unionBy(messages.list, payload.data, 'id'),
                paging: {
                    ...messages.paging,
                    ...payload.paging,
                },
            };
        case ACTIONS.PAGINATION.PAGE_CHANGE:
            return {
                ...messages,
                isPageChanging: true,
                paging: {
                    ...messages.paging,
                    pageNo:
                        payload > messages.paging.pageCount ? messages.paging.pageCount : payload,
                },
            };
        case ACTIONS.PAGINATION.PAGE_SIZE_CHANGE:
            return {
                ...messages,
                paging: {
                    ...messages.paging,
                    pageNo: 1,
                    pageSize: payload < 1 ? NOTIFICATIONS_PAGE_SIZE_DEFAULT : payload,
                },
            };
        case ACTIONS.READ_COUNTER_GET_SUCCESS:
            return {
                ...messages,
                readCounter: payload,
            };
        case ACTIONS.READ_COUNTER_UPDATE_SUCCESS:
            const newList = [...messages.list];
            _.set(
                _.find(newList, (e) => e.id === payload),
                'isRead',
                true,
            );
            return {
                ...messages,
                list: newList,
                readCounter: messages.readCounter > 0 && messages.readCounter - 1,
            };
        case ACTIONS.READ_COUNTER_GET:
        case ACTIONS.READ_COUNTER_UPDATE:
        default:
            return messages;
    }
}
