import PropTypes from 'prop-types';
import React from 'react';

import _ from 'lodash';

import { ICONS } from '../constants/icons';
import { Row } from 'antd';
import ImgIcon from './ImgIcon';

const SIZES = {
    DEFAULT: { key: 'default', value: 32 },
    LARGE: { key: 'large', value: 64 },
    SMALL: { key: 'small', value: 16 },
};

const Loader = ({ size, style, ...rest }) => (
    <Row
        align="middle"
        justify="center"
        style={{ ...{ flexDirection: 'column', height: 'inherit' }, ...style }}
        {...rest}
    >
        <ImgIcon
            lazy={false}
            width={_.find(_.values(SIZES), (s) => s.key === size).value}
            icon={ICONS.LOADER}
        />
    </Row>
);

Loader.defaultProps = {
    size: SIZES.DEFAULT.key,
};

Loader.propTypes = {
    size: PropTypes.oneOf(_.map(_.values(SIZES), (s) => s.key)),
};

export default Loader;
