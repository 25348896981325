export const ACTIONS = {
    AREAS_GET: 'AREAS_GET',
    AREAS_GET_FAILURE: 'AREAS_GET_FAILURE',
    AREAS_GET_SUCCESS: 'AREAS_GET_SUCCESS',
    CITIES_GET: 'CITIES_GET',
    CITIES_GET_FAILURE: 'CITIES_GET_FAILURE',
    CITIES_GET_SUCCESS: 'CITIES_GET_SUCCESS',
    PROVINCES_GET: 'PROVINCES_GET',
    PROVINCES_GET_FAILURE: 'PROVINCES_GET_FAILURE',
    PROVINCES_GET_SUCCESS: 'PROVINCES_GET_SUCCESS',
};

/**
 * Get list of area init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getAreas = (dispatch) =>
    dispatch({
        type: ACTIONS.AREAS_GET,
    });

/**
 * Get list of area success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String[]} payload List of area
 */
export const getAreasSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AREAS_GET_SUCCESS,
    });

/**
 * Get list of area failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getAreasFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AREAS_GET_FAILURE,
    });

/**
 * Get list of cities init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getCities = (dispatch) =>
    dispatch({
        type: ACTIONS.CITIES_GET,
    });

/**
 * Get list of cities success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String[]} payload List of provinces
 */
export const getCitiesSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CITIES_GET_SUCCESS,
    });

/**
 * Get list of cities failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getCitiesFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CITIES_GET_FAILURE,
    });

/**
 * Get list of provinces init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getProvinces = (dispatch) =>
    dispatch({
        type: ACTIONS.PROVINCES_GET,
    });

/**
 * Get list of provinces success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String[]} payload List of cities
 */
export const getProvincesSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PROVINCES_GET_SUCCESS,
    });

/**
 * Get list of provinces failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getProvincesFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PROVINCES_GET_FAILURE,
    });
