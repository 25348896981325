export const ACTIONS = {
    OPERATIONS_CLAIM_PASSCODE: 'OPERATIONS_CLAIM_PASSCODE',
    OPERATIONS_CLEAR: 'OPERATIONS_CLEAR',
    OPERATIONS_REG_CODE_REQUEST: 'OPERATIONS_REG_CODE_REQUEST',
    OPERATIONS_SIGNUP: 'OPERATIONS_SIGNUP',
    OPERATIONS_SIGNUP_AFFILIATE: 'OPERATIONS_SIGNUP_AFFILIATE',
};

/**
 * Clear operation action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearOperation = (dispatch) =>
    dispatch({
        type: ACTIONS.OPERATIONS_CLEAR,
    });

/**
 * Action for registration code request operation
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Operation id
 */
export const regCodeRequestOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_REG_CODE_REQUEST,
    });

/**
 * Action for claim passcode operation during password reset
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Operation id
 */
export const claimPasscodeOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_CLAIM_PASSCODE,
    });

/**
 * Action for sign up operation
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Operation id
 */
export const signUpOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_SIGNUP,
    });

/**
 * Action for  affiliate sign up operation
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Operation id
 */
export const affiliateSignUpOperation = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.OPERATIONS_SIGNUP_AFFILIATE,
    });
