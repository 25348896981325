import _ from 'lodash';

import { ACTIONS } from './rewardsActions';
import { REWARDS_PAGE_NO_DEFAULT, REWARDS_PAGE_SIZE_DEFAULT, REWARD_STATUSES } from './constants';

export default function rewardReducer(
    rewards = {
        banner: [],
        bonusCode: {},
        isPageChanging: false,
        list: [],
        paging: {
            pageNo: REWARDS_PAGE_NO_DEFAULT,
            pageSize: REWARDS_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        rewardModalVisible: false,
        selectedMenuItem: 'All',
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.REWARDS_CLEAR:
            return {
                ...rewards,
                isPageChanging: false,
                list: [],
                paging: {
                    pageNo: REWARDS_PAGE_NO_DEFAULT,
                    pageSize: REWARDS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                selectedMenuItem: 'All',
            };
        case ACTIONS.REWARDS_GET_SUCCESS:
            return {
                ...rewards,
                isPageChanging: false,
                list:
                    rewards.paging.totalRecordCount === 0
                        ? payload.data
                        : payload.status === REWARD_STATUSES.EXPIRED
                        ? _.unionBy(rewards.list, payload.data, 'bonusRequest.id')
                        : _.unionBy(rewards.list, payload.data, 'id'),
                paging: {
                    ...rewards.paging,
                    ...payload.paging,
                },
            };
        case ACTIONS.REWARDS_GET_DETAILS_SUCCESS:
            const rewardDetails = payload.data;
            return {
                ...rewards,
                rewardDetails,
            };
        case ACTIONS.PAGINATION.PAGE_CHANGE:
            return {
                ...rewards,
                isPageChanging: true,
                paging: {
                    ...rewards.paging,
                    pageNo: payload > rewards.paging.pageCount ? rewards.paging.pageCount : payload,
                },
            };
        case ACTIONS.PAGINATION.PAGE_SIZE_CHANGE:
            return {
                ...rewards,
                paging: {
                    ...rewards.paging,
                    pageNo: 1,
                    pageSize: payload < 1 ? REWARDS_PAGE_SIZE_DEFAULT : payload,
                },
            };
        case ACTIONS.REWARDS_MODAL_OPEN:
            return {
                ...rewards,
                rewardModalVisible: true,
            };
        case ACTIONS.REWARDS_MODAL_CLOSE:
            return {
                ...rewards,
                rewardModalVisible: false,
                selectedDeposit: {},
            };
        case ACTIONS.REWARDS_GET_CATEGORY_SUCCESS:
            return {
                ...rewards,
                category: payload,
            };
        case ACTIONS.REWARDS_SET_SELECTED_CATEGORY:
            return {
                ...rewards,
                paging: {
                    pageNo: REWARDS_PAGE_NO_DEFAULT,
                    pageSize: REWARDS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                selectedMenuItem: payload,
            };

        case ACTIONS.REWARDS_BANNER_GET_SUCCESS:
            return {
                ...rewards,
                banner: payload,
            };

        case ACTIONS.REWARDS_BANNER_CLEAR:
            return {
                ...rewards,
                banner: [],
            };
        case ACTIONS.REWARDS_SET_BONUS_CODE:
            return {
                ...rewards,
                bonusCode: payload,
            };
        case ACTIONS.REWARDS_SET_DEPOSIT_TASK_NO:
            return {
                ...rewards,
                selectedDeposit: payload,
            };
        case ACTIONS.GET_LOGIN_BONUS_SUCCESS:
            return {
                ...rewards,
                ...payload,
            };
        case ACTIONS.REWARDS_GET_DEPOSIT_SUCCESS:
            return {
                ...rewards,
                ...payload,
            };
        case ACTIONS.REWARDS_GET_DEPOSIT:
        case ACTIONS.UPDATE_LOGIN_BONUS:
        case ACTIONS.UPDATE_LOGIN_BONUS_SUCCESS:
        case ACTIONS.GET_LOGIN_BONUS:
        case ACTIONS.REWARDS_BANNER_GET:
        case ACTIONS.REWARDS_GET_CATEGORY:
        case ACTIONS.REWARDS_GET:
        case ACTIONS.REWARDS_GET_DETAILS:
        case ACTIONS.REWARDS_JOIN:
        case ACTIONS.REWARDS_JOIN_SUCCESS:
        case ACTIONS.REWARDS_STATIC_BANNER_GET:
        case ACTIONS.REWARDS_STATIC_BANNER_GET_SUCCESS:
        case ACTIONS.REWARDS_STATIC_BANNER_GET_FAILURE:
        default:
            return rewards;
    }
}
