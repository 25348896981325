import { useCallback, useEffect, useState } from 'react';

import { forceCheck } from 'react-lazyload';
import _ from 'lodash';

import {
    API,
    BETS_REFRESH_DELAY,
    EVENTS_REFRESH_DELAY,
    RESULTS_REFRESH_DELAY,
} from '../common/constants/api';
import {
    BETS_PAGE_NO_DEFAULT,
    BETS_PAGE_SIZE_DEFAULT,
    BETS_SLIP_STATUSES,
    ODD_TYPES,
} from '../components/bets/constants';
import { BET_LIST_TIMEZONE } from '../common/constants/localization';
import { ES_PLATFORM, MARKETS } from '../common/constants/misc';
import { clearError } from '../common/actions/errorActions';
import {
    getBetLimits,
    getBetLimitsFailure,
    getBetLimitsSuccess,
    getBetsSlip,
    getBetsSlipFailure,
    getBetsSlipSuccess,
    getPlayerBetEvents,
    getPlayerBetEventsFailure,
    getPlayerBetEventsSuccess,
    getPlayerBetInfo,
    getPlayerBetInfoFailure,
    getPlayerBetInfoSuccess,
    getPlayerBetStatements,
    getPlayerBetStatementsFailure,
    getPlayerBetStatementsSuccess,
    submitBet,
    submitBetErrorHide,
    submitBetErrorShow,
    submitBetFailure,
    submitBetSuccess,
} from '../components/bets/betsActions';
import {
    getBetSettings,
    getBetSettingsFailure,
    getBetSettingsSuccess,
    getOddsForEvent,
    getOddsForEventFailure,
    getOddsForEventSuccess,
} from '../components/bets/oddsActions';
import {
    getCurrentUser,
    getErrorMessage,
    getErrorStatus,
    getResponseBodyProp,
    isListNotEmpty,
    isUserLoggedIn,
} from '../common/helpers/api';
import { getEventById } from '../components/bets/helpers';
import {
    getEventsList,
    getEventsListFailure,
    getEventsListSuccess,
} from '../components/markets/events/eventsActions';
import { getPeriodQuickPicks } from '../common/components/DateRangeQuickPick';
import {
    getResults,
    getResultsFailure,
    getResultsSuccess,
} from '../components/markets/results/resultsActions';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { setSabaUnderMaintenance } from '../common/actions/sabaActions';
import {
    updateBetSettings,
    updateBetSettingsFailure,
    updateBetSettingsSuccess,
} from '../common/actions/userActions';
import { useDispatch, useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import { useUpdateSelectedBetOdds } from '../components/bets/useUpdateSelectedBetOdds';
import useApi from './hooks/useApi';
import useError from './hooks/useError';

const { VERSION, BET_SETTING, GGBOOK, EVENTS, ODDS, BETS, RESULTS } = API;

export function useGetEventsAsyncEndpoint(platform) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const marketType = getStateProp(state, 'events.marketType', MARKETS.TODAY);
    const oddsType = getStateProp(state, 'user.oddsType', ODD_TYPES.DECIMAL);
    const gameName = getStateProp(state, 'events.gameName');
    const geolocationCurrencyCode = getStateProp(state, 'country.geolocation.currencyCode');
    const userCurrency = getStateProp(state, 'user.currencyCode');
    const userCurrencyCode = isUserLoggedIn() ? userCurrency : geolocationCurrencyCode;
    const eventsList = getStateProp(state, 'events.list', []);

    // ============================================
    // METHODS
    // ============================================

    const getEvents = useCallback(() => {
        getEventsList(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                gameName,
                marketType,
                oddsType,
                userCurrencyCode,
            },
        },
        trigger,
        url: `${VERSION.V1}${platform}${EVENTS}`,
    });

    useEffect(() => {
        if (platform === ES_PLATFORM.SABA || !!userCurrencyCode) getEvents();
    }, [dispatch, marketType, getEvents, gameName, userCurrencyCode, platform]);

    useEffect(() => {
        let interval;
        if (response || error) interval = setTimeout(() => getEvents(), EVENTS_REFRESH_DELAY);
        return () => clearInterval(interval);
    }, [error, getEvents, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (response.data.data) {
                getEventsListSuccess(dispatch, {
                    list: getResponseBodyProp(response, 'events', []),
                    totalEvents: getResponseBodyProp(response, 'totalEventsByMarketType', {}),
                });
                forceCheck();
            }
            if (response.data.serviceUnderMaintenance) {
                setSabaUnderMaintenance(dispatch, {
                    serviceUnderMaintenance: response.data.serviceUnderMaintenance,
                });
            }
        }
    }, [dispatch, response]);

    useUpdateSelectedBetOdds('events', getResponseBodyProp(response, 'events', []), platform);

    //const message = useError(error, getEventsListFailure);

    //useToasterErrorMessage(error, message);
    const isNotEmpty = isListNotEmpty(response, eventsList, 'data.data.events');

    return [loading, isNotEmpty];
}

export function useGetEventDetailsAsyncEndpoint(platform) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const marketType = getStateProp(state, 'events.marketType', MARKETS.TODAY);
    const oddsType = getStateProp(state, 'user.oddsType', ODD_TYPES.DECIMAL);
    const gameName = getStateProp(state, 'events.gameName');
    const geolocationCurrencyCode = getStateProp(state, 'country.geolocation.currencyCode');
    const userCurrency = getStateProp(state, 'user.currencyCode');
    const userCurrencyCode = isUserLoggedIn() ? userCurrency : geolocationCurrencyCode;
    const eventsList = getStateProp(state, 'events.list', []);
    const isNeedUpdate = _.isEmpty(eventsList);

    // ============================================
    // METHODS
    // ============================================

    const getEvents = useCallback(() => {
        getEventsList(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                gameName,
                marketType,
                oddsType,
                userCurrencyCode,
            },
        },
        trigger,
        url: `${VERSION.V1}${platform}${EVENTS}`,
    });

    useEffect(() => {
        if (platform === ES_PLATFORM.SABA && !!userCurrencyCode && isNeedUpdate) getEvents();
    }, [dispatch, isNeedUpdate, getEvents, platform, userCurrencyCode]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (response.data.data) {
                getEventsListSuccess(dispatch, {
                    list: getResponseBodyProp(response, 'events', []),
                    totalEvents: getResponseBodyProp(response, 'totalEventsByMarketType', {}),
                });
                forceCheck();
            }
            if (response.data.serviceUnderMaintenance) {
                setSabaUnderMaintenance(dispatch, {
                    serviceUnderMaintenance: response.data.serviceUnderMaintenance,
                });
            }
        }
    }, [dispatch, response]);

    const message = useError(error, getEventsListFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetBetsByEventIdAsyncEndpoint(eventId, platform) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const eventMarketType = getStateProp(getEventById(state, eventId), 'marketType');
    const geolocationCurrencyCode = getStateProp(state, 'country.geolocation.currencyCode');
    const userCurrency = getStateProp(state, 'user.currencyCode');
    const userCurrencyCode = isUserLoggedIn() ? userCurrency : geolocationCurrencyCode;

    // ============================================
    // METHODS
    // ============================================

    const getEventBets = useCallback(() => {
        getOddsForEvent(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                eventId,
                eventVersionKey: 0,
                marketType: eventMarketType,
                marketVersionKey: 0,
                oddsType: getStateProp(state, 'user.oddsType', ODD_TYPES.DECIMAL),
                userCurrencyCode,
            },
        },
        trigger,
        url: `${VERSION.V1}${platform}${ODDS}`,
    });

    useEffect(() => {
        if (eventMarketType && (platform === ES_PLATFORM.SABA || !!userCurrencyCode)) {
            getEventBets();
        }
    }, [dispatch, eventMarketType, getEventBets, platform, userCurrencyCode]);

    useEffect(() => {
        let interval;
        if (response || error) interval = setTimeout(() => getEventBets(), BETS_REFRESH_DELAY);
        return () => clearInterval(interval);
    }, [error, getEventBets, response]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            if (response.data.data) {
                getOddsForEventSuccess(dispatch, {
                    event: getResponseBodyProp(response, 'event', {}),
                    list: getResponseBodyProp(response, 'oddGroups', []),
                });
            }
        }
    }, [dispatch, response]);

    useUpdateSelectedBetOdds('bets', getResponseBodyProp(response, 'oddGroups', []), platform);

    const message = useError(error, getOddsForEventFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useSubmitBetAsyncEndpoint(platform) {
    const [state, dispatch] = useStore();
    const selectedBet = getStateProp(state, 'bets.selectedBet');
    const {
        decimalPrice,
        key,
        id: marketId,
        malayPrice,
        mapNumber,
        marketOption,
        marketType,
        point,
        price,
        stake,
        eventId,
        eventOccurrence,
        useDecimalPrice,
    } = selectedBet;
    const refreshWallet = getStateProp(state, 'wallet.refreshWalletSummary', () => {});
    const oddsType = getStateProp(state, 'user.oddsType', ODD_TYPES.DECIMAL);
    const autoAcceptBetterOdd = getStateProp(state, 'odds.autoAcceptBetterOdds', false);
    const isTFGamingPlatform = platform === ES_PLATFORM.TF_GAMING;

    // ============================================
    // METHODS
    // ============================================

    const handleBetSubmit = () => {
        submitBetErrorHide(dispatch);
        submitBet(dispatch);
        executeRequest();
    };

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response, executeRequest } = useApi({
        method: 'POST',
        options: {
            data: {
                autoAcceptBetterOdd: isTFGamingPlatform ? undefined : autoAcceptBetterOdd,
                eventId,
                eventOccurrence,
                key,
                malayPrice,
                mapNumber,
                marketId,
                marketOption,
                marketType,
                oddsType: useDecimalPrice ? ODD_TYPES.DECIMAL : oddsType,
                point,
                price: useDecimalPrice ? decimalPrice : price,
                stake,
            },
        },
        url: `${VERSION.V1}${platform}${BETS.PLACE}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            submitBetSuccess(dispatch, {
                isTFGamingPlatform,
                pending: 0,
                running: getResponseBodyProp(response, 'running'),
            });
            refreshWallet();
        }
    }, [dispatch, isTFGamingPlatform, refreshWallet, response]);

    useEffect(() => {
        if ([400, 550].includes(getErrorStatus(error))) {
            submitBetErrorShow(dispatch, getErrorMessage(error));
            submitBetFailure(dispatch, {
                message: getErrorMessage(error),
            });
        }
    }, [dispatch, error]);

    useToasterErrorMessage(getErrorStatus(error) === 500);

    useToasterSuccessMessage(response, getTranslation('Your bet has been placed'));

    return [loading, handleBetSubmit];
}

export function useGetBetsSlipAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const slipStatus = getStateProp(state, 'bets.slipStatus', BETS_SLIP_STATUSES.RUNNING);
    const betSlipPeriod = getStateProp(state, 'bets.slipPeriod', null);
    const isStatementRequest = slipStatus === BETS_SLIP_STATUSES.STATEMENTS;
    const isRunningBets = slipStatus === BETS_SLIP_STATUSES.RUNNING;

    const betList = getStateProp(state, 'bets.slip', []);
    const platform = getStateProp(state, 'bets.slipPlatform');

    const periodFilter = betSlipPeriod
        ? betSlipPeriod
        : getPeriodQuickPicks(BET_LIST_TIMEZONE.OFFSET).THIS_WEEK.value;
    const params = isStatementRequest
        ? {
              ...periodFilter,
              platforms: platform.split(','),
          }
        : {
              platforms: platform.split(','),
          };

    // ============================================
    // METHODS
    // ============================================

    const handleGetSlip = useCallback(() => {
        getBetsSlip(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: isStatementRequest || isRunningBets ? 'POST' : 'GET',
        options: {
            data: params,
        },
        trigger,
        url: `${VERSION.V1}${
            isStatementRequest ? BETS.STATEMENTS : isRunningBets ? BETS.RUNNING : BETS.PENDING
        }`,
    });

    useEffect(() => {
        handleGetSlip();
    }, [handleGetSlip, slipStatus, betSlipPeriod, platform]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBetsSlipSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getBetsSlipFailure);

    useToasterErrorMessage(error, message);
    const isNotEmpty = isListNotEmpty(response, betList);

    return [loading, isNotEmpty];
}

export function useGetPlayerBetInfoAsyncEndpoint(userId) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const getBetInfo = useCallback(() => {
        getPlayerBetInfo(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${GGBOOK}${BETS.PLAYER_BET_INFO}`,
    });

    useEffect(() => {
        getBetInfo();
    }, [getBetInfo]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getPlayerBetInfoSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getPlayerBetInfoFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetBetLimitsAsyncEndpoint(marketId, key, platform) {
    const dispatch = useDispatch();
    const [trigger, setTrigger] = useState(null);
    const isTFGamingPlatform = platform === ES_PLATFORM.TF_GAMING;

    // ============================================
    // METHODS
    // ============================================

    const handleGetBetLimits = useCallback(() => {
        getBetLimits(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${GGBOOK}${BETS.BET_LIMITS}?marketId=${marketId}&key=${key}`,
    });

    useEffect(() => {
        if (marketId && key && !isTFGamingPlatform) handleGetBetLimits();
    }, [handleGetBetLimits, marketId, key, isTFGamingPlatform]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBetLimitsSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    useEffect(() => {
        if (getErrorStatus(error) === 550) {
            submitBetErrorShow(dispatch, getErrorMessage(error));
            getBetLimitsFailure(dispatch, {
                message: getErrorMessage(error),
            });
        }
    }, [dispatch, error]);

    useToasterErrorMessage(getErrorStatus(error) === 500);

    return [loading];
}

export function useGetResultsAsyncEndpoint(platform) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const period = getStateProp(state, 'results.period');
    const gameName = getStateProp(state, 'results.selected');
    const resultsList = getStateProp(state, 'results.list', []);

    // ============================================
    // METHODS
    // ============================================

    const getEventResults = useCallback(() => {
        getResults(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ...period,
                gameName,
            },
        },
        trigger,
        url: `${VERSION.V1}${platform}${RESULTS}`,
    });

    useEffect(() => {
        getEventResults();
        const interval = setInterval(() => getEventResults(), RESULTS_REFRESH_DELAY);
        return () => clearInterval(interval);
    }, [dispatch, getEventResults, gameName, period]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getResultsSuccess(dispatch, response.data.data);
            forceCheck();
        }
    }, [dispatch, response]);

    const message = useError(error, getResultsFailure);

    useToasterErrorMessage(error, message);
    const isNotEmpty = isListNotEmpty(response, resultsList);

    return [loading, isNotEmpty];
}

export function useGetResultDetailsAsyncEndpoint(platform) {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const period = getStateProp(state, 'results.period');
    const gameName = getStateProp(state, 'results.selected');
    const resultsList = getStateProp(state, 'results.list', []);
    const isNeedUpdate = _.isEmpty(resultsList);

    // ============================================
    // METHODS
    // ============================================

    const getEventResults = useCallback(() => {
        getResults(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                ...period,
                gameName,
            },
        },
        trigger,
        url: `${VERSION.V1}${platform}${RESULTS}`,
    });

    useEffect(() => {
        if (isNeedUpdate) getEventResults();
    }, [dispatch, getEventResults, isNeedUpdate]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getResultsSuccess(dispatch, response.data.data);
            forceCheck();
        }
    }, [dispatch, response]);

    const message = useError(error, getResultsFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetBetSettingsAsyncEndpoint() {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    const handleGetBetSettings = useCallback(() => {
        getBetSettings(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${BET_SETTING}/${userId}`,
    });

    useEffect(() => {
        if (userId) handleGetBetSettings();
    }, [handleGetBetSettings, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getBetSettingsSuccess(dispatch, response.data.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getBetSettingsFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useUpdateBetSettingsAsyncEndpoint(fieldName, value) {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    const formattedInfo = [
        {
            op: 'replace',
            path: `/${fieldName}`,
            value,
        },
    ];

    // ============================================
    // METHODS
    // ============================================

    const handleUpdateBetSettings = () => {
        updateBetSettings(dispatch);
        setTrigger(+new Date());
    };

    // ============================================
    // HOOKS
    // ============================================

    const { error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'PATCH',
        options: {
            data: formattedInfo,
        },
        trigger,
        url: `${VERSION.V1}${BET_SETTING}/${userId}`,
    });

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            updateBetSettingsSuccess(dispatch, { [fieldName]: value });
        }
    }, [dispatch, fieldName, response, value]);

    const errorMsg = useError(error, updateBetSettingsFailure);
    useToasterErrorMessage(error, errorMsg);

    return [handleUpdateBetSettings];
}

export function useGetPlayerBetEventsAsyncEndpoint(platform) {
    const dispatch = useDispatch();
    const userId = getStateProp(getCurrentUser(), 'id');
    const [trigger, setTrigger] = useState(null);

    // ============================================
    // METHODS
    // ============================================

    const handleGetPlayerBetEvents = useCallback(() => {
        getPlayerBetEvents(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger && !!userId,
        method: 'GET',
        trigger,
        url: `${VERSION.V1}${platform}${BETS.PLAYER_BET_EVENTS}`,
    });

    useEffect(() => {
        if (userId) handleGetPlayerBetEvents();
    }, [handleGetPlayerBetEvents, userId]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            const payload =
                platform === ES_PLATFORM.SABA
                    ? {
                          sabaBets: response.data.data,
                      }
                    : { TFBets: response.data.data };
            getPlayerBetEventsSuccess(dispatch, payload);
        }
    }, [dispatch, platform, response]);

    const message = useError(error, getPlayerBetEventsFailure);

    useToasterErrorMessage(error, message);

    return [loading];
}

export function useGetGamesBetsStatementAsyncEndpoint() {
    const [state, dispatch] = useStore();
    const [trigger, setTrigger] = useState(null);
    const slipStatus = getStateProp(state, 'bets.slipStatus', BETS_SLIP_STATUSES.RUNNING);
    const betSlipPeriod = getStateProp(state, 'bets.slipPeriod', null);
    const betList = getStateProp(state, 'bets.slip', []);
    const platformCode = getStateProp(state, 'bets.statementPlatform');
    const productCode = getStateProp(state, 'bets.statementProduct');

    const { pageNo, pageSize } = getStateProp(state, 'bets.paging')
        ? getStateProp(state, 'bets.paging')
        : {
              pageNo: BETS_PAGE_NO_DEFAULT,
              pageSize: BETS_PAGE_SIZE_DEFAULT,
          };
    const periodFilter = betSlipPeriod
        ? betSlipPeriod
        : getPeriodQuickPicks(BET_LIST_TIMEZONE.OFFSET).THIS_WEEK.value;

    // ============================================
    // METHODS
    // ============================================

    const handleGetGamesBetsStatement = useCallback(() => {
        getPlayerBetStatements(dispatch);
        setTrigger(+new Date());
    }, [dispatch]);

    // ============================================
    // HOOKS
    // ============================================

    const { loading, error, response } = useApi({
        forceDispatchEffect: () => !!trigger,
        method: 'POST',
        options: {
            data: {
                fromDate: periodFilter.dateFrom,
                pageNo,
                pageSize,
                platformCode,
                productCode,
                toDate: periodFilter.dateTo,
            },
        },
        trigger,
        url: `${VERSION.V1}${BETS.BETS_PLAYER_STATEMENTS}`,
    });

    useEffect(() => {
        handleGetGamesBetsStatement();
    }, [
        handleGetGamesBetsStatement,
        slipStatus,
        betSlipPeriod,
        platformCode,
        productCode,
        pageNo,
        pageSize,
    ]);

    useEffect(() => {
        if (response) {
            clearError(dispatch);
            getPlayerBetStatementsSuccess(dispatch, response.data);
        }
    }, [dispatch, response]);

    const message = useError(error, getPlayerBetStatementsFailure);

    useToasterErrorMessage(error, message);
    const isNotEmpty = isListNotEmpty(response, betList);

    return [loading, isNotEmpty];
}
