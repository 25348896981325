import { Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';

import {
    AboutUs,
    Advantplay,
    AdvantplayMiniGames,
    Affiliate,
    AgReturn,
    AppDownload,
    AppVersion,
    Aster,
    BankCards,
    BettingRoot,
    CasinoGames,
    CryptoTutorial,
    DailyLoginInfo,
    EWallet,
    EventDetails,
    FAQ,
    ForgotPassword,
    GeekFam,
    Home,
    Login,
    Markets,
    News,
    Notifications,
    Profile,
    Referral,
    Register,
    ResultDetails,
    Rewards,
    Rules,
    SponsorShip,
    TFGaming,
    Terms,
    Transactions,
    Triton,
    VerifyAccount,
    Vip,
    Wallet,
} from '../..';
import { ES_PLATFORM } from '../../../common/constants/misc';
import { ICONS } from '../../../common/constants/icons';
import { ROUTES } from '../../../common/constants/routing';
import {
    useGetGeoLocationAsyncEndpoint,
    useGetUserAsyncEndpoint,
    useUpdateAppLanguageAsyncEndpoint,
} from '../../../services/userService';
import { useGetReadCounterAsyncEndpoint } from '../../../services/notificationsService';
import AdvantplayNavBar from '../../advantplay/AdvantplayNavBar';
import Layout from '../layout/Layout';
import LayoutRoute from './LayoutRoute';
import Loader from '../../../common/components/Loader';
import PrivateRoute from './PrivateRoute';
import useHandleMultiLogin from '../../../services/hooks/useHandleMultiLogin';
import useInitSignalRHub from '../../../services/hooks/useInitSignalRHub';
import useVersioning from '../../../services/hooks/useVersioning';

const BettingNavPanel = lazy(() => import('../../bets/BettingNavPanel'));
const ErrorPage = lazy(() => import('../../../common/components/error-page/ErrorPage'));
const MarketsNavBar = lazy(() => import('../../markets/MarketsNavBar'));
const NotFoundPage = lazy(() => import('../../../common/components/error-page/NotFoundPage'));
const StatsRoutes = lazy(() => import('./StatsRoutes'));
const WalletNavBar = lazy(() => import('../../wallet/WalletNavBar'));
const RegisterNavBar = lazy(() => import('../../register/RegisterNavBar'));

const AppRoutes = () => {
    // ============================================
    // HOOKS
    // ============================================

    const [geoLocationLoading] = useGetGeoLocationAsyncEndpoint();
    useGetUserAsyncEndpoint();
    useGetReadCounterAsyncEndpoint();
    const [appLoading] = useVersioning();
    const [onPlayerSignedIn] = useHandleMultiLogin();
    useInitSignalRHub({ onPlayerSignedIn });
    useUpdateAppLanguageAsyncEndpoint();

    // ============================================
    // RENDER
    // ============================================

    return appLoading ? (
        <Loader style={{ height: '100vh ' }} size="large" />
    ) : (
        <Suspense
            fallback={
                <Layout>
                    <Loader size="large" />
                </Layout>
            }
        >
            <Switch>
                <LayoutRoute
                    exact
                    path={ROUTES.HOME}
                    component={Home}
                    componentProps={{ geoLocationLoading }}
                />
                <Route path={ROUTES.CASINO.ROOT} component={CasinoGames} />
                <PrivateRoute path={ROUTES.PROFILE.ROOT} component={Profile} />
                <LayoutRoute
                    privateRoute
                    path={ROUTES.WALLET.ROOT}
                    component={Wallet}
                    customHeader={WalletNavBar}
                />
                <LayoutRoute
                    path={ROUTES.NEWS.ROOT}
                    component={News}
                    backToHeader={{ title: 'News' }}
                />
                <LayoutRoute
                    privateRoute
                    path={ROUTES.TRANSACTIONS.ROOT}
                    component={Transactions}
                    backToHeader={{ title: 'Transactions' }}
                />
                <LayoutRoute
                    path={ROUTES.LOGIN}
                    component={Login}
                    backToHeader={{ title: 'Welcome Back' }}
                    defaultFooter={false}
                />
                <LayoutRoute
                    path={ROUTES.RESET_PASSWORD}
                    component={ForgotPassword}
                    backToHeader={{ title: 'Forgot Password' }}
                    defaultFooter={false}
                />
                <LayoutRoute
                    path={ROUTES.REGISTER}
                    component={Register}
                    defaultFooter={false}
                    customHeader={RegisterNavBar}
                />

                <PrivateRoute path={ROUTES.BANK_CARDS.ROOT} component={BankCards} />
                <PrivateRoute path={ROUTES.E_WALLET.ROOT} component={EWallet} />

                <LayoutRoute
                    path={ROUTES.DAILY_LOGIN}
                    backToHeader={{ title: 'Daily Login' }}
                    component={DailyLoginInfo}
                />
                <LayoutRoute
                    path={ROUTES.REWARDS.ROOT}
                    backToHeader={{ title: 'Rewards' }}
                    component={Rewards}
                    componentProps={{ geoLocationLoading }}
                />
                <LayoutRoute
                    path={ROUTES.BETTING.ROOT}
                    backToHeader={{ title: 'Betting Summary' }}
                    component={BettingRoot}
                />
                <LayoutRoute
                    privateRoute
                    path={ROUTES.REFERRAL_PROGRAM}
                    backToHeader={{ title: 'Referral Program' }}
                    component={Referral}
                />
                <Route path={ROUTES.AFFILIATE_PROGRAM.ROOT} component={Affiliate} />
                <LayoutRoute
                    path={ROUTES.SABA_MARKETS.EVENT_DETAILS}
                    backToHeader={{ title: 'Event Details' }}
                    component={EventDetails}
                    componentProps={{ platform: ES_PLATFORM.SABA }}
                    defaultFooter={false}
                />
                <LayoutRoute
                    path={ROUTES.TF_GAMING_MARKETS.EVENT_DETAILS}
                    backToHeader={{ title: 'Event Details' }}
                    component={EventDetails}
                    componentProps={{ platform: ES_PLATFORM.TF_GAMING }}
                    defaultFooter={false}
                />
                <LayoutRoute
                    path={ROUTES.SABA_MARKETS.RESULT_DETAILS}
                    backToHeader={{ title: 'Result Details' }}
                    component={ResultDetails}
                    componentProps={{ platform: ES_PLATFORM.SABA }}
                />
                <LayoutRoute
                    path={ROUTES.TF_GAMING_MARKETS.RESULT_DETAILS}
                    backToHeader={{ title: 'Result Details' }}
                    component={ResultDetails}
                    componentProps={{ platform: ES_PLATFORM.TF_GAMING }}
                />
                <LayoutRoute
                    path={ROUTES.SABA_MARKETS.ROOT}
                    component={Markets}
                    customFooter={BettingNavPanel}
                    customFooterProps={{ platform: ES_PLATFORM.SABA }}
                    customHeader={MarketsNavBar}
                />
                <LayoutRoute
                    path={ROUTES.TF_GAMING_MARKETS.ROOT}
                    component={TFGaming}
                    customFooter={BettingNavPanel}
                    customFooterProps={{ platform: ES_PLATFORM.TF_GAMING }}
                    customHeader={MarketsNavBar}
                />
                <LayoutRoute
                    path={ROUTES.NOTIFICATIONS.ROOT}
                    component={Notifications}
                    backToHeader={{ title: 'Notifications & Messages' }}
                />
                <LayoutRoute
                    path={ROUTES.ABOUT_US}
                    component={AboutUs}
                    backToHeader={{ title: 'About Us' }}
                />
                <LayoutRoute
                    path={ROUTES.APP_VERSION}
                    component={AppVersion}
                    backToHeader={{ title: 'App Version' }}
                />
                <LayoutRoute
                    path={ROUTES.TERMS}
                    component={Terms}
                    backToHeader={{ title: 'Terms & Conditions' }}
                />
                <LayoutRoute
                    path={ROUTES.RULES}
                    component={Rules}
                    backToHeader={{ title: 'Rules & Regulation' }}
                />
                <LayoutRoute path={ROUTES.FAQ} component={FAQ} backToHeader={{ title: 'FAQ' }} />
                <LayoutRoute
                    path={ROUTES.ERROR}
                    customHeader={MarketsNavBar}
                    component={ErrorPage}
                />
                <LayoutRoute
                    path={ROUTES.APP_DOWNLOAD}
                    component={AppDownload}
                    backToHeader={{ title: 'App Download' }}
                />

                <LayoutRoute
                    path={ROUTES.SPONSORSHIP.GEEKFAM}
                    component={GeekFam}
                    backToHeader={{ title: 'GeekFam' }}
                />
                <LayoutRoute
                    path={ROUTES.SPONSORSHIP.ASTER}
                    component={Aster}
                    backToHeader={{ title: 'Team Aster' }}
                />
                <LayoutRoute
                    path={ROUTES.SPONSORSHIP.TRITON}
                    component={Triton}
                    backToHeader={{ title: 'Triton Poker' }}
                />
                <LayoutRoute
                    path={ROUTES.SPONSORSHIP.ROOT}
                    component={SponsorShip}
                    backToHeader={{ title: 'Sponsorships' }}
                />
                <LayoutRoute
                    path={ROUTES.CRYPTO_TUTORIAL}
                    component={CryptoTutorial}
                    backToHeader={{ title: 'USDT Crypto Currency' }}
                />

                <LayoutRoute
                    privateRoute
                    path={ROUTES.VERIFY_ACCOUNT}
                    component={VerifyAccount}
                    backToHeader={{
                        title: 'Verify Account',
                    }}
                />

                <LayoutRoute path={ROUTES.AG_RETURN} component={AgReturn} />
                <Route path={ROUTES.STATS.ROOT} component={StatsRoutes} />
                <LayoutRoute
                    path={ROUTES.ADVANTPLAY.ROOT}
                    component={Advantplay}
                    customHeader={AdvantplayNavBar}
                    customHeaderProps={{
                        icon: ICONS.ADVANTPLAY.LOGO,
                    }}
                />
                <LayoutRoute
                    path={ROUTES.ADVANTPLAY_MINI_GAME.ROOT}
                    component={AdvantplayMiniGames}
                    customHeader={AdvantplayNavBar}
                    customHeaderProps={{
                        icon: ICONS.ADVANTPLAY_MINI_GAME.LOGO,
                    }}
                />
                <LayoutRoute
                    path={ROUTES.VIP.ROOT}
                    backToHeader={{ title: 'Vip Program' }}
                    component={Vip}
                    componentProps={{ geoLocationLoading }}
                />

                <LayoutRoute customHeader={MarketsNavBar} component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};

export default AppRoutes;
