import { useEffect } from 'react';

import * as signalR from '@microsoft/signalr';

import { getCurrentUser, isUserLoggedIn } from '../../common/helpers/api';
import { getStateProp, isTestEnvironment } from '../../common/helpers/misc';

const useInitSignalRHub = ({ onPlayerSignedIn = () => {} }) => {
    let connection = null;

    const userLoggedIn = isUserLoggedIn();
    const accessToken = getStateProp(getCurrentUser(), 'accessToken', null);
    const hubUrl =
        window.location.hostname === 'localhost'
            ? 'https://app.dev.ggbook.org/esports-hub'
            : '/esports-hub';

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (userLoggedIn) {
            connection = new signalR.HubConnectionBuilder()
                .withUrl(hubUrl, { useDefaultPath: false })
                .withAutomaticReconnect()
                .configureLogging(signalR.LogLevel.Information)
                .build();

            const startConnection = () => {
                connection
                    .start()
                    .then(() => {
                        connection.invoke('initializeAsync', accessToken);
                    })
                    .catch((err) => console.error('SignalR Connection Error: ', err));
            };

            startConnection();

            connection.on('connected', () => {
                if (isTestEnvironment())
                    console.log('SignalR connection established successfully.');
            });

            connection.on('disconnected', () => {
                console.log('SignalR connection failed, invalid token');
            });

            connection.onclose(startConnection);

            connection.on('player_signed_in', (response) => {
                console.log('User logged in', response);

                if (response) onPlayerSignedIn(response);
            });
        }

        return () => {
            if (connection) connection.stop();
        };
        /*eslint react-hooks/exhaustive-deps:0*/
    }, [hubUrl, userLoggedIn]);

    return connection;
};

export default useInitSignalRHub;
