import { ACTIONS } from './eventsActions';
import { MARKETS } from '../../../common/constants/misc';
import _ from 'lodash';

export default function eventsReducer(
    events = {
        marketType: MARKETS.TODAY,
        showStream: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.GAME_EVENTS_GET_SUCCESS:
            const { list, totalEvents } = payload;
            return {
                ...events,
                list,
                totalEvents,
            };
        case ACTIONS.GAME_EVENTS_MARKET_TYPE_CHANGE:
            return {
                ...events,
                filterPredicate: (x) => x,
                list: events.marketType === payload ? events.list : [],
                marketType: payload,
                selected: null,
            };
        case ACTIONS.GAME_EVENTS_GET_FAILURE:
            return {};
        case ACTIONS.GAME_EVENT_ADD_TO_FAVOURITES:
            return {
                ...events,
                favourites: events.favourites ? [...events.favourites, payload] : [payload],
            };
        case ACTIONS.GAME_EVENT_REMOVE_FROM_FAVOURITES:
            return {
                ...events,
                favourites: [..._.filter(events.favourites, (f) => f !== payload)],
            };
        case ACTIONS.GAME_EVENTS_APPLY_FILTER:
            const { selected, filterPredicate } = payload;
            return {
                ...events,
                filterPredicate,
                selected,
            };
        case ACTIONS.GAME_EVENTS_DROP_FILTER:
            return {
                ...events,
                filterPredicate: (x) => x,
                selected: null,
            };

        case ACTIONS.GAME_EVENTS_APPLY_GAME_FILTER:
            return {
                ...events,
                gameName: payload,
                list: events.gameName === payload ? events.list : [],
            };
        case ACTIONS.GAME_EVENTS_DROP_GAME_FILTER:
            return {
                ...events,
                gameName: null,
                list: [],
            };
        case ACTIONS.GAME_EVENTS_SHOW_STREAM:
            return {
                ...events,
                showStream: payload,
            };
        case ACTIONS.GAME_EVENTS_GET:
        default:
            return events;
    }
}
