import PropTypes from 'prop-types';
import React from 'react';

import { Button, Col, Popover, Row } from 'antd';

import './SupportPopover.less';
import { ICONS } from '../constants/icons';
import { detectWebView, getStateProp } from '../helpers/misc';
import { getTranslation } from '../helpers/locale';
import ImgIcon from './ImgIcon';

const SupportPopover = ({ visible, onCancel, openLiveChat, state }) => {
    const geolocationCountryCode = getStateProp(state, 'country.geolocation.countryCode', '');
    const browserCountryCode = getStateProp(state, 'country.code', '');
    const countryCode = geolocationCountryCode || browserCountryCode;
    const isIndo = countryCode === 'id'; //show whatsapp
    const isThai = countryCode === 'th'; //show Line
    const isMalaysia = countryCode === 'my'; //show whatsapp

    const ChatButton = ({ icon, name, onClick = () => {} }) => {
        return (
            <Col span={8} className="support-popover__item__content" onClick={onClick}>
                <ImgIcon height={35} icon={icon} />
                <span className="support-popover__item__text">{name}</span>
                <span className="support-popover__item__indicator" />
            </Col>
        );
    };

    const ChatQRCode = () => {
        const qrCode = isMalaysia
            ? ICONS.CUSTOMER_SUPPORT.WHATSAPP_QR.MALAY
            : isIndo
            ? ICONS.CUSTOMER_SUPPORT.WHATSAPP_QR.INDO
            : ICONS.CUSTOMER_SUPPORT.LINE_QR;
        return (
            <>
                <p className="support-popover__caption">
                    {getTranslation('Or scan QR code below:')}
                </p>
                <Row justify="space-around" className="support-popover__qr">
                    <ImgIcon icon={qrCode} height={90} />
                    {isIndo && <ImgIcon icon={ICONS.CUSTOMER_SUPPORT.TELEGRAM_QR} height={90} />}
                </Row>
            </>
        );
    };

    const PopoverContent = () => {
        return (
            <>
                <Row justify="center">
                    <span className="support-popover__title">
                        {getTranslation('Customer Support')}
                    </span>
                </Row>

                {isIndo ? (
                    <>
                        <Row justify="center">
                            <span className="support-popover__caption">
                                {getTranslation('Select an option:')}
                            </span>
                        </Row>
                        <Row justify="space-around" className="support-popover__content">
                            <ChatButton
                                icon={ICONS.CUSTOMER_SUPPORT.LIVECHAT}
                                name={'Livechat'}
                                onClick={openLiveChat}
                            />
                            {isMalaysia && (
                                <ChatButton
                                    icon={ICONS.CUSTOMER_SUPPORT.WHATSAPP}
                                    name={'Whatsapp'}
                                    onClick={() => {
                                        window.open(
                                            'https://wa.me/message/BP4TN3L3Y5Y4G1',
                                            '_blank',
                                        );
                                    }}
                                />
                            )}
                            {isIndo && (
                                <>
                                    <ChatButton
                                        icon={ICONS.CUSTOMER_SUPPORT.WHATSAPP}
                                        name="Whatsapp"
                                        onClick={() => {
                                            window.open(
                                                'https://api.whatsapp.com/send/?phone=6287780369899&text&app_absent=0',
                                                '_blank',
                                            );
                                        }}
                                    />
                                    <ChatButton
                                        icon={ICONS.CUSTOMER_SUPPORT.TELEGRAM}
                                        name="Telegram"
                                        onClick={() => {
                                            window.open('https://t.me/GGBOOKS', '_blank');
                                        }}
                                    />
                                </>
                            )}
                            {isThai && (
                                <ChatButton
                                    icon={ICONS.CUSTOMER_SUPPORT.LINE}
                                    name={'Line'}
                                    onClick={() => {
                                        window.open('https://lin.ee/b6T8Ioi', '_blank');
                                    }}
                                />
                            )}
                        </Row>
                        {(isThai || isMalaysia || isIndo) && !detectWebView() ? (
                            <ChatQRCode />
                        ) : null}
                    </>
                ) : (
                    <Row justify="center" style={{ padding: '10px' }}>
                        <span className="support-popover__caption">
                            {getTranslation('Live chat is currently not available')}
                        </span>
                    </Row>
                )}
                <Button className="support-popover__btn" onClick={onCancel}>
                    {getTranslation('Close')}
                </Button>
            </>
        );
    };

    return (
        visible && (
            <div>
                <Popover
                    content={<PopoverContent />}
                    trigger="click"
                    visible={true}
                    placement="bottom"
                    className="support-popover"
                />
            </div>
        )
    );
};

SupportPopover.defaultProps = {
    closable: true,
    visible: false,
};

SupportPopover.propTypes = {
    closable: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

export default SupportPopover;
