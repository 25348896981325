import React from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Menu, Row } from 'antd';
import moment from 'moment';

import { detectIphone, getStateProp } from '../../common/helpers/misc';
import { getAvailableLanguages } from '../../common/helpers/locale';
import { setCurrentLanguage } from './actions';
import { setLangStorageItem } from '../../common/helpers/api';
import { useStore } from '../../store/StateProvider';
import FlagIcon from '../../common/components/FlagIcon.jsx';

const LanguageMenu = () => {
    const [state, dispatch] = useStore();
    const currentLanguage = getStateProp(state, 'country.language');
    const availableLanguage = getAvailableLanguages(state);

    // ============================================
    // HOOKS
    // ============================================

    // ============================================
    // METHODS
    // ============================================

    const switchLocale = ({ key }) => {
        setLangStorageItem(key);
        moment.locale(key);
        setCurrentLanguage(dispatch, { language: key });
        //if (window.LiveChatWidget) window.LiveChatWidget.call('destroy');//comment out while using geo to route chats,only use to call when destroying chat sessions.
        if (detectIphone()) window.location.reload();
    };

    // ============================================
    // RENDER
    // ============================================

    return (
        <Menu onClick={switchLocale} theme="dark" selectedKeys={[currentLanguage]}>
            {availableLanguage.map((lang) => (
                <Menu.Item key={lang.value}>
                    <Row align="middle" justify="space-between">
                        <span style={{ paddingLeft: 6 }}>
                            <FlagIcon code={lang.code} />
                            <span style={{ paddingLeft: 6, paddingRight: 12 }}>{lang.name}</span>
                        </span>
                        {lang.value === currentLanguage && <CheckCircleOutlined />}
                    </Row>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default LanguageMenu;
