export const ACTIONS = {
    BET_SETTINGS_GET: 'BET_SETTINGS_GET',
    BET_SETTINGS_GET_FAILURE: 'BET_SETTINGS_GET_FAILURE',
    BET_SETTINGS_GET_SUCCESS: 'BET_SETTINGS_GET_SUCCESS',
    ODDS_CLEAR: 'ODDS_CLEAR',
    ODDS_FOR_EVENT_GET: 'ODDS_FOR_EVENT_GET',
    ODDS_FOR_EVENT_GET_FAILURE: 'ODDS_FOR_EVENT_GET_FAILURE',
    ODDS_FOR_EVENT_GET_SUCCESS: 'ODDS_FOR_EVENT_GET_SUCCESS',
};

/**
 * Get odds init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getOddsForEvent = (dispatch) =>
    dispatch({
        type: ACTIONS.ODDS_FOR_EVENT_GET,
    });

/**
 * Get odds success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{event: Object, list: Array<Object>}} payload Odds data from API response
 */
export const getOddsForEventSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ODDS_FOR_EVENT_GET_SUCCESS,
    });

/**
 * Get odds failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getOddsForEventFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.ODDS_FOR_EVENT_GET_FAILURE,
    });

/**
 * Get bet settings init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getBetSettings = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_SETTINGS_GET,
    });

/**
 * Get bet settings success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {BetSettings} payload Action payload
 */
export const getBetSettingsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SETTINGS_GET_SUCCESS,
    });

/**
 * Get bet settings failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getBetSettingsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SETTINGS_GET_FAILURE,
    });

/**
 * Clear odds action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearOdds = (dispatch) =>
    dispatch({
        type: ACTIONS.ODDS_CLEAR,
    });
