import PropTypes from 'prop-types';
import React from 'react';

import { ICONS } from '../constants/icons';
import { getImageByDomain } from '../helpers/misc';
import ImgIcon from './ImgIcon';

const Logo = ({ size, width, height = null }) => {
    const isLargeLogo = size === 'large';
    const logo = isLargeLogo ? getImageByDomain(ICONS.LOGO_BIG) : getImageByDomain(ICONS.LOGOS);
    const imgWidth = height ? 'auto' : width ? width : isLargeLogo ? 100 : 110;
    const imgHeight = height ? height : 'auto';

    return <ImgIcon icon={logo} width={imgWidth} height={imgHeight} alt="logo" />;
};

Logo.propTypes = {
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(['small', 'large']),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Logo;
