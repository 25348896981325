export const ACTIONS = {
    GET_SABA_PP_JACKPOT: 'GET_SABA_PP_JACKPOT',
    GET_SABA_PP_JACKPOT_FAILURE: 'GET_SABA_PP_JACKPOT_FAILURE',
    GET_SABA_PP_JACKPOT_SUCCESS: 'GET_SABA_PP_JACKPOT_SUCCESS',
    SET_SABA_UNDER_MAINTENANCE: 'SET_SABA_UNDER_MAINTENANCE',
};

/**
 * Set if SABA service is under maintenance
 * @param {Function} dispatch Action dispatcher from store
 * @param {{serviceUnderMaintenance: Boolean}} payload Action payload
 */
export const setSabaUnderMaintenance = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_SABA_UNDER_MAINTENANCE,
    });

/**
 * Get PP Jackpot init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPPJackpot = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_SABA_PP_JACKPOT,
    });

/**
 * Get PP Jackpot success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Action payload
 */
export const getPPJackpotSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_SABA_PP_JACKPOT_SUCCESS,
    });

/**
 * Get PP Jackpot failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPPJackpotFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_SABA_PP_JACKPOT_FAILURE,
    });
