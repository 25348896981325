import React, { useEffect, useState } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Menu, Row } from 'antd';

import { ODD_TYPES } from './constants';
import { getStateProp } from '../../common/helpers/misc';
import { getTranslation } from '../../common/helpers/locale';
import { isUserLoggedIn } from '../../common/helpers/api';
import { setOddsTypeLocally } from '../../common/actions/userActions';
import { useStore } from '../../store/StateProvider';
import { useUpdateBetSettingsAsyncEndpoint } from '../../services/marketsService';

const OddTypesMenu = () => {
    const [state, dispatch] = useStore();
    const currentOddsType = getStateProp(state, 'user.oddsType', ODD_TYPES.DECIMAL);

    const [oddsType, setOddsType] = useState(currentOddsType);

    // ============================================
    // METHODS
    // ============================================

    const onSwitchOddsType = (selectedOddTypeValue) => {
        setOddsType(selectedOddTypeValue.key);
        if (isUserLoggedIn()) handleSwitch();
        else setOddsTypeLocally(dispatch, selectedOddTypeValue.key);
    };

    // ============================================
    // HOOKS
    // ============================================

    const [handleSwitch] = useUpdateBetSettingsAsyncEndpoint('oddsType', oddsType);

    useEffect(() => {
        setOddsType(currentOddsType);
    }, [currentOddsType]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Menu onClick={onSwitchOddsType} theme="dark" selectedKeys={[oddsType]}>
            {Object.values(ODD_TYPES).map((value) => (
                <Menu.Item key={value}>
                    <Row align="middle" justify="space-between">
                        <span style={{ paddingLeft: 6 }}>{getTranslation(value)}</span>
                        {value === oddsType && <CheckCircleOutlined />}
                    </Row>
                </Menu.Item>
            ))}
        </Menu>
    );
};

export default OddTypesMenu;
