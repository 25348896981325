import { useEffect } from 'react';

import { clearError } from '../../common/actions/errorActions';
import { getErrorMessage, getErrorStatus } from '../../common/helpers/api';
import { getTranslation } from '../../common/helpers/locale';
import { useDispatch } from '../../store/StateProvider';

export default function useError(error, failureAction) {
    const dispatch = useDispatch();

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        if (error) {
            clearError(dispatch);
            const message = getErrorMessage(error);
            failureAction(dispatch, {
                message,
            });
        }
    }, [dispatch, error, failureAction]);

    // for 500 error status return common message
    return getErrorStatus(error) === 500
        ? getTranslation('Something went wrong!')
        : getErrorMessage(error);
}
