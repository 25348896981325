export const NOTIFICATION_TYPES = {
    ANNOUNCEMENT: 'Announcements',
    MESSAGE: 'Messages',
};

export const MESSAGE_CARD_DISPLAY_TYPES = {
    DETAILS: 'Announcement details',
    LIST_ITEM: 'Announcement list item',
};

export const ANNOUNCEMENT_TYPE = {
    EVENT: 'Event',
    GENERAL: 'General',
};

export const MESSAGE_ID_SALT = "I'm programmer";

export const NOTIFICATIONS_PAGE_SIZE_DEFAULT = 10;
export const NOTIFICATIONS_PAGE_NO_DEFAULT = 1;
