import { lazy } from 'react';

import {
    AboutUs,
    AppDownload,
    AppVersion,
    Aster,
    FAQ,
    GeekFam,
    Rules,
    SponsorShip,
    Terms,
    Triton,
} from './common-info';
import IntlProvider from './internationalisation/IntlProvider';

const Affiliate = lazy(() => import('./profile/affiliate-program/Affiliate'));
const AgReturn = lazy(() => import('./home/AgReturn'));
const BankCards = lazy(() => import('./banking/BankCards'));
const EWallet = lazy(() => import('./e-wallet/EWallet'));
const BettingSummary = lazy(() => import('./bets/betting-summary/BettingSummary'));
const CasinoGames = lazy(() => import('./casino-games/CasinoGames'));
const EventDetails = lazy(() => import('./markets/events/EventDetails'));
const ForgotPassword = lazy(() => import('./login/ForgotPassword'));
const Home = lazy(() => import('./home/Home'));
const Login = lazy(() => import('./login/Login'));
const Markets = lazy(() => import('./markets/Markets'));
const News = lazy(() => import('./news/News'));
const Notifications = lazy(() => import('./notifications/Notifications'));
const Profile = lazy(() => import('./profile/Profile'));
const Register = lazy(() => import('./register/Register'));
const ResultDetails = lazy(() => import('./markets/results/ResultDetails'));
const Rewards = lazy(() => import('./rewards/Rewards'));
const TFGaming = lazy(() => import('./markets/TFGamingMarkets'));
const Transactions = lazy(() => import('./transactions/Transactions'));
const Wallet = lazy(() => import('./wallet/Wallet'));
const Referral = lazy(() => import('./profile/referral-program/ReferralProgram'));
const VerifyAccount = lazy(() => import('./register/VerifyAccount'));
const CryptoTutorial = lazy(() => import('./crypto-tutorial/CryptoTutorial'));
const DailyLoginInfo = lazy(() => import('./daily-login/daily-login-info/DailyLoginInfo'));
const Advantplay = lazy(() => import('./advantplay/AdvantplayGames'));
const Vip = lazy(() => import('./profile/vip-program/Vip'));
const BettingRoot = lazy(() => import('./bets/betting-summary/BettingRoot'));
const AdvantplayMiniGames = lazy(() => import('./advantplay-mini-games/AdvantplayMiniGames'));

export {
    AboutUs,
    Affiliate,
    AgReturn,
    AppVersion,
    BankCards,
    BettingSummary,
    CasinoGames,
    EventDetails,
    FAQ,
    ForgotPassword,
    Home,
    IntlProvider,
    Login,
    Markets,
    News,
    Notifications,
    Profile,
    Rewards,
    Register,
    ResultDetails,
    Rules,
    Terms,
    TFGaming,
    Transactions,
    Wallet,
    Referral,
    SponsorShip,
    Triton,
    GeekFam,
    Aster,
    AppDownload,
    VerifyAccount,
    CryptoTutorial,
    DailyLoginInfo,
    Advantplay,
    Vip,
    BettingRoot,
    EWallet,
    AdvantplayMiniGames,
};
