export const API = {
    ADVANTPLAY: {
        JACKPOT: '/advantplay/jackpot/pool',
        START_GAME: '/advantplay/startgame',
    },
    AFFILIATE_STATUS: '/MyAffiliate/Affiliate/Get',
    ASIA_GAMING: '/ag/getagurl',
    BANK_CARD: {
        AREA: '/Platforms/GeoLocations/areas',
        CITY: '/Platforms/GeoLocations/districts',
        PROVINCE: '/Platforms/GeoLocations/regions',
        ROOT: '/Players/bankcards',
    },
    BANNERS: {
        ROOT: '/Banners',
    },
    BETS: {
        BETS_PLAYER_STATEMENTS: '/platforms/Bets/player/statement',
        BET_LIMITS: '/bets/betLimit',
        PENDING: '/ggbook/bets/pending',
        PLACE: '/bets/place',
        PLAYER_BET_EVENTS: '/bets/playerbet/events',
        PLAYER_BET_INFO: '/bets/playerBetInfo',
        RUNNING: '/platforms/bets/slip',
        STATEMENTS: '/platforms/bets/statement',
    },
    BET_SETTING: '/Players/betSetting',
    BTI: '/bti/geturl',
    CARD_RACING: '/cardRacing/getUrl',
    CHANGE_FIN_PIN: '/Players/financialPin',
    CHANGE_PASSWORD: '/identity/me/password',
    CRASH: {
        GET_URL: '/crash/geturl',
        WALLET_GET_URL: '/crash/wallet/getUrl',
        WALLET_SETUP: '/crash/wallet/setup',
    },
    CRYPTOCURRENCIES: '/Payments/cryptocurrencies',
    EVENTS: '/events/query',
    EWALLET: {
        EWALLET_ACC: '/Players/eWalletAccounts',
        GET: '/Players/eWalletAccount/status',
    },
    GAME_ACCESS: '/Players/game-access/get',
    GATEWAYS: '/Payments/gateways',
    GEOLOCATION: '/Platforms/IpAddresses/country_ip_info',
    GGBOOK: '/ggbook',
    GGPAY: {
        CHECK: '/GGPay/check',
        DEPOSIT: '/GGPay/deposit',
        RATES: '/GGPay/rates',
        WITHDRAWAL: '/GGPay/withdraw',
    },
    WEALTHPAY: {
        PREDEPOSIT: '/WealthPay/Pre-Deposit',
    },
    IDENTITY: '/identity',
    LOGIN_BONUS: {
        PLAYER: '/loginBonus/player',
    },
    NEWS: '/News',
    NOTIFICATIONS: {
        ANNOUNCEMENTS: '/Announcement/lang',
        ANNOUNCEMENT_DETAILS: '/Announcement',
        MESSAGES: '/PersonalMessage/user',
        READ_COUNTER: '/PersonalMessage/read-counter',
    },
    ODDS: '/odds/query',
    OPERATIONS: '/Operations',
    OTP_SIGN_IN: '/identity/otp/sign-in',
    PAYMENTS: {
        CARDS: '/Payments/cards',
        DEPOSITS: '/Payments/deposits',
        DEPOSIT_BANK_LIST: '/Payments/banks/details',
        ELIGIBLE_DEPOSIT_CHECK: '/Payments/Eligiblity/check',
        E_WALLET_DEPOSIT: '/Payments/eWalletTransfers/transfers',
        E_WALLET_LIST: '/Payments/eWallets/details',
        E_WALLET_WITHDRAWALS: '/Payments/eWallets/withdrawals',
        OFFLINE_DEPOSITS: '/Payments/offlineDeposits',
        ROOT: '/Payments',
        TRANSACTION_AMOUNT_SETTING: '/Payments/settings/search',
        TRANSACTION_AMOUNT_SETTING_EWALLET: '/Payments/eWallets/settings/search',
        UPLOAD_RECEIPT: '/uploadReceipt',
        WITHDRAWALS: '/Payments/withdrawals',
    },
    PLAYERS: '/Players',
    PLAYERS_APP_LANGUAGE: '/Players/language',
    PLAYERS_VIP_DETAILS: '/Players/vip/detail/query',
    PLAYTECH: {
        MEMBER_INFO: '/playtech/memberinfo',
    },
    PROMOTIONS: {
        BY_PLAYER: '/promotions/byplayer',
        JOIN: '/promotions/join/',
    },
    REFERRAL: '/players/referral-program',
    REGISTER_AFFILIATE: '/Players/betfiliate/create',
    REGISTER_USER: '/identity/sign-up/player',
    REGISTER_USER_V2: '/identity/sign-upv2/player',
    REQUEST_BETFILIATE_OTP: '/otp/request/join-betfiliate-program',
    REQUEST_FIN_PIN_OTP: '/otp/request/change-financial-pin',
    REQUEST_OTP: {
        LOGIN: '/otp/request/otp-login',
        NEW_MOBILE_NO: '/otp/request/verify-new-mobileno',
        RESET_PASSWORD: '/otp/request/reset-password',
    },
    RESET_PASSWORD: '/reset-password',
    RESULTS: '/games/results/query',
    REWARDS: {
        CATEGORY: '/category',
        DEPOSIT: '/Bonus/deposits',
        JOIN_BONUS: '/Bonus',
        ROOT: '/Promotions',
    },
    SABA: {
        GET_ANONYMUS_GAME_URL: '/saba/players/getAnnonymousGameUrl',
        GET_ANONYMUS_URL: '/saba/getUrl',
        GET_GAME_URL: '/saba/players/getAuthorizedGameUrl',
        GET_PLAYER_URL: '/saba/players/getUrl',
        GET_PP_JACKPOT: '/saba/getPPJackpotMeter',
        PLAYER_WALLET: '/saba/players/wallet',
        SPORTS_TYPE_GROUP: '/saba/sportsTypeGroup',
    },
    SIGN_IN: '/identity/sign-in',
    SLOT88: {
        GAME_ACCESS: '/Slot88/game/access',
        GAME_LIST: '/Slot88/game/list',
    },
    SOCIAL_PLATFORM_ACCESS: '/platforms/chatroom/access-url',
    TOKENS: '/access-tokens',
    TRANSACTIONS: {
        AFFILIATES: '/Payments/histories/affiliatePayouts',
        DEPOSITS: '/Payments/histories/deposits',
        REFERRAL: '/Payments/histories/referralPayouts',
        REWARDS: '/Payments/histories/bonus',
        TRANSFERS: '/Payments/histories/transfers',
        WITHDRAWALS: '/Payments/histories/withdrawals',
    },
    VERIFY_ACCOUNT: '/identity/account/verification',
    VERIFY_FIN_PIN: '/Players/financialPin/verify',
    VERSION: {
        V1: '/api/v1',
        V2: '/api/v2',
    },
    WALLET: '/wallet',
    WALLETS: {
        ROOT: '/platforms/wallets',
        SUMMARY: '/platforms/wallets/details',
    },
};

export const halfTypes = {
    firstHalf: 'firstHalf',
    secondHalf: 'secondHalf',
};

export const GAME_SLUGS = {
    'CS:GO': '/csgo',
    'Dota 2': '/dota2',
    'League of Legends': '/lol',
};

export const EVENTS_REFRESH_DELAY = 5000;

export const BETS_REFRESH_DELAY = 5000;

export const RESULTS_REFRESH_DELAY = 30000;

export const CRYPTOCURRENCY_REFRESH_DELAY = 300000;

export const VERIFICATION_CODE_VALIDITY_DELAY = 60000;

export const TRANSFER_DIRECTIONS = {
    IN: '/transferIn',
    OUT: '/transferOut',
};

export const OPERATION_STATES = {
    COMPLETED: 'completed',
    PENDING: 'pending',
    REJECTED: 'rejected',
};

export const ENVIRONMENTS = {
    DEV: 'dev',
    INT: 'int',
    PROD: 'pro',
    SIT: 'sit',
    STG: 'stg',
    UAT: 'uat',
};

export const DEFAULT_TIMEOUT = 30000;
