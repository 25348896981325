import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import './UserNavBar.less';
import { ICONS } from '../../../common/constants/icons';
import { ROUTES } from '../../../common/constants/routing';
import { detectIphonePWA, getStateProp, isTestEnvironment } from '../../../common/helpers/misc';
import { getUserCurrency } from '../../../common/helpers/currency';
import { isUserLoggedIn } from '../../../common/helpers/api';
import { useNavigateSocialPlatformEndpoint } from '../../../services/userService';
import { useStore } from '../../../store/StateProvider';
import ImgIcon from '../../../common/components/ImgIcon';

const NavBar = () => {
    const history = useHistory();
    const location = useLocation();
    const [state] = useStore();
    const initiallySelected = location.pathname.split('/')[1];

    const [selected, setSelected] = useState(initiallySelected);
    const isClickedToday = getStateProp(state, 'rewards.dailyLoginInfo.isClickedToday', true);
    const userCurrency = getUserCurrency(state);

    // ============================================
    // METHODS
    // ============================================

    const navigateToPage = (name, route, isLoginRequired = false) => {
        setSelected(name);
        if (isLoginRequired) {
            if (isUserLoggedIn()) history.push(route);
            else {
                history.push(ROUTES.LOGIN, { redirectTo: route });
            }
        } else history.push(route);
    };

    // ============================================
    // HOOKS
    // ============================================
    const [handleNavigateSocialPlatform] = useNavigateSocialPlatformEndpoint();

    useEffect(() => {
        setSelected(initiallySelected);
    }, [initiallySelected]);

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row
            justify="space-around"
            align="middle"
            className="user-navbar__container"
            style={detectIphonePWA() ? { paddingBottom: 14 } : null}
        >
            <Col className="user-navbar__item">
                <ImgIcon
                    icon={selected === 'wallet' ? ICONS.NAV.WALLET_SELECTED : ICONS.NAV.WALLET}
                    onClick={() => navigateToPage('wallet', ROUTES.WALLET.ROOT, true)}
                    size={38}
                />
            </Col>
            <Col className="user-navbar__item">
                <ImgIcon
                    icon={selected === 'rewards' ? ICONS.NAV.REWARDS_SELECTED : ICONS.NAV.REWARDS}
                    onClick={() => navigateToPage('rewards', ROUTES.REWARDS.ROOT)}
                    size={38}
                />
            </Col>
            <Col>
                <ImgIcon
                    icon={ICONS.NAV.GGBOOK}
                    width={40}
                    onClick={() => navigateToPage('landing', ROUTES.HOME)}
                />
            </Col>
            <Col className="user-navbar__item">
                <ImgIcon
                    icon={
                        isTestEnvironment()
                            ? selected === 'bet-slip'
                                ? ICONS.NAV.SOCIAL_HUB.SELECTED
                                : ICONS.NAV.SOCIAL_HUB
                            : selected === 'bet-slip'
                            ? ICONS.NAV.BET_SLIP_SELECTED
                            : ICONS.NAV.BET_SLIP
                    }
                    onClick={
                        isTestEnvironment()
                            ? handleNavigateSocialPlatform
                            : () =>
                                  navigateToPage(
                                      'bet-slip',
                                      ROUTES.BETTING.ESPORTS_BETTING.ROOT,
                                      true,
                                  )
                    }
                    size={38}
                />
            </Col>
            <Col className="user-navbar__item">
                {userCurrency === 'IDR'
                    ? isUserLoggedIn() &&
                      !isClickedToday && <div className="user-navbar__red-dot" />
                    : null}
                <ImgIcon
                    icon={selected === 'profile' ? ICONS.NAV.PROFILE_SELECTED : ICONS.NAV.PROFILE}
                    onClick={() => navigateToPage('profile', ROUTES.PROFILE.ROOT, true)}
                    size={38}
                />
            </Col>
        </Row>
    );
};

export default NavBar;
