import { ACTIONS } from './transactionsActions';
import { getPeriodQuickPicks } from '../../common/components/DateRangeQuickPick';

export default function transactionsReducer(
    transactions = {
        period: {
            ...getPeriodQuickPicks().THIS_WEEK.value,
        },
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.TRANSACTIONS_GET_SUCCESS:
            return {
                ...transactions,
                ...payload,
            };
        case ACTIONS.TRANSACTIONS_PERIOD_CHANGE:
            return {
                ...transactions,
                period: payload,
            };
        case ACTIONS.TRANSACTIONS_GET:
            return {
                ...transactions,
                transactions: [],
            };
        case ACTIONS.SABA_TRANSACTIONS_GET:
            return {
                ...payload,
                sabaTransaction: [],
            };
        case ACTIONS.SABA_TRANSACTIONS_GET_SUCCESS:
            return {
                ...payload,
            };
        default:
            return transactions;
    }
}
