export const ROUTES = {
    ABOUT_US: '/about-us',
    ADVANTPLAY: {
        ROOT: '/advantplay',
    },
    ADVANTPLAY_MINI_GAME: {
        ROOT: '/advantplay-mini-games',
    },
    AFFILIATE_PROGRAM: {
        CREATE: '/affiliate-program/create',
        ROOT: '/affiliate-program',
    },
    AG_RETURN: '/ag-return',
    APP_DOWNLOAD: '/app-download',
    APP_VERSION: '/app-version',
    BANK_CARDS: {
        ADD_BANK_CARD: '/bank-cards/add',
        ADD_BTC_WALLET: '/bank-cards/add-btc',
        ADD_USDT_WALLET: '/bank-cards/add-usdt',
        ROOT: '/bank-cards',
    },
    BANK_TRANSFER: '/bank-transfer',
    BETTING: {
        BETTING_SUMMARY: {
            ROOT: '/betting/betting-summary',
            RUNNING: '/betting/betting-summary/running',
            STATEMENTS: {
                DETAILS: '/betting/betting-summary/statements/:id',
                ROOT: '/betting/betting-summary/statements',
            },
        },
        ESPORTS_BETTING: {
            ROOT: '/betting/betting-esport',
            RUNNING: '/betting/betting-esport/running',
            STATEMENTS: {
                DETAILS: '/betting/betting-esport/statements/:id',
                ROOT: '/betting/betting-esport/statements',
            },
        },
        ROOT: '/betting',
    },
    CASINO: {
        PG: '/casino/pg',
        PLAYTECH: '/casino/playtech',
        PLAYTECH_NON_PROGRESSIVE: '/casino/playtech/non-progressive',
        PLAYTECH_PROGRESSIVE: '/casino/playtech/progressive',
        PRAGMATIC_GAMES: '/casino/pragmatic-games',
        PRAGMATIC_GAMES_NON_PROGRESSIVE: '/casino/pragmatic-games/non-progressive',
        PRAGMATIC_GAMES_PROGRESSIVE: '/casino/pragmatic-games/progressive',
        ROOT: '/casino',
        SABA_CASINO: '/casino/saba-casino',
        SLOT88: '/casino/slot-88',
        SPADE_GAMING: '/casino/spade-gaming',
        VOID_BRIDGE: '/casino/void-bridge',
    },
    CRYPTO_TUTORIAL: '/crypto-tutorial',
    DAILY_LOGIN: '/daily-login',
    ERROR: '/error',
    E_WALLET: {
        ADD_E_WALLET: '/e-wallet/add',
        ROOT: '/e-wallet',
    },
    FAQ: '/faq',
    HOME: '/',
    LOGIN: '/login',
    NEWS: {
        DETAILS: '/news/:id',
        ROOT: '/news',
    },
    NOTIFICATIONS: {
        ANNOUNCEMENTS: '/notifications/announcements',
        ANNOUNCEMENT_DETAILS: '/notifications/announcements/:id',
        MESSAGES: '/notifications/messages',
        MESSAGE_DETAILS: '/notifications/messages/:id',
        ROOT: '/notifications',
    },
    ONE_RNG: '/one-rng',
    PROFILE: {
        CHANGE_FIN_PIN: '/profile/change-financial-pin',
        CHANGE_PASSWORD: '/profile/change-password',
        EDIT_BIRTHDAY: '/profile/edit-birthdate',
        EDIT_EMAIL: '/profile/edit-email',
        EDIT_QQ: '/profile/edit-QQ',
        EDIT_WECHAT: '/profile/edit-Wechat',
        NETT_TURNOVER: '/profile/nett-turnover',
        ROOT: '/profile',
        USER_DETAILS: '/profile/user-details',
    },
    PROMOTIONS: '/promotions',
    REFERRAL_PROGRAM: '/referral-program',
    REGISTER: '/register',
    RESET_FIN_PIN: '/reset-fin-pin',
    RESET_PASSWORD: '/reset-password',
    REWARDS: {
        COMPLETED: '/rewards/completed',
        DETAILS: '/rewards/:id/:type',
        JOINED: '/rewards/joined',
        ROOT: '/rewards',
        RUNNING: '/rewards/running',
    },
    RULES: '/rules-and-regulation',
    SABA_MARKETS: {
        EVENTS: '/saba-markets/events',
        EVENT_DETAILS: '/saba-markets/events/:id',
        RESULTS: '/saba-markets/results',
        RESULT_DETAILS: '/saba-markets/results/:id',
        ROOT: '/saba-markets',
    },
    SPONSORSHIP: {
        ASTER: '/sponsorship/aster',
        GEEKFAM: '/sponsorship/geekfam',
        ROOT: '/sponsorship',
        TRITON: '/sponsorship/triton',
    },
    STATS: {
        HEAD2HEAD: {
            DETAILS: '/stats/head-2-head/details/:team1/:team2',
            ROOT: '/stats/head-2-head',
        },
        LEADERBOARD: '/stats/leaderboard',
        PLAYER: {
            MAIN_HEROES: '/stats/player/:id/main-heroes',
            RECENT_MATCHES: '/stats/player/:id/recent-matches',
            ROOT: '/stats/player/:id',
        },
        ROOT: '/stats',
        SERIES: {
            MATCH: {
                LIVE: {
                    HEAD2HEAD: '/head2head',
                    ROOT: '/live',
                },
                RESULTS: {
                    DETAILS: {
                        PLAYER_STATS: '/player-stats',
                        TEAM_STATS: '/team-stats',
                    },
                    ROOT: '/results',
                },
                ROOT: '/match/:id',
            },
            ROOT: '/stats/series/:seriesId',
        },
        TEAM: {
            HERO_STATS: '/stats/team/:id/hero-stats',
            ROOT: '/stats/team/:id',
        },
        TOURNAMENT: {
            DETAILS: {
                PAGES: {
                    INFO: '/info',
                    OVERVIEW: '/overview',
                    PARTICIPANTS: '/participants',
                    SCHEDULE: '/schedule',
                    STATS: '/stats',
                },
                ROOT: '/stats/tournament/:id',
            },
            ROOT: '/stats/tournament',
        },
    },
    TERMS: '/terms-and-conditions',
    TF_GAMING_MARKETS: {
        EVENTS: '/markets/events',
        EVENT_DETAILS: '/markets/events/:id',
        RESULTS: '/markets/results',
        RESULT_DETAILS: '/markets/results/:id',
        ROOT: '/markets',
    },
    TRANSACTIONS: {
        AFFILIATE: '/transactions/affiliate',
        DEPOSIT: '/transactions/deposit',
        REFERRAL: '/transactions/referral',
        REWARD: '/transactions/reward',
        ROOT: '/transactions',
        TRANSFER: '/transactions/transfer',
        WITHDRAWAL: '/transactions/withdrawal',
    },
    VERIFY_ACCOUNT: '/verify-account',
    VIP: {
        ALL: '/vip/all',
        ROOT: '/vip',
    },
    WALLET: {
        DEPOSIT: '/wallet/deposit',
        ROOT: '/wallet',
        TRANSFER: '/wallet/transfer',
        WITHDRAWAL: '/wallet/withdrawal',
    },
};

export const SIDEBAR_LINKS = [
    {
        isAuthRequired: true,
        path: ROUTES.BETTING.ROOT,
        title: 'Betting Summary',
    },
    {
        isAuthRequired: false,
        path: ROUTES.VIP.ROOT,
        title: 'VIP Program',
    },
    {
        isAuthRequired: false,
        path: ROUTES.AFFILIATE_PROGRAM.ROOT,
        title: 'Affiliate Program',
    },
    {
        isAuthRequired: true,
        path: ROUTES.REFERRAL_PROGRAM,
        title: 'Referral Program',
    },
    {
        isAuthRequired: false,
        path: ROUTES.SPONSORSHIP.ROOT,
        title: 'Sponsorships',
    },
    {
        isAuthRequired: false,
        path: ROUTES.TERMS,
        title: 'Terms & Conditions',
    },
    {
        isAuthRequired: false,
        path: ROUTES.RULES,
        title: 'Rules & Regulation',
    },
    {
        isAuthRequired: false,
        path: ROUTES.FAQ,
        title: 'FAQ',
    },
    {
        isAuthRequired: false,
        path: ROUTES.ABOUT_US,
        title: 'About Us',
    },
    {
        isAuthRequired: false,
        path: ROUTES.APP_DOWNLOAD,
        title: 'App Download',
    },
];

export const LIVE_CHAT_LINK =
    'https://cleat.ciritum.com/chat/Hotline/channel.jsp?cid=5067992&cnfid=14532&j=7893320474&s=1';
