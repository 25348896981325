import { SUPPORTED_CURRENCIES } from '../constants/localization';
import { getCurrencyByCountry, isSupportedCurrencyCountry } from './locale';
import { getStateProp } from './misc';
import { isUserLoggedIn } from './api';

export const isCurrencySupported = (currencyCode) =>
    SUPPORTED_CURRENCIES.indexOf(currencyCode) !== -1;

export const getUserCurrency = (state) => {
    const userCurrency = getStateProp(state, 'user.currencyCode', 'USD');
    const geolocationCurrency = getStateProp(state, 'country.geolocation.currencyCode', 'USD');

    return isUserLoggedIn() ? userCurrency : geolocationCurrency;
};

export const isChinaCurrencyHelper = (state) => {
    const userCurrency = getStateProp(state, 'user.currencyCode', '');
    const geolocationCurrency = getStateProp(state, 'country.geolocation.currencyCode', '');

    return isUserLoggedIn()
        ? userCurrency === 'USD'
            ? geolocationCurrency === 'CNY'
            : userCurrency === 'CNY'
        : geolocationCurrency === 'CNY';
};

export const isIndoOrVietnamCurrency = (currency) => currency === 'IDR' || currency === 'VND';

export const getDefaultCurrency = (countryCode) => {
    let initCurrencyCode = getCurrencyByCountry(countryCode);
    let initCurrencyList =
        countryCode === 'id' //id no need to have usd.not using crypto
            ? [{ value: initCurrencyCode }]
            : isSupportedCurrencyCountry(countryCode)
            ? [{ value: initCurrencyCode }, { value: 'USD' }]
            : [{ value: initCurrencyCode }];
    return {
        currencyCode: initCurrencyCode,
        currencyList: initCurrencyList,
    };
};
