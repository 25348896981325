import { Link, useHistory, useLocation } from 'react-router-dom';
import React, { useState } from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Badge, Col, Drawer, Menu, Row, message } from 'antd';
import _ from 'lodash';

import './SideBar.less';
import { ButtonGray, ButtonGreen } from '../../../common/components/Buttons';
import { ICONS } from '../../../common/constants/icons';
import { ROUTES, SIDEBAR_LINKS } from '../../../common/constants/routing';
import { clearRewardBanner } from '../../rewards/rewardsActions';
import { clearWalletInfo } from '../../wallet/walletActions';
import { deleteStorageItem, getCurrentUser, isUserLoggedIn } from '../../../common/helpers/api';
import { getStateProp, isTestEnvironment } from '../../../common/helpers/misc';
import { getTranslation } from '../../../common/helpers/locale';
import { useStore } from '../../../store/StateProvider';
import { userLogout } from '../../../common/actions/userActions';
import ConfirmModal from '../../../common/components/confirm-modal/ConfirmModal';
import ImgIcon from '../../../common/components/ImgIcon';
import LanguageMenu from '../../internationalisation/LanguageMenu';
import OddTypesMenu from '../../bets/OddTypesMenu';
import SideBarLink from './SideBarLink';

const SideBar = ({ onClose, visible }) => {
    const [state, dispatch] = useStore();
    const user = getCurrentUser();
    const userName = getStateProp(user, 'claims.userName', getTranslation('Anonymous'));
    const readCounter = getStateProp(state, 'notifications.messages.readCounter', 0);

    const history = useHistory();
    const location = useLocation();

    const [logoutModalVisible, setLogoutModalVisible] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    const openLogoutModal = () => setLogoutModalVisible(true);
    const closeLogoutModal = () => {
        setLogoutModalVisible(false);
    };

    const logout = () => {
        deleteStorageItem('user');
        userLogout(dispatch);
        clearWalletInfo(dispatch);
        setLogoutModalVisible(false);
        message.warning(getTranslation("You've been logged out"));
        clearRewardBanner(dispatch);
        history.push(ROUTES.LOGIN);
        onClose();
    };

    const login = () => {
        history.push(ROUTES.LOGIN, { redirectTo: location.pathname });
        onClose();
    };

    const register = () => {
        history.push(ROUTES.REGISTER);
        onClose();
    };

    const gotToNotifications = () => {
        history.push(ROUTES.NOTIFICATIONS.MESSAGES);
        onClose();
    };

    const noneAnimation = () => null;

    const showLink = (link) => !link.isAuthRequired || (link.isAuthRequired && isUserLoggedIn());

    // ============================================
    // RENDER
    // ============================================

    const SideBarHeader = (
        <Row>
            <ArrowLeftOutlined className="navbar__icon--secondary" onClick={onClose} />
        </Row>
    );

    return (
        <Drawer
            title={SideBarHeader}
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            className="side-bar"
            getContainer={false}
            destroyOnClose={true}
        >
            <Row justify="space-between" align="middle" className="side-bar__profile">
                <Col className="side-bar__user-text">
                    <Row>{getTranslation('Welcome')}</Row>
                    <Row className="side-bar__user-name">
                        {isUserLoggedIn() ? userName : getTranslation('to GGBook')}
                    </Row>
                    {isUserLoggedIn() && (
                        <Row className="side-bar__profile-link">
                            <Link to={ROUTES.PROFILE.USER_DETAILS}>
                                {getTranslation('Edit profile')}
                            </Link>
                        </Row>
                    )}
                </Col>
                {isUserLoggedIn() && (
                    <Col>
                        <ImgIcon icon={ICONS.SIDEBAR.NOTIFICATIONS} onClick={gotToNotifications} />
                        <Badge count={readCounter} className="side-bar__readCounter__badge" />
                    </Col>
                )}
            </Row>
            <Menu className="side-bar__menu" mode="inline" motion={noneAnimation}>
                <Menu.SubMenu
                    key="language"
                    title={
                        <span className="side-bar__menu__title">{getTranslation('Language')}</span>
                    }
                >
                    <LanguageMenu />
                </Menu.SubMenu>
                <Menu.SubMenu
                    key="odds"
                    title={
                        <span className="side-bar__menu__title">{getTranslation('Odds Type')}</span>
                    }
                >
                    <OddTypesMenu />
                </Menu.SubMenu>
            </Menu>
            {_.map(
                SIDEBAR_LINKS,
                (link) =>
                    showLink(link) &&
                    (isTestEnvironment() || link.title !== 'Affiliate Program') && (
                        <SideBarLink onClose={onClose} link={link} key={link.title} />
                    ),
            )}
            {/* Hided temporarily while it is not implemented
                {isUserLoggedIn() && (
                <Row
                    
                    align="middle"
                    justify="space-between"
                    className="side-bar__item switch-item"
                >
                    <span className="side-bar__item__title">
                        {getTranslation('Accept Better Odds').toLocaleUpperCase()}
                    </span>
                    <AutoAcceptBetterOddsSwitch />
                </Row>
            )} */}
            <div className="side-bar__footer">
                {!isUserLoggedIn() ? (
                    <Col span={24} className="side-bar__login-buttons">
                        <ButtonGray
                            className="side-bar__btn"
                            label={getTranslation('Sign In')}
                            onClick={login}
                        />
                        <ButtonGreen
                            className="side-bar__btn"
                            label={getTranslation('Sign Up')}
                            onClick={register}
                        />
                    </Col>
                ) : (
                    <Col span={24} className="side-bar__logout-button">
                        <ButtonGray
                            className="side-bar__btn"
                            onClick={openLogoutModal}
                            label={getTranslation('Logout')}
                        />
                    </Col>
                )}
                <ConfirmModal
                    text={getTranslation('Are you sure you want to logout?')}
                    onCancel={closeLogoutModal}
                    visible={logoutModalVisible}
                    withAction={true}
                    confirmAction={logout}
                />
            </div>
        </Drawer>
    );
};

export default SideBar;
