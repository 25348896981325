export const ACTIONS = {
    BETS_SLIP_APPLY_FILTER: 'BETS_SLIP_APPLY_FILTER',
    BETS_SLIP_GET: 'BETS_SLIP_GET',
    BETS_SLIP_GET_FAILURE: 'BETS_SLIP_GET_FAILURE',
    BETS_SLIP_GET_SUCCESS: 'BETS_SLIP_GET_SUCCESS',
    BETS_SLIP_PERIOD_CHANGE: 'BETS_SLIP_PERIOD_CHANGE',
    BETS_SLIP_PLATFORM_CHANGE: 'BETS_SLIP_PLATFORM_CHANGE',
    BETS_SLIP_STATUS_CHANGE: 'BETS_SLIP_STATUS_CHANGE',
    BETS_STATEMENT_GAMES_PLATFORM_CHANGE: 'BETS_STATEMENT_GAMES_PLATFORM_CHANGE',
    BETS_STATEMENT_GAMES_PRODUCT_CHANGE: 'BETS_STATEMENT_GAMES_PRODUCT_CHANGE',
    BET_CREATE: 'BET_CREATE',
    BET_DELETE: 'BET_DELETE',
    BET_LIMITS_GET: 'BET_LIMITS_GET',
    BET_LIMITS_GET_FAILURE: 'BET_LIMITS_GET_FAILURE',
    BET_LIMITS_GET_SUCCESS: 'BET_LIMITS_GET_SUCCESS',
    BET_SUBMIT: 'BET_SUBMIT',
    BET_SUBMIT_ERROR_HIDE: 'BET_SUBMIT_ERROR_HIDE',
    BET_SUBMIT_ERROR_SHOW: 'BET_SUBMIT_ERROR_SHOW',
    BET_SUBMIT_FAILURE: 'BET_SUBMIT_FAILURE',
    BET_SUBMIT_SUCCESS: 'BET_SUBMIT_SUCCESS',
    BET_SUCCESS_MODAL_CLOSE: 'BET_SUCCESS_MODAL_CLOSE',
    BET_UPDATE: 'BET_UPDATE',
    PAGINATION: {
        PAGE_CHANGE: 'BETS_PAGE_CHANGE',
        PAGE_SIZE_CHANGE: 'BETS_PAGE_SIZE_CHANGE',
    },
    PLAYER_BET_EVENTS_GET: 'PLAYER_BET_EVENTS_GET',
    PLAYER_BET_EVENTS_GET_FAILURE: 'PLAYER_BET_EVENTS_GET_FAILURE',
    PLAYER_BET_EVENTS_GET_SUCCESS: 'PLAYER_BET_EVENTS_GET_SUCCESS',
    PLAYER_BET_INFO_GET: 'PLAYER_BET_INFO_GET',
    PLAYER_BET_INFO_GET_FAILURE: 'PLAYER_BET_INFO_GET_FAILURE',
    PLAYER_BET_INFO_GET_SUCCESS: 'PLAYER_BET_INFO_GET_SUCCESS',
    PLAYER_BET_STATEMENTS_GET: 'PLAYER_BET_STATEMENTS_GET',
    PLAYER_BET_STATEMENTS_GET_FAILURE: 'PLAYER_BET_STATEMENTS_GET_FAILURE',
    PLAYER_BET_STATEMENTS_GET_SUCCESS: 'PLAYER_BET_STATEMENTS_GET_SUCCESS',
};

/**
 * Apply bets slip filter action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Function} payload Action payload
 */
export const applyBetsSlipFilter = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_APPLY_FILTER,
    });

/**
 * Get bets slip init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getBetsSlip = (dispatch) =>
    dispatch({
        type: ACTIONS.BETS_SLIP_GET,
    });

/**
 * Get bets slip success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {*} payload Action payload (Array of TypeOfSlipCardData)
 */
export const getBetsSlipSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_GET_SUCCESS,
    });

/**
 * Get bets slip failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getBetsSlipFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_GET_FAILURE,
    });

/**
 * Bets slip period filter action (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {{dateFrom: String, dateTo: String}} payload Action payload
 */
export const changeBetsSlipPeriod = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_PERIOD_CHANGE,
    });

/**
 * Bets slip status filter action (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Bet slip status
 */
export const changeBetsSlipStatus = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_STATUS_CHANGE, //here
    });

/**
 * Create bet action
 * @param {Function} dispatch Action dispatcher from store
 * @param {*} payload Action payload (TypeOfSelectedBet)
 */
export const createBet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_CREATE,
    });

/**
 * Delete bet action
 * @param {Function} dispatch Action dispatcher from store
 */
export const deleteBet = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_DELETE,
    });

/**
 * Update bet action
 * @param {Function} dispatch Action dispatcher from store
 * @param {*} payload Action payload (TypeOfSelectedBet)
 */
export const updateBet = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_UPDATE,
    });

/**
 * Submit bet init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const submitBet = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_SUBMIT,
    });

/**
 * Submit bet success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{pending: Number, running: Number, isTFGamingPlatform: Boolean}} payload Action payload
 */
export const submitBetSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SUBMIT_SUCCESS,
    });

/**
 * Submit bet failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const submitBetFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SUBMIT_FAILURE,
    });

/**
 * Hide error on bet submit action
 * @param {Function} dispatch Action dispatcher from store
 */
export const submitBetErrorHide = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_SUBMIT_ERROR_HIDE,
    });

/**
 * Show error on bet submit action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Error text
 */
export const submitBetErrorShow = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SUBMIT_ERROR_SHOW,
    });

/**
 * Close bet success modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const betSuccessModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_SUCCESS_MODAL_CLOSE,
    });

/**
 * Get player bet info init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPlayerBetInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_BET_INFO_GET,
    });

/**
 * Get player bet info success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{minBet: Number, maxBet: Number, betCount: Number}} payload Action payload
 */
export const getPlayerBetInfoSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_INFO_GET_SUCCESS,
    });

/**
 * Get player bet info failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPlayerBetInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_INFO_GET_FAILURE,
    });

/**
 * Get bet limits init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getBetLimits = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_LIMITS_GET,
    });

/**
 * Get bet limits success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{minBet: Number, maxBet: Number}} payload Action payload
 */
export const getBetLimitsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIMITS_GET_SUCCESS,
    });

/**
 * Get bet limits failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getBetLimitsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_LIMITS_GET_FAILURE,
    });

/**
 * Get player bet events init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPlayerBetEvents = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_BET_EVENTS_GET,
    });

/**
 * Get player bet events success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{eventId: Number, key: String, betTeam: String}[]} payload Action payload
 */
export const getPlayerBetEventsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_EVENTS_GET_SUCCESS,
    });

/**
 * Get player bet events failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPlayerBetEventsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_EVENTS_GET_FAILURE,
    });

/**
 * Bets slip platform filter action (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Betting platform
 */
export const changeBetsPlatform = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_SLIP_PLATFORM_CHANGE,
    });

/**
 * Get player bet statements init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPlayerBetStatements = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYER_BET_STATEMENTS_GET,
    });

/**
 * Get player bet events success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{eventId: Number, key: String, betTeam: String}[]} payload Action payload
 */
export const getPlayerBetStatementsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_STATEMENTS_GET_SUCCESS,
    });

/**
 * Get player bet events failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPlayerBetStatementsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYER_BET_STATEMENTS_GET_FAILURE,
    });

/**
 * Bets statement platform filter action (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Betting platform
 */
export const changeBetsStatementPlatform = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_STATEMENT_GAMES_PLATFORM_CHANGE,
    });

/**
 * Bets statement product filter action (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Betting product
 */
export const changeBetsStatementProduct = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BETS_STATEMENT_GAMES_PRODUCT_CHANGE,
    });

/**
 * Change page number action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page number
 */
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_CHANGE,
    });

/**
 * Change page size action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page size
 */
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_SIZE_CHANGE,
    });
