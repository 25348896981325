import { ACTIONS } from './advantplayActions';

export default function advantplayReducer(advantplay, { type, payload = null }) {
    switch (type) {
        case ACTIONS.GET_ADVANTPLAY_JACKPOT_SUCCESS:
            return {
                ...advantplay,
                jackpotAmount: payload,
            };
        case ACTIONS.GET_ADVANTPLAY_JACKPOT:
        default: {
            return advantplay;
        }
    }
}
