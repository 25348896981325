import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'antd';

import './ConfirmModal.less';
import { ICONS } from '../../constants/icons';
import ActionButton from './ActionButton';
import CancelButton from './CancelButton';
import ConfirmModalContent from './ConfirmModalContent';
import Modal from '../Modal';
import SingleButton from './SingleButton';

const ConfirmModal = ({
    visible,
    image,
    text,
    cancelText,
    onCancel,
    withAction,
    actionText,
    confirmAction,
    actionPending,
    closable,
}) => {
    return (
        visible && (
            <Modal
                visible={visible}
                closable={closable}
                onCancel={onCancel}
                centered
                className="confirm-modal"
                title={<Row />}
                footer={
                    withAction
                        ? [
                              <ActionButton
                                  key="action"
                                  actionText={actionText}
                                  actionPending={actionPending}
                                  confirmAction={confirmAction}
                                  justify="left"
                              />,
                              <CancelButton
                                  key="close"
                                  cancelText={cancelText}
                                  onCancel={onCancel}
                              />,
                          ]
                        : [<SingleButton key="close" cancelText={cancelText} onCancel={onCancel} />]
                }
                maskListener={false}
            >
                <ConfirmModalContent text={text} image={image} />
            </Modal>
        )
    );
};

ConfirmModal.defaultProps = {
    actionText: 'Ok',
    cancelText: 'Close',
    closable: true,
    image: ICONS.MODAL.WARNING,
    visible: true,
    withAction: false,
};

ConfirmModal.propTypes = {
    actionPending: PropTypes.bool,
    actionText: PropTypes.string,
    cancelText: PropTypes.string,
    confirmAction: PropTypes.func,
    image: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    visible: PropTypes.bool,
    withAction: PropTypes.bool,
};

export default ConfirmModal;
