import React, { useEffect, useState } from 'react';

import intl from 'react-intl-universal';
import moment from 'moment';

import { detectWebView, getStateProp, isChinaDomain } from '../../common/helpers/misc';
import {
    getBrowserLocale,
    getCountryByLocale,
    getFirstLanguage,
    getSupportedLocales,
} from '../../common/helpers/locale';
import { getCurrentLanguage, setLangStorageItem } from '../../common/helpers/api';
import { setCurrentCountry, setCurrentLanguage } from './actions';
import { useStore } from '../../store/StateProvider';
import useUrlLanguage from '../../services/hooks/useUrlLanguage';

const IntlProvider = (props) => {
    const [state, dispatch] = useStore();
    const [initDone, setInitDone] = useState(false);
    const [isFirstLoad, setIsFirstLoad] = useState(false);
    const currentLanguage = isChinaDomain() && detectWebView() ? 'zh-CN' : getCurrentLanguage();
    const geolocationCountryCode = getStateProp(state, 'country.geolocation.countryCode', null);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        const language = getFirstLanguage(isFirstLoad, geolocationCountryCode, setIsFirstLoad);
        intl.init({
            currentLocale: language,
            locales: getSupportedLocales(),
        }).then(() => {
            !geolocationCountryCode &&
                setCurrentCountry(dispatch, getCountryByLocale(getBrowserLocale()));
            setCurrentLanguage(dispatch, { language });
            setLangStorageItem(language);
            moment.locale(language);
            setInitDone(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, currentLanguage, geolocationCountryCode]);

    useEffect(() => {
        if (!currentLanguage) setIsFirstLoad(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useUrlLanguage();

    // ============================================
    // RENDER
    // ============================================

    return initDone && <div>{props.children}</div>;
};

export default IntlProvider;
