import { ACTIONS } from '../constants/actions';
import { ACTIONS as ANNOUNCEMENT } from '../../components/notifications/announcements/announcementsActions';
import { ACTIONS as BANK } from '../../components/banking/bankActions';
import { ACTIONS as BETS } from '../../components/bets/betsActions';
import { ACTIONS as EVENTS } from '../../components/markets/events/eventsActions';
import { ACTIONS as GEOLOCATION } from '../../components/internationalisation/actions';
import { ACTIONS as MESSAGE } from '../../components/notifications/messages/messagesActions';
import { ACTIONS as NEWS } from '../../components/news/newsActions';
import { ACTIONS as ODDS } from '../../components/bets/oddsActions';
import { ACTIONS as RESULTS } from '../../components/markets/results/resultsActions';
import { ACTIONS as REWARDS } from '../../components/rewards/rewardsActions';
import { ACTIONS as TRANSACTIONS } from '../../components/transactions/transactionsActions';
import { ACTIONS as USER } from '../actions/userActions';
import { ACTIONS as WALLET } from '../../components/wallet/walletActions';

export default function errorsReducer(error, { type, payload = null }) {
    switch (type) {
        case MESSAGE.MESSAGE_GET_FAILURE:
        case MESSAGE.READ_COUNTER_GET_FAILURE:
        case MESSAGE.READ_COUNTER_UPDATE_FAILURE:
        case ANNOUNCEMENT.ANNOUNNCEMENT_GET_FAILURE:
        case REWARDS.REWARDS_GET_FAILURE:
        case REWARDS.REWARDS_GET_DETAILS_FAILURE:
        case REWARDS.REWARDS_JOIN_FAILURE:
        case REWARDS.REWARDS_BANNER_GET_FAILURE:
        case REWARDS.UPDATE_LOGIN_BONUS_FAILURE:
        case REWARDS.GET_LOGIN_BONUS_FAILURE:
        case BANK.AREAS_GET_FAILURE:
        case BANK.CITIES_GET_FAILURE:
        case BANK.PROVINCES_GET_FAILURE:
        case BETS.BETS_SLIP_GET_FAILURE:
        case BETS.BET_LIMITS_GET_FAILURE:
        case BETS.BET_SUBMIT_FAILURE:
        case BETS.PLAYER_BET_EVENTS_GET_FAILURE:
        case BETS.PLAYER_BET_INFO_GET_FAILURE:
        case EVENTS.GAME_EVENTS_GET_FAILURE:
        case NEWS.NEWS_GET_FAILURE:
        case NEWS.NEWS_DETAILS_GET_FAILURE:
        case ODDS.BET_SETTINGS_GET_FAILURE:
        case ODDS.ODDS_FOR_EVENT_GET_FAILURE:
        case RESULTS.GAME_RESULTS_GET_FAILURE:
        case TRANSACTIONS.TRANSACTIONS_GET_FAILURE:
        case TRANSACTIONS.SABA_TRANSACTIONS_GET_FAILURE:
        case USER.AFFILIATE_REGISTER_FAILURE:
        case USER.BTI_REDIRECT_FAILURE:
        case USER.AG_REDIRECT_FAILURE:
        case USER.SABA_REDIRECT_FAILURE:
        case USER.RNG_REDIRECT_FAILURE:
        case USER.USER_BANK_CARD_ADD_FAILURE:
        case USER.USER_BANK_CARD_DELETE_FAILURE:
        case USER.USER_CHANGE_FIN_PIN_FAILURE:
        case USER.USER_CHANGE_PASSWORD_FAILURE:
        case USER.USER_CLAIM_PASS_CODE_FAILURE:
        case USER.REFERRAL_GET_FAILURE:
        case USER.USER_DETAILS_GET_FAILURE:
        case USER.USER_DETAILS_UPDATE_FAILURE:
        case USER.USER_LOGIN_FAILURE:
        case USER.USER_REGISTER_FAILURE:
        case USER.USER_REG_CODE_REQUEST_FAILURE:
        case USER.USER_RESET_PASSWORD_FAILURE:
        case USER.USER_VERIFY_FIN_PIN_FAILURE:
        case USER.PLAYTECH_MEMBER_INFO_GET_FAILURE:
        case USER.GAME_ACCESS_GET_FAILURE:
        case USER.APP_LANGUAGE_UPDATE_FAILURE:
        case USER.USER_VIP_LEVEL_DETAILS_GET_FAILURE:
        case USER.AFFILIATE_STATUS_FAILURE:
        case WALLET.CRYPTOCURRENCY_RATES_GET_FAILURE:
        case WALLET.PAYMENT_DEPOSIT_REQUEST_FAILURE:
        case WALLET.PAYMENT_GATEWAYS_GET_FAILURE:
        case WALLET.PAYMENT_TRANSFER_REQUEST_FAILURE:
        case WALLET.PAYMENT_WITHDRAWAL_REQUEST_FAILURE:
        case WALLET.CRYPTOCURRENCY_LIST_GET_FAILURE:
        case WALLET.CHECK_ELIGIBLE_DEPOSIT_FAILURE:
        case WALLET.WALLETS_BALANCE_GET_FAILURE:
        case WALLET.WALLET_SUMMARY_GET_FAILURE:
        case WALLET.UPLOAD_RECEIPT_FAILURE:
        case WALLET.TRANSACTION_AMOUNT_SETTING_GET_FAILURE:
        case WALLET.TRANSACTION_EWALLET_AMOUNT_SETTING_GET_FAILURE:
        case GEOLOCATION.GEOLOCATION_GET_FAILURE:
            return {
                ...error,
                ...payload,
            };
        case ACTIONS.CLEAR_ERROR:
            return {};
        default:
            return error;
    }
}
