import { enableLogging } from '../common/helpers/misc';
import advantplayReducer from '../components/advantplay/advantplayReducer';
import bankReducer from '../components/banking/bankReducer';
import betsReducer from '../components/bets/betsReducer';
import countryReducer from '../components/internationalisation/reducer';
import errorsReducer from '../common/reducers/errorsReducer';
import eventsReducer from '../components/markets/events/eventsReducer';
import newsReducer from '../components/news/newsReducer';
import notificationsReducer from '../components/notifications/notificationsReducer';
import oddsReducer from '../components/bets/oddsReducer';
import operationsReducer from '../common/reducers/operationsReducer';
import resultsReducer from '../components/markets/results/resultsReducer';
import rewardsReducer from '../components/rewards/rewardsReducer';
import sabaReducer from '../common/reducers/sabaReducer';
import transactionsReducer from '../components/transactions/transactionsReducer';
import userReducer from '../common/reducers/userReducer';
import walletReducer from '../components/wallet/walletReducer';

/**
 * @typedef Action
 * @type {object}
 * @property {string} type Action type.
 * @property {*} payload Action payload.
 *
 */

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */
export default function appReducer(state, action) {
    if (enableLogging()) console.log('ACTION: ', action);
    const {
        operation,
        error,
        country,
        user,
        wallet,
        events,
        rewards,
        bank,
        bets,
        odds,
        transactions,
        results,
        news,
        notifications,
        saba,
        advantplay,
    } = state;
    const updatedState = {
        advantplay: advantplayReducer(advantplay, action),
        bank: bankReducer(bank, action),
        bets: betsReducer(bets, action),
        country: countryReducer(country, action),
        error: errorsReducer(error, action),
        events: eventsReducer(events, action),
        news: newsReducer(news, action),
        notifications: notificationsReducer(notifications, action),
        odds: oddsReducer(odds, action),
        operation: operationsReducer(operation, action),
        results: resultsReducer(results, action),
        rewards: rewardsReducer(rewards, action),
        saba: sabaReducer(saba, action),
        transactions: transactionsReducer(transactions, action),
        user: userReducer(user, action),
        wallet: walletReducer(wallet, action),
    };
    if (enableLogging()) console.log('UPDATED STATE: ', updatedState);
    return updatedState;
}
