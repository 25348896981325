import announcementsReducer from './announcements/announcementsReducer';
import messageCardReducer from './shared/messageCardReducer';
import messagesReducer from './messages/messagesReducer';

export default function notificationsReducer(notifications = {}, action) {
    const { announcements, messages, messageCard } = notifications;

    return {
        announcements: announcementsReducer(announcements, action),
        messageCard: messageCardReducer(messageCard, action),
        messages: messagesReducer(messages, action),
    };
}
