import { ACTIONS } from './messageCardActions';

export default function messageCardReducer(messageCard = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.MESSAGE_CARD_SELECTED:
            return {
                ...messageCard,
                selectedCard: payload,
            };
        case ACTIONS.MESSAGE_CARD_DESELECTED:
            return {
                ...messageCard,
                selectedCard: null,
            };

        default:
            return messageCard;
    }
}
