import { useLayoutEffect } from 'react';

import { message } from 'antd';

import { getTranslation } from '../../common/helpers/locale';

const DEFAULT_DELAY = 3;

message.config({
    maxCount: 1,
});

export function useToasterSuccessMessage(trigger = null, text = 'Success', delay = DEFAULT_DELAY) {
    useLayoutEffect(() => {
        if (trigger) message.success(text, delay);
    }, [text, delay, trigger]);
}

export function useToasterErrorMessage(
    trigger = null,
    text = getTranslation('Something went wrong!'),
    delay = DEFAULT_DELAY,
) {
    useLayoutEffect(() => {
        if (trigger) message.error(text, delay);
    }, [text, delay, trigger]);
}

export function useToasterWarningMessage(trigger = null, text = 'Warning!', delay = DEFAULT_DELAY) {
    useLayoutEffect(() => {
        if (trigger) message.warning(text, delay);
    }, [text, delay, trigger]);
}
