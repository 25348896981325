import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { ACTIONS } from '../common/actions/userActions';
import { API, OPERATION_STATES } from '../common/constants/api';
import { clearError } from '../common/actions/errorActions';
import { clearOperation } from '../common/actions/operationsActions';
import { deleteStorageItem } from '../common/helpers/api';
import { getStateProp } from '../common/helpers/misc';
import { getTranslation } from '../common/helpers/locale';
import { useDebounce } from './hooks/useDebounce';
import { useStore } from '../store/StateProvider';
import { useToasterErrorMessage, useToasterSuccessMessage } from './hooks/useToasterMessage';
import useApi from './hooks/useApi';

const { VERSION, OPERATIONS } = API;

/**
 * Triggers request to operations endpoint, and checks operation status.
 * @param {String} operation State selector name which holds operation id.
 * @param {String} successActionType Action type to be dispatched if operation status is "completed".
 * @param {String} failureActionType Action type to be dispatched if error, operation status is "rejected" or request failed.
 * @param {String} successMsg Completed operation toaster message.
 * @param {String} returnResponse Response object of api call.
 * @returns {Boolean[]} Request [complete, error, success] states.
 */
export function useOperationAsyncEndpoint(
    operation,
    successActionType,
    failureActionType,
    successMsg,
    returnResponse = {},
) {
    const [state, dispatch] = useStore();
    const [error, setError] = useState(undefined);
    const [successMessage, setSuccessMessage] = useState(null);
    const [operationComplete, setOperationComplete] = useState(null);
    const [operationSuccess, setOperationSuccess] = useState(null);
    const operationId = getStateProp(state.operation, operation);

    // ============================================
    // METHODS
    // ============================================

    const handleOperationResponse = useCallback(
        (response) => {
            setSuccessMessage(null);
            setOperationComplete(null);
            if (response) {
                clearError(dispatch);
                if (response.data.state === OPERATION_STATES.COMPLETED) {
                    dispatch({
                        type: successActionType,
                    });
                    setSuccessMessage(getTranslation(successMsg));
                    setOperationComplete(true);
                    clearOperation(dispatch);
                    if (successActionType === ACTIONS.USER_REGISTER_SUCCESS) {
                        setOperationSuccess(true);
                        deleteStorageItem('affiliateAndRefInfo');
                    }
                } else if (response.data.state === OPERATION_STATES.REJECTED) {
                    setOperationSuccess(false);
                    setError({
                        message: response.data.reason,
                    });
                }
            }
        },
        [dispatch, successActionType, successMsg],
    );

    // ============================================
    // HOOKS
    // ============================================

    const { error: operationsError, response: operationsResponse } = useApi({
        forceDispatchEffect: () => !!operationId && !_.isEmpty(returnResponse),
        method: 'GET',
        timeout: 60000,
        trigger: useDebounce(operationId, 2000),
        url: `${VERSION.V1}${OPERATIONS}/${operationId}`,
    });

    useEffect(() => {
        handleOperationResponse(operationsResponse);
    }, [handleOperationResponse, operationsResponse]);

    useEffect(() => {
        if (operationsError) {
            setError(operationsError);
            dispatch({
                payload: {
                    message: 'Operations endpoint error',
                },
                type: failureActionType,
            });
        }
    }, [dispatch, failureActionType, operationsError]);

    // clear error between requests
    useEffect(() => {
        setError(undefined);
    }, [operationId]);

    useToasterErrorMessage(error, getStateProp(operationsResponse, 'data.reason'));
    useToasterSuccessMessage(successMessage, successMessage);

    return [operationComplete, error, operationSuccess];
}
