import { ACTIONS } from './oddsActions';

export default function oddsReducer(odds = {}, { type, payload = null }) {
    switch (type) {
        case ACTIONS.ODDS_FOR_EVENT_GET_SUCCESS:
        case ACTIONS.BET_SETTINGS_GET_SUCCESS:
            return {
                ...odds,
                ...payload,
            };
        case ACTIONS.ODDS_CLEAR:
            return {
                odds: {},
            };
        case ACTIONS.ODDS_FOR_EVENT_GET:
        case ACTIONS.BET_SETTINGS_GET:
        default:
            return odds;
    }
}
