import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { ROUTES } from '../../../common/constants/routing';
import { isUserLoggedIn } from '../../../common/helpers/api';

const PrivateRoute = ({ component: Component, componentProps, ...rest }) => {
    // ============================================
    // RENDER
    // ============================================

    return (
        <Route
            {...rest}
            render={(routeProps) =>
                isUserLoggedIn() ? (
                    <Component {...routeProps} {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: ROUTES.LOGIN,
                            state: { redirectTo: routeProps.location.pathname },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
