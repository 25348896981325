import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

import { ROUTES } from '../../../common/constants/routing';
import { isUserLoggedIn } from '../../../common/helpers/api';
import Layout from '../layout/Layout';

const LayoutRoute = ({
    component: Component,
    componentProps,
    privateRoute,
    backgroundClassName,
    customHeader,
    customHeaderProps,
    customFooter,
    customFooterProps,
    backToHeader,
    defaultFooter,
    overflowYAuto = true,
    ...rest
}) => {
    return privateRoute ? (
        isUserLoggedIn() ? (
            <Route
                {...rest}
                render={(matchProps) => (
                    <Layout
                        backgroundClassName={backgroundClassName}
                        customFooter={customFooter}
                        customFooterProps={customFooterProps}
                        customHeader={customHeader}
                        customHeaderProps={customHeaderProps}
                        backToHeader={backToHeader}
                        defaultFooter={defaultFooter}
                        overflowYAuto={overflowYAuto}
                    >
                        <Component {...matchProps} {...componentProps} />
                    </Layout>
                )}
            />
        ) : (
            <Redirect to={ROUTES.LOGIN} />
        )
    ) : (
        <Route
            {...rest}
            render={(matchProps) => (
                <Layout
                    backgroundClassName={backgroundClassName}
                    customFooter={customFooter}
                    customFooterProps={customFooterProps}
                    customHeader={customHeader}
                    customHeaderProps={customHeaderProps}
                    backToHeader={backToHeader}
                    defaultFooter={defaultFooter}
                    overflowYAuto={overflowYAuto}
                >
                    <Component {...matchProps} {...componentProps} />
                </Layout>
            )}
        />
    );
};

LayoutRoute.defaultProps = {
    backToHeader: null,
    customFooter: null,
    customHeader: null,
    defaultFooter: true,
    privateRoute: false,
};

LayoutRoute.propTypes = {
    backToHeader: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string.isRequired,
    }),
    backgroundClassName: PropTypes.string,
    component: PropTypes.elementType.isRequired,
    customFooter: PropTypes.elementType,
    customHeader: PropTypes.elementType,
    defaultFooter: PropTypes.bool,
    privateRoute: PropTypes.bool,
};

export default LayoutRoute;
