import _ from 'lodash';

import { ACTIONS } from './betsActions';
import { BETS_PAGE_NO_DEFAULT, BETS_PAGE_SIZE_DEFAULT, PLATFORM_FILTER_VALUES } from './constants';
import { MARKETS } from '../../common/constants/misc';

export default function betsReducer(
    bets = {
        TFBets: [],
        gamesBetsIsPageChanging: false,
        limits: {
            maxBet: 100,
            minBet: 1,
        },
        paging: {
            pageNo: BETS_PAGE_NO_DEFAULT,
            pageSize: BETS_PAGE_SIZE_DEFAULT,
            totalRecordCount: 0,
        },
        playerGamesBet: [],
        sabaBets: [],
        slipFilter: (slip) => slip.marketType === MARKETS.TODAY || slip.marketType === MARKETS.LIVE,
        slipPlatform: PLATFORM_FILTER_VALUES.ALL,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.BET_CREATE:
            return {
                ...bets,
                betErrorMessage: null,
                betErrorVisible: false,
                betSuccessModalVisible: false,
                bettingModalVisible: true,
                selectedBet: {
                    ...payload,
                    ...bets.limits,
                },
            };

        case ACTIONS.BET_UPDATE:
            return {
                ...bets,
                selectedBet: {
                    ...bets.selectedBet,
                    ...payload,
                },
            };

        case ACTIONS.BET_SUBMIT_SUCCESS:
            const { pending, running, isTFGamingPlatform } = payload;
            let newSabaBets = [...bets.sabaBets];
            let newTFBets = [...bets.TFBets];
            if (isTFGamingPlatform) newTFBets = [...newTFBets, bets.selectedBet];
            else newSabaBets = [...newSabaBets, bets.selectedBet];
            return {
                ...bets,
                TFBets: [...newTFBets],
                betSuccessModalVisible: true,
                bettingModalVisible: false,
                count: {
                    pending,
                    running,
                },
                sabaBets: [...newSabaBets],
                selectedBet: {},
            };
        case ACTIONS.BET_DELETE:
            return {
                ...bets,
                bettingModalVisible: false,
                selectedBet: {},
            };
        case ACTIONS.BETS_SLIP_STATUS_CHANGE:
            return {
                ...bets,
                slip: [],
                slipStatus: payload,
            };
        case ACTIONS.BETS_STATEMENT_GAMES_PLATFORM_CHANGE:
            return {
                ...bets,
                paging: {
                    pageNo: BETS_PAGE_NO_DEFAULT,
                    pageSize: BETS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                playerGamesBet: [],
                slip: [],
                statementPlatform: payload,
            };
        case ACTIONS.BETS_STATEMENT_GAMES_PRODUCT_CHANGE:
            return {
                ...bets,
                paging: {
                    pageNo: BETS_PAGE_NO_DEFAULT,
                    pageSize: BETS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                playerGamesBet: [],
                slip: [],
                statementProduct: payload,
            };
        case ACTIONS.BETS_SLIP_PLATFORM_CHANGE:
            return {
                ...bets,
                paging: {
                    pageNo: BETS_PAGE_NO_DEFAULT,
                    pageSize: BETS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                playerGamesBet: [],
                slip: [],
                slipPlatform: payload,
            };
        case ACTIONS.BETS_SLIP_GET_SUCCESS:
            return {
                ...bets,
                slip: payload,
            };
        case ACTIONS.BETS_SLIP_PERIOD_CHANGE:
            return {
                ...bets,
                gamesBetsIsPageChanging: false,
                paging: {
                    pageNo: BETS_PAGE_NO_DEFAULT,
                    pageSize: BETS_PAGE_SIZE_DEFAULT,
                    totalRecordCount: 0,
                },
                playerGamesBet: [],
                slipPeriod: payload,
            };
        case ACTIONS.PLAYER_BET_EVENTS_GET_SUCCESS:
            return {
                ...bets,
                ...payload,
            };
        case ACTIONS.PLAYER_BET_INFO_GET_SUCCESS:
            const { betCount } = payload;
            return {
                ...bets,
                count: {
                    ...betCount,
                },
            };
        case ACTIONS.BET_LIMITS_GET_SUCCESS:
            const { minBet, maxBet } = payload;
            return {
                ...bets,
                limits: {
                    maxBet,
                    minBet,
                },
            };
        case ACTIONS.BET_SUCCESS_MODAL_CLOSE:
            return {
                ...bets,
                betSuccessModalVisible: false,
            };
        case ACTIONS.BETS_SLIP_APPLY_FILTER:
            return {
                ...bets,
                slipFilter: payload,
            };
        case ACTIONS.BET_SUBMIT_ERROR_SHOW:
            return {
                ...bets,
                betErrorMessage: payload,
                betErrorVisible: true,
            };
        case ACTIONS.BET_SUBMIT_ERROR_HIDE:
            return {
                ...bets,
                betErrorMessage: null,
                betErrorVisible: false,
            };
        case ACTIONS.PLAYER_BET_STATEMENTS_GET_SUCCESS:
            return {
                ...bets,
                gamesBetsIsPageChanging: false,
                paging: {
                    ...bets.paging,
                    ...payload.paging,
                },
                playerGamesBet: _.unionBy(bets.playerGamesBet, payload.data),
            };
        case ACTIONS.PAGINATION.PAGE_CHANGE:
            return {
                ...bets,
                gamesBetsIsPageChanging: true,
                paging: {
                    ...bets.paging,
                    pageNo: payload > bets.paging.pageCount ? bets.paging.pageCount : payload,
                },
            };
        case ACTIONS.PAGINATION.PAGE_SIZE_CHANGE:
            return {
                ...bets,
                paging: {
                    ...bets.paging,
                    pageNo: 1,
                    pageSize: payload < 1 ? BETS_PAGE_SIZE_DEFAULT : payload,
                },
            };
        case ACTIONS.PLAYER_BET_STATEMENTS_GET:
        case ACTIONS.BET_LIMITS_GET:
        case ACTIONS.PLAYER_BET_EVENTS_GET:
        case ACTIONS.PLAYER_BET_INFO_GET:
        case ACTIONS.BETS_SLIP_GET:
        case ACTIONS.BET_SUBMIT:
        default:
            return bets;
    }
}
