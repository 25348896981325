export const ICONS = {
    ADVANTPLAY: {
        BATTLE_OF_HEROES: {
            CN: {
                '1X': require('../assets/images/advantplay/Battle of Heroes-cn.gif'),
                '2X': require('../assets/images/advantplay/Battle of Heroes@2x-cn.gif'),
            },
            US: {
                '1X': require('../assets/images/advantplay/Battle of Heroes.gif'),
                '2X': require('../assets/images/advantplay/Battle of Heroes@2x.gif'),
            },
        },
        BOBO_MONSTER: {
            CN: require('../assets/images/advantplay/BOBO-monster-cn.gif'),
            US: require('../assets/images/advantplay/BOBO-monster.gif'),
        },
        BOM_BOM_BOT: {
            CN: require('../assets/images/advantplay/bom-bom-bot-cn.png'),
            US: require('../assets/images/advantplay/bom-bom-bot-en.png'),
        },
        BUBBLE_DRAGON: {
            CN: require('../assets/images/advantplay/bubble-dragon-cn.png'),
            US: require('../assets/images/advantplay/bubble-dragon-en.png'),
        },
        CHRONICLE_OF_HEROES_DIVINE_TREE: {
            CN: {
                '1X': require('../assets/images/advantplay/Divine Tree-CN.gif'),
                '2X': require('../assets/images/advantplay/Divine Tree-CN@2x.gif'),
            },
            US: {
                '1X': require('../assets/images/advantplay/Divine Tree.gif'),
                '2X': require('../assets/images/advantplay/Divine Tree@2x.gif'),
            },
        },
        CHRONICLE_OF_HEROES_LEGENDARY_THREE_WARRIORS: {
            CN: {
                '1X': require('../assets/images/advantplay/Three Warriors-CN.gif'),
                '2X': require('../assets/images/advantplay/Three Warriors-CN@2x.gif'),
            },
            US: {
                '1X': require('../assets/images/advantplay/Three Warriors.gif'),
                '2X': require('../assets/images/advantplay/Three Warriors@2x.gif'),
            },
        },
        COMING_SOON: {
            CN: {
                '1X': require('../assets/images/advantplay/comingsoon.png'),
                '2X': require('../assets/images/advantplay/comingsoon@2x.png'),
            },
            US: {
                '1X': require('../assets/images/advantplay/comingsoon.png'),
                '2X': require('../assets/images/advantplay/comingsoon@2x.png'),
            },
        },
        COUNTER_TERRORISTS: {
            CN: {
                '1X': require('../assets/images/advantplay/Counter Terrorists-cn.gif'),
                '2X': require('../assets/images/advantplay/Counter Terrorists@2x-cn.gif'),
            },
            US: {
                '1X': require('../assets/images/advantplay/Counter Terrorists.gif'),
                '2X': require('../assets/images/advantplay/Counter Terrorists@2x.gif'),
            },
        },
        EYE_OF_RA: {
            CN: {
                '1X': require('../assets/images/advantplay/eye-of-ra-cn.png'),
                '2X': require('../assets/images/advantplay/eye-of-ra-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/advantplay/eye-of-ra-en.png'),
                '2X': require('../assets/images/advantplay/eye-of-ra-en@2x.png'),
            },
        },
        GAME_TEXT_ICON: {
            CN: require('../assets/images/Home/advantplay-game-text-icon-cn.png'),
            US: require('../assets/images/Home/advantplay-game-text-icon.png'),
        },
        KING_OF_GLORY: {
            CN: require('../assets/images/advantplay/king-of-glory-cn.png'),
            US: require('../assets/images/advantplay/king-of-glory-en.png'),
        },
        LOGO: require('../assets/images/advantplay/logo.png'),
        LUCKY_DRAGON: {
            CN: {
                '1X': require('../assets/images/advantplay/lucky-dragon-cn.gif'),
                '2X': require('../assets/images/advantplay/lucky-dragon-cn@2x.gif'),
            },
            US: {
                '1X': require('../assets/images/advantplay/lucky-dragon.gif'),
                '2X': require('../assets/images/advantplay/lucky-dragon@2x.gif'),
            },
        },
        NINJA_LEGEND: {
            CN: require('../assets/images/advantplay/ninja-legend-cn.png'),
            US: require('../assets/images/advantplay/ninja-legend-en.png'),
        },
        PROGRESSIVE_JACKPOT_LVL_1: {
            CN: require('../assets/images/Home/progressive-jackpot-lvl1-cn.png'),
            US: require('../assets/images/Home/Progressive Jackpot - Level 1.png'),
        },
        PROGRESSIVE_JACKPOT_LVL_2: {
            CN: require('../assets/images/Home/progressive-jackpot-lvl2-cn.png'),
            US: require('../assets/images/Home/Progressive Jackpot - Level 2.png'),
        },
        ROAD_TO_EURO: {
            CN: require('../assets/images/advantplay/road-to-euro-cn.png'),
            US: require('../assets/images/advantplay/road-to-euro-en.png'),
        },
    },
    ADVANTPLAY_MINI_GAME: {
        CARD_RACING: {
            CN: require('../assets/images/advantplay-mini-games/card-racing-cn.png'),
            US: require('../assets/images/advantplay-mini-games/card-racing-en.png'),
        },
        CRASH: {
            CN: require('../assets/images/advantplay-mini-games/crash-cn.png'),
            US: require('../assets/images/advantplay-mini-games/crash-en.png'),
        },
        CRASH_EURO: {
            CN: require('../assets/images/advantplay-mini-games/crash-euro-cn.png'),
            US: require('../assets/images/advantplay-mini-games/crash-euro-en.png'),
        },
        LOGO: require('../assets/images/advantplay-mini-games/logo.png'),
    },
    AFFILIATE: {
        CONTACTS: {
            QQ: require('../assets/images/AffiliateProgram/qq-icon.png'),
            SKYPE: require('../assets/images/AffiliateProgram/skype-icon.png'),
            TELEGRAM: require('../assets/images/AffiliateProgram/telegram-icon.png'),
            WHATSAPP: require('../assets/images/AffiliateProgram/whatsapp-icon.png'),
        },
        CORES: {
            EYE_ICON: require('../assets/images/AffiliateProgram/eye-icon.png'),
            LIKE_ICON: require('../assets/images/AffiliateProgram/like-icon.png'),
            LOCK_ICON: require('../assets/images/AffiliateProgram/lock-icon.png'),
        },
        INVITE: {
            CONVERSATION_ICON: require('../assets/images/AffiliateProgram/conversation-icon.png'),
            QRCODE_ICON: require('../assets/images/AffiliateProgram/qrcode-icon.png'),
            SHARE_ICON: require('../assets/images/AffiliateProgram/share-icon.png'),
        },
        PENDING_ICON: require('../assets/images/AffiliateProgram/processing.png'),
        REJECTED_ICON: require('../assets/images/AffiliateProgram/rejected.png'),
        SUCCESSFUL_ICON: require('../assets/images/AffiliateProgram/successful.png'),
        TRANSACTION_ICON: require('../assets/images/Transaction/affiliate-payout.png'),
    },
    APP_DOWNLOAD: {
        ANDROID: require('../assets/images/AppDownload/android.png'),
        IOS: require('../assets/images/AppDownload/ios.png'),
        QR_CODE: {
            ANDROID: {
                GGBAO: require('../assets/images/AppDownload/ggbao_android.png'),
                GGBOOK: require('../assets/images/AppDownload/ggbook_android.png'),
            },
            IOS: {
                GGBAO: require('../assets/images/AppDownload/ggbao_ios.png'),
                GGBOOK: require('../assets/images/AppDownload/ggbook_ios.png'),
            },
        },
    },
    BANK_CARDS: {
        DELETE: require('../assets/images/BankCards/delete-icon.png'),
        NO_CARDS: require('../assets/images/BankCards/no-cards.png'),
    },
    BANNER: {
        ADVANTPLAY: require('../assets/images/banner/advantplay-banner-v1.jpg'),
        ADVANTPLAY_CRASH_EURO: require('../assets/images/banner/advantplay-euro-crash-v1.jpg'),
        ADVANTPLAY_EURO: require('../assets/images/banner/advantplay-euro-v1.jpg'),
        KOG: require('../assets/images/banner/kog-gg.jpeg'),
        SPONSORSHIP: {
            CN: require('../assets/images/banner/sponsorship-cn.jpg'),
            ID: require('../assets/images/banner/sponsorship-id.jpg'),
            TH: require('../assets/images/banner/sponsorship-th.jpg'),
            US: require('../assets/images/banner/sponsorship-us.jpg'),
            VN: require('../assets/images/banner/sponsorship-vn.jpg'),
        },
        TF_GAMING: {
            CN: require('../assets/images/banner/tf-gaming-cn.jpg'),
            ID: require('../assets/images/banner/tf-gaming-id.jpg'),
            TH: require('../assets/images/banner/tf-gaming-th.jpg'),
            US: require('../assets/images/banner/tf-gaming-us.jpg'),
            VN: require('../assets/images/banner/tf-gaming-vn.jpg'),
        },
    },
    BETTING_PLATFORM_FILTER: require('../assets/images/Bets/filter-icon.png'),
    CHICKEN: {
        CN: require('../assets/images/Markets/chicken-dinner-cn.gif'),
        US: require('../assets/images/Markets/chicken-dinner-us.gif'),
    },
    CRYPTOCURRENCIES: {
        BTC: require('../assets/images/Wallet/BTC.png'),
        BTC_FILLED: require('../assets/images/Wallet/BTC-filled.png'),
        ETH: require('../assets/images/Wallet/ETH.png'),
        QR_ICON: {
            BTC: {
                CN: require('../assets/images/Wallet/crypto/qr-btc-cn.png'),
                US: require('../assets/images/Wallet/crypto/qr-btc-us.png'),
            },
            ETH: {
                CN: require('../assets/images/Wallet/crypto/qr-eth-cn.png'),
                US: require('../assets/images/Wallet/crypto/qr-eth-us.png'),
            },
            USDT: {
                CN: require('../assets/images/Wallet/crypto/qr-usdt-cn.png'),
                US: require('../assets/images/Wallet/crypto/qr-usdt-us.png'),
            },
        },
        USDT: require('../assets/images/Wallet/USDT.png'),
    },
    CRYPTO_TUTORIAL: {
        ANDROID_LOGO: require('../assets/images/CryptoTutorial/android-logo.png'),
        BITPIE: require('../assets/images/CryptoTutorial/bitpie.png'),
        BITPIE_LOGO: require('../assets/images/CryptoTutorial/bitpie-logo.png'),
        BTC_ICON: require('../assets/images/CryptoTutorial/btc-icon.png'),
        CONNECT_ICON: require('../assets/images/CryptoTutorial/connect-icon.png'),
        ETH_ICON: require('../assets/images/CryptoTutorial/eth-icon.png'),
        FLAGS: {
            CHINA: require('../assets/images/CryptoTutorial/cn-flag.png'),
            INDO: require('../assets/images/CryptoTutorial/in-flag.png'),
            MALAYSIA: require('../assets/images/CryptoTutorial/my-flag.png'),
            THAI: require('../assets/images/CryptoTutorial/th-flag.png'),
            VIETNAM: require('../assets/images/CryptoTutorial/vn-flag.png'),
        },
        IOS_LOGO: require('../assets/images/CryptoTutorial/ios-logo.png'),
        LOGO: require('../assets/images/CryptoTutorial/usdt.png'),
        OPERATION_GUIDE: {
            BINANCE: {
                STEP_1: {
                    CN: require('../assets/images/CryptoTutorial/binance/step1-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step1-us.png'),
                },
                STEP_10: {
                    CN: require('../assets/images/CryptoTutorial/binance/step10-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step10-us.png'),
                },
                STEP_11: {
                    CN: require('../assets/images/CryptoTutorial/binance/step11-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step11-us.png'),
                },
                STEP_12: {
                    CN: require('../assets/images/CryptoTutorial/binance/step12-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step12-us.png'),
                },
                STEP_2: {
                    CN: require('../assets/images/CryptoTutorial/binance/step2-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step2-us.png'),
                },
                STEP_3: {
                    CN: require('../assets/images/CryptoTutorial/binance/step3-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step3-us.png'),
                },
                STEP_4: {
                    CN: require('../assets/images/CryptoTutorial/binance/step4-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step4-us.png'),
                },
                STEP_5: {
                    CN: require('../assets/images/CryptoTutorial/binance/step5-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step5-us.png'),
                },
                STEP_6: {
                    CN: require('../assets/images/CryptoTutorial/binance/step6-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step6-us.png'),
                },
                STEP_7: {
                    CN: require('../assets/images/CryptoTutorial/binance/step7-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step7-us.png'),
                },
                STEP_8: {
                    CN: require('../assets/images/CryptoTutorial/binance/step8-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step8-us.png'),
                },
                STEP_9: {
                    CN: require('../assets/images/CryptoTutorial/binance/step9-cn.png'),
                    US: require('../assets/images/CryptoTutorial/binance/step9-us.png'),
                },
            },
            BITPIE: {
                STEP_1: require('../assets/images/CryptoTutorial/bitpie/step-1.png'),
                STEP_10: require('../assets/images/CryptoTutorial/bitpie/step-10.png'),
                STEP_11: require('../assets/images/CryptoTutorial/bitpie/step-11.png'),
                STEP_12: require('../assets/images/CryptoTutorial/bitpie/step-12.png'),
                STEP_13: require('../assets/images/CryptoTutorial/bitpie/step-13.png'),
                STEP_2: require('../assets/images/CryptoTutorial/bitpie/step-2.png'),
                STEP_3: require('../assets/images/CryptoTutorial/bitpie/step-3.png'),
                STEP_4: require('../assets/images/CryptoTutorial/bitpie/step-4.png'),
                STEP_5: require('../assets/images/CryptoTutorial/bitpie/step-5.png'),
                STEP_6: require('../assets/images/CryptoTutorial/bitpie/step-6.png'),
                STEP_7: require('../assets/images/CryptoTutorial/bitpie/step-7.png'),
                STEP_8: require('../assets/images/CryptoTutorial/bitpie/step-8.png'),
                STEP_9: require('../assets/images/CryptoTutorial/bitpie/step-9.png'),
            },
        },
        PLUS_MINUS_ICON: require('../assets/images/CryptoTutorial/plus-minus-icon.png'),
        QR_ICON: require('../assets/images/CryptoTutorial/qr-icon.png'),
        RECEIVE_ICON: require('../assets/images/CryptoTutorial/receive-icon.png'),
        SELL_ICON: require('../assets/images/CryptoTutorial/sell-icon.png'),
        SETTING_LOGO: require('../assets/images/CryptoTutorial/setting-logo.png'),
        TRANSFER_ICON: require('../assets/images/CryptoTutorial/transfer-icon.png'),
        WALLET_ICON: require('../assets/images/CryptoTutorial/wallet-icon.png'),
    },
    CURRENCY_ICONS: {
        CNY: {
            '1X': require('../assets/images/currency/CNY.png'),
            '2X': require('../assets/images/currency/CNY@2x.png'),
        },
        IDR: {
            '1X': require('../assets/images/currency/IDR.png'),
            '2X': require('../assets/images/currency/IDR@2x.png'),
        },
        MYR: {
            '1X': require('../assets/images/currency/MYR.png'),
            '2X': require('../assets/images/currency/MYR@2x.png'),
        },
        THB: {
            '1X': require('../assets/images/currency/THB.png'),
            '2X': require('../assets/images/currency/THB@2x.png'),
        },
        USD: {
            '1X': require('../assets/images/currency/USD.png'),
            '2X': require('../assets/images/currency/USD@2x.png'),
        },
        VND: {
            '1X': require('../assets/images/currency/VND.png'),
            '2X': require('../assets/images/currency/VND@2x.png'),
        },
    },
    CUSTOMER_SUPPORT: {
        LINE: require('../assets/images/Home/customer-support/line.png'),
        LINE_QR: require('../assets/images/Home/customer-support/support-line-ggbook.jpeg'),
        LIVECHAT: require('../assets/images/Home/customer-support/livechat.png'),
        TELEGRAM: require('../assets/images/Home/customer-support/telegram.png'),
        TELEGRAM_QR: require('../assets/images/Home/customer-support/qr-code-telegram-indo.png'),
        WHATSAPP: require('../assets/images/Home/customer-support/whatsapp.png'),
        WHATSAPP_QR: {
            INDO: require('../assets/images/Home/customer-support/qr-code-whatsapp-indo.png'),
            MALAY: require('../assets/images/Home/customer-support/qrcode-whatsup-malay.png'),
        },
    },
    DEFAULT_TEAM: {
        SABA: require('../assets/images/Markets/default-team-saba.png'),
        TF_GAMING: require('../assets/images/Markets/default-team-ggbook.png'),
    },
    ERROR: {
        ERROR_4XX: require('../assets/images/common/400-errors.png'),
        ERROR_5XX: require('../assets/images/common/500-errors.png'),
    },
    EVENT: {
        ARROW_DOWN: require('../assets/images/Bets/arrow-down.png'),
        ARROW_UP: require('../assets/images/Bets/arrow-up.png'),
    },
    EVENT_STATS_LINK: require('../assets/images/Markets/stats-link-ico.png'),
    FEATURED_GAMES: {
        AG: {
            '1X': require('../assets/images/games/featured-games/ag.png'),
            '2X': require('../assets/images/games/featured-games/ag@2x.png'),
        },
        ALLBET: {
            '1X': require('../assets/images/games/featured-games/allbet.png'),
            '2X': require('../assets/images/games/featured-games/allbet@2x.png'),
        },
        EURO_VIRTUAL_LEAGUES: {
            CN: {
                '1X': require('../assets/images/games/featured-games/saba-european-vl-cn.png'),
                '2X': require('../assets/images/games/featured-games/saba-european-vl-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/games/featured-games/saba-european-vl-en.png'),
                '2X': require('../assets/images/games/featured-games/saba-european-vl-en@2x.png'),
            },
        },
        NBA: {
            CN: {
                '1X': require('../assets/images/games/featured-games/nba-cn.png'),
                '2X': require('../assets/images/games/featured-games/nba-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/games/featured-games/nba-en.png'),
                '2X': require('../assets/images/games/featured-games/nba-en@2x.png'),
            },
        },
        SEXY_GAMING: {
            '1X': require('../assets/images/games/featured-games/sexy-gaming.png'),
            '2X': require('../assets/images/games/featured-games/sexy-gaming@2x.png'),
        },
        UEFA_CHAMPIONS_CUP: {
            CN: {
                '1X': require('../assets/images/games/featured-games/saba-uefa-champions-cup-cn.png'),
                '2X': require('../assets/images/games/featured-games/saba-uefa-champions-cup-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/games/featured-games/saba-uefa-champions-cup-en.png'),
                '2X': require('../assets/images/games/featured-games/saba-uefa-champions-cup-en@2x.png'),
            },
        },
        VIRTUAL_GAME: {
            CN: {
                '1X': require('../assets/images/games/featured-games/virtual-games-cn.png'),
                '2X': require('../assets/images/games/featured-games/virtual-games-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/games/featured-games/virtual-games-en.png'),
                '2X': require('../assets/images/games/featured-games/virtual-games-en@2x.png'),
            },
        },
    },
    GAME_ICONS: {
        AOV: require('../assets/images/games/esport/icons/aov.png'),
        ARTIFACT: require('../assets/images/games/esport/icons/artifact.png'),
        BASKETBALL: require('../assets/images/games/esport/icons/basketball.png'),
        COD: require('../assets/images/games/esport/icons/cod.png'),
        CS_GO: require('../assets/images/games/esport/icons/cs-go.png'),
        DOTA2: require('../assets/images/games/esport/icons/dota2.png'),
        FIFA: require('../assets/images/games/esport/icons/fifa.png'),
        FORTNITE: require('../assets/images/games/esport/icons/fortnite.png'),
        GAMING: require('../assets/images/games/esport/icons/gaming.png'),
        GFP: require('../assets/images/games/esport/icons/gfp.png'),
        HEARTHSTONE: require('../assets/images/games/esport/icons/hearthstone.png'),
        HOTS: require('../assets/images/games/esport/icons/hots.png'),
        KOG: require('../assets/images/games/esport/icons/kog.png'),
        LOL: require('../assets/images/games/esport/icons/lol.png'),
        MOBILE_LEGENDS: require('../assets/images/games/esport/icons/mobile-legends.png'),
        NBA2K18: require('../assets/images/games/esport/icons/nba2k18.png'),
        OVERWATCH: require('../assets/images/games/esport/icons/overwatch.png'),
        PUBG: require('../assets/images/games/esport/icons/pubg.png'),
        QUAKE: require('../assets/images/games/esport/icons/quake.png'),
        RBOW6: require('../assets/images/games/esport/icons/rainbow6.png'),
        RLEAGUE: require('../assets/images/games/esport/icons/rocket-league.png'),
        STARCRAFT: require('../assets/images/games/esport/icons/starcraft.png'),
        STARCRAFT2: require('../assets/images/games/esport/icons/starcraft2.png'),
        STREETF5: require('../assets/images/games/esport/icons/street-fighter5.png'),
        VALORANT: require('../assets/images/games/esport/icons/valorant.png'),
        WARCRAFT3: require('../assets/images/games/esport/icons/warcraft3.png'),
    },
    GAME_THUMBNAILS: {
        AOV: {
            '1X': require('../assets/images/games/esport/thumbnails/aov.png'),
            '2X': require('../assets/images/games/esport/thumbnails/aov@2x.png'),
        },
        ARTIFACT: {
            '1X': require('../assets/images/games/esport/thumbnails/artifact.png'),
            '2X': require('../assets/images/games/esport/thumbnails/artifact@2x.png'),
        },
        COD: {
            '1X': require('../assets/images/games/esport/thumbnails/cod.png'),
            '2X': require('../assets/images/games/esport/thumbnails/cod@2x.png'),
        },
        CS_GO: {
            '1X': require('../assets/images/games/esport/thumbnails/cs-go.png'),
            '2X': require('../assets/images/games/esport/thumbnails/cs-go@2x.png'),
        },
        DOTA2: {
            '1X': require('../assets/images/games/esport/thumbnails/dota2.png'),
            '2X': require('../assets/images/games/esport/thumbnails/dota2@2x.png'),
        },
        HEARTHSTONE: {
            '1X': require('../assets/images/games/esport/thumbnails/hearthstone.png'),
            '2X': require('../assets/images/games/esport/thumbnails/hearthstone@2x.png'),
        },
        KOG: {
            '1X': require('../assets/images/games/esport/thumbnails/kog.png'),
            '2X': require('../assets/images/games/esport/thumbnails/kog@2x.png'),
        },
        LOL: {
            '1X': require('../assets/images/games/esport/thumbnails/lol.png'),
            '2X': require('../assets/images/games/esport/thumbnails/lol@2x.png'),
        },
        OVERWATCH: {
            '1X': require('../assets/images/games/esport/thumbnails/overwatch.png'),
            '2X': require('../assets/images/games/esport/thumbnails/overwatch@2x.png'),
        },
        PUBG: {
            '1X': require('../assets/images/games/esport/thumbnails/pubg.png'),
            '2X': require('../assets/images/games/esport/thumbnails/pubg@2x.png'),
        },
        RBOW6: {
            '1X': require('../assets/images/games/esport/thumbnails/rainbow6.png'),
            '2X': require('../assets/images/games/esport/thumbnails/rainbow6@2x.png'),
        },
        RLEAGUE: {
            '1X': require('../assets/images/games/esport/thumbnails/rocket-league.png'),
            '2X': require('../assets/images/games/esport/thumbnails/rocket-league@2x.png'),
        },
        STARCRAFT: {
            '1X': require('../assets/images/games/esport/thumbnails/starcraft.png'),
            '2X': require('../assets/images/games/esport/thumbnails/starcraft@2x.png'),
        },
        STARCRAFT2: {
            '1X': require('../assets/images/games/esport/thumbnails/starcraft2.png'),
            '2X': require('../assets/images/games/esport/thumbnails/starcraft2@2x.png'),
        },
        STREETF5: {
            '1X': require('../assets/images/games/esport/thumbnails/street-fighter5.png'),
            '2X': require('../assets/images/games/esport/thumbnails/street-fighter5@2x.png'),
        },
        WARCRAFT3: {
            '1X': require('../assets/images/games/esport/thumbnails/warcraft3.png'),
            '2X': require('../assets/images/games/esport/thumbnails/warcraft3@2x.png'),
        },
    },
    HEADER: {
        BURGER_MENU: require('../assets/images/Layout/ico-burger.png'),
        SUPPORT: require('../assets/images/Layout/ico-support.png'),
    },
    HELP_MODAL: {
        ADD_HOME_SCREEN: {
            CN: {
                '1X': require('../assets/images/common/pwa-help-modal/add-home-screen-cn.png'),
                '2X': require('../assets/images/common/pwa-help-modal/add-home-screen-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/common/pwa-help-modal/add-home-screen-us.png'),
                '2X': require('../assets/images/common/pwa-help-modal/add-home-screen-us@2x.png'),
            },
        },
        ANDROID: {
            '1X': require('../assets/images/common/pwa-help-modal/android.png'),
            '2X': require('../assets/images/common/pwa-help-modal/android@2x.png'),
        },
        ARROW_DOWN: {
            '1X': require('../assets/images/common/pwa-help-modal/arrow-down.png'),
            '2X': require('../assets/images/common/pwa-help-modal/arrow-down@2x.png'),
        },
        ARROW_UP: {
            '1X': require('../assets/images/common/pwa-help-modal/arrow-up.png'),
            '2X': require('../assets/images/common/pwa-help-modal/arrow-up@2x.png'),
        },
        HAND_TOUCH: {
            '1X': require('../assets/images/common/pwa-help-modal/hand-touch.png'),
            '2X': require('../assets/images/common/pwa-help-modal/hand-touch@2x.png'),
        },
        IOS: {
            '1X': require('../assets/images/common/pwa-help-modal/ios.png'),
            '2X': require('../assets/images/common/pwa-help-modal/ios@2x.png'),
        },
    },
    HOME: {
        ANNOUNCEMENTS: require('../assets/images/Home/announcements-icon.png'),
        COLLAPSE_ICON: require('../assets/images/Home/floating-collapse-btn.png'),
        DAILY_LOGIN_ICON: require('../assets/images/Home/daily-login/daily-login-icon.png'),
        DAILY_LOGIN_MODAL_ICON: require('../assets/images/Home/daily-login/daily-login-modal-icon.png'),
        EXPAND_ICON: require('../assets/images/Home/floating-expand-btn.png'),
        GAMES: {
            CASINO: {
                ONE_RNG: {
                    FOREGROUND: require('../assets/images/Home/games/casino/onerng-player.png'),
                    IBCBET_LOGO: require('../assets/images/Home/games/casino/ibcbet-casino-logo.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/onerng-logo-cn.png'),
                        US: require('../assets/images/Home/games/casino/onerng-logo-us.png'),
                    },
                },
                PG: {
                    FOREGROUND: require('../assets/images/Home/games/casino/pgsoft-player.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/pgsoft-logo-us.png'),
                        US: require('../assets/images/Home/games/casino/pgsoft-logo-us.png'),
                    },
                },
                PLAYTECH: {
                    FOREGROUND: require('../assets/images/Home/games/casino/playtech-player.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/playtech-logo-us.png'),
                        US: require('../assets/images/Home/games/casino/playtech-logo-us.png'),
                    },
                },
                PRAGMATIC_PLAY: {
                    FOREGROUND: require('../assets/images/Home/games/casino/pp-player.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/pp-logo-cn.png'),
                        US: require('../assets/images/Home/games/casino/pp-logo-us.png'),
                    },
                },
                SPADE_GAMING: {
                    FOREGROUND: require('../assets/images/Home/games/casino/sg-player.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/sg-logo-cn.png'),
                        US: require('../assets/images/Home/games/casino/sg-logo-us.png'),
                    },
                },
                VOID_BRIDGE: {
                    FOREGROUND: require('../assets/images/Home/games/casino/vb-player.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/casino/vb-logo-cn.png'),
                        US: require('../assets/images/Home/games/casino/vb-logo-us.png'),
                    },
                },
            },
            ESPORTS: {
                DIRE_ESPORTS: require('../assets/images/Home/games/esports/dire-esports.png'),
                GGBOOK_ESPORTS: require('../assets/images/Home/games/esports/ggbook-esports.png'),
                GGBOOK_LOGO: {
                    CN: require('../assets/images/Home/games/esports/ggbook-logo-cn.png'),
                    US: require('../assets/images/Home/games/esports/ggbook-logo-us.png'),
                },
                SABA_ESPORTS: require('../assets/images/Home/games/esports/saba-esports.png'),
                SABA_LOGO: {
                    CN: require('../assets/images/Home/games/esports/saba-logo-cn.png'),
                    US: require('../assets/images/Home/games/esports/saba-logo-us.png'),
                },
            },
            HOT_GAMES: {
                BIG_SMALL: {
                    FOREGROUND: {
                        CN: require('../assets/images/Home/games/hot-games/big-small-foreground-cn.png'),
                        US: require('../assets/images/Home/games/hot-games/big-small-foreground-us.png'),
                    },
                    LOGO: {
                        CN: require('../assets/images/Home/games/hot-games/big-small-logo.png'),
                        US: require('../assets/images/Home/games/hot-games/big-small-logo.png'),
                    },
                },
                ESPORTS: {
                    FOREGROUND: require('../assets/images/Home/games/hot-games/esports-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/hot-games/esports-logo-cn.png'),
                        US: require('../assets/images/Home/games/hot-games/esports-logo-us.png'),
                    },
                },
                LOTTERY: {
                    FOREGROUND: {
                        CN: require('../assets/images/Home/games/hot-games/lottery-foreground-cn.png'),
                        US: require('../assets/images/Home/games/hot-games/lottery-foreground-us.png'),
                    },
                },
                SABA_CLUB: {
                    FOREGROUND: require('../assets/images/Home/games/hot-games/saba-club-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/hot-games/saba-club-logo-us.png'),
                        US: require('../assets/images/Home/games/hot-games/saba-club-logo-us.png'),
                    },
                },
                VIRTUAL_SPORTS: {
                    FOREGROUND: require('../assets/images/Home/games/hot-games/virtual-sports-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/hot-games/virtual-sports-logo-cn.png'),
                        US: require('../assets/images/Home/games/hot-games/virtual-sports-logo-us.png'),
                    },
                },
            },
            LIVE_CASINO: {
                AG: {
                    FOREGROUND: require('../assets/images/Home/games/live-casino/ag-girl.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/live-casino/ag-logo-cn.png'),
                        US: require('../assets/images/Home/games/live-casino/ag-logo-us.png'),
                    },
                },
                ALLBET: {
                    FOREGROUND: require('../assets/images/Home/games/live-casino/allbet-girl.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/live-casino/allbet-logo-cn.png'),
                        US: require('../assets/images/Home/games/live-casino/allbet-logo-us.png'),
                    },
                },
                GD: {
                    FOREGROUND: require('../assets/images/Home/games/live-casino/gd-girl.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/live-casino/gd-logo-cn.png'),
                        US: require('../assets/images/Home/games/live-casino/gd-logo-us.png'),
                    },
                },
                PLAYTECH: {
                    FOREGROUND: require('../assets/images/Home/games/live-casino/playtech-girl.png'),
                },
                SG: {
                    FOREGROUND: require('../assets/images/Home/games/live-casino/sg-girl.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/live-casino/sg-logo-us.png'),
                        US: require('../assets/images/Home/games/live-casino/sg-logo-us.png'),
                    },
                },
            },
            LOTTERY: {
                KENO: {
                    FOREGROUND: require('../assets/images/Home/games/lottery/keno-foreground.png'),
                    IBCBET_LOGO: require('../assets/images/Home/games/lottery/ibcbet-keno-logo.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/lottery/keno-logo-cn.png'),
                        US: require('../assets/images/Home/games/lottery/keno-logo-us.png'),
                    },
                },
                LOTTERY: {
                    FOREGROUND: {
                        CN: require('../assets/images/Home/games/lottery/lottery-foreground-cn.png'),
                        US: require('../assets/images/Home/games/lottery/lottery-foreground-us.png'),
                    },
                    IBCBET_LOGO: require('../assets/images/Home/games/lottery/ibcbet-lottery-logo.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/lottery/lottery-logo-cn.png'),
                        US: require('../assets/images/Home/games/lottery/lottery-logo-us.png'),
                    },
                },
                NUMBER_GAMES: {
                    FOREGROUND: require('../assets/images/Home/games/lottery/number-games-foreground.png'),
                    IBCBET_LOGO: require('../assets/images/Home/games/lottery/ibcbet-number-game-logo.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/lottery/number-games-logo-cn.png'),
                        US: require('../assets/images/Home/games/lottery/number-games-logo-us.png'),
                    },
                },
            },
            MINI_GAMES: {
                ADVANTPLAY: {
                    FOREGROUND: require('../assets/images/Home/games/mini-games/advant-play-foreground.png'),
                    LOGO: require('../assets/images/Home/games/mini-games/advant-play-logo.png'),
                },
                ADVANTPLAY_MINI_GAME: {
                    FOREGROUND: require('../assets/images/Home/games/mini-games/advant-play-mini-game-foreground.png'),
                    LOGO: require('../assets/images/Home/games/mini-games/advant-play-mini-game-logo.png'),
                },
                CRASH_GAME: {
                    FOREGROUND: require('../assets/images/Home/games/mini-games/crash-game-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/mini-games/to-the-moon-logo-cn.png'),
                        US: require('../assets/images/Home/games/mini-games/to-the-moon-logo-en.png'),
                    },
                },
            },
            POKER: {
                FOREGROUND: require('../assets/images/Home/games/poker/poker-foreground.png'),
                LOGO: require('../assets/images/Home/games/poker/poker-logo.png'),
            },
            SPORTS: {
                BTI: {
                    FOREGROUND: require('../assets/images/Home/games/sports/bti-foreground.png'),
                    LOGO: require('../assets/images/Home/games/sports/bti-logo-us.png'),
                },
                SABA: {
                    FOREGROUND: require('../assets/images/Home/games/sports/sports-foreground.png'),
                    IBCBET_LOGO: require('../assets/images/Home/games/sports/ibcbet-logo.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/sports/sports-logo-cn.png'),
                        US: require('../assets/images/Home/games/sports/sports-logo.png'),
                    },
                },
            },
            VIRTUAL_SPORTS: {
                BETRADAR: {
                    FOREGROUND: require('../assets/images/Home/games/virtual-sports/betradar-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/virtual-sports/betradar-logo-cn.png'),
                        US: require('../assets/images/Home/games/virtual-sports/betradar-logo-us.png'),
                    },
                },
                INSPIRED: {
                    FOREGROUND: require('../assets/images/Home/games/virtual-sports/inspired-foreground.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/virtual-sports/inspired-logo-cn.png'),
                        US: require('../assets/images/Home/games/virtual-sports/inspired-logo-us.png'),
                    },
                },
                LOGO: {
                    CN: require('../assets/images/Home/games/virtual-sports/saba-virtual-games-logo-cn.png'),
                    US: require('../assets/images/Home/games/virtual-sports/saba-virtual-games-logo-us.png'),
                },
                SABA_SOCCER: {
                    FOREGROUND: require('../assets/images/Home/games/virtual-sports/saba-soccer-foreground.png'),
                    IBCBET_LOGO: require('../assets/images/Home/games/virtual-sports/saba-soccer-ibcbet.png'),
                    LOGO: {
                        CN: require('../assets/images/Home/games/virtual-sports/saba-soccer-cn.png'),
                        US: require('../assets/images/Home/games/virtual-sports/saba-soccer-us.png'),
                    },
                },
            },
        },
        GGBOOK_TOURNAMENT: require('../assets/images/Home/ggbook_tournament.png'),
        HOME_SCREEN: require('../assets/images/Home/home-screen.png'),
        NAV: {
            CASINO: require('../assets/images/Home/navigation/casino.png'),
            CASINO_SELECTED: require('../assets/images/Home/navigation/casino-selected.png'),
            ESPORTS: require('../assets/images/Home/navigation/esports.png'),
            ESPORTS_SELECTED: require('../assets/images/Home/navigation/esports-selected.png'),
            HOT_GAMES: require('../assets/images/Home/navigation/hot-games.png'),
            HOT_GAMES_SELECTED: require('../assets/images/Home/navigation/hot-games-selected.png'),
            LIVE_CASINO: require('../assets/images/Home/navigation/live-casino.png'),
            LIVE_CASINO_SELECTED: require('../assets/images/Home/navigation/live-casino-selected.png'),
            LOTTERY: require('../assets/images/Home/navigation/lottery.png'),
            LOTTERY_SELECTED: require('../assets/images/Home/navigation/lottery-selected.png'),
            POKER: require('../assets/images/Home/navigation/poker.png'),
            POKER_SELECTED: require('../assets/images/Home/navigation/poker-selected.png'),
            SPORTS: require('../assets/images/Home/navigation/sports.png'),
            SPORTS_SELECTED: require('../assets/images/Home/navigation/sports-selected.png'),
            VIRTUAL_SPORTS: require('../assets/images/Home/navigation/virtual-sports.png'),
            VIRTUAL_SPORTS_SELECTED: require('../assets/images/Home/navigation/virtual-sports-selected.png'),
        },
        NEWS: require('../assets/images/Home/news.png'),
        REFER_A_FRIEND: require('../assets/images/Home/refer-a-friend.png'),
        STATISTICS: require('../assets/images/Home/statistics.png'),
    },
    HOT_EVENTS: require('../assets/images/Markets/hot-events-ico.png'),
    INFO_ICON: require('../assets/images/common/info-icon.png'),
    LOADER: require('../assets/images/common/loader.gif'),
    LOGOS: {
        ASTER: require('../assets/images/Layout/aster-logo.png'),
        CN: require('../assets/images/Layout/ggbook-cn.png'),
        GEEKFAM: require('../assets/images/Layout/geekfam-logo.png'),
        TRITON: require('../assets/images/Layout/triton-logo.png'),
        US: require('../assets/images/Layout/ggbook-en.png'),
    },
    LOGO_BIG: {
        CN: require('../assets/images/Login/logo-big-cn.png'),
        US: require('../assets/images/Login/logo-big-us.png'),
    },
    MODAL: {
        ACCOUNT_VERIFY_ALERT: require('../assets/images/common/modal-account-verify-alert.png'),
        ERROR: require('../assets/images/common/modal-error.png'),
        INFO: require('../assets/images/common/modal-info.png'),
        INSUFFICIENT_BALANCE: require('../assets/images/common/modal-insufficient-balance.png'),
        LOCKED: require('../assets/images/common/modal-locked.png'),
        SUCCESS: require('../assets/images/common/modal-success.png'),
        WARNING: require('../assets/images/common/modal-warning.png'),
    },
    NAV: {
        BET_SLIP: require('../assets/images/Layout/nav-ico-bet-slip.png'),
        BET_SLIP_SELECTED: require('../assets/images/Layout/nav-ico-bet-slip-hover.png'),
        CHRISTMAS: require('../assets/images/Layout/ico-christmas.png'),
        GGBOOK: require('../assets/images/Layout/ico-ggbook.png'),
        PROFILE: require('../assets/images/Layout/nav-ico-profile.png'),
        PROFILE_SELECTED: require('../assets/images/Layout/nav-ico-profile-hover.png'),
        PUMPKKIN: require('../assets/images/Layout/ico-pumkin.png'),
        REWARDS: require('../assets/images/Layout/nav-ico-rewards.png'),
        REWARDS_SELECTED: require('../assets/images/Layout/nav-ico-rewards-hover.png'),
        SOCIAL_HUB: require('../assets/images/Layout/nav-ico-socialhub.png'),
        SOCIAL_HUB_SELECTED: require('../assets/images/Layout/nav-ico-socialhub-hover.png'),
        WALLET: require('../assets/images/Layout/nav-ico-wallet.png'),
        WALLET_SELECTED: require('../assets/images/Layout/nav-ico-wallet-hover.png'),
    },
    NO_DATA: require('../assets/images/NoData@2x.png'),
    NO_RESULTS: require('../assets/images/common/no-results.png'),
    ONBOARDING: {
        ASTER: require('../assets/images/Layout/aster-onboarding.png'),
        GEEKFAM: require('../assets/images/Layout/geekfam-onboarding.png'),
        MYR_PROMO: {
            CN: require('../assets/images/Layout/myr-promo-cn.png'),
            US: require('../assets/images/Layout/myr-promo-us.png'),
        },
        ONBOARDING: require('../assets/images/Layout/onboarding-background.jpg'),
        TRITON: require('../assets/images/Layout/triton-onboarding.png'),
    },
    ONBOARDING_MESSAGE_MYR: {
        CN: require('../assets/images/Layout/onboard-message-cn.png'),
        US: require('../assets/images/Layout/onboard-message-en.png'),
    },
    OTHER_GAMES: {
        BIG_SMALL: {
            CN: require('../assets/images/games/other-games/big-small-cn.png'),
            US: require('../assets/images/games/other-games/big-small-en.png'),
        },
        GOLD_DELUXE: {
            CN: {
                '1X': require('../assets/images/games/other-games/gold-deluxe-cn.png'),
                '2X': require('../assets/images/games/other-games/gold-deluxe-cn@2x.png'),
            },
            US: {
                '1X': require('../assets/images/games/other-games/gold-deluxe-en.png'),
                '2X': require('../assets/images/games/other-games/gold-deluxe-en@2x.png'),
            },
        },
        KENO: {
            '1X': require('../assets/images/games/other-games/keno.png'),
            '2X': require('../assets/images/games/other-games/keno@2x.png'),
        },
        LOTTERY: {
            CN: require('../assets/images/games/other-games/lottery-cn.png'),
            US: require('../assets/images/games/other-games/lottery-en.png'),
        },
        NUMBER: {
            '1X': require('../assets/images/games/other-games/number-games.png'),
            '2X': require('../assets/images/games/other-games/number-games@2x.png'),
        },
    },
    PARTNERS: {
        LOGOS: {
            ALLBET: require('../assets/images/Partners/allbet-logo.png'),
            ASIA_GAMING: require('../assets/images/Partners/asia-gaming-logo.png'),
            BETRADAR: require('../assets/images/Partners/betradar-logo.png'),
            BTI: require('../assets/images/Partners/bti-logo.png'),
            INSPIRED: require('../assets/images/Partners/inspired-logo.png'),
            PLAYTECH: require('../assets/images/Partners/playtech-logo.png'),
            PRAGMATIC_PLAY: require('../assets/images/Partners/pragmatic-play-logo.png'),
            SABA: {
                CN: require('../assets/images/Partners/saba-logo.png'),
                US: require('../assets/images/Partners/ibc-bet-logo.png'),
            },
            SEXY_GAMING: require('../assets/images/Partners/sexy-gaming-logo.png'),
            SPADE_GAMING: require('../assets/images/Partners/spade-gaming-logo.png'),
        },
    },
    PAYMENT_CORRECTIONS: {
        BALANCE_CORRECTION: require('../assets/images/Wallet/balance-correction.png'),
        CRYPTO_CORRECTION: require('../assets/images/Wallet/crypto-correction.png'),
        DEPOSIT_CORRECTION: require('../assets/images/Wallet/deposit-correction.png'),
    },
    PAYMENT_ICON_GRAY: {
        ALIPAY: require('../assets/images/paymentIcon/gray-alipay.png'),
        BANK_INDO_BCA: require('../assets/images/paymentIcon/gray-bank-bca.png'),
        BANK_INDO_BNI: require('../assets/images/paymentIcon/gray-bank-bni.png'),
        BANK_INDO_BRI: require('../assets/images/paymentIcon/gray-bank-bri.png'),
        BANK_INDO_MANDIRI: require('../assets/images/paymentIcon/gray-bank-mandiri.png'),
        BANK_TRANSFER: require('../assets/images/paymentIcon/gray-bank-transfer.png'),
        BITCOIN: require('../assets/images/paymentIcon/gray-bitcoin.png'),
        DIREPAY: require('../assets/images/paymentIcon/gray-direpay.png'),
        EEZIE_PAY: require('../assets/images/paymentIcon/gray-eezie-pay.png'),
        ETH: require('../assets/images/paymentIcon/gray-eth.png'),
        HELP_2_PAY: require('../assets/images/paymentIcon/gray-help2pay.png'),
        JDPAY: require('../assets/images/paymentIcon/gray-jdpay.png'),
        PAYTRUST: require('../assets/images/paymentIcon/gray-paytrust.png'),
        QQPAY: require('../assets/images/paymentIcon/gray-qqpay.png'),
        THAI_QRPAY: require('../assets/images/paymentIcon/gray-thai-qr.png'),
        UNIONPAY: require('../assets/images/paymentIcon/gray-unionpay.png'),
        UNIONPAY_QR: require('../assets/images/paymentIcon/gray-unionpay-qrcode.png'),
        USDT: require('../assets/images/paymentIcon/gray-usdt.png'),
        WECHATPAY: require('../assets/images/paymentIcon/gray-wechat-pay.png'),
    },
    PAYMENT_METHODS: {
        ALI_PAY: require('../assets/images/Wallet/ali-pay.png'),
        BANKTRANSFER_PAY: require('../assets/images/Wallet/bank-transfer.png'),
        CRYPTOCURRENCY_PAY: require('../assets/images/Wallet/crypto.png'),
        DIREPAY_LOGO: require('../assets/images/Wallet/direpay.png'),
        WEALTHPAY_LOGO: require('../assets/images/Wallet/wealthpay.png'),
        EEZIE_PAY: require('../assets/images/Wallet/eezie-pay.png'),
        E_WALLET: require('../assets/images/Wallet/e-wallet.png'),
        GO_PAY: require('../assets/images/Ewallet/go-pay.png'),
        HELP2PAY: require('../assets/images/Wallet/help2pay.png'),
        JD_PAY: require('../assets/images/Wallet/jd-pay.png'),
        MANGOPAY: require('../assets/images/Wallet/mango-pay.png'),
        MANGOPAY_QR: require('../assets/images/Wallet/mango-pay-qr.jpg'),
        ONLINE_BANKING: require('../assets/images/Wallet/local-online-banking.png'),
        OVO: require('../assets/images/Ewallet/ovo.png'),
        PAY_TRUST_88: require('../assets/images/Wallet/pay-trust-88.png'),
        QQ_PAY: require('../assets/images/Wallet/qq-pay.png'),
        UNION_PAY: require('../assets/images/Wallet/union-pay.png'),
        WECHAT_PAY: require('../assets/images/Wallet/wechat-pay.png'),
    },
    PG: {
        BALI_VACATION: require('../assets/images/PG/BaliVacation.png'),
        BIKINI_PARADISE: require('../assets/images/PG/BikiniParadise.png'),
        CAI_SHEN_WINS: require('../assets/images/PG/CaiShenWins.png'),
        CANDY_BONANZA: require('../assets/images/PG/CandyBonanza.png'),
        CANDY_BURST: require('../assets/images/PG/CandyBurst.png'),
        CAPTAINS_BOUNTY: require('../assets/images/PG/CaptainsBounty.png'),
        CIRCUS_DELIGHT: require('../assets/images/PG/CircusDelight.png'),
        CRYPTO_GOLD: require('../assets/images/PG/CryptoGold.png'),
        DIM_SUM_MANIA: require('../assets/images/PG/DimSumMania.png'),
        DOUBLE_FORTUNE: require('../assets/images/PG/DoubleFortune.png'),
        DRAGON_HATCH: require('../assets/images/PG/DragonHatch.png'),
        DRAGON_LEGEND: require('../assets/images/PG/DragonLegend.png'),
        DRAGON_TIGER_LUCK: require('../assets/images/PG/DragonTigerLuck.png'),
        DREAMS_OF_MACAU: require('../assets/images/PG/DreamOfMacau.png'),
        EGYPT_BOOK_MYSTERY: require('../assets/images/PG/EgyptBookMystery.png'),
        EMPERORS_FAVOUR: require('../assets/images/PG/EmperorsFavour.png'),
        FLIRTING_SCHOLAR: require('../assets/images/PG/FlirtingScholar.png'),
        FORTUNE_GODS: require('../assets/images/PG/FortuneGods.png'),
        FORTUNE_MOUSE: require('../assets/images/PG/FortuneMouse.png'),
        FORTUNE_OX: require('../assets/images/PG/FortuneOx.png'),
        GALACTIC_GEMS: require('../assets/images/PG/GalacticGems.png'),
        GANESHA_FORTUNE: require('../assets/images/PG/GaneshaFortune.png'),
        GANESHA_GOLD: require('../assets/images/PG/GaneshaGold.png'),
        GEM_SAVIOUR: require('../assets/images/PG/GemSaviour.png'),
        GEM_SAVIOUR_CONQUEST: require('../assets/images/PG/GemSaviourConquest.png'),
        GEM_SAVIOUR_SWORD: require('../assets/images/PG/GemSaviourSword.png'),
        GENIES_3_WISHES: require('../assets/images/PG/Genies3Wishes.png'),
        GUARDIANS_OF_ICE_AND_FIRE: require('../assets/images/PG/GuardiansOfIceAndFire.png'),
        HEIST_STAKES: require('../assets/images/PG/HeistofStakes.png'),
        HIP_HOP_PANDA: require('../assets/images/PG/HipHopPanda.png'),
        HONEY_TRAP_OF_DIAO_CHAN: require('../assets/images/PG/HoneyTrapOfDiaoChan.png'),
        HOOD_VS_WOLF: require('../assets/images/PG/HoodVsWolf.png'),
        HOTPOT: require('../assets/images/PG/Hotpot.png'),
        JACK_FROSTS_WINTER: require('../assets/images/PG/JackFrostsWinter.png'),
        JEWELS_OF_PROSPERITY: require('../assets/images/PG/JewelsOfProsperity.png'),
        JOURNEY_TO_THE_WEALTH: require('../assets/images/PG/JourneyToTheWealth.png'),
        JUNGLE_DELIGHT: require('../assets/images/PG/JungleDelight.png'),
        LEGEND_OF_HOU_YI: require('../assets/images/PG/LegendOfHouYi.png'),
        LEPRECHAUN_RICHES: require('../assets/images/PG/LeprechaunRiches.png'),
        LUCKY_NEKO: require('../assets/images/PG/LuckyNeko.png'),
        MAHJONG_WAYS: require('../assets/images/PG/MahjongWays.png'),
        MAHJONG_WAYS_2: require('../assets/images/PG/MahjongWays2.png'),
        MAJESTIC_TREASURES: require('../assets/images/PG/MajesticTreasures.png'),
        MEDUSA: require('../assets/images/PG/Medusa.png'),
        MEDUSA_II: require('../assets/images/PG/MedusaII.png'),
        MR_HALLOW_WIN: require('../assets/images/PG/MrHallowWin.png'),
        MUAY_THAI_CHAMPION: require('../assets/images/PG/MuayThaiChampion.png'),
        NINJA_VS_SAMURAI: require('../assets/images/PG/NinjaVSSamurai.png'),
        OPERA_DYNASTY: require('../assets/images/PG/OperaDynasty.png'),
        PHOENIX_RISES: require('../assets/images/PG/PhoenixRises.png'),
        PIGGY_GOLD: require('../assets/images/PG/PiggyGold.png'),
        PLUSHIE_FRENZY: require('../assets/images/PG/PlushieFrenzy.png'),
        PROSPERITY_LION: require('../assets/images/PG/ProsperityLion.png'),
        QUEEN_OF_BOUNTY: require('../assets/images/PG/QueenOfBounty.png'),
        REEL_LOVE: require('../assets/images/PG/ReelLove.png'),
        SANTAS_GIFT_RUSH: require('../assets/images/PG/SantasGiftRush.png'),
        SECRET_OF_CLEOPATRA: require('../assets/images/PG/SecretsofCleopatra.png'),
        SHAOLIN_SOCCER: require('../assets/images/PG/ShaolinSoccer.png'),
        STEAMPUNK_WHEEL_OF_DESTINY: require('../assets/images/PG/SteampunkWheelOfDestiny.png'),
        SUMMON_CONQUER: require('../assets/images/PG/SummonConquer.png'),
        SYMBOLS_OF_EGYPT: require('../assets/images/PG/SymbolsOfEgypt.png'),
        THAI_RIVER_WONDERS: require('../assets/images/PG/ThaiRiverWonders.png'),
        THE_GREAT_ICESCAPE: require('../assets/images/PG/TheGreatLcescape.png'),
        THREE_MONKEYS: require('../assets/images/PG/ThreeMonkeys.png'),
        TREASURES_OF_AZTEC: require('../assets/images/PG/TreasuresofAztec.png'),
        TREE_OF_FORTUNE: require('../assets/images/PG/TreeOfFortune.png'),
        VAMPIRES_CHARM: require('../assets/images/PG/VampiresCharm.png'),
        WAYS_OF_THE_QILIN: require('../assets/images/PG/WaysofQilin.png'),
        WILD_BANDITO: require('../assets/images/PG/WildBandito.png'),
        WILD_FIREWORKS: require('../assets/images/PG/WildFireworks.png'),
        WIN_WIN_WON: require('../assets/images/PG/WinWinWon.png'),
        WIZDOM_WONDERS: require('../assets/images/PG/WizdomWonders.png'),
    },
    PLAYTECH: {
        ACES_AND_FACES_MULTI_HAND: {
            '1X': require('../assets/images/Playtech/Aces and Faces Multi-Hand.png'),
            '2X': require('../assets/images/Playtech/Aces and Faces Multi-Hand@2x.png'),
        },
        ADVENTURES_BEYOND_WONDERLAND: {
            '1X': require('../assets/images/Playtech/Adventures Beyond Wonderland.png'),
            '2X': require('../assets/images/Playtech/Adventures Beyond Wonderland@2x.png'),
        },
        ADVENTURES_IN_WONDERLAND_DELUXE: {
            '1X': require('../assets/images/Playtech/Adventures in Wonderland Deluxe.png'),
            '2X': require('../assets/images/Playtech/Adventures in Wonderland Deluxe@2x.png'),
        },
        ADVENTURE_TRAIL: {
            '1X': require('../assets/images/Playtech/adventure-trail.png'),
            '2X': require('../assets/images/Playtech/adventure-trail@2x.png'),
        },
        AGE_OF_EGYPT: {
            '1X': require('../assets/images/Playtech/Age of Egypt.png'),
            '2X': require('../assets/images/Playtech/Age of Egypt@2x.png'),
        },
        AGE_OF_GODS_BONUS_ROULETTE: {
            '1X': require('../assets/images/Playtech/age-of-gods-bonus-roulette.png'),
            '2X': require('../assets/images/Playtech/age-of-gods-bonus-roulette@2x.png'),
        },
        AGE_OF_GODS_BOOK_OF_ORACLE: require('../assets/images/Playtech/Age-of-Gods-Book-of-Oracle.png'),
        AGE_OF_THE_GODS: {
            '1X': require('../assets/images/Playtech/Age of the Gods.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods@2x.png'),
        },
        AGE_OF_THE_GODS_EPIC_TROY: {
            '1X': require('../assets/images/Playtech/Age of the Gods Epic Troy.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods Epic Troy@2x.png'),
        },
        AGE_OF_THE_GODS_FURIOUS_FOUR: {
            '1X': require('../assets/images/Playtech/Age of the Gods_Furious Four.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods_Furious Four@2x.png'),
        },
        AGE_OF_THE_GODS_GLORIOUS_GRIFFIN: {
            '1X': require('../assets/images/Playtech/age-of-gods-glorious-griffin.png'),
            '2X': require('../assets/images/Playtech/age-of-gods-glorious-griffin@2x.png'),
        },
        AGE_OF_THE_GODS_GODDESS_OF_WISDOM: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Goddess of Wisdom.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Goddess of Wisdom@2x.png'),
        },
        AGE_OF_THE_GODS_GOD_OF_STORMS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-God of Storms.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-God of Storms@2x.png'),
        },
        AGE_OF_THE_GODS_KING_OF_OLYMPUS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-King of Olympus.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-King of Olympus@2x.png'),
        },
        AGE_OF_THE_GODS_MEDUSA_MONSTERS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Medusa & Monsters.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Medusa & Monsters@2x.png'),
        },
        AGE_OF_THE_GODS_MIGHTY_MIDAS: {
            '1X': require('../assets/images/Playtech/Age Of The Gods-Mighty Midas.png'),
            '2X': require('../assets/images/Playtech/Age Of The Gods-Mighty Midas@2x.png'),
        },
        AGE_OF_THE_GODS_NORSE_BOOK_OF_DWARVES: {
            '1X': require('../assets/images/Playtech/book-of-dwarves.png'),
            '2X': require('../assets/images/Playtech/book-of-dwarves@2x.png'),
        },
        AGE_OF_THE_GODS_NORSE_GODS_AND_GIANTS: {
            '1X': require('../assets/images/Playtech/Age of the Gods Norse Gods and Giants.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods Norse Gods and Giants@2x.png'),
        },
        AGE_OF_THE_GODS_NORSE_LEGENDS: require('../assets/images/Playtech/AgeoftheGodsNorseNorseLegends.png'),
        AGE_OF_THE_GODS_NORSE_WAYS_OF_THUNDER: {
            '1X': require('../assets/images/Playtech/Age of the Gods Norse Ways of Thunder.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods Norse Ways of Thunder@2x.png'),
        },
        AGE_OF_THE_GODS_PRINCE_OF_OLYMPUS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Prince of Olympus.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Prince of Olympus@2x.png'),
        },
        AGE_OF_THE_GODS_ROULETTE: {
            '1X': require('../assets/images/Playtech/Age of the Gods Roulette.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods Roulette@2x.png'),
        },
        AGE_OF_THE_GODS_RULERS_OF_OLYMPUS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Rulers of Olympus.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Rulers of Olympus@2x.png'),
        },
        AGE_OF_THE_GODS_RULER_OF_THE_SEAS: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Ruler of the Seas.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Ruler of the Seas@2x.png'),
        },
        AGE_OF_THE_GODS_RULER_OF_THE_SKY: {
            '1X': require('../assets/images/Playtech/Age of the Gods-Ruler of the Sky.png'),
            '2X': require('../assets/images/Playtech/Age of the Gods-Ruler of the Sky@2x.png'),
        },
        AGE_OF_THE_GOD_FATE_SISTERS: {
            '1X': require('../assets/images/Playtech/Age of the_God_Fate_sisters.png'),
            '2X': require('../assets/images/Playtech/Age of the_God_Fate_sisters@2x.png'),
        },
        ALL_BETS_BLACKJACK: {
            '1X': require('../assets/images/Playtech/All Bets Blackjack.png'),
            '2X': require('../assets/images/Playtech/All Bets Blackjack@2x.png'),
        },
        AMAZING_FACTORY: {
            '1X': require('../assets/images/Playtech/fire-blaze-golden.png'),
            '2X': require('../assets/images/Playtech/fire-blaze-golden@2x.png'),
        },
        AMAZON_WILD: {
            '1X': require('../assets/images/Playtech/Amazon Wild.png'),
            '2X': require('../assets/images/Playtech/Amazon Wild@2x.png'),
        },
        ANACONDA_WILD: {
            '1X': require('../assets/images/Playtech/Anaconda Wild.png'),
            '2X': require('../assets/images/Playtech/Anaconda Wild@2x.png'),
        },
        ANACONDA_WILD_II: {
            '1X': require('../assets/images/Playtech/anaconda-wild.png'),
            '2X': require('../assets/images/Playtech/anaconda-wild@2x.png'),
        },
        APOLLO_POWER: {
            '1X': require('../assets/images/Playtech/Apollo Power.png'),
            '2X': require('../assets/images/Playtech/Apollo Power@2x.png'),
        },
        ARCHER: {
            '1X': require('../assets/images/Playtech/Archer.png'),
            '2X': require('../assets/images/Playtech/Archer@2x.png'),
        },
        ARCTICTREASURE: {
            '1X': require('../assets/images/Playtech/ArcticTreasure.png'),
            '2X': require('../assets/images/Playtech/ArcticTreasure@2x.png'),
        },
        AROWANAS_LUCK: {
            '1X': require('../assets/images/Playtech/arowanas.png'),
            '2X': require('../assets/images/Playtech/arowanas@2x.png'),
        },
        ASIAN_FANTASY: {
            '1X': require('../assets/images/Playtech/Asian-Fantasy.png'),
            '2X': require('../assets/images/Playtech/Asian-Fantasy@2x.png'),
        },
        ASTROBABES: {
            '1X': require('../assets/images/Playtech/AstroBabes.png'),
            '2X': require('../assets/images/Playtech/AstroBabes@2x.png'),
        },
        ATLANTISQUEEN: {
            '1X': require('../assets/images/Playtech/AtlantisQueen.png'),
            '2X': require('../assets/images/Playtech/AtlantisQueen@2x.png'),
        },
        A_NIGHT_OUT: {
            '1X': require('../assets/images/Playtech/A Night Out.png'),
            '2X': require('../assets/images/Playtech/A Night Out@2x.png'),
        },
        BAAMBOOM: {
            '1X': require('../assets/images/Playtech/BaamBoom.png'),
            '2X': require('../assets/images/Playtech/BaamBoom@2x.png'),
        },
        BACCARAT: {
            '1X': require('../assets/images/Playtech/Baccarat.png'),
            '2X': require('../assets/images/Playtech/Baccarat@2x.png'),
        },
        BAISHI: {
            '1X': require('../assets/images/Playtech/BaiShi.png'),
            '2X': require('../assets/images/Playtech/BaiShi@2x.png'),
        },
        BATTLE_OF_THE_GODS: {
            '1X': require('../assets/images/Playtech/Battle of the Gods.png'),
            '2X': require('../assets/images/Playtech/Battle of the Gods@2x.png'),
        },
        BEACHLIFE: {
            '1X': require('../assets/images/Playtech/BeachLife.png'),
            '2X': require('../assets/images/Playtech/BeachLife@2x.png'),
        },
        BEE_FRENZY: {
            '1X': require('../assets/images/Playtech/bee-frenzy.png'),
            '2X': require('../assets/images/Playtech/bee-frenzy@2x.png'),
        },
        BERMUDATRIANGLE: {
            '1X': require('../assets/images/Playtech/BermudaTriangle.png'),
            '2X': require('../assets/images/Playtech/BermudaTriangle@2x.png'),
        },
        BERRYBERRYBONANZA: {
            '1X': require('../assets/images/Playtech/BerryBerryBonanza.png'),
            '2X': require('../assets/images/Playtech/BerryBerryBonanza@2x.png'),
        },
        BETTER_WILDS: {
            '1X': require('../assets/images/Playtech/Better Wilds.png'),
            '2X': require('../assets/images/Playtech/Better Wilds@2x.png'),
        },
        BIGSHOTS: {
            '1X': require('../assets/images/Playtech/BigShots.png'),
            '2X': require('../assets/images/Playtech/BigShots@2x.png'),
        },
        BIG_BEAR: {
            '1X': require('../assets/images/Playtech/Big Bear.png'),
            '2X': require('../assets/images/Playtech/Big Bear@2x.png'),
        },
        BLACKJACKSURRENDER: {
            '1X': require('../assets/images/Playtech/BlackjackSurrender.png'),
            '2X': require('../assets/images/Playtech/BlackjackSurrender@2x.png'),
        },
        BLACKJACKSWITCH: {
            '1X': require('../assets/images/Playtech/BlackjackSwitch.png'),
            '2X': require('../assets/images/Playtech/BlackjackSwitch@2x.png'),
        },
        BLAZING_BELLS_POWERPLAY_JACKPOT: {
            '1X': require('../assets/images/Playtech/blazing-bells-powerplay.png'),
            '2X': require('../assets/images/Playtech/blazing-bells-powerplay@2x.png'),
        },
        BLAZZING_BELLS: {
            '1X': require('../assets/images/Playtech/blazing-bells.png'),
            '2X': require('../assets/images/Playtech/blazing-bells@2x.png'),
        },
        BLUEWIZARD: {
            '1X': require('../assets/images/Playtech/BlueWizard.png'),
            '2X': require('../assets/images/Playtech/BlueWizard@2x.png'),
        },
        BOMBS: {
            '1X': require('../assets/images/Playtech/Bombs.png'),
            '2X': require('../assets/images/Playtech/Bombs@2x.png'),
        },
        BONUSBEARS: {
            '1X': require('../assets/images/Playtech/BonusBears.png'),
            '2X': require('../assets/images/Playtech/BonusBears@2x.png'),
        },
        BONUSTRAINBANDITS: {
            '1X': require('../assets/images/Playtech/BonusTrainBandits.png'),
            '2X': require('../assets/images/Playtech/BonusTrainBandits@2x.png'),
        },
        BOOKOFKINGS: {
            '1X': require('../assets/images/Playtech/BookOfKings.png'),
            '2X': require('../assets/images/Playtech/BookOfKings@2x.png'),
        },
        BOOK_OF_KINGS_POWERPLAY_JACKPOT: require('../assets/images/Playtech/Book-of-Kings-PowerPlay-Jackpot.png'),
        BOUNTYOFTHEBEANSTALK: {
            '1X': require('../assets/images/Playtech/BountyOfTheBeanstalk.png'),
            '2X': require('../assets/images/Playtech/BountyOfTheBeanstalk@2x.png'),
        },
        BOWLINGFRENZY: {
            '1X': require('../assets/images/Playtech/BowlingFrenzy.png'),
            '2X': require('../assets/images/Playtech/BowlingFrenzy@2x.png'),
        },
        BREAKOUT_BOB: require('../assets/images/Playtech/Breakout-Bob.png'),
        BUCCANEERBLAST: {
            '1X': require('../assets/images/Playtech/BuccaneerBlast.png'),
            '2X': require('../assets/images/Playtech/BuccaneerBlast@2x.png'),
        },
        BUCKLEUP: {
            '1X': require('../assets/images/Playtech/BuckleUp.png'),
            '2X': require('../assets/images/Playtech/BuckleUp@2x.png'),
        },
        BUFFALOBLITZ: {
            '1X': require('../assets/images/Playtech/BuffaloBlitz.png'),
            '2X': require('../assets/images/Playtech/BuffaloBlitz@2x.png'),
        },
        BUFFALO_BLITZ_2: {
            '1X': require('../assets/images/Playtech/buffalo-blitz-ii.png'),
            '2X': require('../assets/images/Playtech/buffalo-blitz-ii@2x.png'),
        },
        BUSTERBLACKJACK: {
            '1X': require('../assets/images/Playtech/BusterBlackjack.png'),
            '2X': require('../assets/images/Playtech/BusterBlackjack@2x.png'),
        },
        CAISHEN_WAYS: {
            '1X': require('../assets/images/Playtech/caishen-ways.png'),
            '2X': require('../assets/images/Playtech/caishen-ways@2x.png'),
        },
        CALL_OF_THE_VALKYRIES: {
            '1X': require('../assets/images/Playtech/Call-of-the-Valkyries.png'),
            '2X': require('../assets/images/Playtech/Call-of-the-Valkyries@2x.png'),
        },
        CAPTAIN_TREASURE: {
            '1X': require('../assets/images/Playtech/Captain-Treasure.png'),
            '2X': require('../assets/images/Playtech/Captain-Treasure@2x.png'),
        },
        CARIBBEAN_STUD_POKER: {
            '1X': require('../assets/images/Playtech/Caribbean-Stud-Poker.png'),
            '2X': require('../assets/images/Playtech/Caribbean-Stud-Poker@2x.png'),
        },
        CASCADING_CAVE: {
            '1X': require('../assets/images/Playtech/Cascading-Cave.png'),
            '2X': require('../assets/images/Playtech/Cascading-Cave@2x.png'),
        },
        CASHBACKBLACKJACK: {
            '1X': require('../assets/images/Playtech/CashbackBlackjack.png'),
            '2X': require('../assets/images/Playtech/CashbackBlackjack@2x.png'),
        },
        CASINOCHARMS: {
            '1X': require('../assets/images/Playtech/CasinoCharms.png'),
            '2X': require('../assets/images/Playtech/CasinoCharms@2x.png'),
        },
        CASINOHOLD_EM: {
            '1X': require("../assets/images/Playtech/CasinoHold'Em.png"),
            '2X': require("../assets/images/Playtech/CasinoHold'Em@2x.png"),
        },
        CATQUEEN: {
            '1X': require('../assets/images/Playtech/CatQueen.png'),
            '2X': require('../assets/images/Playtech/CatQueen@2x.png'),
        },
        CATWALK: {
            '1X': require('../assets/images/Playtech/Catwalk.png'),
            '2X': require('../assets/images/Playtech/Catwalk@2x.png'),
        },
        CAT_IN_VEGAS: {
            '1X': require('../assets/images/Playtech/Cat-in-Vegas.png'),
            '2X': require('../assets/images/Playtech/Cat-in-Vegas@2x.png'),
        },
        CHAOJI_888: {
            '1X': require('../assets/images/Playtech/Chaoji888.png'),
            '2X': require('../assets/images/Playtech/Chaoji888@2x.png'),
        },
        CHARMS_OF_THE_SEA: {
            '1X': require('../assets/images/Playtech/Charms of the Sea.png'),
            '2X': require('../assets/images/Playtech/Charms of the Sea@2x.png'),
        },
        CHERRY_LOVE: {
            '1X': require('../assets/images/Playtech/Cherry Love.png'),
            '2X': require('../assets/images/Playtech/Cherry Love@2x.png'),
        },
        CHESTS_OF_PLENTY: {
            '1X': require('../assets/images/Playtech/Chests of Plenty.png'),
            '2X': require('../assets/images/Playtech/Chests of Plenty@2x.png'),
        },
        CHINESE_KITCHEN: {
            '1X': require('../assets/images/Playtech/Chinese Kitchen.png'),
            '2X': require('../assets/images/Playtech/Chinese Kitchen@2x.png'),
        },
        CHRISTMAS_JACKPOT_BELLS: {
            '1X': require('../assets/images/Playtech/Christmas Jackpot Bells.png'),
            '2X': require('../assets/images/Playtech/Christmas Jackpot Bells@2x.png'),
        },
        CLASSIC_ROULETTE: {
            '1X': require('../assets/images/Playtech/Classic Roulette.png'),
            '2X': require('../assets/images/Playtech/Classic Roulette@2x.png'),
        },
        COIN_COIN_COIN_CNY: {
            '1X': require('../assets/images/Playtech/Coin-Coin-Coin-CNY.png'),
            '2X': require('../assets/images/Playtech/Coin-Coin-Coin-CNY@2x.png'),
        },
        COPS_N_BANDITS: {
            '1X': require("../assets/images/Playtech/Cops N' Bandits.png"),
            '2X': require("../assets/images/Playtech/Cops N' Bandits@2x.png"),
        },
        COSMIC_DISCO: {
            '1X': require('../assets/images/Playtech/Cosmic Disco.png'),
            '2X': require('../assets/images/Playtech/Cosmic Disco@2x.png'),
        },
        CRAZY_7: {
            '1X': require('../assets/images/Playtech/Crazy 7.png'),
            '2X': require('../assets/images/Playtech/Crazy 7@2x.png'),
        },
        DARING_DAVE_THE_EYE_OF_RA: {
            '1X': require('../assets/images/Playtech/Daring Dave & the Eye of Ra.png'),
            '2X': require('../assets/images/Playtech/Daring Dave & the Eye of Ra@2x.png'),
        },
        DA_VINCI_S_VAULT: {
            '1X': require("../assets/images/Playtech/Da Vinci's Vault.png"),
            '2X': require("../assets/images/Playtech/Da Vinci's Vault@2x.png"),
        },
        DESERT_TREASURE: {
            '1X': require('../assets/images/Playtech/Desert Treasure.png'),
            '2X': require('../assets/images/Playtech/Desert Treasure@2x.png'),
        },
        DEUCES_WILD_MULTI_HAND: {
            '1X': require('../assets/images/Playtech/Deuces Wild Multi-Hand.png'),
            '2X': require('../assets/images/Playtech/Deuces Wild Multi-Hand@2x.png'),
        },
        DIAMOND_BET_ROULETTE: {
            '1X': require('../assets/images/Playtech/Diamond Bet Roulette.png'),
            '2X': require('../assets/images/Playtech/Diamond Bet Roulette@2x.png'),
        },
        DOLPHIN_REEF: {
            '1X': require('../assets/images/Playtech/Dolphin Reef.png'),
            '2X': require('../assets/images/Playtech/Dolphin Reef@2x.png'),
        },
        DR: {
            '1X': require('../assets/images/Playtech/Dr. Lovemore.png'),
            '2X': require('../assets/images/Playtech/Dr. Lovemore@2x.png'),
        },
        DRAGON_BOND: {
            '1X': require('../assets/images/Playtech/Dragon Bond.png'),
            '2X': require('../assets/images/Playtech/Dragon Bond@2x.png'),
        },
        DRAGON_CHAMPIONS: {
            '1X': require('../assets/images/Playtech/Dragon Champions.png'),
            '2X': require('../assets/images/Playtech/Dragon Champions@2x.png'),
        },
        DRAGON_CHI: {
            '1X': require('../assets/images/Playtech/dragon-chi.png'),
            '2X': require('../assets/images/Playtech/dragon-chi@2x.png'),
        },
        DRAGON_JACKPOT_ROULETTE: {
            '1X': require('../assets/images/Playtech/Dragon Jackpot Roulette.png'),
            '2X': require('../assets/images/Playtech/Dragon Jackpot Roulette@2x.png'),
        },
        DRAGON_KINGDOM: {
            '1X': require('../assets/images/Playtech/Dragon Kingdom.png'),
            '2X': require('../assets/images/Playtech/Dragon Kingdom@2x.png'),
        },
        DRAGON_SPARK: {
            '1X': require('../assets/images/Playtech/Dragon Spark.png'),
            '2X': require('../assets/images/Playtech/Dragon Spark@2x.png'),
        },
        DRINKS_ON_THE_BEACH: {
            '1X': require('../assets/images/Playtech/Drinks on the Beach.png'),
            '2X': require('../assets/images/Playtech/Drinks on the Beach@2x.png'),
        },
        EASTER_SURPRISE: {
            '1X': require('../assets/images/Playtech/Easter Surprise.png'),
            '2X': require('../assets/images/Playtech/Easter Surprise@2x.png'),
        },
        EGYPTIAN_EMERALDS: {
            '1X': require('../assets/images/Playtech/Egyptian Emeralds.png'),
            '2X': require('../assets/images/Playtech/Egyptian Emeralds@2x.png'),
        },
        EGYPTIAN_EMERALDS_POWERPLAY_JACKPOT: require('../assets/images/Playtech/Egyptian_Emeralds_PowerPlay_Jackpot.png'),
        ELIMINATORS: {
            '1X': require('../assets/images/Playtech/Eliminators.png'),
            '2X': require('../assets/images/Playtech/Eliminators@2x.png'),
        },
        EPIC_APE: {
            '1X': require('../assets/images/Playtech/Epic Ape.png'),
            '2X': require('../assets/images/Playtech/Epic Ape@2x.png'),
        },
        ESMERALDA: {
            '1X': require('../assets/images/Playtech/Esmeralda.png'),
            '2X': require('../assets/images/Playtech/Esmeralda@2x.png'),
        },
        ETERNAL_LADY: {
            '1X': require('../assets/images/Playtech/Eternal Lady.png'),
            '2X': require('../assets/images/Playtech/Eternal Lady@2x.png'),
        },
        EVERYBODY_S_JACKPOT: {
            '1X': require("../assets/images/Playtech/Everybody's Jackpot.png"),
            '2X': require("../assets/images/Playtech/Everybody's Jackpot@2x.png"),
        },
        EXTREME_FRUITS_ULTIMATE: {
            '1X': require('../assets/images/Playtech/Extreme Fruits Ultimate.png'),
            '2X': require('../assets/images/Playtech/Extreme Fruits Ultimate@2x.png'),
        },
        FAIREST_OF_THEM_ALL: {
            '1X': require('../assets/images/Playtech/Fairest of Them All.png'),
            '2X': require('../assets/images/Playtech/Fairest of Them All@2x.png'),
        },
        FAIRGROUND_FORTUNES_GHOST_TRAIN: {
            '1X': require('../assets/images/Playtech/Fairground Fortunes Ghost Train.png'),
            '2X': require('../assets/images/Playtech/Fairground Fortunes Ghost Train@2x.png'),
        },
        FAT_CHOY_CHOY_SUN: {
            '1X': require('../assets/images/Playtech/Fat Choy Choy Sun.png'),
            '2X': require('../assets/images/Playtech/Fat Choy Choy Sun@2x.png'),
        },
        FEAR_THE_BEAR: {
            '1X': require('../assets/images/Playtech/Fear the Bear.png'),
            '2X': require('../assets/images/Playtech/Fear the Bear@2x.png'),
        },
        FEI_CUI_GONG_ZHU: {
            '1X': require('../assets/images/Playtech/Fei Cui Gong Zhu.png'),
            '2X': require('../assets/images/Playtech/Fei Cui Gong Zhu@2x.png'),
        },
        FEI_LONG_ZAI_TIAN: {
            '1X': require('../assets/images/Playtech/Fei Long Zai Tian.png'),
            '2X': require('../assets/images/Playtech/Fei Long Zai Tian@2x.png'),
        },
        FENH_KUANG_MA_JIANG: {
            '1X': require('../assets/images/Playtech/feng-kuang-ma-jiang.png'),
            '2X': require('../assets/images/Playtech/feng-kuang-ma-jiang@2x.png'),
        },
        FIELDS_OF_FORTUNE: {
            '1X': require('../assets/images/Playtech/Fields of Fortune.png'),
            '2X': require('../assets/images/Playtech/Fields of Fortune@2x.png'),
        },
        FIESTA_DE_LA_MEMORIA: {
            '1X': require('../assets/images/Playtech/Fiesta De La Memoria.png'),
            '2X': require('../assets/images/Playtech/Fiesta De La Memoria@2x.png'),
        },
        FIREBLAZE_GOLDEN_BUCANEER_BELLS: require('../assets/images/Playtech/FireBlazeGoldenBuccaneerBells.png'),
        FIREMEN: {
            '1X': require('../assets/images/Playtech/Firemen.png'),
            '2X': require('../assets/images/Playtech/Firemen@2x.png'),
        },
        FIRE_BLAZE_RED_WIZARD: require('../assets/images/Playtech/Fire_Blaze_Red_Wizard.png'),
        FIRE_BLAZE_RIVER_EMPRESS: require('../assets/images/Playtech/Fire-Blaze-River-Empress.png'),
        FIRE_BLAZE_SISTERS_GIFT: require('../assets/images/Playtech/Fire-Blaze-Sisters-Gift.png'),
        FIVE_AGES_OF_GOLD: {
            '1X': require('../assets/images/Playtech/5 Ages Of Gold.png'),
            '2X': require('../assets/images/Playtech/5 Ages Of Gold@2x.png'),
        },
        FIVE_TIGER_GENERALS: {
            '1X': require('../assets/images/Playtech/five-tigers-generals.png'),
            '2X': require('../assets/images/Playtech/five-tigers-generals@2x.png'),
        },
        FOOTBALL_CARNIVAL: {
            '1X': require('../assets/images/Playtech/Football Carnival.png'),
            '2X': require('../assets/images/Playtech/Football Carnival@2x.png'),
        },
        FOOTBALL_RULES: {
            '1X': require('../assets/images/Playtech/Football Rules.png'),
            '2X': require('../assets/images/Playtech/Football Rules@2x.png'),
        },
        FOOTBALL_SCRATCH_POWERPLAY_JACKPOT: require('../assets/images/Playtech/FootballScratchPowerPlayJackpot.png'),
        FOREST_OF_WONDERS: {
            '1X': require('../assets/images/Playtech/Forest of Wonders.png'),
            '2X': require('../assets/images/Playtech/Forest of Wonders@2x.png'),
        },
        FOREST_PRINCE: {
            '1X': require('../assets/images/Playtech/Forest Prince.png'),
            '2X': require('../assets/images/Playtech/Forest Prince@2x.png'),
        },
        FORTUNATE_FIVE: {
            '1X': require('../assets/images/Playtech/Fortunate Five.png'),
            '2X': require('../assets/images/Playtech/Fortunate Five@2x.png'),
        },
        FORTUNES_OF_THE_FOX: {
            '1X': require('../assets/images/Playtech/Fortunes of the Fox.png'),
            '2X': require('../assets/images/Playtech/Fortunes of the Fox@2x.png'),
        },
        FORTUNE_DAY: {
            '1X': require('../assets/images/Playtech/fortune-day.png'),
            '2X': require('../assets/images/Playtech/fortune-day@2x.png'),
        },
        FORTUNE_LIONS: {
            '1X': require('../assets/images/Playtech/fortune-lions.png'),
            '2X': require('../assets/images/Playtech/fortune-lions@2x.png'),
        },
        FOUNTAIN_OF_YOUTH: {
            '1X': require('../assets/images/Playtech/Fountain of Youth.png'),
            '2X': require('../assets/images/Playtech/Fountain of Youth@2x.png'),
        },
        FREE_CHIP_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Free Chip Blackjack.png'),
            '2X': require('../assets/images/Playtech/Free Chip Blackjack@2x.png'),
        },
        FROG_GIFT: {
            '1X': require('../assets/images/Playtech/frogs-gift.png'),
            '2X': require('../assets/images/Playtech/frogs-gift@2x.png'),
        },
        FROM_RUSSIA_WITH_LOVE: {
            '1X': require('../assets/images/Playtech/From Russia With Love.png'),
            '2X': require('../assets/images/Playtech/From Russia With Love@2x.png'),
        },
        FRUIT_MANIA: {
            '1X': require('../assets/images/Playtech/Fruit Mania.png'),
            '2X': require('../assets/images/Playtech/Fruit Mania@2x.png'),
        },
        FULL_MOON_FORTUNES: {
            '1X': require('../assets/images/Playtech/Full Moon Fortunes.png'),
            '2X': require('../assets/images/Playtech/Full Moon Fortunes@2x.png'),
        },
        FUNKY_FRUITS: {
            '1X': require('../assets/images/Playtech/Funky Fruits.png'),
            '2X': require('../assets/images/Playtech/Funky Fruits@2x.png'),
        },
        FUNKY_FRUITS_FARM: {
            '1X': require('../assets/images/Playtech/Funky Fruits Farm.png'),
            '2X': require('../assets/images/Playtech/Funky Fruits Farm@2x.png'),
        },
        FUNKY_MONKEY: {
            '1X': require('../assets/images/Playtech/Funky Monkey.png'),
            '2X': require('../assets/images/Playtech/Funky Monkey@2x.png'),
        },
        FUNKY_MONKEY_JACKPOT: {
            '1X': require('../assets/images/Playtech/Funky Monkey Jackpot.png'),
            '2X': require('../assets/images/Playtech/Funky Monkey Jackpot@2x.png'),
        },
        GAELIC_LUCK: {
            '1X': require('../assets/images/Playtech/Gaelic Luck.png'),
            '2X': require('../assets/images/Playtech/Gaelic Luck@2x.png'),
        },
        GALACTIC_STREAK: {
            '1X': require('../assets/images/Playtech/Galactic Streak.png'),
            '2X': require('../assets/images/Playtech/Galactic Streak@2x.png'),
        },
        GEISHA_STORY: {
            '1X': require('../assets/images/Playtech/Geisha Story.png'),
            '2X': require('../assets/images/Playtech/Geisha Story@2x.png'),
        },
        GEMBURST: {
            '1X': require('../assets/images/Playtech/GemBurst.png'),
            '2X': require('../assets/images/Playtech/GemBurst@2x.png'),
        },
        GEM_HEAT: {
            '1X': require('../assets/images/Playtech/Gem Heat.png'),
            '2X': require('../assets/images/Playtech/Gem Heat@2x.png'),
        },
        GEM_QUEEN: {
            '1X': require('../assets/images/Playtech/gem-queen.png'),
            '2X': require('../assets/images/Playtech/gem-queen@2x.png'),
        },
        GEM_SPLASH_RAINBOWS_GIFT: {
            '1X': require('../assets/images/Playtech/Gem Splash Rainbows Gift.png'),
            '2X': require('../assets/images/Playtech/Gem Splash Rainbows Gift@2x.png'),
        },
        GIANT_GRIZZLY: {
            '1X': require('../assets/images/Playtech/Giant Grizzly.png'),
            '2X': require('../assets/images/Playtech/Giant Grizzly@2x.png'),
        },
        GLORY_AND_BRITANNIA: {
            '1X': require('../assets/images/Playtech/Glory and Britannia.png'),
            '2X': require('../assets/images/Playtech/Glory and Britannia@2x.png'),
        },
        GOD_OF_STORMS_LIVE: {
            '1X': require('../assets/images/Playtech/god-of-storms-live.png'),
            '2X': require('../assets/images/Playtech/god-of-storms-live@2x.png'),
        },
        GOLDEN_GAMES: {
            '1X': require('../assets/images/Playtech/Golden Games.png'),
            '2X': require('../assets/images/Playtech/Golden Games@2x.png'),
        },
        GOLDEN_MACAQUE: {
            '1X': require('../assets/images/Playtech/Golden Macaque.png'),
            '2X': require('../assets/images/Playtech/Golden Macaque@2x.png'),
        },
        GOLDEN_TOUR: {
            '1X': require('../assets/images/Playtech/Golden Tour.png'),
            '2X': require('../assets/images/Playtech/Golden Tour@2x.png'),
        },
        GOLDEN_WAYS: {
            '1X': require('../assets/images/Playtech/golden-ways.png'),
            '2X': require('../assets/images/Playtech/golden-ways@2x.png'),
        },
        GOLD_PILE_ORANGUTAN: require('../assets/images/Playtech/Gold_Pile_Orangutan.png'),
        GOLD_PILE_TIGER_PRIDE: require('../assets/images/Playtech/Gold-Pile-Tigers-Pride.png'),
        GOLD_RALLY: {
            '1X': require('../assets/images/Playtech/Gold Rally.png'),
            '2X': require('../assets/images/Playtech/Gold Rally@2x.png'),
        },
        GREATEST_ODYSSEY: {
            '1X': require('../assets/images/Playtech/Greatest Odyssey.png'),
            '2X': require('../assets/images/Playtech/Greatest Odyssey@2x.png'),
        },
        GREAT_BLUE: {
            '1X': require('../assets/images/Playtech/Great Blue.png'),
            '2X': require('../assets/images/Playtech/Great Blue@2x.png'),
        },
        GREAT_BLUE_JACKPOT: {
            '1X': require('../assets/images/Playtech/Great Blue Jackpot.png'),
            '2X': require('../assets/images/Playtech/Great Blue Jackpot@2x.png'),
        },
        HAINAN_ICE: {
            '1X': require('../assets/images/Playtech/Hainan Ice.png'),
            '2X': require('../assets/images/Playtech/Hainan Ice@2x.png'),
        },
        HALLOWEEN_FORTUNE: {
            '1X': require('../assets/images/Playtech/Halloween Fortune.png'),
            '2X': require('../assets/images/Playtech/Halloween Fortune@2x.png'),
        },
        HALLOWEEN_FORTUNE_2: {
            '1X': require('../assets/images/Playtech/Halloween Fortune 2.png'),
            '2X': require('../assets/images/Playtech/Halloween Fortune 2@2x.png'),
        },
        HAUNTED_HOUSE: {
            '1X': require('../assets/images/Playtech/Haunted House.png'),
            '2X': require('../assets/images/Playtech/Haunted House@2x.png'),
        },
        HAWAIIAN_TREASURE: {
            '1X': require('../assets/images/Playtech/Hawaiian Treasure.png'),
            '2X': require('../assets/images/Playtech/Hawaiian Treasure@2x.png'),
        },
        HEADS_UP_HOLD_EM: {
            '1X': require("../assets/images/Playtech/Heads Up Hold'em.png"),
            '2X': require("../assets/images/Playtech/Heads Up Hold'em@2x.png"),
        },
        HEART_OF_THE_FRONTIER: {
            '1X': require('../assets/images/Playtech/Heart of the Frontier.png'),
            '2X': require('../assets/images/Playtech/Heart of the Frontier@2x.png'),
        },
        HEART_OF_THE_JUNGLE: {
            '1X': require('../assets/images/Playtech/Heart of the Jungle.png'),
            '2X': require('../assets/images/Playtech/Heart of the Jungle@2x.png'),
        },
        HEAVENLY_RULER: {
            '1X': require('../assets/images/Playtech/heavenly-ruler.png'),
            '2X': require('../assets/images/Playtech/heavenly-ruler@2x.png'),
        },
        HIGHWAY_KINGS: {
            '1X': require('../assets/images/Playtech/Highway Kings.png'),
            '2X': require('../assets/images/Playtech/Highway Kings@2x.png'),
        },
        HI_LO_PREMIUM: {
            '1X': require('../assets/images/Playtech/Hi-Lo Premium.png'),
            '2X': require('../assets/images/Playtech/Hi-Lo Premium@2x.png'),
        },
        HOLOGRAM_WILDS: {
            '1X': require('../assets/images/Playtech/Hologram Wilds.png'),
            '2X': require('../assets/images/Playtech/Hologram Wilds@2x.png'),
        },
        HOT_GEMS: {
            '1X': require('../assets/images/Playtech/Hot Gems.png'),
            '2X': require('../assets/images/Playtech/Hot Gems@2x.png'),
        },
        HOT_GEMS_XTREME_SCRATCH: require('../assets/images/Playtech/Hot_Gems_Xtreme_Scratch.png'),
        HOY_KTV: {
            '1X': require('../assets/images/Playtech/hot-ktv.png'),
            '2X': require('../assets/images/Playtech/hot-ktv@2x.png'),
        },
        HUNDRED_ONE_ROULETTE: {
            '1X': require('../assets/images/Playtech/101 Roulette.png'),
            '2X': require('../assets/images/Playtech/101 Roulette@2x.png'),
        },
        ICE_CAVE: {
            '1X': require('../assets/images/Playtech/Ice Cave.png'),
            '2X': require('../assets/images/Playtech/Ice Cave@2x.png'),
        },
        ICE_RUN: {
            '1X': require('../assets/images/Playtech/Ice Run.png'),
            '2X': require('../assets/images/Playtech/Ice Run@2x.png'),
        },
        INCA_JACKPOT: {
            '1X': require('../assets/images/Playtech/inca-jackpot.png'),
            '2X': require('../assets/images/Playtech/inca-jackpot@2x.png'),
        },
        INFINITY_DRAGON: {
            '1X': require('../assets/images/Playtech/infinity-dragon.png'),
            '2X': require('../assets/images/Playtech/infinity-dragon@2x.png'),
        },
        INNOCENCE_OR_TEMPTATION: {
            '1X': require('../assets/images/Playtech/Innocence or Temptation.png'),
            '2X': require('../assets/images/Playtech/Innocence or Temptation@2x.png'),
        },
        IRISH_LUCK: {
            '1X': require('../assets/images/Playtech/Irish Luck.png'),
            '2X': require('../assets/images/Playtech/Irish Luck@2x.png'),
        },
        JACKPOT_BELLS: {
            '1X': require('../assets/images/Playtech/Jackpot Bells.png'),
            '2X': require('../assets/images/Playtech/Jackpot Bells@2x.png'),
        },
        JACKPOT_GIANT: {
            '1X': require('../assets/images/Playtech/Jackpot Giant.png'),
            '2X': require('../assets/images/Playtech/Jackpot Giant@2x.png'),
        },
        JACKS_OR_BETTER: {
            '1X': require('../assets/images/Playtech/Jacks or Better.png'),
            '2X': require('../assets/images/Playtech/Jacks or Better@2x.png'),
        },
        JACKS_OR_BETTER_MULTI_HAND: {
            '1X': require('../assets/images/Playtech/Jacks or Better Multi-Hand.png'),
            '2X': require('../assets/images/Playtech/Jacks or Better Multi-Hand@2x.png'),
        },
        JADE_EMPEROR_YU_HUANG_DA_DI: {
            '1X': require('../assets/images/Playtech/Jade Emperor Yu Huang Da Di.png'),
            '2X': require('../assets/images/Playtech/Jade Emperor Yu Huang Da Di@2x.png'),
        },
        JANE_JONES_IN_BOOK_OF_KING2: require('../assets/images/Playtech/Jane-Jones-In-Book-Of-King2.png'),
        JEKYLL_AND_HYDE: {
            '1X': require('../assets/images/Playtech/Jekyll and Hyde.png'),
            '2X': require('../assets/images/Playtech/Jekyll and Hyde@2x.png'),
        },
        JINFU_LONG: {
            '1X': require('../assets/images/Playtech/Jinfu Long.png'),
            '2X': require('../assets/images/Playtech/Jinfu Long@2x.png'),
        },
        JINFU_XINGYUN: {
            '1X': require('../assets/images/Playtech/Jinfu Xingyun.png'),
            '2X': require('../assets/images/Playtech/Jinfu Xingyun@2x.png'),
        },
        JINNS_MOON: {
            '1X': require('../assets/images/Playtech/Jinns Moon.png'),
            '2X': require('../assets/images/Playtech/Jinns Moon@2x.png'),
        },
        JIN_QIAN_WA: {
            '1X': require('../assets/images/Playtech/Jin-Qian-Wa.png'),
            '2X': require('../assets/images/Playtech/Jin-Qian-Wa@2x.png'),
        },
        JI_XIANG: {
            '1X': require('../assets/images/Playtech/Ji Xiang 8.png'),
            '2X': require('../assets/images/Playtech/Ji Xiang 8@2x.png'),
        },
        JOKER_HOT_REELS: {
            '1X': require('../assets/images/Playtech/Joker Hot Reels.png'),
            '2X': require('../assets/images/Playtech/Joker Hot Reels@2x.png'),
        },
        JOKER_POKER: {
            '1X': require('../assets/images/Playtech/Joker Poker.png'),
            '2X': require('../assets/images/Playtech/Joker Poker@2x.png'),
        },
        JUEGO_DE_LA_OCA: {
            '1X': require('../assets/images/Playtech/Juego De La Oca.png'),
            '2X': require('../assets/images/Playtech/Juego De La Oca@2x.png'),
        },
        JUICY_BOOTY: {
            '1X': require('../assets/images/Playtech/Juicy Booty.png'),
            '2X': require('../assets/images/Playtech/Juicy Booty@2x.png'),
        },
        JUNGLE_GIANTS: {
            '1X': require('../assets/images/Playtech/Jungle Giants.png'),
            '2X': require('../assets/images/Playtech/Jungle Giants@2x.png'),
        },
        JUNGLE_TROUBLE: {
            '1X': require('../assets/images/Playtech/Jungle Trouble.png'),
            '2X': require('../assets/images/Playtech/Jungle Trouble@2x.png'),
        },
        JURASSIC_ISLAND: {
            '1X': require('../assets/images/Playtech/Jurassic Island.png'),
            '2X': require('../assets/images/Playtech/Jurassic Island@2x.png'),
        },
        KANPAI_BONZAI: {
            '1X': require('../assets/images/Playtech/Kanpai Bonzai.png'),
            '2X': require('../assets/images/Playtech/Kanpai Bonzai@2x.png'),
        },
        KING_OF_ASGARD: {
            '1X': require('../assets/images/Playtech/King of Asgard.png'),
            '2X': require('../assets/images/Playtech/King of Asgard@2x.png'),
        },
        LADY_LUCK: {
            '1X': require('../assets/images/Playtech/lady-luck.png'),
            '2X': require('../assets/images/Playtech/lady-luck@2x.png'),
        },
        LAND_OF_GOLD: {
            '1X': require('../assets/images/Playtech/Land of Gold.png'),
            '2X': require('../assets/images/Playtech/Land of Gold@2x.png'),
        },
        LEGACY_OF_THE_TIGER: {
            '1X': require('../assets/images/Playtech/legacy-of-the-tiger.png'),
            '2X': require('../assets/images/Playtech/legacy-of-the-tiger@2x.png'),
        },
        LEGACY_OF_THE_WILD: {
            '1X': require('../assets/images/Playtech/Legacy of the Wild.png'),
            '2X': require('../assets/images/Playtech/Legacy of the Wild@2x.png'),
        },
        LEGACY_OF_THE_WILDS: {
            '1X': require('../assets/images/Playtech/legacy-of-the-wild.png'),
            '2X': require('../assets/images/Playtech/legacy-of-the-wild@2x.png'),
        },
        LEGEND_OF_HYDRA: {
            '1X': require('../assets/images/Playtech/legend-of-hydra.png'),
            '2X': require('../assets/images/Playtech/legend-of-hydra@2x.png'),
        },
        LEGEND_OF_THE_JAGUAR: {
            '1X': require('../assets/images/Playtech/Legend of the Jaguar.png'),
            '2X': require('../assets/images/Playtech/Legend of the Jaguar@2x.png'),
        },
        LEPRECHAUN_S_LUCK: {
            '1X': require("../assets/images/Playtech/Leprechaun's Luck.png"),
            '2X': require("../assets/images/Playtech/Leprechaun's Luck@2x.png"),
        },
        LIE_YAN_ZUAN_SHI: {
            '1X': require('../assets/images/Playtech/Lie Yan Zuan Shi.png'),
            '2X': require('../assets/images/Playtech/Lie Yan Zuan Shi@2x.png'),
        },
        LIU_FU_SHOU: {
            '1X': require('../assets/images/Playtech/liu-fu-shou.png'),
            '2X': require('../assets/images/Playtech/liu-fu-shou@2x.png'),
        },
        LIVE_3_CARD_BRAG: {
            '1X': require('../assets/images/Playtech/Live 3 Card Brag.png'),
            '2X': require('../assets/images/Playtech/Live 3 Card Brag@2x.png'),
        },
        LIVE_7_SEAT_BACCARAT: {
            '1X': require('../assets/images/Playtech/Live 7-Seat Baccarat.png'),
            '2X': require('../assets/images/Playtech/Live 7-Seat Baccarat@2x.png'),
        },
        LIVE_7_SEAT_NO_COMMISSION_BACCARAT: {
            '1X': require('../assets/images/Playtech/Live 7-Seat No Commission Baccarat.png'),
            '2X': require('../assets/images/Playtech/Live 7-Seat No Commission Baccarat@2x.png'),
        },
        LIVE_AGE_OF_THE_GODS_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live Age of The Gods Roulette.png'),
            '2X': require('../assets/images/Playtech/Live Age of The Gods Roulette@2x.png'),
        },
        LIVE_ALL_BETS_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Live All Bets Blackjack.png'),
            '2X': require('../assets/images/Playtech/Live All Bets Blackjack@2x.png'),
        },
        LIVE_AMERICAN_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live American Roulette.png'),
            '2X': require('../assets/images/Playtech/Live American Roulette@2x.png'),
        },
        LIVE_BACCARAT: {
            '1X': require('../assets/images/Playtech/Live Baccarat.png'),
            '2X': require('../assets/images/Playtech/Live Baccarat@2x.png'),
        },
        LIVE_BACCARAT_DRAGON_JACKPOT: {
            '1X': require('../assets/images/Playtech/Live Baccarat Dragon Jackpot.png'),
            '2X': require('../assets/images/Playtech/Live Baccarat Dragon Jackpot@2x.png'),
        },
        LIVE_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Live Blackjack.png'),
            '2X': require('../assets/images/Playtech/Live Blackjack@2x.png'),
        },
        LIVE_CASINO_HOLD_EM: {
            '1X': require("../assets/images/Playtech/Live Casino Hold'em.png"),
            '2X': require("../assets/images/Playtech/Live Casino Hold'em@2x.png"),
        },
        LIVE_CASINO_STUD_POKER: {
            '1X': require('../assets/images/Playtech/Live Casino Stud Poker.png'),
            '2X': require('../assets/images/Playtech/Live Casino Stud Poker@2x.png'),
        },
        LIVE_DRAGON_TIGER: {
            '1X': require('../assets/images/Playtech/Live Dragon Tiger.png'),
            '2X': require('../assets/images/Playtech/Live Dragon Tiger@2x.png'),
        },
        LIVE_FRENCH_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live French Roulette.png'),
            '2X': require('../assets/images/Playtech/Live French Roulette@2x.png'),
        },
        LIVE_HEADS_UP_HOLD_EM: {
            '1X': require("../assets/images/Playtech/Live Heads-Up Hold'em.png"),
            '2X': require("../assets/images/Playtech/Live Heads-Up Hold'em@2x.png"),
        },
        LIVE_HI_LO: {
            '1X': require('../assets/images/Playtech/Live Hi-Lo.png'),
            '2X': require('../assets/images/Playtech/Live Hi-Lo@2x.png'),
        },
        LIVE_NO_COMMISSION_BACCARAT: {
            '1X': require('../assets/images/Playtech/Live No Commission Baccarat.png'),
            '2X': require('../assets/images/Playtech/Live No Commission Baccarat@2x.png'),
        },
        LIVE_NO_COMMISSION_MINI_BACCARAT_DRAGON_JACKPOT: {
            '1X': require('../assets/images/Playtech/Live No Commission Mini Baccarat Dragon Jackpot.png'),
            '2X': require('../assets/images/Playtech/Live No Commission Mini Baccarat Dragon Jackpot@2x.png'),
        },
        LIVE_QUANTUM_7_SEAT_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Live Quantum 7 seat Blackjack.png'),
            '2X': require('../assets/images/Playtech/Live Quantum 7 seat Blackjack@2x.png'),
        },
        LIVE_QUANTUM_ALL_BETS_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Live Quantum All Bets Blackjack.png'),
            '2X': require('../assets/images/Playtech/Live Quantum All Bets Blackjack@2x.png'),
        },
        LIVE_QUANTUM_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live Quantum Roulette.png'),
            '2X': require('../assets/images/Playtech/Live Quantum Roulette@2x.png'),
        },
        LIVE_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live Roulette.png'),
            '2X': require('../assets/images/Playtech/Live Roulette@2x.png'),
        },
        LIVE_SLOTS_BUFFALO_BLITZ: {
            '1X': require('../assets/images/Playtech/Live Slots Buffalo Blitz.png'),
            '2X': require('../assets/images/Playtech/Live Slots Buffalo Blitz@2x.png'),
        },
        LIVE_SPIN_A_WIN: {
            '1X': require('../assets/images/Playtech/Live Spin a Win.png'),
            '2X': require('../assets/images/Playtech/Live Spin a Win@2x.png'),
        },
        LIVE_SPREAD_BET_ROULETTE: {
            '1X': require('../assets/images/Playtech/Live Spread Bet Roulette.png'),
            '2X': require('../assets/images/Playtech/Live Spread Bet Roulette@2x.png'),
        },
        LIVE_UNLIMITED_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Live Unlimited Blackjack.png'),
            '2X': require('../assets/images/Playtech/Live Unlimited Blackjack@2x.png'),
        },
        LOGO: require('../assets/images/Playtech/logo.png'),
        LONG_JIA_XIANG_YUN: {
            '1X': require('../assets/images/Playtech/Long Jia Xiang Yun.png'),
            '2X': require('../assets/images/Playtech/Long Jia Xiang Yun@2x.png'),
        },
        LONG_LONG_LONG: {
            '1X': require('../assets/images/Playtech/long-long-long.png'),
            '2X': require('../assets/images/Playtech/long-long-long@2x.png'),
        },
        LOTTO_MADNESS: {
            '1X': require('../assets/images/Playtech/Lotto Madness.png'),
            '2X': require('../assets/images/Playtech/Lotto Madness@2x.png'),
        },
        LOTUS_HEART: {
            '1X': require('../assets/images/Playtech/Lotus Heart.png'),
            '2X': require('../assets/images/Playtech/Lotus Heart@2x.png'),
        },
        LOVEFOOL: require('../assets/images/Playtech/lovefool.png'),
        LUCKY_EMERALDS: {
            '1X': require('../assets/images/Playtech/Lucky Emeralds.png'),
            '2X': require('../assets/images/Playtech/Lucky Emeralds@2x.png'),
        },
        LUCKY_LUCKY_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Lucky Lucky Blackjack.png'),
            '2X': require('../assets/images/Playtech/Lucky Lucky Blackjack@2x.png'),
        },
        LUCKY_U: {
            '1X': require('../assets/images/Playtech/Lucky U.png'),
            '2X': require('../assets/images/Playtech/Lucky U@2x.png'),
        },
        LUMINOUS_LIFE: {
            '1X': require('../assets/images/Playtech/Luminous Life.png'),
            '2X': require('../assets/images/Playtech/Luminous Life@2x.png'),
        },
        MAGICAL_STACKS: {
            '1X': require('../assets/images/Playtech/Magical Stacks.png'),
            '2X': require('../assets/images/Playtech/Magical Stacks@2x.png'),
        },
        MAJI_WILDS: {
            '1X': require('../assets/images/Playtech/Maji Wilds.png'),
            '2X': require('../assets/images/Playtech/Maji Wilds@2x.png'),
        },
        MAJORITY_RULES_SPEED_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Majority Rules Speed Blackjack.png'),
            '2X': require('../assets/images/Playtech/Majority Rules Speed Blackjack@2x.png'),
        },
        MANSIONS_28: {
            '1X': require('../assets/images/Playtech/28-mansions.png'),
            '2X': require('../assets/images/Playtech/28-mansions@2x.png'),
        },
        MEGA_FIRE_BLAZE_ROULETTE: {
            '1X': require('../assets/images/Playtech/mega-roulette.png'),
            '2X': require('../assets/images/Playtech/mega-roulette@2x.png'),
        },
        MIDNIGHT_WILDS: {
            '1X': require('../assets/images/Playtech/Midnight Wilds.png'),
            '2X': require('../assets/images/Playtech/Midnight Wilds@2x.png'),
        },
        MINI_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Mini Blackjack.png'),
            '2X': require('../assets/images/Playtech/Mini Blackjack@2x.png'),
        },
        MINI_ROULETTE: {
            '1X': require('../assets/images/Playtech/Mini Roulette.png'),
            '2X': require('../assets/images/Playtech/Mini Roulette@2x.png'),
        },
        MINTED_MONEY: {
            '1X': require('../assets/images/Playtech/Minted Money.png'),
            '2X': require('../assets/images/Playtech/Minted Money@2x.png'),
        },
        MISS_FORTUNE: {
            '1X': require('../assets/images/Playtech/Miss Fortune.png'),
            '2X': require('../assets/images/Playtech/Miss Fortune@2x.png'),
        },
        MOBILE_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Mobile Blackjack.png'),
            '2X': require('../assets/images/Playtech/Mobile Blackjack@2x.png'),
        },
        MOBILE_ROULETTE: {
            '1X': require('../assets/images/Playtech/Mobile Roulette.png'),
            '2X': require('../assets/images/Playtech/Mobile Roulette@2x.png'),
        },
        MONKEY_MANIA: {
            '1X': require('../assets/images/Playtech/Monkey Mania.png'),
            '2X': require('../assets/images/Playtech/Monkey Mania@2x.png'),
        },
        MR: {
            '1X': require('../assets/images/Playtech/Mr. Cashback.png'),
            '2X': require('../assets/images/Playtech/Mr. Cashback@2x.png'),
        },
        MURDER_MYSTERY: {
            '1X': require('../assets/images/Playtech/Murder Mystery.png'),
            '2X': require('../assets/images/Playtech/Murder Mystery@2x.png'),
        },
        MY_FAIR_PEARL: {
            '1X': require('../assets/images/Playtech/My Fair Pearl.png'),
            '2X': require('../assets/images/Playtech/My Fair Pearl@2x.png'),
        },
        NEON_LIFE: {
            '1X': require('../assets/images/Playtech/Neon Life.png'),
            '2X': require('../assets/images/Playtech/Neon Life@2x.png'),
        },
        NEPTUNE_S_KINGDOM: {
            '1X': require("../assets/images/Playtech/Neptune's Kingdom.png"),
            '2X': require("../assets/images/Playtech/Neptune's Kingdom@2x.png"),
        },
        NEW_YEARS_GOLD: {
            '1X': require('../assets/images/Playtech/new-years-gold.png'),
            '2X': require('../assets/images/Playtech/new-years-gold@2x.png'),
        },
        NEW_YEAR_S_BONANZA: {
            '1X': require("../assets/images/Playtech/New Year's Bonanza.png"),
            '2X': require("../assets/images/Playtech/New Year's Bonanza@2x.png"),
        },
        NIAN_NIAN_YOU_YU: {
            '1X': require('../assets/images/Playtech/Nian Nian You Yu.png'),
            '2X': require('../assets/images/Playtech/Nian Nian You Yu@2x.png'),
        },
        NIAN_NIAN_YOU_YU_ASIA: {
            '1X': require('../assets/images/Playtech/Nian Nian You Yu Asia.png'),
            '2X': require('../assets/images/Playtech/Nian Nian You Yu Asia@2x.png'),
        },
        NI_SHU_SHEN_ME: {
            '1X': require('../assets/images/Playtech/Ni Shu Shen Me.png'),
            '2X': require('../assets/images/Playtech/Ni Shu Shen Me@2x.png'),
        },
        NOSTRADAMUS: {
            '1X': require('../assets/images/Playtech/Nostradamus.png'),
            '2X': require('../assets/images/Playtech/Nostradamus@2x.png'),
        },
        OCEANUS_RISING: {
            '1X': require('../assets/images/Playtech/Oceanus Rising.png'),
            '2X': require('../assets/images/Playtech/Oceanus Rising@2x.png'),
        },
        OCEAN_PRINCESS: {
            '1X': require('../assets/images/Playtech/Ocean Princess.png'),
            '2X': require('../assets/images/Playtech/Ocean Princess@2x.png'),
        },
        OX_RICHES: {
            '1X': require('../assets/images/Playtech/ox-riches.png'),
            '2X': require('../assets/images/Playtech/ox-riches@2x.png'),
        },
        PANDA_LUCK: {
            '1X': require('../assets/images/Playtech/panda-luck.png'),
            '2X': require('../assets/images/Playtech/panda-luck@2x.png'),
        },
        PANTHER_MOON: {
            '1X': require('../assets/images/Playtech/Panther Moon.png'),
            '2X': require('../assets/images/Playtech/Panther Moon@2x.png'),
        },
        PANTHER_PAYS: {
            '1X': require('../assets/images/Playtech/Panther Pays.png'),
            '2X': require('../assets/images/Playtech/Panther Pays@2x.png'),
        },
        PANTRY_PLUNDER: {
            '1X': require('../assets/images/Playtech/Pantry Plunder.png'),
            '2X': require('../assets/images/Playtech/Pantry Plunder@2x.png'),
        },
        PEARLSPEARLSPEARLS: require('../assets/images/Playtech/pearls-pearls-pearls.png'),
        PENGUIN_VACATION: {
            '1X': require('../assets/images/Playtech/Penguin Vacation.png'),
            '2X': require('../assets/images/Playtech/Penguin Vacation@2x.png'),
        },
        PENNY_ROULETTE: {
            '1X': require('../assets/images/Playtech/Penny Roulette.png'),
            '2X': require('../assets/images/Playtech/Penny Roulette@2x.png'),
        },
        PERFECT_BLACKJACK_MULTIHAND_5: {
            '1X': require('../assets/images/Playtech/Perfect Blackjack Multihand 5.png'),
            '2X': require('../assets/images/Playtech/Perfect Blackjack Multihand 5@2x.png'),
        },
        PHARAOH_S_DAUGHTER: {
            '1X': require("../assets/images/Playtech/Pharaoh's Daughter.png"),
            '2X': require("../assets/images/Playtech/Pharaoh's Daughter@2x.png"),
        },
        PHARAOH_S_SECRETS: {
            '1X': require("../assets/images/Playtech/Pharaoh's Secrets.png"),
            '2X': require("../assets/images/Playtech/Pharaoh's Secrets@2x.png"),
        },
        PHARAOH_S_TREASURE_DELUXE: {
            '1X': require("../assets/images/Playtech/Pharaoh's Treasure Deluxe.png"),
            '2X': require("../assets/images/Playtech/Pharaoh's Treasure Deluxe@2x.png"),
        },
        PIGGIES_AND_THE_WOLF: {
            '1X': require('../assets/images/Playtech/Piggies and the Wolf.png'),
            '2X': require('../assets/images/Playtech/Piggies and the Wolf@2x.png'),
        },
        PINBALL_ROULETTE: {
            '1X': require('../assets/images/Playtech/Pinball Roulette.png'),
            '2X': require('../assets/images/Playtech/Pinball Roulette@2x.png'),
        },
        PIXEL_SAMURAI: {
            '1X': require('../assets/images/Playtech/Pixel Samurai.png'),
            '2X': require('../assets/images/Playtech/Pixel Samurai@2x.png'),
        },
        PLENTY_O_FORTUNE: {
            '1X': require("../assets/images/Playtech/Plenty O'Fortune.png"),
            '2X': require("../assets/images/Playtech/Plenty O'Fortune@2x.png"),
        },
        PREMIUM_AMERICAN_ROULETTE: {
            '1X': require('../assets/images/Playtech/Premium American Roulette.png'),
            '2X': require('../assets/images/Playtech/Premium American Roulette@2x.png'),
        },
        PREMIUM_BACCARAT: require('../assets/images/Playtech/Premium_Baccarat.png'),
        PREMIUM_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Premium Blackjack.png'),
            '2X': require('../assets/images/Playtech/Premium Blackjack@2x.png'),
        },
        PREMIUM_BLACKJACK_SINGLE_HAND: {
            '1X': require('../assets/images/Playtech/Premium Blackjack Single Hand.png'),
            '2X': require('../assets/images/Playtech/Premium Blackjack Single Hand@2x.png'),
        },
        PREMIUM_EUROPEAN_ROULETTE: {
            '1X': require('../assets/images/Playtech/Premium European Roulette.png'),
            '2X': require('../assets/images/Playtech/Premium European Roulette@2x.png'),
        },
        PREMIUM_FRENCH_ROULETTE: {
            '1X': require('../assets/images/Playtech/Premium French Roulette.png'),
            '2X': require('../assets/images/Playtech/Premium French Roulette@2x.png'),
        },
        PUMPKIN_BONANZA: {
            '1X': require('../assets/images/Playtech/Pumpkin Bonanza.png'),
            '2X': require('../assets/images/Playtech/Pumpkin Bonanza@2x.png'),
        },
        PURPLE_HOT: {
            '1X': require('../assets/images/Playtech/Purple Hot.png'),
            '2X': require('../assets/images/Playtech/Purple Hot@2x.png'),
        },
        PURPLE_HOT_KIOSK: {
            '1X': require('../assets/images/Playtech/Purple Hot Kiosk.png'),
            '2X': require('../assets/images/Playtech/Purple Hot Kiosk@2x.png'),
        },
        PYRAMID_OF_RAMESSES: {
            '1X': require('../assets/images/Playtech/Pyramid of Ramesses.png'),
            '2X': require('../assets/images/Playtech/Pyramid of Ramesses@2x.png'),
        },
        QUANTUM_ROULETTE_INSTANT_PLAY: {
            '1X': require('../assets/images/Playtech/Quantum Roulette Instant Play.png'),
            '2X': require('../assets/images/Playtech/Quantum Roulette Instant Play@2x.png'),
        },
        QUEEN_OF_WANDS: {
            '1X': require('../assets/images/Playtech/queen-of-wands.png'),
            '2X': require('../assets/images/Playtech/queen-of-wands@2x.png'),
        },
        QUEST_WEST: {
            '1X': require('../assets/images/Playtech/quest-west.png'),
            '2X': require('../assets/images/Playtech/quest-west@2x.png'),
        },
        RAIDERS_OF_THE_HIDDEN_REALM: {
            '1X': require('../assets/images/Playtech/Raiders of the Hidden Realm.png'),
            '2X': require('../assets/images/Playtech/Raiders of the Hidden Realm@2x.png'),
        },
        RECORD_RICHES: require('../assets/images/Playtech/record-riches.png'),
        RETRO_RUSH: {
            '1X': require('../assets/images/Playtech/Retro Rush.png'),
            '2X': require('../assets/images/Playtech/Retro Rush@2x.png'),
        },
        RIDE_THE_TIGER: {
            '1X': require('../assets/images/Playtech/Ride The Tiger.png'),
            '2X': require('../assets/images/Playtech/Ride The Tiger@2x.png'),
        },
        RI_RI_JIN_CAI: {
            '1X': require('../assets/images/Playtech/Ri-ri-jin-cai.png'),
            '2X': require('../assets/images/Playtech/Ri-ri-jin-cai@2x.png'),
        },
        RI_RI_SHENG_CAI: {
            '1X': require('../assets/images/Playtech/ri-ri-sheng-cai.png'),
            '2X': require('../assets/images/Playtech/ri-ri-sheng-cai@2x.png'),
        },
        ROARING_BEARS: {
            '1X': require('../assets/images/Playtech/Roaring Bears.png'),
            '2X': require('../assets/images/Playtech/Roaring Bears@2x.png'),
        },
        ROME_AND_GLORY: {
            '1X': require('../assets/images/Playtech/Rome and Glory.png'),
            '2X': require('../assets/images/Playtech/Rome and Glory@2x.png'),
        },
        ROULETTE_DELUXE: {
            '1X': require('../assets/images/Playtech/Roulette Deluxe.png'),
            '2X': require('../assets/images/Playtech/Roulette Deluxe@2x.png'),
        },
        ROYAL_RESPIN_DELUXE: {
            '1X': require('../assets/images/Playtech/Royal Respin Deluxe.png'),
            '2X': require('../assets/images/Playtech/Royal Respin Deluxe@2x.png'),
        },
        RUBIKS_CUBE: require('../assets/images/Playtech/Rubiks-Cube.png'),
        RULER_OF_THE_DEAD: {
            '1X': require('../assets/images/Playtech/ruler-of-the-dead.png'),
            '2X': require('../assets/images/Playtech/ruler-of-the-dead@2x.png'),
        },
        SACRED_STONES: {
            '1X': require('../assets/images/Playtech/Sacred Stones.png'),
            '2X': require('../assets/images/Playtech/Sacred Stones@2x.png'),
        },
        SAFARI_HEAT: {
            '1X': require('../assets/images/Playtech/Safari Heat.png'),
            '2X': require('../assets/images/Playtech/Safari Heat@2x.png'),
        },
        SAMBA_BRAZIL: {
            '1X': require('../assets/images/Playtech/Samba Brazil.png'),
            '2X': require('../assets/images/Playtech/Samba Brazil@2x.png'),
        },
        SANTA_SURPRISE: {
            '1X': require('../assets/images/Playtech/Santa Surprise.png'),
            '2X': require('../assets/images/Playtech/Santa Surprise@2x.png'),
        },
        SATSUMO_S_REVENGE: {
            '1X': require("../assets/images/Playtech/Satsumo's Revenge.png"),
            '2X': require("../assets/images/Playtech/Satsumo's Revenge@2x.png"),
        },
        SAVAGE_JUNGLE: {
            '1X': require('../assets/images/Playtech/Savage Jungle.png'),
            '2X': require('../assets/images/Playtech/Savage Jungle@2x.png'),
        },
        SCATTER_BRAINS_2: {
            '1X': require('../assets/images/Playtech/Scatter Brains 2.png'),
            '2X': require('../assets/images/Playtech/Scatter Brains 2@2x.png'),
        },
        SECRETS_OF_THE_AMAZON: {
            '1X': require('../assets/images/Playtech/Secrets of the Amazon.png'),
            '2X': require('../assets/images/Playtech/Secrets of the Amazon@2x.png'),
        },
        SELFIE_ELFIE: {
            '1X': require('../assets/images/Playtech/Selfie Elfie.png'),
            '2X': require('../assets/images/Playtech/Selfie Elfie@2x.png'),
        },
        SETTE_E_MEZZO: {
            '1X': require('../assets/images/Playtech/Sette e Mezzo.png'),
            '2X': require('../assets/images/Playtech/Sette e Mezzo@2x.png'),
        },
        SEVEN_E_MEZZO: {
            '1X': require('../assets/images/Playtech/7 e mezzo.png'),
            '2X': require('../assets/images/Playtech/7 e mezzo@2x.png'),
        },
        SHERLOCK_MYSTERY: {
            '1X': require('../assets/images/Playtech/Sherlock Mystery.png'),
            '2X': require('../assets/images/Playtech/Sherlock Mystery@2x.png'),
        },
        SHIELDS_OF_ROME: {
            '1X': require('../assets/images/Playtech/Shields of Rome.png'),
            '2X': require('../assets/images/Playtech/Shields of Rome@2x.png'),
        },
        SIC_BO: {
            '1X': require('../assets/images/Playtech/Live Sic Bo.png'),
            '2X': require('../assets/images/Playtech/Live Sic Bo@2x.png'),
        },
        SIC_BO_DELUXE: {
            '1X': require('../assets/images/Playtech/Live Sic Bo Deluxe.png'),
            '2X': require('../assets/images/Playtech/Live Sic Bo Deluxe@2x.png'),
        },
        SILENT_SAMURAI: {
            '1X': require('../assets/images/Playtech/Silent Samurai.png'),
            '2X': require('../assets/images/Playtech/Silent Samurai@2x.png'),
        },
        SILVER_BULLET: {
            '1X': require('../assets/images/Playtech/Silver Bullet.png'),
            '2X': require('../assets/images/Playtech/Silver Bullet@2x.png'),
        },
        SINBAD_S_GOLDEN_VOYAGE: {
            '1X': require("../assets/images/Playtech/Sinbad's Golden Voyage.png"),
            '2X': require("../assets/images/Playtech/Sinbad's Golden Voyage@2x.png"),
        },
        SI_XIANG: {
            '1X': require('../assets/images/Playtech/si-xiang.png'),
            '2X': require('../assets/images/Playtech/si-xiang@2x.png'),
        },
        SKY_QUEEN: {
            '1X': require('../assets/images/Playtech/Sky Queen.png'),
            '2X': require('../assets/images/Playtech/Sky Queen@2x.png'),
        },
        SOLAR_SE7EN: {
            '1X': require('../assets/images/Playtech/Solar Se7en.png'),
            '2X': require('../assets/images/Playtech/Solar Se7en@2x.png'),
        },
        SPACE_DIGGER: {
            '1X': require('../assets/images/Playtech/Space Digger.png'),
            '2X': require('../assets/images/Playtech/Space Digger@2x.png'),
        },
        SPIN_A_WIN: {
            '1X': require('../assets/images/Playtech/Spin a Win.png'),
            '2X': require('../assets/images/Playtech/Spin a Win@2x.png'),
        },
        SPREAD_BET_ROULETTE: {
            '1X': require('../assets/images/Playtech/Spread Bet Roulette.png'),
            '2X': require('../assets/images/Playtech/Spread Bet Roulette@2x.png'),
        },
        SPUD_O_REILLY_S_CROPS_OF_GOLD_DESKTOP: {
            '1X': require("../assets/images/Playtech/Spud O'Reilly's Crops of Gold Desktop.png"),
            '2X': require("../assets/images/Playtech/Spud O'Reilly's Crops of Gold Desktop@2x.png"),
        },
        SPUD_O_REILLY_S_CROPS_OF_GOLD_MOBILE: {
            '1X': require("../assets/images/Playtech/Spud O'Reilly's Crops of Gold Mobile.png"),
            '2X': require("../assets/images/Playtech/Spud O'Reilly's Crops of Gold Mobile@2x.png"),
        },
        SPY_RISE: {
            '1X': require('../assets/images/Playtech/Spy Rise.png'),
            '2X': require('../assets/images/Playtech/Spy Rise@2x.png'),
        },
        STALLION_STRIKE: {
            '1X': require('../assets/images/Playtech/Stallion Strike.png'),
            '2X': require('../assets/images/Playtech/Stallion Strike@2x.png'),
        },
        STARMADA_EXILES: {
            '1X': require('../assets/images/Playtech/Starmada Exiles.png'),
            '2X': require('../assets/images/Playtech/Starmada Exiles@2x.png'),
        },
        STARS_ABLAZE: {
            '1X': require('../assets/images/Playtech/Stars Ablaze.png'),
            '2X': require('../assets/images/Playtech/Stars Ablaze@2x.png'),
        },
        STARS_AWAKENING: {
            '1X': require('../assets/images/Playtech/Stars Awakening.png'),
            '2X': require('../assets/images/Playtech/Stars Awakening@2x.png'),
        },
        STREAK_OF_LUCK: {
            '1X': require('../assets/images/Playtech/Streak of Luck.png'),
            '2X': require('../assets/images/Playtech/Streak of Luck@2x.png'),
        },
        SUN_WUKONG: {
            '1X': require('../assets/images/Playtech/Sun WuKong.png'),
            '2X': require('../assets/images/Playtech/Sun WuKong@2x.png'),
        },
        SUPER_LION: {
            '1X': require('../assets/images/Playtech/super-lion.png'),
            '2X': require('../assets/images/Playtech/super-lion@2x.png'),
        },
        SUPER_ROULETTE: {
            '1X': require('../assets/images/Playtech/Super Roulette.png'),
            '2X': require('../assets/images/Playtech/Super Roulette@2x.png'),
        },
        SWEET_PARTY: {
            '1X': require('../assets/images/Playtech/Sweet Party.png'),
            '2X': require('../assets/images/Playtech/Sweet Party@2x.png'),
        },
        TABLE_3_CARD_BRAG: {
            '1X': require('../assets/images/Playtech/Table 3 Card Brag.png'),
            '2X': require('../assets/images/Playtech/Table 3 Card Brag@2x.png'),
        },
        TABLE_CASINO_HOLD_EM: {
            '1X': require("../assets/images/Playtech/Table Casino Hold'em.png"),
            '2X': require("../assets/images/Playtech/Table Casino Hold'em@2x.png"),
        },
        TABLE_CASINO_STUD_POKER: {
            '1X': require('../assets/images/Playtech/Table Casino Stud Poker.png'),
            '2X': require('../assets/images/Playtech/Table Casino Stud Poker@2x.png'),
        },
        TABLE_DIAMOND_FRENCH_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Diamond French Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Diamond French Roulette@2x.png'),
        },
        TABLE_DIAMOND_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Diamond Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Diamond Roulette@2x.png'),
        },
        TABLE_DRAGON_JACKPOT_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Dragon Jackpot Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Dragon Jackpot Baccarat@2x.png'),
        },
        TABLE_DRAGON_JACKPOT_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Dragon Jackpot Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Dragon Jackpot Baccarat NC@2x.png'),
        },
        TABLE_DRAGON_TIGER: {
            '1X': require('../assets/images/Playtech/Table Dragon Tiger.png'),
            '2X': require('../assets/images/Playtech/Table Dragon Tiger@2x.png'),
        },
        TABLE_GOLDEN_BACCARAT_1: {
            '1X': require('../assets/images/Playtech/Table Golden Baccarat 1.png'),
            '2X': require('../assets/images/Playtech/Table Golden Baccarat 1@2x.png'),
        },
        TABLE_GOLDEN_BACCARAT_1_NC: {
            '1X': require('../assets/images/Playtech/Table Golden Baccarat 1 NC.png'),
            '2X': require('../assets/images/Playtech/Table Golden Baccarat 1 NC@2x.png'),
        },
        TABLE_GRAND_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Grand Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Grand Baccarat@2x.png'),
        },
        TABLE_GRAND_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Grand Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Grand Baccarat NC@2x.png'),
        },
        TABLE_GRAND_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Table Grand Blackjack.png'),
            '2X': require('../assets/images/Playtech/Table Grand Blackjack@2x.png'),
        },
        TABLE_HI_LO: {
            '1X': require('../assets/images/Playtech/Table Hi-Lo.png'),
            '2X': require('../assets/images/Playtech/Table Hi-Lo@2x.png'),
        },
        TABLE_LIVE_FOOTBALL_FRENCH_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Live Football French Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Live Football French Roulette@2x.png'),
        },
        TABLE_LIVE_FOOTBALL_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Live Football Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Live Football Roulette@2x.png'),
        },
        TABLE_LOUNGE_7_SEAT_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Lounge 7 Seat Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Lounge 7 Seat Baccarat@2x.png'),
        },
        TABLE_LOUNGE_7_SEAT_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Lounge 7 Seat Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Lounge 7 Seat Baccarat NC@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_1: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 1.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 1@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_2: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 2.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 2@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_3: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 3.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 3@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_4: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 4.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 4@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_5: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 5.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 5@2x.png'),
        },
        TABLE_LOUNGE_BLACKJACK_6: {
            '1X': require('../assets/images/Playtech/Table Lounge Blackjack 6.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Blackjack 6@2x.png'),
        },
        TABLE_LOUNGE_FRENCH_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Lounge French Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Lounge French Roulette@2x.png'),
        },
        TABLE_LOUNGE_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Lounge Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Lounge Roulette@2x.png'),
        },
        TABLE_LOUNGE_VIP_BLACKJACK_1: {
            '1X': require('../assets/images/Playtech/Table Lounge VIP Blackjack 1.png'),
            '2X': require('../assets/images/Playtech/Table Lounge VIP Blackjack 1@2x.png'),
        },
        TABLE_OPAL_7_SEAT_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Opal 7 Seat Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Opal 7 Seat Baccarat@2x.png'),
        },
        TABLE_OPAL_7_SEAT_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Opal 7 Seat Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Opal 7 Seat Baccarat NC@2x.png'),
        },
        TABLE_PEARL_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Pearl Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Pearl Roulette@2x.png'),
        },
        TABLE_PEARL_ROULETTE_FRENCH: {
            '1X': require('../assets/images/Playtech/Table Pearl Roulette French.png'),
            '2X': require('../assets/images/Playtech/Table Pearl Roulette French@2x.png'),
        },
        TABLE_PRESTIGE_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Prestige Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Prestige Baccarat@2x.png'),
        },
        TABLE_PRESTIGE_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Prestige Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Prestige Baccarat NC@2x.png'),
        },
        TABLE_PRESTIGE_ROULETTE_COPY_1: {
            '1X': require('../assets/images/Playtech/Table Prestige Roulette copy 1.png'),
            '2X': require('../assets/images/Playtech/Table Prestige Roulette copy 1@2x.png'),
        },
        TABLE_PRIME_SLINGSHOT: {
            '1X': require('../assets/images/Playtech/Table Prime Slingshot.png'),
            '2X': require('../assets/images/Playtech/Table Prime Slingshot@2x.png'),
        },
        TABLE_QUANTUM_BLACKJACK_LIVE: {
            '1X': require('../assets/images/Playtech/Table Quantum Blackjack Live.png'),
            '2X': require('../assets/images/Playtech/Table Quantum Blackjack Live@2x.png'),
        },
        TABLE_QUANTUM_ROULETTE_LIVE: {
            '1X': require('../assets/images/Playtech/Table Quantum Roulette Live.png'),
            '2X': require('../assets/images/Playtech/Table Quantum Roulette Live@2x.png'),
        },
        TABLE_ROYALE_BLACKJACK_1: {
            '1X': require('../assets/images/Playtech/Table Royale Blackjack 1.png'),
            '2X': require('../assets/images/Playtech/Table Royale Blackjack 1@2x.png'),
        },
        TABLE_ROYALE_BLACKJACK_2: {
            '1X': require('../assets/images/Playtech/Table Royale Blackjack 2.png'),
            '2X': require('../assets/images/Playtech/Table Royale Blackjack 2@2x.png'),
        },
        TABLE_ROYALE_BLACKJACK_3: {
            '1X': require('../assets/images/Playtech/Table Royale Blackjack 3.png'),
            '2X': require('../assets/images/Playtech/Table Royale Blackjack 3@2x.png'),
        },
        TABLE_ROYALE_BLACKJACK_4: {
            '1X': require('../assets/images/Playtech/Table Royale Blackjack 4.png'),
            '2X': require('../assets/images/Playtech/Table Royale Blackjack 4@2x.png'),
        },
        TABLE_ROYALE_BLACKJACK_5: {
            '1X': require('../assets/images/Playtech/Table Royale Blackjack 5.png'),
            '2X': require('../assets/images/Playtech/Table Royale Blackjack 5@2x.png'),
        },
        TABLE_SAPPHIRE_ROOM_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Sapphire Room Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Sapphire Room Baccarat@2x.png'),
        },
        TABLE_SAPPHIRE_ROOM_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Sapphire Room Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Sapphire Room Baccarat NC@2x.png'),
        },
        TABLE_SAPPHIRE_ROOM_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Sapphire Room Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Sapphire Room Roulette@2x.png'),
        },
        TABLE_SICBO_DELUXE: {
            '1X': require('../assets/images/Playtech/Table Sicbo Deluxe.png'),
            '2X': require('../assets/images/Playtech/Table Sicbo Deluxe@2x.png'),
        },
        TABLE_SLINGSHOT: {
            '1X': require('../assets/images/Playtech/Table Slingshot.png'),
            '2X': require('../assets/images/Playtech/Table Slingshot@2x.png'),
        },
        TABLE_SOHO_BLACKJACK_1: {
            '1X': require('../assets/images/Playtech/Table Soho Blackjack 1.png'),
            '2X': require('../assets/images/Playtech/Table Soho Blackjack 1@2x.png'),
        },
        TABLE_SOHO_BLACKJACK_2: {
            '1X': require('../assets/images/Playtech/Table Soho Blackjack 2.png'),
            '2X': require('../assets/images/Playtech/Table Soho Blackjack 2@2x.png'),
        },
        TABLE_SOHO_BLACKJACK_3: {
            '1X': require('../assets/images/Playtech/Table Soho Blackjack 3.png'),
            '2X': require('../assets/images/Playtech/Table Soho Blackjack 3@2x.png'),
        },
        TABLE_SOIREE_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Soiree Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Soiree Baccarat@2x.png'),
        },
        TABLE_SOIREE_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Soiree Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Soiree Baccarat NC@2x.png'),
        },
        TABLE_SOIRÉE_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Table Soirée Blackjack.png'),
            '2X': require('../assets/images/Playtech/Table Soirée Blackjack@2x.png'),
        },
        TABLE_SOIRÉE_ELITE_BLACKJACK_100: {
            '1X': require('../assets/images/Playtech/Table Soirée Elite Blackjack 100.png'),
            '2X': require('../assets/images/Playtech/Table Soirée Elite Blackjack 100@2x.png'),
        },
        TABLE_SOIRÉE_ELITE_BLACKJACK_200: {
            '1X': require('../assets/images/Playtech/Table Soirée Elite Blackjack 200.png'),
            '2X': require('../assets/images/Playtech/Table Soirée Elite Blackjack 200@2x.png'),
        },
        TABLE_SOIRÉE_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Soirée Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Soirée Roulette@2x.png'),
        },
        TABLE_SPEED_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Speed Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Speed Roulette@2x.png'),
        },
        TABLE_SPIN_A_WIN: {
            '1X': require('../assets/images/Playtech/Table Spin a Win.png'),
            '2X': require('../assets/images/Playtech/Table Spin a Win@2x.png'),
        },
        TABLE_SPREAD_BET_ROULETTE: {
            '1X': require('../assets/images/Playtech/Table Spread Bet Roulette.png'),
            '2X': require('../assets/images/Playtech/Table Spread Bet Roulette@2x.png'),
        },
        TABLE_TOPAZ_SPEED_BACCARAT: {
            '1X': require('../assets/images/Playtech/Table Topaz Speed Baccarat.png'),
            '2X': require('../assets/images/Playtech/Table Topaz Speed Baccarat@2x.png'),
        },
        TABLE_TOPAZ_SPEED_BACCARAT_NC: {
            '1X': require('../assets/images/Playtech/Table Topaz Speed Baccarat NC.png'),
            '2X': require('../assets/images/Playtech/Table Topaz Speed Baccarat NC@2x.png'),
        },
        TABLE_TURQUOISE_SICBO: {
            '1X': require('../assets/images/Playtech/Table Turquoise Sicbo.png'),
            '2X': require('../assets/images/Playtech/Table Turquoise Sicbo@2x.png'),
        },
        TABLE_UNLIMITED_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Table Unlimited Blackjack.png'),
            '2X': require('../assets/images/Playtech/Table Unlimited Blackjack@2x.png'),
        },
        THAI_PARADISE: {
            '1X': require('../assets/images/Playtech/Thai Paradise.png'),
            '2X': require('../assets/images/Playtech/Thai Paradise@2x.png'),
        },
        THAI_TEMPLE: {
            '1X': require('../assets/images/Playtech/Thai Temple.png'),
            '2X': require('../assets/images/Playtech/Thai Temple@2x.png'),
        },
        THE_DISCOVERY: {
            '1X': require('../assets/images/Playtech/The Discovery.png'),
            '2X': require('../assets/images/Playtech/The Discovery@2x.png'),
        },
        THE_GLASS_SLIPPER: {
            '1X': require('../assets/images/Playtech/The Glass Slipper.png'),
            '2X': require('../assets/images/Playtech/The Glass Slipper@2x.png'),
        },
        THE_GREAT_MING_EMPIRE: {
            '1X': require('../assets/images/Playtech/The Great Ming Empire.png'),
            '2X': require('../assets/images/Playtech/The Great Ming Empire@2x.png'),
        },
        THE_GREAT_REVEAL: {
            '1X': require('../assets/images/Playtech/The Great Reveal.png'),
            '2X': require('../assets/images/Playtech/The Great Reveal@2x.png'),
        },
        THE_MULTIPLIER_MADNESS: {
            '1X': require('../assets/images/Playtech/The Multiplier Madness.png'),
            '2X': require('../assets/images/Playtech/The Multiplier Madness@2x.png'),
        },
        THE_PERFECT_HEIST: require('../assets/images/Playtech/ThePerfectHeist.png'),
        THE_RICHES_OF_DON_QUIXOTE: {
            '1X': require('../assets/images/Playtech/The Riches of Don Quixote.png'),
            '2X': require('../assets/images/Playtech/The Riches of Don Quixote@2x.png'),
        },
        THE_THREE_MUSKETEERS_AND_THE_QUEEN_S_DIAMOND: {
            '1X': require("../assets/images/Playtech/The Three Musketeers and the Queen's Diamond.png"),
            '2X': require("../assets/images/Playtech/The Three Musketeers and the Queen's Diamond@2x.png"),
        },
        THOUSAND_DIAMOND_BET_ROULETTE: {
            '1X': require('../assets/images/Playtech/1000 Diamond Bet Roulette.png'),
            '2X': require('../assets/images/Playtech/1000 Diamond Bet Roulette@2x.png'),
        },
        THREE_CARD_BRAG: {
            '1X': require('../assets/images/Playtech/3 Card Brag.png'),
            '2X': require('../assets/images/Playtech/3 Card Brag@2x.png'),
        },
        TIGER_CLAW: {
            '1X': require('../assets/images/Playtech/Tiger Claw.png'),
            '2X': require('../assets/images/Playtech/Tiger Claw@2x.png'),
        },
        TIGER_STACKS: {
            '1X': require('../assets/images/Playtech/Tiger Stacks.png'),
            '2X': require('../assets/images/Playtech/Tiger Stacks@2x.png'),
        },
        TIGER_TURTLE_DRAGON_PHOENIX: {
            '1X': require('../assets/images/Playtech/Tiger, Turtle, Dragon, Phoenix.png'),
            '2X': require('../assets/images/Playtech/Tiger, Turtle, Dragon, Phoenix@2x.png'),
        },
        TIKI_PARADISE: {
            '1X': require('../assets/images/Playtech/Tiki Paradise.png'),
            '2X': require('../assets/images/Playtech/Tiki Paradise@2x.png'),
        },
        TIME_FOR_A_DEAL: {
            '1X': require('../assets/images/Playtech/Time for a Deal.png'),
            '2X': require('../assets/images/Playtech/Time for a Deal@2x.png'),
        },
        TINDERBOX_TREASURES: {
            '1X': require('../assets/images/Playtech/Tinderbox Treasures.png'),
            '2X': require('../assets/images/Playtech/Tinderbox Treasures@2x.png'),
        },
        TIP_TOP_TOTEMS: {
            '1X': require('../assets/images/Playtech/Tip Top Totems.png'),
            '2X': require('../assets/images/Playtech/Tip Top Totems@2x.png'),
        },
        TIP_TOP_TOTEMS_POWERPLAY_JACKPOT: require('../assets/images/Playtech/TipTopTotemsPowerPlayJackpot.png'),
        TOADSGIFT: require('../assets/images/Playtech/toad-gift.png'),
        TOLTEC_TREASURE: {
            '1X': require('../assets/images/Playtech/toltec-treasure.png'),
            '2X': require('../assets/images/Playtech/toltec-treasure@2x.png'),
        },
        TREASURES_OF_THE_LAMPS: {
            '1X': require('../assets/images/Playtech/Treasures of the Lamps.png'),
            '2X': require('../assets/images/Playtech/Treasures of the Lamps@2x.png'),
        },
        TRIPLE_MONKEY: {
            '1X': require('../assets/images/Playtech/triple-monkey.png'),
            '2X': require('../assets/images/Playtech/triple-monkey@2x.png'),
        },
        TRUE_LOVE: {
            '1X': require('../assets/images/Playtech/True Love.png'),
            '2X': require('../assets/images/Playtech/True Love@2x.png'),
        },
        TSAI_SHEN_S_GIFT: {
            '1X': require("../assets/images/Playtech/Tsai Shen's Gift.png"),
            '2X': require("../assets/images/Playtech/Tsai Shen's Gift@2x.png"),
        },
        TURN_IT_ON: {
            '1X': require('../assets/images/Playtech/Turn It On.png'),
            '2X': require('../assets/images/Playtech/Turn It On@2x.png'),
        },
        UGGA_BUGGA: {
            '1X': require('../assets/images/Playtech/Ugga Bugga.png'),
            '2X': require('../assets/images/Playtech/Ugga Bugga@2x.png'),
        },
        UPGRADIUM: {
            '1X': require('../assets/images/Playtech/Upgradium.png'),
            '2X': require('../assets/images/Playtech/Upgradium@2x.png'),
        },
        VACATION_STATION: {
            '1X': require('../assets/images/Playtech/Vacation Station.png'),
            '2X': require('../assets/images/Playtech/Vacation Station@2x.png'),
        },
        VACATION_STATION_DELUXE: {
            '1X': require('../assets/images/Playtech/Vacation Station Deluxe.png'),
            '2X': require('../assets/images/Playtech/Vacation Station Deluxe@2x.png'),
        },
        VAMPIRE_PRINCESS_OF_DARKNESS: {
            '1X': require('../assets/images/Playtech/Vampire Princess of Darkness.png'),
            '2X': require('../assets/images/Playtech/Vampire Princess of Darkness@2x.png'),
        },
        VEGAS_BLACKJACK: {
            '1X': require('../assets/images/Playtech/Vegas Blackjack.png'),
            '2X': require('../assets/images/Playtech/Vegas Blackjack@2x.png'),
        },
        VIKINGMANIA: {
            '1X': require('../assets/images/Playtech/Vikingmania.png'),
            '2X': require('../assets/images/Playtech/Vikingmania@2x.png'),
        },
        WACKY_WATERS: {
            '1X': require('../assets/images/Playtech/Wacky Waters.png'),
            '2X': require('../assets/images/Playtech/Wacky Waters@2x.png'),
        },
        WALL_STREET_FEVER: {
            '1X': require('../assets/images/Playtech/Wall Street Fever.png'),
            '2X': require('../assets/images/Playtech/Wall Street Fever@2x.png'),
        },
        WARRIOR_FISHING: {
            '1X': require('../assets/images/Playtech/Warrior Fishing.png'),
            '2X': require('../assets/images/Playtech/Warrior Fishing@2x.png'),
        },
        WAYS_OF_THE_PHOENIX: {
            '1X': require('../assets/images/Playtech/Ways of the Phoenix.png'),
            '2X': require('../assets/images/Playtech/Ways of the Phoenix@2x.png'),
        },
        WHAT_S_COOKING: {
            '1X': require("../assets/images/Playtech/What's Cooking.png"),
            '2X': require("../assets/images/Playtech/What's Cooking@2x.png"),
        },
        WHEELS_N_REELS: {
            '1X': require('../assets/images/Playtech/Wheels N Reels.png'),
            '2X': require('../assets/images/Playtech/Wheels N Reels@2x.png'),
        },
        WHITE_KING: {
            '1X': require('../assets/images/Playtech/White King.png'),
            '2X': require('../assets/images/Playtech/White King@2x.png'),
        },
        WHITE_KING_II: {
            '1X': require('../assets/images/Playtech/White King II.png'),
            '2X': require('../assets/images/Playtech/White King II@2x.png'),
        },
        WILD_BEATS: {
            '1X': require('../assets/images/Playtech/Wild Beats.png'),
            '2X': require('../assets/images/Playtech/Wild Beats@2x.png'),
        },
        WILD_GAMBLER: {
            '1X': require('../assets/images/Playtech/Wild Gambler.png'),
            '2X': require('../assets/images/Playtech/Wild Gambler@2x.png'),
        },
        WILD_GAMBLER_2_ARCTIC_ADVENTURE: {
            '1X': require('../assets/images/Playtech/Wild Gambler 2_Arctic Adventure.png'),
            '2X': require('../assets/images/Playtech/Wild Gambler 2_Arctic Adventure@2x.png'),
        },
        WILD_LAVA: {
            '1X': require('../assets/images/Playtech/wild-lava.png'),
            '2X': require('../assets/images/Playtech/wild-lava@2x.png'),
        },
        WILD_LAVA_SCRATCH: require('../assets/images/Playtech/Wild_Lava_Scratch.png'),
        WILD_SPIRIT: {
            '1X': require('../assets/images/Playtech/Wild Spirit.png'),
            '2X': require('../assets/images/Playtech/Wild Spirit@2x.png'),
        },
        WILD_WEST_WILDS: {
            '1X': require('../assets/images/Playtech/Wild West Wilds.png'),
            '2X': require('../assets/images/Playtech/Wild West Wilds@2x.png'),
        },
        WILD_WISHES: {
            '1X': require('../assets/images/Playtech/Wild Wishes.png'),
            '2X': require('../assets/images/Playtech/Wild Wishes@2x.png'),
        },
        WINGS_OF_GOLD: {
            '1X': require('../assets/images/Playtech/Wings of Gold.png'),
            '2X': require('../assets/images/Playtech/Wings of Gold@2x.png'),
        },
        WINNINGS_OF_OZ: {
            '1X': require('../assets/images/Playtech/Winnings of Oz.png'),
            '2X': require('../assets/images/Playtech/Winnings of Oz@2x.png'),
        },
        WOLVES_WOLVES_WOLVES: {
            '1X': require('../assets/images/Playtech/Wolves Wolves Wolves.png'),
            '2X': require('../assets/images/Playtech/Wolves Wolves Wolves@2x.png'),
        },
        WU_LONG: {
            '1X': require('../assets/images/Playtech/Wu Long.png'),
            '2X': require('../assets/images/Playtech/Wu Long@2x.png'),
        },
        WU_LU_CAI_SHEN: {
            '1X': require('../assets/images/Playtech/wu-lu-cai-shen.png'),
            '2X': require('../assets/images/Playtech/wu-lu-cai-shen@2x.png'),
        },
        XUAN_PU_LIAN_HUAN: {
            '1X': require('../assets/images/Playtech/Xuan Pu Lian Huan.png'),
            '2X': require('../assets/images/Playtech/Xuan Pu Lian Huan@2x.png'),
        },
        YUN_CONG_LONG: {
            '1X': require('../assets/images/Playtech/Yun Cong Long.png'),
            '2X': require('../assets/images/Playtech/Yun Cong Long@2x.png'),
        },
        YUTU: {
            '1X': require('../assets/images/Playtech/Yutu.png'),
            '2X': require('../assets/images/Playtech/Yutu@2x.png'),
        },
        ZHAO_CAI_JIN_BAO: {
            '1X': require('../assets/images/Playtech/Zhao Cai Jin Bao.png'),
            '2X': require('../assets/images/Playtech/Zhao Cai Jin Bao@2x.png'),
        },
        ZHAO_CAI_JIN_BAO_2: {
            '1X': require('../assets/images/Playtech/zhao-cai-jin-bao.png'),
            '2X': require('../assets/images/Playtech/zhao-cai-jin-bao@2x.png'),
        },
        ZHAO_CAI_JIN_BAO_JACKPOT: {
            '1X': require('../assets/images/Playtech/Zhao Cai Jin Bao Jackpot.png'),
            '2X': require('../assets/images/Playtech/Zhao Cai Jin Bao Jackpot@2x.png'),
        },
        ZHAO_CAI_TONG_ZI: {
            '1X': require('../assets/images/Playtech/zhao-cai-tong-zi.png'),
            '2X': require('../assets/images/Playtech/zhao-cai-tong-zi@2x.png'),
        },
        ZODIAC_CHARMS: require('../assets/images/Playtech/ZodiacCharms.png'),
    },
    PRAGMATIC_GAMES: {
        ALADDIN_AND_SORCERER: {
            '1X': require('../assets/images/PragmaticGames/alladin-and-the-sorcerer.png'),
            '2X': require('../assets/images/PragmaticGames/alladin-and-the-sorcerer@2x.png'),
        },
        ALADDIN_TREASURE: {
            '1X': require('../assets/images/PragmaticGames/aladdins-treasure.png'),
            '2X': require('../assets/images/PragmaticGames/aladdins-treasure@2x.png'),
        },
        ANCIENT_EGYPT: {
            '1X': require('../assets/images/PragmaticGames/ancient-egypt.png'),
            '2X': require('../assets/images/PragmaticGames/ancient-egypt@2x.png'),
        },
        ASGARD: {
            '1X': require('../assets/images/PragmaticGames/asgard.png'),
            '2X': require('../assets/images/PragmaticGames/asgard@2x.png'),
        },
        AZTEC_BONANZA: {
            '1X': require('../assets/images/PragmaticGames/aztec-bonanza.png'),
            '2X': require('../assets/images/PragmaticGames/aztec-bonanza@2x.png'),
        },
        AZTEC_GEMS: {
            '1X': require('../assets/images/PragmaticGames/aztec.png'),
            '2X': require('../assets/images/PragmaticGames/aztec@2x.png'),
        },
        AZTEC_GEMS_DELUXE: {
            '1X': require('../assets/images/PragmaticGames/aztec-gems-deluxe.png'),
            '2X': require('../assets/images/PragmaticGames/aztec-gems-deluxe@2x.png'),
        },
        AZTEC_GEMS_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/aztec-gems-jp.png'),
            '2X': require('../assets/images/PragmaticGames/aztec-gems-jp@2x.png'),
        },
        BEOWULF: {
            '1X': require('../assets/images/PragmaticGames/beowulf.png'),
            '2X': require('../assets/images/PragmaticGames/beowulf@2x.png'),
        },
        BIG_BASS_BONANZA: require('../assets/images/PragmaticGames/big-bass-bonanza.png'),
        BOOK_OF_KINGDOMS: {
            '1X': require('../assets/images/PragmaticGames/book-of-kingdoms.png'),
            '2X': require('../assets/images/PragmaticGames/book-of-kingdoms@2x.png'),
        },
        BRONCO_SPIRIT: {
            '1X': require('../assets/images/PragmaticGames/bronco-spirit.png'),
            '2X': require('../assets/images/PragmaticGames/bronco-spirit@2x.png'),
        },
        BUSY_BEES: {
            '1X': require('../assets/images/PragmaticGames/busy-bees.png'),
            '2X': require('../assets/images/PragmaticGames/busy-bees@2x.png'),
        },
        CAISHEN_CASH: {
            '1X': require('../assets/images/PragmaticGames/caishens-cash.png'),
            '2X': require('../assets/images/PragmaticGames/caishens-cash@2x.png'),
        },
        CAISHEN_GOLD: {
            '1X': require('../assets/images/PragmaticGames/caishens-gold.png'),
            '2X': require('../assets/images/PragmaticGames/caishens-gold@2x.png'),
        },
        CHILLI_HEAT: {
            '1X': require('../assets/images/PragmaticGames/chili-heat.png'),
            '2X': require('../assets/images/PragmaticGames/chili-heat@2x.png'),
        },
        CONGO_CASH: require('../assets/images/PragmaticGames/congo-cash.png'),
        COWBOYS_GOLD: {
            '1X': require('../assets/images/PragmaticGames/cowboys-gold.png'),
            '2X': require('../assets/images/PragmaticGames/cowboys-gold@2x.png'),
        },
        CRAZY_7: {
            '1X': require('../assets/images/PragmaticGames/crazy-7s.png'),
            '2X': require('../assets/images/PragmaticGames/crazy-7s@2x.png'),
        },
        CURSE_WAREWOLF: {
            '1X': require('../assets/images/PragmaticGames/curse-warewolf.png'),
            '2X': require('../assets/images/PragmaticGames/curse-warewolf@2x.png'),
        },
        DA_VINCI_TREASURE: {
            '1X': require('../assets/images/PragmaticGames/davinci-treasure.png'),
            '2X': require('../assets/images/PragmaticGames/davinci-treasure@2x.png'),
        },
        DEVILS_13: {
            '1X': require('../assets/images/PragmaticGames/devil13.png'),
            '2X': require('../assets/images/PragmaticGames/devil13@2x.png'),
        },
        DIAMOND_STRIKE: {
            '1X': require('../assets/images/PragmaticGames/diamond-strike.png'),
            '2X': require('../assets/images/PragmaticGames/diamond-strike@2x.png'),
        },
        DRAGON_BALL: {
            '1X': require('../assets/images/PragmaticGames/dragon-ball.png'),
            '2X': require('../assets/images/PragmaticGames/dragon-ball@2x.png'),
        },
        DRAGON_KINGDOM: {
            '1X': require('../assets/images/PragmaticGames/dragon-kingdom.png'),
            '2X': require('../assets/images/PragmaticGames/dragon-kingdom@2x.png'),
        },
        DRAGON_KINGDOM_EYES_OF_FIRE: require('../assets/images/PragmaticGames/dragon-kingdom-eyes-of-fire.png'),
        DRAGON_TIGER: {
            '1X': require('../assets/images/PragmaticGames/dragon-tiger.png'),
            '2X': require('../assets/images/PragmaticGames/dragon-tiger@2x.png'),
        },
        DRAGO_JEWELS_OF_FORTUNE: {
            '1X': require('../assets/images/PragmaticGames/drago-jewels-of-fortune.png'),
            '2X': require('../assets/images/PragmaticGames/drago-jewels-of-fortune@2x.png'),
        },
        DWARVEN_GOLD: {
            '1X': require('../assets/images/PragmaticGames/dwarven-gold.png'),
            '2X': require('../assets/images/PragmaticGames/dwarven-gold@2x.png'),
        },
        DWARVEN_GOLD_DELUXE: {
            '1X': require('../assets/images/PragmaticGames/dwarven-gold-deluxe.png'),
            '2X': require('../assets/images/PragmaticGames/dwarven-gold-deluxe@2x.png'),
        },
        EGYPTIAN_FORTUNES: {
            '1X': require('../assets/images/PragmaticGames/egyptian.png'),
            '2X': require('../assets/images/PragmaticGames/egyptian@2x.png'),
        },
        EIGHT_DRAGON: {
            '1X': require('../assets/images/PragmaticGames/8dragons.png'),
            '2X': require('../assets/images/PragmaticGames/8dragons@2x.png'),
        },
        EMERALD_KING: {
            '1X': require('../assets/images/PragmaticGames/emerald-king.png'),
            '2X': require('../assets/images/PragmaticGames/emerald-king@2x.png'),
        },
        EMERALD_KING_RAINBOW_ROAD: require('../assets/images/PragmaticGames/emerald-king-rainbow-road.png'),
        EXTRA_JUICY: {
            '1X': require('../assets/images/PragmaticGames/extra-juicy.png'),
            '2X': require('../assets/images/PragmaticGames/extra-juicy@2x.png'),
        },
        EYE_OF_THE_STORM: require('../assets/images/PragmaticGames/eye-of-the-strom.png'),
        FAIRYTALE_FORTUNE: {
            '1X': require('../assets/images/PragmaticGames/fairytale-fortune.png'),
            '2X': require('../assets/images/PragmaticGames/fairytale-fortune@2x.png'),
        },
        FIRE_88: {
            '1X': require('../assets/images/PragmaticGames/fire88.png'),
            '2X': require('../assets/images/PragmaticGames/fire88@2x.png'),
        },
        FIRE_STRIKE: {
            '1X': require('../assets/images/PragmaticGames/fire-strike.png'),
            '2X': require('../assets/images/PragmaticGames/fire-strike@2x.png'),
        },
        FIVE_LIONS: {
            '1X': require('../assets/images/PragmaticGames/5-lions.png'),
            '2X': require('../assets/images/PragmaticGames/5-lions@2x.png'),
        },
        FIVE_LIONS_GOLD: {
            '1X': require('../assets/images/PragmaticGames/5-lions-gold.png'),
            '2X': require('../assets/images/PragmaticGames/5-lions-gold@2x.png'),
        },
        FRUITY_BLAST: {
            '1X': require('../assets/images/PragmaticGames/fruity-blast.png'),
            '2X': require('../assets/images/PragmaticGames/fruity-blast@2x.png'),
        },
        FRUIT_PARTY: {
            '1X': require('../assets/images/PragmaticGames/fruit-party.png'),
            '2X': require('../assets/images/PragmaticGames/fruit-party@2x.png'),
        },
        FRUIT_RAINBOW: {
            '1X': require('../assets/images/PragmaticGames/fruit-rainbow.png'),
            '2X': require('../assets/images/PragmaticGames/fruit-rainbow@2x.png'),
        },
        FUFUFU: {
            '1X': require('../assets/images/PragmaticGames/fufufu.png'),
            '2X': require('../assets/images/PragmaticGames/fufufu@2x.png'),
        },
        GEMS_BONANZA: require('../assets/images/PragmaticGames/gems-bonanza.png'),
        GLORIOUS_ROME: {
            '1X': require('../assets/images/PragmaticGames/glorious-rome.png'),
            '2X': require('../assets/images/PragmaticGames/glorious-rome@2x.png'),
        },
        GOLDEN_BEAUTY: {
            '1X': require('../assets/images/PragmaticGames/golden-beauty.png'),
            '2X': require('../assets/images/PragmaticGames/golden-beauty@2x.png'),
        },
        GOLDEN_OX: require('../assets/images/PragmaticGames/golden-ox.png'),
        GOLDEN_PIG: {
            '1X': require('../assets/images/PragmaticGames/golden-pig.png'),
            '2X': require('../assets/images/PragmaticGames/golden-pig@2x.png'),
        },
        GOLD_RUSH: {
            '1X': require('../assets/images/PragmaticGames/gold-rush.png'),
            '2X': require('../assets/images/PragmaticGames/gold-rush@2x.png'),
        },
        GOLD_TRAIN: {
            '1X': require('../assets/images/PragmaticGames/gold-train.png'),
            '2X': require('../assets/images/PragmaticGames/gold-train@2x.png'),
        },
        GREAT_REEF: {
            '1X': require('../assets/images/PragmaticGames/great-reef.png'),
            '2X': require('../assets/images/PragmaticGames/great-reef@2x.png'),
        },
        GREAT_RHINO: {
            '1X': require('../assets/images/PragmaticGames/great-rhino.png'),
            '2X': require('../assets/images/PragmaticGames/great-rhino@2x.png'),
        },
        GREAT_RHINO_DELUXE: {
            '1X': require('../assets/images/PragmaticGames/great-rhino-deluxe.png'),
            '2X': require('../assets/images/PragmaticGames/great-rhino-deluxe@2x.png'),
        },
        GREEK_GODS: {
            '1X': require('../assets/images/PragmaticGames/greek-gods.png'),
            '2X': require('../assets/images/PragmaticGames/greek-gods@2x.png'),
        },
        HERCULES_AND_PEGASUS: {
            '1X': require('../assets/images/PragmaticGames/hercules-and-pegasus.png'),
            '2X': require('../assets/images/PragmaticGames/hercules-and-pegasus@2x.png'),
        },
        HERCULES_SON_OF_ZEUS: {
            '1X': require('../assets/images/PragmaticGames/hercules-son-of-zeus.png'),
            '2X': require('../assets/images/PragmaticGames/hercules-son-of-zeus@2x.png'),
        },
        HOCKY_LEAGUE: {
            '1X': require('../assets/images/PragmaticGames/hockey-league.png'),
            '2X': require('../assets/images/PragmaticGames/hockey-league@2x.png'),
        },
        HOCKY_LEAGUE_WILD_MATCH: {
            '1X': require('../assets/images/PragmaticGames/hockey-league-wild-match.png'),
            '2X': require('../assets/images/PragmaticGames/hockey-league-wild-match@2x.png'),
        },
        HONEY_HONEY_HONEY: {
            '1X': require('../assets/images/PragmaticGames/honey-honey.png'),
            '2X': require('../assets/images/PragmaticGames/honey-honey@2x.png'),
        },
        HOT_CHILLI: {
            '1X': require('../assets/images/PragmaticGames/hot-chilli.png'),
            '2X': require('../assets/images/PragmaticGames/hot-chilli@2x.png'),
        },
        HOT_SAFARI: {
            '1X': require('../assets/images/PragmaticGames/hot-safari.png'),
            '2X': require('../assets/images/PragmaticGames/hot-safari@2x.png'),
        },
        HOT_SAFARI_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/hot-safari-jp.png'),
            '2X': require('../assets/images/PragmaticGames/hot-safari-jp@2x.png'),
        },
        HOT_TO_BURN: {
            '1X': require('../assets/images/PragmaticGames/hot-to-burn.png'),
            '2X': require('../assets/images/PragmaticGames/hot-to-burn@2x.png'),
        },
        HOT_TO_BURN_HOLD_AND_SPIN: require('../assets/images/PragmaticGames/hot-to-burn-hold-and-spin.png'),
        IRISH_CHARMS: {
            '1X': require('../assets/images/PragmaticGames/irish-charms.png'),
            '2X': require('../assets/images/PragmaticGames/irish-charms@2x.png'),
        },
        JOHN_HUNTER_AND_THE_BOOK_OF_TUT: {
            '1X': require('../assets/images/PragmaticGames/john-hunter-and-book-of-tut.png'),
            '2X': require('../assets/images/PragmaticGames/john-hunter-and-book-of-tut@2x.png'),
        },
        JOHN_HUNTER_AND_THE_MAYA_GODS: {
            '1X': require('../assets/images/PragmaticGames/john-hunter.png'),
            '2X': require('../assets/images/PragmaticGames/john-hunter@2x.png'),
        },
        JOHN_HUNTER_AND_TOMB_OF_THE_SCRAB_QUEEN: {
            '1X': require('../assets/images/PragmaticGames/john-hunter-and-the-tomb.png'),
            '2X': require('../assets/images/PragmaticGames/john-hunter-and-the-tomb@2x.png'),
        },
        JOHN_HUNTER_AZTEC_TREASURE: {
            '1X': require('../assets/images/PragmaticGames/john-hunter-and-the-aztec-treasure.png'),
            '2X': require('../assets/images/PragmaticGames/john-hunter-and-the-aztec-treasure@2x.png'),
        },
        JOKERS_JEWELS: {
            '1X': require('../assets/images/PragmaticGames/joker-jewels.png'),
            '2X': require('../assets/images/PragmaticGames/joker-jewels@2x.png'),
        },
        JOKER_KING: require('../assets/images/PragmaticGames/joker-king.png'),
        JOURNEY_TO_THE_WEST: {
            '1X': require('../assets/images/PragmaticGames/journey-to-the-west.png'),
            '2X': require('../assets/images/PragmaticGames/journey-to-the-west@2x.png'),
        },
        JOURNEY_TO_THE_WEST_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/journey-to-the-west-jp.png'),
            '2X': require('../assets/images/PragmaticGames/journey-to-the-west-jp@2x.png'),
        },
        JUNGLE_GORILLA: {
            '1X': require('../assets/images/PragmaticGames/jungle-gorilla.png'),
            '2X': require('../assets/images/PragmaticGames/jungle-gorilla@2x.png'),
        },
        JURASSIC_GIANTS: {
            '1X': require('../assets/images/PragmaticGames/jurassic-giants.png'),
            '2X': require('../assets/images/PragmaticGames/jurassic-giants@2x.png'),
        },
        KTV: {
            '1X': require('../assets/images/PragmaticGames/ktv.png'),
            '2X': require('../assets/images/PragmaticGames/ktv@2x.png'),
        },
        LADY_GODIVA: {
            '1X': require('../assets/images/PragmaticGames/lady-godiva.png'),
            '2X': require('../assets/images/PragmaticGames/lady-godiva@2x.png'),
        },
        LADY_OF_THE_MOON: {
            '1X': require('../assets/images/PragmaticGames/lady-of-the-moon.png'),
            '2X': require('../assets/images/PragmaticGames/lady-of-the-moon@2x.png'),
        },
        LEPRECHAUN_CAROL: {
            '1X': require('../assets/images/PragmaticGames/leprechaun-carol.png'),
            '2X': require('../assets/images/PragmaticGames/leprechaun-carol@2x.png'),
        },
        LEPRECHUAN_SONG: {
            '1X': require('../assets/images/PragmaticGames/leprechaun-song.png'),
            '2X': require('../assets/images/PragmaticGames/leprechaun-song@2x.png'),
        },
        LIONS_DANCE: {
            '1X': require('../assets/images/PragmaticGames/5-lions-dance.png'),
            '2X': require('../assets/images/PragmaticGames/5-lions-dance@2x.png'),
        },
        LOGO: require('../assets/images/PragmaticGames/logo.png'),
        LUCKY_DRAGONS: {
            '1X': require('../assets/images/PragmaticGames/lucky-dragons.png'),
            '2X': require('../assets/images/PragmaticGames/lucky-dragons@2x.png'),
        },
        LUCKY_NEW_YEAR: {
            '1X': require('../assets/images/PragmaticGames/lucky-new-year.png'),
            '2X': require('../assets/images/PragmaticGames/lucky-new-year@2x.png'),
        },
        MADAME_DESTINY: {
            '1X': require('../assets/images/PragmaticGames/madame-destine.png'),
            '2X': require('../assets/images/PragmaticGames/madame-destine@2x.png'),
        },
        MAGIC_CRYSTALS: {
            '1X': require('../assets/images/PragmaticGames/magic-crystals.png'),
            '2X': require('../assets/images/PragmaticGames/magic-crystals@2x.png'),
        },
        MAGIC_JOURNEY: {
            '1X': require('../assets/images/PragmaticGames/magic-journey.png'),
            '2X': require('../assets/images/PragmaticGames/magic-journey@2x.png'),
        },
        MASTER_CHEN: {
            '1X': require('../assets/images/PragmaticGames/master-chen.png'),
            '2X': require('../assets/images/PragmaticGames/master-chen@2x.png'),
        },
        MASTER_JOKER: {
            '1X': require('../assets/images/PragmaticGames/master-joker.png'),
            '2X': require('../assets/images/PragmaticGames/master-joker@2x.png'),
        },
        MIGHTY_KONG: {
            '1X': require('../assets/images/PragmaticGames/mighty-kong.png'),
            '2X': require('../assets/images/PragmaticGames/mighty-kong@2x.png'),
        },
        MONEY_MONEY: {
            '1X': require('../assets/images/PragmaticGames/money-money.png'),
            '2X': require('../assets/images/PragmaticGames/money-money@2x.png'),
        },
        MONEY_MOUSE: {
            '1X': require('../assets/images/PragmaticGames/money-mouse.png'),
            '2X': require('../assets/images/PragmaticGames/money-mouse@2x.png'),
        },
        MONEY_ROLL: {
            '1X': require('../assets/images/PragmaticGames/money-roll.png'),
            '2X': require('../assets/images/PragmaticGames/money-roll@2x.png'),
        },
        MONKEY_MADNESS: {
            '1X': require('../assets/images/PragmaticGames/monkey-madness.png'),
            '2X': require('../assets/images/PragmaticGames/monkey-madness@2x.png'),
        },
        MONKEY_MADNESS_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/monkey-madness-jp.png'),
            '2X': require('../assets/images/PragmaticGames/monkey-madness-jp@2x.png'),
        },
        MONKEY_WARRIOR: {
            '1X': require('../assets/images/PragmaticGames/monkey-warrior.png'),
            '2X': require('../assets/images/PragmaticGames/monkey-warrior@2x.png'),
        },
        MUSTANG_GOLD: {
            '1X': require('../assets/images/PragmaticGames/mustang-gold.png'),
            '2X': require('../assets/images/PragmaticGames/mustang-gold@2x.png'),
        },
        MYSTERIOUS: {
            '1X': require('../assets/images/PragmaticGames/mysterious.png'),
            '2X': require('../assets/images/PragmaticGames/mysterious@2x.png'),
        },
        PANDA_FORTUNE: {
            '1X': require('../assets/images/PragmaticGames/pandas-fortune.png'),
            '2X': require('../assets/images/PragmaticGames/pandas-fortune@2x.png'),
        },
        PIRATE_GOLD_DELUXE: {
            '1X': require('../assets/images/PragmaticGames/pirate-gold.png'),
            '2X': require('../assets/images/PragmaticGames/pirate-gold@2x.png'),
        },
        PIXIE_WINGS: {
            '1X': require('../assets/images/PragmaticGames/pixie-wings.png'),
            '2X': require('../assets/images/PragmaticGames/pixie-wings@2x.png'),
        },
        PYRAMID_KING: {
            '1X': require('../assets/images/PragmaticGames/pyramid-king.png'),
            '2X': require('../assets/images/PragmaticGames/pyramid-king@2x.png'),
        },
        QUEEN_OF_ATLANTIS: {
            '1X': require('../assets/images/PragmaticGames/queen-of-atlantis.png'),
            '2X': require('../assets/images/PragmaticGames/queen-of-atlantis@2x.png'),
        },
        QUEEN_OF_GOLD: {
            '1X': require('../assets/images/PragmaticGames/queen-of-gold.png'),
            '2X': require('../assets/images/PragmaticGames/queen-of-gold@2x.png'),
        },
        RELEASE_THE_KRAKEN: {
            '1X': require('../assets/images/PragmaticGames/release-the-kraken.png'),
            '2X': require('../assets/images/PragmaticGames/release-the-kraken@2x.png'),
        },
        RETURN_OF_THE_DEAD: {
            '1X': require('../assets/images/PragmaticGames/return-of-the-dead.png'),
            '2X': require('../assets/images/PragmaticGames/return-of-the-dead@2x.png'),
        },
        RISE_OF_SAMURAI: {
            '1X': require('../assets/images/PragmaticGames/rise-of-samurai.png'),
            '2X': require('../assets/images/PragmaticGames/rise-of-samurai@2x.png'),
        },
        ROMOE_AND_JULIET: {
            '1X': require('../assets/images/PragmaticGames/romeo-and-juliet.png'),
            '2X': require('../assets/images/PragmaticGames/romeo-and-juliet@2x.png'),
        },
        SABA_FUFUFU: {
            '1X': require('../assets/images/PragmaticGames/saba-fufufu.png'),
            '2X': require('../assets/images/PragmaticGames/saba-fufufu@2x.png'),
        },
        SAFARI_KING: {
            '1X': require('../assets/images/PragmaticGames/safari-king.png'),
            '2X': require('../assets/images/PragmaticGames/safari-king@2x.png'),
        },
        SANTA: {
            '1X': require('../assets/images/PragmaticGames/santa.png'),
            '2X': require('../assets/images/PragmaticGames/santa@2x.png'),
        },
        SEVEN_MONKEY: {
            '1X': require('../assets/images/PragmaticGames/7monkeys.png'),
            '2X': require('../assets/images/PragmaticGames/7monkeys@2x.png'),
        },
        SEVEN_MONKEYS_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/7-monkeys-jp.png'),
            '2X': require('../assets/images/PragmaticGames/7-monkeys-jp@2x.png'),
        },
        SEVEN_PIGGIES: {
            '1X': require('../assets/images/PragmaticGames/7piggies.png'),
            '2X': require('../assets/images/PragmaticGames/7piggies@2x.png'),
        },
        SPARTAN_KING: {
            '1X': require('../assets/images/PragmaticGames/spartan-king.png'),
            '2X': require('../assets/images/PragmaticGames/spartan-king@2x.png'),
        },
        SPOOKY_FORTUNE: {
            '1X': require('../assets/images/PragmaticGames/spooky-fortune.png'),
            '2X': require('../assets/images/PragmaticGames/spooky-fortune@2x.png'),
        },
        STARZ_MEGAWAYS: {
            '1X': require('../assets/images/PragmaticGames/starz-megaways.png'),
            '2X': require('../assets/images/PragmaticGames/starz-megaways@2x.png'),
        },
        STAR_BOUNTY: {
            '1X': require('../assets/images/PragmaticGames/starbounty.png'),
            '2X': require('../assets/images/PragmaticGames/starbounty@2x.png'),
        },
        STREET_RACER: {
            '1X': require('../assets/images/PragmaticGames/street-racer.png'),
            '2X': require('../assets/images/PragmaticGames/street-racer@2x.png'),
        },
        SUGAR_RUSH: {
            '1X': require('../assets/images/PragmaticGames/sugar-rush.png'),
            '2X': require('../assets/images/PragmaticGames/sugar-rush@2x.png'),
        },
        SUGAR_RUSH_SUMMER_TIME: {
            '1X': require('../assets/images/PragmaticGames/sugar-rush-summer-time.png'),
            '2X': require('../assets/images/PragmaticGames/sugar-rush-summer-time@2x.png'),
        },
        SUGAR_RUSH_VALENTINE_DAY: {
            '1X': require('../assets/images/PragmaticGames/sugar-rush-valentines-day.png'),
            '2X': require('../assets/images/PragmaticGames/sugar-rush-valentines-day@2x.png'),
        },
        SUGAR_RUSH_WINTER: {
            '1X': require('../assets/images/PragmaticGames/sugar-rush-winter.png'),
            '2X': require('../assets/images/PragmaticGames/sugar-rush-winter@2x.png'),
        },
        SUPER_7: {
            '1X': require('../assets/images/PragmaticGames/super7s.png'),
            '2X': require('../assets/images/PragmaticGames/super7s@2x.png'),
        },
        SUPER_JOKER: {
            '1X': require('../assets/images/PragmaticGames/super-joker.png'),
            '2X': require('../assets/images/PragmaticGames/super-joker@2x.png'),
        },
        SWEET_BONANZA: {
            '1X': require('../assets/images/PragmaticGames/sweet-bonanza.png'),
            '2X': require('../assets/images/PragmaticGames/sweet-bonanza@2x.png'),
        },
        SWEET_BONANZA_XMAS: {
            '1X': require('../assets/images/PragmaticGames/sweet-bonanza-xms.png'),
            '2X': require('../assets/images/PragmaticGames/sweet-bonanza-xms@2x.png'),
        },
        TALES_OF_EGYPT: {
            '1X': require('../assets/images/PragmaticGames/tales-of-egypt.png'),
            '2X': require('../assets/images/PragmaticGames/tales-of-egypt@2x.png'),
        },
        THE_CATFATHER: {
            '1X': require('../assets/images/PragmaticGames/the-catfather.png'),
            '2X': require('../assets/images/PragmaticGames/the-catfather@2x.png'),
        },

        THE_CATFATHER_2: {
            '1X': require('../assets/images/PragmaticGames/the-catfather-ii.png'),
            '2X': require('../assets/images/PragmaticGames/the-catfather-ii@2x.png'),
        },
        THE_CATFATHER_2_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/the-catfather-part-II-jp.png'),
            '2X': require('../assets/images/PragmaticGames/the-catfather-part-II-jp@2x.png'),
        },
        THE_CHAMPIONS: {
            '1X': require('../assets/images/PragmaticGames/the-champions.png'),
            '2X': require('../assets/images/PragmaticGames/the-champions@2x.png'),
        },
        THE_DOG_HOUSE: {
            '1X': require('../assets/images/PragmaticGames/the-dog-house.png'),
            '2X': require('../assets/images/PragmaticGames/the-dog-house@2x.png'),
        },
        THE_DOG_HOUSE_MEGAWAYS: {
            '1X': require('../assets/images/PragmaticGames/the-dog-house-megaway.png'),
            '2X': require('../assets/images/PragmaticGames/the-dog-house-megaway@2x.png'),
        },
        THE_GREAT_CHIKEN_ESCAPSE: {
            '1X': require('../assets/images/PragmaticGames/the-great-chicken-escape.png'),
            '2X': require('../assets/images/PragmaticGames/the-great-chicken-escape@2x.png'),
        },
        THE_TIGER_WARRIOR: {
            '1X': require('../assets/images/PragmaticGames/the-tiger-warrior.png'),
            '2X': require('../assets/images/PragmaticGames/the-tiger-warrior@2x.png'),
        },
        THE_WILD_MACHINE: {
            '1X': require('../assets/images/PragmaticGames/the-wild-machine.png'),
            '2X': require('../assets/images/PragmaticGames/the-wild-machine@2x.png'),
        },
        THREE_GENIE_WISHES: {
            '1X': require('../assets/images/PragmaticGames/3-genie-wishes.png'),
            '2X': require('../assets/images/PragmaticGames/3-genie-wishes@2x.png'),
        },
        THREE_KINGDOM_BATTLE_OF_RED_CLIFFS: {
            '1X': require('../assets/images/PragmaticGames/3-kingdoms.png'),
            '2X': require('../assets/images/PragmaticGames/3-kingdoms@2x.png'),
        },
        THREE_STAR_FORTUNE: {
            '1X': require('../assets/images/PragmaticGames/three-star-fortune.png'),
            '2X': require('../assets/images/PragmaticGames/three-star-fortune@2x.png'),
        },
        TREASURE_HORSE: {
            '1X': require('../assets/images/PragmaticGames/treasure-horse.png'),
            '2X': require('../assets/images/PragmaticGames/treasure-horse@2x.png'),
        },
        TREE_OF_RICHES: {
            '1X': require('../assets/images/PragmaticGames/tree-of-riches.png'),
            '2X': require('../assets/images/PragmaticGames/tree-of-riches@2x.png'),
        },
        TRIPLE_DRAGON: {
            '1X': require('../assets/images/PragmaticGames/triple-dragons.png'),
            '2X': require('../assets/images/PragmaticGames/triple-dragons@2x.png'),
        },
        TRIPLE_JOKERS: {
            '1X': require('../assets/images/PragmaticGames/triple-jokers.png'),
            '2X': require('../assets/images/PragmaticGames/triple-jokers@2x.png'),
        },
        TRIPLE_TIGERS: {
            '1X': require('../assets/images/PragmaticGames/triple-tigers.png'),
            '2X': require('../assets/images/PragmaticGames/triple-tigers@2x.png'),
        },
        TRI_EIGHT_DRAGONS: {
            '1X': require('../assets/images/PragmaticGames/888-dragon.png'),
            '2X': require('../assets/images/PragmaticGames/888-dragon@2x.png'),
        },
        TRI_EIGHT_DRAGONS_JACKPOT: {
            '1X': require('../assets/images/PragmaticGames/888-dragons-jp.png'),
            '2X': require('../assets/images/PragmaticGames/888-dragons-jp@2x.png'),
        },
        ULTRA_BURN: {
            '1X': require('../assets/images/PragmaticGames/ultra-burn.png'),
            '2X': require('../assets/images/PragmaticGames/ultra-burn@2x.png'),
        },
        ULTRA_HOLD_AND_SPIN: {
            '1X': require('../assets/images/PragmaticGames/ultra-hold-and-spin.png'),
            '2X': require('../assets/images/PragmaticGames/ultra-hold-and-spin@2x.png'),
        },
        VAMPIRES_VS_WOLVES: {
            '1X': require('../assets/images/PragmaticGames/vampires-as-wolves.png'),
            '2X': require('../assets/images/PragmaticGames/vampires-as-wolves@2x.png'),
        },
        VEGAS_MAGIC: {
            '1X': require('../assets/images/PragmaticGames/vegas-magic.png'),
            '2X': require('../assets/images/PragmaticGames/vegas-magic@2x.png'),
        },
        VEGAS_NIGHTS: {
            '1X': require('../assets/images/PragmaticGames/vegas-nights.png'),
            '2X': require('../assets/images/PragmaticGames/vegas-nights@2x.png'),
        },
        WILD_GLADATORS: {
            '1X': require('../assets/images/PragmaticGames/wild-gladiators.png'),
            '2X': require('../assets/images/PragmaticGames/wild-gladiators@2x.png'),
        },
        WILD_SPELLS: {
            '1X': require('../assets/images/PragmaticGames/wild-spells.png'),
            '2X': require('../assets/images/PragmaticGames/wild-spells@2x.png'),
        },
        WILD_WALKER: {
            '1X': require('../assets/images/PragmaticGames/wild-walker.png'),
            '2X': require('../assets/images/PragmaticGames/wild-walker@2x.png'),
        },
        WILD_WEST_GOLD: {
            '1X': require('../assets/images/PragmaticGames/wild-west-gold.png'),
            '2X': require('../assets/images/PragmaticGames/wild-west-gold@2x.png'),
        },
        WOLF_GOLD: {
            '1X': require('../assets/images/PragmaticGames/wolf-gold.png'),
            '2X': require('../assets/images/PragmaticGames/wolf-gold@2x.png'),
        },
    },
    PROFILE_PAGE: {
        AFFILIATE: require('../assets/images/Profile/profile-item-affiliate.png'),
        DEFAULT_PROFILE_IMAGE: require('../assets/images/Profile/default-profile.png'),
        SPONSORSHIP: require('../assets/images/Profile/profile-item-sponsorship.png'),
        TRIANGLE_WARNING: require('../assets/images/Profile/triangle-warning.png'),
        VIP: require('../assets/images/Profile/vip.png'),
    },
    REFERRAL: {
        TRANSACTION_ICON: require('../assets/images/Transaction/referral-payout.png'),
    },
    REWARDS: {
        DAILY_LOGIN_BANNER: {
            CN: require('../assets/images/Rewards/daily-login-event-cn.jpg'),
            ID: require('../assets/images/Rewards/daily-login-event-id.jpg'),
            TH: require('../assets/images/Rewards/daily-login-event-th.jpg'),
            US: require('../assets/images/Rewards/daily-login-event-us.png'),
            VN: require('../assets/images/Rewards/daily-login-event-vi.jpg'),
        },
        TRANSACTION_ICON: require('../assets/images/Transaction/bonus.png'),
    },
    SABA_CASINO: {
        BACCARAT: {
            '1X': require('../assets/images/SabaCasino/baccarat.png'),
            '2X': require('../assets/images/SabaCasino/baccarat@2x.png'),
        },
        BLACKJACK: {
            '1X': require('../assets/images/SabaCasino/blackjack.png'),
            '2X': require('../assets/images/SabaCasino/blackjack@2x.png'),
        },
        BLACKJACK_PERFECT_PAIR: {
            '1X': require('../assets/images/SabaCasino/blackjack-perfect-pair.png'),
            '2X': require('../assets/images/SabaCasino/blackjack-perfect-pair@2x.png'),
        },
        CASINO_HOLDEM: {
            '1X': require('../assets/images/SabaCasino/casino.png'),
            '2X': require('../assets/images/SabaCasino/casino@2x.png'),
        },
        GOD_OF_PROSPERITY: {
            '1X': require('../assets/images/SabaCasino/god-of-prosperity.png'),
            '2X': require('../assets/images/SabaCasino/god-of-prosperity@2x.png'),
        },
        IBCBET_LOGO: require('../assets/images/SabaCasino/ibcbet-logo.png'),
        JUNGLE_WILD: {
            '1X': require('../assets/images/SabaCasino/junglewild.png'),
            '2X': require('../assets/images/SabaCasino/junglewild@2x.png'),
        },
        LOGO: require('../assets/images/SabaCasino/logo.png'),
        MAGICIAN: {
            '1X': require('../assets/images/SabaCasino/magician.png'),
            '2X': require('../assets/images/SabaCasino/magician@2x.png'),
        },
        ORIENTAL_BEAUTY: {
            '1X': require('../assets/images/SabaCasino/orientalbeauty.png'),
            '2X': require('../assets/images/SabaCasino/orientalbeauty@2x.png'),
        },
        ROULETTE: {
            '1X': require('../assets/images/SabaCasino/roulette.png'),
            '2X': require('../assets/images/SabaCasino/roulette@2x.png'),
        },
        SICBO: {
            '1X': require('../assets/images/SabaCasino/sicbo.png'),
            '2X': require('../assets/images/SabaCasino/sicbo@2x.png'),
        },
        TALES_OF_BROTHEL: {
            '1X': require('../assets/images/SabaCasino/talesofbrothel.png'),
            '2X': require('../assets/images/SabaCasino/talesofbrothel@2x.png'),
        },
        TEXAS: {
            '1X': require('../assets/images/SabaCasino/texas.png'),
            '2X': require('../assets/images/SabaCasino/texas@2x.png'),
        },
        TRICARD_POKER: {
            '1X': require('../assets/images/SabaCasino/tri-card-poker.png'),
            '2X': require('../assets/images/SabaCasino/tri-card-poker@2x.png'),
        },
    },
    SIDEBAR: {
        NOTIFICATIONS: require('../assets/images/SideBar/notifications.png'),
    },
    SLOT_88: {
        FOREGROUND: require('../assets/images/Slot88/slot88-foreground.png'),
        LOGO: {
            CN: require('../assets/images/Slot88/slot88-logo.png'),
            US: require('../assets/images/Slot88/slot88-logo.png'),
        },
        LOGO_SMALL: require('../assets/images/Slot88/slot88-logo-small.png'),
    },
    SPADE_GAMING: {
        ALIBABA: {
            '1X': require('../assets/images/SpadeGaming/alibaba.png'),
            '2X': require('../assets/images/SpadeGaming/alibaba@2x.png'),
        },
        BABY_CAI_SHEN: {
            '1X': require('../assets/images/SpadeGaming/Baby Cai Shen.png'),
            '2X': require('../assets/images/SpadeGaming/Baby Cai Shen@2x.png'),
        },
        BIG_PROSPERITY_SA: {
            '1X': require('../assets/images/SpadeGaming/Big Prosperity SA.png'),
            '2X': require('../assets/images/SpadeGaming/Big Prosperity SA@2x.png'),
        },
        BROTHERS_KINGDOM: {
            '1X': require('../assets/images/SpadeGaming/brotherkingdom.png'),
            '2X': require('../assets/images/SpadeGaming/brotherkingdom@2x.png'),
        },
        CAI_SHEN_888: {
            '1X': require('../assets/images/SpadeGaming/cai-shen-888.png'),
            '2X': require('../assets/images/SpadeGaming/cai-shen-888@2x.png'),
        },
        CAI_YUAN_GUANG_JIN: {
            '1X': require('../assets/images/SpadeGaming/cai-yuan-guang-jin.png'),
            '2X': require('../assets/images/SpadeGaming/cai-yuan-guang-jin@2x.png'),
        },
        DADDY_VACATION: {
            '1X': require('../assets/images/SpadeGaming/daddys-vacation.png'),
            '2X': require('../assets/images/SpadeGaming/daddys-vacation@2x.png'),
        },
        DANCING_FEVER: {
            '1X': require('../assets/images/SpadeGaming/dancingfever.png'),
            '2X': require('../assets/images/SpadeGaming/dancingfever@2x.png'),
        },
        DA_FU_XIAO_FU: {
            '1X': require('../assets/images/SpadeGaming/da-fu-xiao-fu.png'),
            '2X': require('../assets/images/SpadeGaming/da-fu-xiao-fu@2x.png'),
        },
        DOUBLE_FLAME: {
            '1X': require('../assets/images/SpadeGaming/double-flame.jpg'),
            '2X': require('../assets/images/SpadeGaming/double-flame@2x.jpg'),
        },
        DOUBLE_FORTUNES: {
            '1X': require('../assets/images/SpadeGaming/Double Fortunes.png'),
            '2X': require('../assets/images/SpadeGaming/Double Fortunes@2x.png'),
        },
        DRAGON_GOLD_SA: {
            '1X': require('../assets/images/SpadeGaming/Dragon Gold SA.png'),
            '2X': require('../assets/images/SpadeGaming/Dragon Gold SA@2x.png'),
        },
        DRUNKEN_JUNGLE: {
            '1X': require('../assets/images/SpadeGaming/druken-jungle.png'),
            '2X': require('../assets/images/SpadeGaming/druken-jungle@2x.png'),
        },
        EMPEROR_GATE_SA: {
            '1X': require('../assets/images/SpadeGaming/emperor-gate-sa.jpg'),
            '2X': require('../assets/images/SpadeGaming/emperor-gate-sa@2x.jpg'),
        },
        FAFAFA: {
            '1X': require('../assets/images/SpadeGaming/fafafa.png'),
            '2X': require('../assets/images/SpadeGaming/fafafa@2x.png'),
        },
        FAFAFA_2: {
            '1X': require('../assets/images/SpadeGaming/fafafa2.png'),
            '2X': require('../assets/images/SpadeGaming/fafafa2@2x.png'),
        },
        FESTIVE_LION: {
            '1X': require('../assets/images/SpadeGaming/festive-lion.jpg'),
            '2X': require('../assets/images/SpadeGaming/festive-lion@2x.jpg'),
        },
        FIVE_FORTUNE_DRAGON: {
            '1X': require('../assets/images/SpadeGaming/5-fortune-dragons.png'),
            '2X': require('../assets/images/SpadeGaming/5-fortune-dragons@2x.png'),
        },
        FIVE_FORTUNE_SA: {
            '1X': require('../assets/images/SpadeGaming/five-fortune-sa.jpg'),
            '2X': require('../assets/images/SpadeGaming/five-fortune-sa@2x.jpg'),
        },
        GANGSTER_AXE: {
            '1X': require('../assets/images/SpadeGaming/gangster-axe.jpg'),
            '2X': require('../assets/images/SpadeGaming/gangster-axe@2x.jpg'),
        },
        GODS_KITCHEN: require('../assets/images/SpadeGaming/gods-kitchen.png'),
        GOLDEN_CHICKEN: require('../assets/images/SpadeGaming/golden-chicken.png'),
        GOLDEN_MONKEY: {
            '1X': require('../assets/images/SpadeGaming/golden-monkey.png'),
            '2X': require('../assets/images/SpadeGaming/golden-monkey@2x.png'),
        },
        GOLDEN_WHALE: require('../assets/images/SpadeGaming/golden-whale.png'),
        GOLD_PANTHER: {
            '1X': require('../assets/images/SpadeGaming/gold-panther.png'),
            '2X': require('../assets/images/SpadeGaming/gold-panther@2x.png'),
        },
        GOLF_CHAMPIONS: require('../assets/images/SpadeGaming/golf-champions.png'),
        GREAT_STARS_SA: {
            '1X': require('../assets/images/SpadeGaming/great-stars-sa.jpg'),
            '2X': require('../assets/images/SpadeGaming/great-stars-sa@2x.jpg'),
        },
        HIGHWAY_FORTUNE: {
            '1X': require('../assets/images/SpadeGaming/highway_fortune.png'),
            '2X': require('../assets/images/SpadeGaming/highway_fortune@2x.png'),
        },
        HONEY_HUNTER: {
            '1X': require('../assets/images/SpadeGaming/honey-hunter.png'),
            '2X': require('../assets/images/SpadeGaming/honey-hunter@2x.png'),
        },
        HO_YEAH_MONKEY: {
            '1X': require('../assets/images/SpadeGaming/Ho-yeah-monkey.png'),
            '2X': require('../assets/images/SpadeGaming/Ho-yeah-monkey@2x.png'),
        },
        ICELAND_SA: {
            '1X': require('../assets/images/SpadeGaming/iceland-sa.jpg'),
            '2X': require('../assets/images/SpadeGaming/iceland-sa@2x.jpg'),
        },
        JUNGLE_KING: {
            '1X': require('../assets/images/SpadeGaming/jungle-king.png'),
            '2X': require('../assets/images/SpadeGaming/jungle-king@2x.png'),
        },
        KING_PHARAOH: {
            '1X': require('../assets/images/SpadeGaming/king-pharaoh.png'),
            '2X': require('../assets/images/SpadeGaming/king-pharaoh@2x.png'),
        },
        LOGO: require('../assets/images/SpadeGaming/logo.png'),
        LUCKY_CAI_SHEN: {
            '1X': require('../assets/images/SpadeGaming/lucky-cai-shen.png'),
            '2X': require('../assets/images/SpadeGaming/lucky-cai-shen@2x.png'),
        },
        LUCKY_FENG_SHUI: require('../assets/images/SpadeGaming/lucky-feng-shui.png'),
        LUCKY_KOI: {
            '1X': require('../assets/images/SpadeGaming/lucky-koi.png'),
            '2X': require('../assets/images/SpadeGaming/lucky-koi@2x.png'),
        },
        LUCKY_MEOW: require('../assets/images/SpadeGaming/lucky-meow.png'),
        MAGICAL_LAMP: {
            '1X': require('../assets/images/SpadeGaming/magicallamp.png'),
            '2X': require('../assets/images/SpadeGaming/magicallamp@2x.png'),
        },
        MASTER_CHEF: require('../assets/images/SpadeGaming/master-chef.png'),
        MERMAID: {
            '1X': require('../assets/images/SpadeGaming/Mermaid.png'),
            '2X': require('../assets/images/SpadeGaming/Mermaid@2x.png'),
        },
        MONEY_MOUSE: {
            '1X': require('../assets/images/SpadeGaming/moneymouse.png'),
            '2X': require('../assets/images/SpadeGaming/moneymouse@2x.png'),
        },
        MR_CHU_TYCOON: {
            '1X': require('../assets/images/SpadeGaming/Mr Chu Tycoon.png'),
            '2X': require('../assets/images/SpadeGaming/Mr Chu Tycoon@2x.png'),
        },
        PAN_FAIRY: {
            '1X': require('../assets/images/SpadeGaming/pan-fairy.jpg'),
            '2X': require('../assets/images/SpadeGaming/pan-fairy@2x.jpg'),
        },
        PIRATE_KING: {
            '1X': require('../assets/images/SpadeGaming/pirate-king.png'),
            '2X': require('../assets/images/SpadeGaming/pirate-king@2x.png'),
        },
        PRINCESS_WANG: {
            '1X': require('../assets/images/SpadeGaming/princess-wang.png'),
            '2X': require('../assets/images/SpadeGaming/princess-wang@2x.png'),
        },
        PROSPERITY_GODS: {
            '1X': require('../assets/images/SpadeGaming/Prosperity Gods.png'),
            '2X': require('../assets/images/SpadeGaming/Prosperity Gods@2x.png'),
        },
        SANTA_GIFTS: require('../assets/images/SpadeGaming/santa-gifts.png'),
        SEA_EMPEROR: {
            '1X': require('../assets/images/SpadeGaming/Sea Emperor.png'),
            '2X': require('../assets/images/SpadeGaming/Sea Emperor@2x.png'),
        },
        SHANG_HAI: {
            '1X': require('../assets/images/SpadeGaming/shanghai-008.jpg'),
            '2X': require('../assets/images/SpadeGaming/shanghai-008@2x.jpg'),
        },
        SPIN_STONE: require('../assets/images/SpadeGaming/spins-stone.png'),
        THREE_LUCKY_STARS: {
            '1X': require('../assets/images/SpadeGaming/three-lucky-stars.png'),
            '2X': require('../assets/images/SpadeGaming/three-lucky-stars@2x.png'),
        },
        TIGER_WARRIOR: {
            '1X': require('../assets/images/SpadeGaming/Tiger Warrior.png'),
            '2X': require('../assets/images/SpadeGaming/Tiger Warrior@2x.png'),
        },
        TRIPLE_EIGHT: {
            '1X': require('../assets/images/SpadeGaming/888.png'),
            '2X': require('../assets/images/SpadeGaming/888@2x.png'),
        },
        TRIPLE_PANDA: {
            '1X': require('../assets/images/SpadeGaming/triplepanda.png'),
            '2X': require('../assets/images/SpadeGaming/triplepanda@2x.png'),
        },
        WONG_CHOY_SA: {
            '1X': require('../assets/images/SpadeGaming/wong-choy-sa.jpg'),
            '2X': require('../assets/images/SpadeGaming/wong-choy-sa@2x.jpg'),
        },
        WOW_PROSPERITY: {
            '1X': require('../assets/images/SpadeGaming/Wow Prosperity.png'),
            '2X': require('../assets/images/SpadeGaming/Wow Prosperity@2x.png'),
        },
        ZEUS: {
            '1X': require('../assets/images/SpadeGaming/zeus.png'),
            '2X': require('../assets/images/SpadeGaming/zeus@2x.png'),
        },
    },
    SPONSORS: {
        ASTER: {
            ABOUT_US: require('../assets/images/Sponsors/aster/about-us.jpg'),
            IFRAME_THUMBNAIL_1: require('../assets/images/Sponsors/aster/iframe-thumbnail-1.jpg'),
            IFRAME_THUMBNAIL_2: require('../assets/images/Sponsors/aster/iframe-thumbnail-2.jpg'),
            IFRAME_THUMBNAIL_3: require('../assets/images/Sponsors/aster/iframe-thumbnail-3.png'),
            IFRAME_THUMBNAIL_4: require('../assets/images/Sponsors/aster/iframe-thumbnail-4.png'),
            LOGO: require('../assets/images/Sponsors/aster/logo.png'),
        },
        CHAMPION_ICON: require('../assets/images/Sponsors/champion-icon.png'),
        CN: {
            DESKTOP: {
                ASTER: require('../assets/images/Sponsors/aster-cn-desktop.jpg'),
                GEEK: require('../assets/images/Sponsors/geekfam-cn-desktop.jpg'),
                TRITON: require('../assets/images/Sponsors/triton-cn.jpg'),
            },
            MOBILE: {
                ASTER: require('../assets/images/Sponsors/aster-cn-mobile.jpg'),
                GEEK: require('../assets/images/Sponsors/geekfam-cn-mobile.jpg'),
                TRITON: require('../assets/images/Sponsors/triton-cn.jpg'),
            },
        },
        GEEKFAM: {
            ABOUT_US: require('../assets/images/Sponsors/geekfam/about-us.jpg'),
            IFRAME_THUMBNAIL_1: require('../assets/images/Sponsors/geekfam/iframe-thumbnail-1.jpg'),
            IFRAME_THUMBNAIL_2: require('../assets/images/Sponsors/geekfam/iframe-thumbnail-2.jpg'),
            IFRAME_THUMBNAIL_3: require('../assets/images/Sponsors/geekfam/iframe-thumbnail-3.jpg'),
            IFRAME_THUMBNAIL_4: require('../assets/images/Sponsors/geekfam/iframe-thumbnail-4.jpg'),
            LOGO: require('../assets/images/Sponsors/geekfam/logo.png'),
        },
        PLAY_BUTTON: require('../assets/images/Sponsors/play-button.png'),
        TRITON: {
            ABOUT_US: require('../assets/images/Sponsors/triton/about-us.jpg'),
            GALLERY: {
                ANDREW_ROBL: require('../assets/images/Sponsors/triton/andrew-robl.jpeg'),
                BRYN_KENNY: require('../assets/images/Sponsors/triton/bryn-kenny.jpeg'),
                CARY_KATZ: require('../assets/images/Sponsors/triton/cary-katz.jpeg'),
                DANIEL_CATES: require('../assets/images/Sponsors/triton/daniel-cates.jpg'),
                IGOR_KURGANOV: require('../assets/images/Sponsors/triton/igor-kurganov.jpg'),
                ISSAC_HAXTON: require('../assets/images/Sponsors/triton/issac-haxton.jpeg'),
                JASON_KOON: require('../assets/images/Sponsors/triton/jason-koon.jpeg'),
                JUSTIN_BONOMO: require('../assets/images/Sponsors/triton/justin-bonomo.jpeg'),
                MICHEAL_SOYZA: require('../assets/images/Sponsors/triton/micheal-soyza.jpeg'),
                MIKITA_BADZ: require('../assets/images/Sponsors/triton/mikita-badz.jpeg'),
                PAUL_PHUA: require('../assets/images/Sponsors/triton/paul-phua.jpeg'),
                PETER_JETTEN: require('../assets/images/Sponsors/triton/peter-jetten.jpeg'),
                PHIL_IVEY: require('../assets/images/Sponsors/triton/phil-ivey.jpg'),
                RICHARD_YONG: require('../assets/images/Sponsors/triton/richard-yong.jpeg'),
                STEPHEN_CHIDWICK: require('../assets/images/Sponsors/triton/stephen-chidwick.jpeg'),
                TOM_DWAN: require('../assets/images/Sponsors/triton/tom-dwan.jpg'),
            },
            IFRAME_THUMBNAIL: require('../assets/images/Sponsors/triton/iframe-thumbnail.png'),
            LOGO: require('../assets/images/Sponsors/triton/logo.png'),
        },
        US: {
            DESKTOP: {
                ASTER: require('../assets/images/Sponsors/aster-us-desktop.jpg'),
                GEEK: require('../assets/images/Sponsors/geekfam-us-desktop.jpg'),
                TRITON: require('../assets/images/Sponsors/triton-us.jpg'),
            },
            MOBILE: {
                ASTER: require('../assets/images/Sponsors/aster-us-mobile.jpg'),
                GEEK: require('../assets/images/Sponsors/geekfam-us-mobile.jpg'),
                TRITON: require('../assets/images/Sponsors/triton-us.jpg'),
            },
        },
    },
    SPORT_GAMES: {
        BADMINTON: {
            '1X': require('../assets/images/games/sport-games/badminton.png'),
            '2X': require('../assets/images/games/sport-games/badminton@2x.png'),
        },
        BASEBALL: {
            '1X': require('../assets/images/games/sport-games/baseball.png'),
            '2X': require('../assets/images/games/sport-games/baseball@2x.png'),
        },
        BASKETBALL: {
            '1X': require('../assets/images/games/sport-games/basketball.png'),
            '2X': require('../assets/images/games/sport-games/basketball@2x.png'),
        },
        BOXING: {
            '1X': require('../assets/images/games/sport-games/boxing.png'),
            '2X': require('../assets/images/games/sport-games/boxing@2x.png'),
        },
        CRICKET: {
            '1X': require('../assets/images/games/sport-games/cricket.png'),
            '2X': require('../assets/images/games/sport-games/cricket@2x.png'),
        },
        FOOTBALL: {
            '1X': require('../assets/images/games/sport-games/football.png'),
            '2X': require('../assets/images/games/sport-games/football@2x.png'),
        },
        FORMULA1: {
            '1X': require('../assets/images/games/sport-games/formula1.png'),
            '2X': require('../assets/images/games/sport-games/formula1@2x.png'),
        },
        GOLF: {
            '1X': require('../assets/images/games/sport-games/golf.png'),
            '2X': require('../assets/images/games/sport-games/golf@2x.png'),
        },
        ICE_HOCKEY: {
            '1X': require('../assets/images/games/sport-games/ice-hockey.png'),
            '2X': require('../assets/images/games/sport-games/ice-hockey@2x.png'),
        },
        MORE_GAMES: {
            '1X': require('../assets/images/games/sport-games/more-games.png'),
            '2X': require('../assets/images/games/sport-games/more-games@2x.png'),
        },
        SNOOKER: {
            '1X': require('../assets/images/games/sport-games/snooker.png'),
            '2X': require('../assets/images/games/sport-games/snooker@2x.png'),
        },
        SOCCER: {
            '1X': require('../assets/images/games/sport-games/soccer.png'),
            '2X': require('../assets/images/games/sport-games/soccer@2x.png'),
        },
        TABLE_TENNIS: {
            '1X': require('../assets/images/games/sport-games/table-tennis.png'),
            '2X': require('../assets/images/games/sport-games/table-tennis@2x.png'),
        },
        TENNIS: {
            '1X': require('../assets/images/games/sport-games/tennis.png'),
            '2X': require('../assets/images/games/sport-games/tennis@2x.png'),
        },
        VOLLEYBALL: {
            '1X': require('../assets/images/games/sport-games/volleyball.png'),
            '2X': require('../assets/images/games/sport-games/volleyball@2x.png'),
        },
    },
    STATS: {
        ARROW_DOWN: require('../assets/images/Stats/arrowdown.png'),
        BARRACKS: require('../assets/images/Stats/barracks.png'),
        CSGO: {
            BLUE_OVERLAY: require('../assets/images/Stats/csgo/blue-overlay.svg'),
            DEFUSER: require('../assets/images/Stats/csgo/defuser.svg'),
            DEFUSER_BIG: require('../assets/images/Stats/csgo/defuser-big.svg'),
            EMPTY: require('../assets/images/Stats/csgo/empty-placeholder.svg'),
            EXPLODE: require('../assets/images/Stats/csgo/explode.svg'),
            HELMET: require('../assets/images/Stats/csgo/helmet.svg'),
            SKULL: require('../assets/images/Stats/csgo/skull.svg'),
        },
        DATE: require('../assets/images/Stats/date.png'),
        DOTA_MAP: require('../assets/images/Stats/dota_map.jpg'),
        GREEN_ARROW: require('../assets/images/Stats/green-arrow.png'),
        HEAD_TO_HEAD: {
            ARROW_RIGHT: require('../assets/images/Stats/head-to-head/arrow-right.png'),
            DRAFT: {
                CSGO: {
                    MAP_BG: {
                        DUST2: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/dust2.png'),
                        INFERNO: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/inferno.png'),
                        MIRAGE: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/mirage.png'),
                        NUKE: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/nuke.png'),
                        OVERPASS: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/overpass.png'),
                        TRAIN: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/train.png'),
                        VERTIGO: require('../assets/images/Stats/head-to-head/draft/Csgo/map-bg/vertigo.png'),
                    },
                    MAP_ICON: {
                        DUST2: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/dust2.png'),
                        INFERNO: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/inferno.png'),
                        MIRAGE: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/mirage.png'),
                        NUKE: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/nuke.png'),
                        OVERPASS: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/overpass.png'),
                        TRAIN: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/train.png'),
                        VERTIGO: require('../assets/images/Stats/head-to-head/draft/Csgo/map-icon/vertigo.png'),
                    },
                    MATCH_COUNT_BG: require('../assets/images/Stats/head-to-head/draft/Csgo/match-count-bg.png'),
                    PISTOL: require('../assets/images/Stats/head-to-head/draft/Csgo/pistol.png'),
                    TOP_1_CROWN: require('../assets/images/Stats/head-to-head/draft/Csgo/top-1-crown.png'),
                    TOP_2_CROWN: require('../assets/images/Stats/head-to-head/draft/Csgo/top-2-crown.png'),
                    TOP_3_CROWN: require('../assets/images/Stats/head-to-head/draft/Csgo/top-3-crown.png'),
                },
            },
            PICKED: require('../assets/images/Stats/head-to-head/picked.png'),
            STATISTICS: {
                AVERAGE_KILL: require('../assets/images/Stats/head-to-head/statistics/average-kill.png'),
                CSGO: {
                    FIRST_ROUND_PISTOL: require('../assets/images/Stats/head-to-head/statistics/Csgo/first-round-pistol.png'),
                    FIRST_TO_FIVE: require('../assets/images/Stats/head-to-head/statistics/Csgo/first-to-five.png'),
                    PISTOL_ROUND_WIN: require('../assets/images/Stats/head-to-head/statistics/Csgo/pistol-round-win.png'),
                    SIXTEENTH_ROUND_PISTOL: require('../assets/images/Stats/head-to-head/statistics/Csgo/sixteenth-round-pistol.png'),
                },
                DOTA: {
                    AVERAGE_BARRACK: require('../assets/images/Stats/head-to-head/statistics/Dota/avg-barrack.png'),
                    AVERAGE_DURATION: require('../assets/images/Stats/head-to-head/statistics/Dota/avg-duration.png'),
                    AVERAGE_ROSHAN: require('../assets/images/Stats/head-to-head/statistics/Dota/avg-roshan.png'),
                    AVERAGE_TOWER: require('../assets/images/Stats/head-to-head/statistics/Dota/avg-tower.png'),
                    FIRST_BARRACK: require('../assets/images/Stats/head-to-head/statistics/Dota/first-barrack.png'),
                    FIRST_ROSHAN: require('../assets/images/Stats/head-to-head/statistics/Dota/first-roshan.png'),
                    FIRST_TEN_KILL: require('../assets/images/Stats/head-to-head/statistics/Dota/first-10-kill.png'),
                    FIRST_TOWER: require('../assets/images/Stats/head-to-head/statistics/Dota/first-tower.png'),
                },
                DOWN: require('../assets/images/Stats/head-to-head/statistics/down.png'),
                FIRST_BLOOD: require('../assets/images/Stats/head-to-head/statistics/first-blood.png'),
                GENERAL_WIN_RATE: require('../assets/images/Stats/head-to-head/statistics/general-win-rate.png'),
                HEAD_2_HEAD_VS: require('../assets/images/Stats/head-to-head/statistics/head-2-head-vs.png'),
                LOL: {
                    AVERAGE_BARON: require('../assets/images/Stats/head-to-head/statistics/Lol/avg-baron.png'),
                    AVERAGE_DRAGON: require('../assets/images/Stats/head-to-head/statistics/Lol/avg-dragon.png'),
                    AVERAGE_INHIBITOR: require('../assets/images/Stats/head-to-head/statistics/Lol/avg-inhibitor.png'),
                    AVERAGE_TURRET: require('../assets/images/Stats/head-to-head/statistics/Lol/avg-turret.png'),
                    FIRST_BARON: require('../assets/images/Stats/head-to-head/statistics/Lol/first-baron.png'),
                    FIRST_DRAGON: require('../assets/images/Stats/head-to-head/statistics/Lol/first-dragon.png'),
                    FIRST_FIVE_KILL: require('../assets/images/Stats/head-to-head/statistics/Lol/first-5-kill.png'),
                    FIRST_INHIBITOR: require('../assets/images/Stats/head-to-head/statistics/Lol/first-inhibitor.png'),
                    FIRST_TURRET: require('../assets/images/Stats/head-to-head/statistics/Lol/first-turret.png'),
                },
                MATCH_WON: require('../assets/images/Stats/head-to-head/statistics/match-won.png'),
                TOTAL_MATCH: require('../assets/images/Stats/head-to-head/statistics/total-match.png'),
                UP: require('../assets/images/Stats/head-to-head/statistics/up.png'),
                WIN_RATE: require('../assets/images/Stats/head-to-head/statistics/win-rate.png'),
                WIN_STREAK: require('../assets/images/Stats/head-to-head/statistics/win-streak.png'),
                WORLD_RANK: require('../assets/images/Stats/head-to-head/statistics/world-rank.png'),
            },
            TAB_ICON: {
                DRAFT: require('../assets/images/Stats/head-to-head/tab-ico-draft.png'),
                OVERVIEW: require('../assets/images/Stats/head-to-head/tab-ico-overview.png'),
                STATISTICS: require('../assets/images/Stats/head-to-head/tab-ico-statistic.png'),
            },
            TEST_COUNTRY: require('../assets/images/Stats/head-to-head/test-country.png'),
            TEST_HERO: require('../assets/images/Stats/head-to-head/test-hero.png'),
            TEST_PROFILE: require('../assets/images/Stats/head-to-head/test-profile.png'),
            TEST_TEAM: require('../assets/images/Stats/head-to-head/test-team.png'),
            WIN_RATE: require('../assets/images/Stats/head-to-head/win-rate.png'),
        },
        HERO_TEST: require('../assets/images/Stats/hero_image.png'),
        LOL_MAP: require('../assets/images/Stats/lol_map.png'),
        RED_ARROW: require('../assets/images/Stats/red-arrow.png'),
        ROSHAN: require('../assets/images/Stats/roshan.png'),
        SEARCH: require('../assets/images/Stats/search_icon.png'),
        SEARCH_ICON_MAIN_PAGE: require('../assets/images/Stats/main-search-icon.svg'),
        SWORDS: require('../assets/images/Stats/swords.png'),
        THICK: require('../assets/images/Stats/thick_icon.png'),
        TOWER: require('../assets/images/Stats/tower.png'),
    },
    STREAM_PLAY_BUTTON: require('../assets/images/Markets/stream-play.png'),
    VIP: {
        GRAY_CHECKMARK: require('../assets/images/Vip/checkmark-icon.png'),
        GREEN_CHECKMARK: require('../assets/images/Vip/checkmark-green.png'),
        LOCK_ICON: require('../assets/images/Vip/lock-icon.png'),
        LOCK_ICON_GREEN: require('../assets/images/Vip/lock-icon-green.png'),
        LUXURY_GIFT: {
            APPLE_WATCH: require('../assets/images/Vip/luxury-gift/apple-watch.png'),
            CASH: require('../assets/images/Vip/luxury-gift/cash.png'),
            IMAC: require('../assets/images/Vip/luxury-gift/imac.png'),
            IPAD_PRO: require('../assets/images/Vip/luxury-gift/ipad-pro.png'),
            PANERAI_LUMINOR: require('../assets/images/Vip/luxury-gift/panerai-luminor.png'),
        },
    },
    VIRTUAL_SPORTS: {
        BASKETBALL: {
            '1X': require('../assets/images/games/virtual-sports/virtual-basketball.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-basketball@2x.png'),
        },
        CYCLING: {
            '1X': require('../assets/images/games/virtual-sports/virtual-cycling.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-cycling@2x.png'),
        },
        GREYHOUND: {
            '1X': require('../assets/images/games/virtual-sports/virtual-greyhounds.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-greyhounds@2x.png'),
        },
        HORSE_RACING: {
            '1X': require('../assets/images/games/virtual-sports/virtual-horse-racing.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-horse-racing@2x.png'),
        },
        MOTORSPORT: {
            '1X': require('../assets/images/games/virtual-sports/virtual-motorsports.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-motorsports@2x.png'),
        },
        SOCCER: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer@2x.png'),
        },
        SOCCER_ASIAN_CUP: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer-asian-cup.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer-asian-cup@2x.png'),
        },
        SOCCER_CHAMPIONS_CUP: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer-champions-cup.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer-champions-cup@2x.png'),
        },
        SOCCER_EURO_CUP: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer-euro-cup.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer-euro-cup@2x.png'),
        },
        SOCCER_LEAGUE: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer-league.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer-league@2x.png'),
        },
        SOCCER_NATION: {
            '1X': require('../assets/images/games/virtual-sports/virtual-soccer-nation.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-soccer-nation@2x.png'),
        },
        SPEEDWAY: {
            '1X': require('../assets/images/games/virtual-sports/virtual-speedway.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-speedway@2x.png'),
        },
        TENNIS: {
            '1X': require('../assets/images/games/virtual-sports/virtual-tennis.png'),
            '2X': require('../assets/images/games/virtual-sports/virtual-tennis@2x.png'),
        },
    },
    VOID_BRIDGE: {
        BIG_HAUL: {
            '1X': require('../assets/images/VoidBridge/big-haul.png'),
            '2X': require('../assets/images/VoidBridge/big-haul@2x.png'),
        },
        BUFFALO_RASING_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/buffalo-rising-megaways.png'),
            '2X': require('../assets/images/VoidBridge/buffalo-rising-megaways@2x.png'),
        },
        BUSHIDO_CODE: {
            '1X': require('../assets/images/VoidBridge/bushido-code.png'),
            '2X': require('../assets/images/VoidBridge/bushido-code@2x.png'),
        },
        BUSY_BEES: {
            '1X': require('../assets/images/VoidBridge/busy-bees.png'),
            '2X': require('../assets/images/VoidBridge/busy-bees@2x.png'),
        },
        CAISHENS_QUEST: {
            '1X': require('../assets/images/VoidBridge/caishens-quest.png'),
            '2X': require('../assets/images/VoidBridge/caishens-quest@2x.png'),
        },
        CATCH_THE_WAVES: {
            '1X': require('../assets/images/VoidBridge/catch-the-waves.png'),
            '2X': require('../assets/images/VoidBridge/catch-the-waves@2x.png'),
        },
        CRYSTAL_CANDIES: require('../assets/images/VoidBridge/crystal-candies.png'),
        Crystal_Tiger: require('../assets/images/VoidBridge/CrystalTiger.png'),
        DANCING_LION: {
            '1X': require('../assets/images/VoidBridge/dancing-lions.png'),
            '2X': require('../assets/images/VoidBridge/dancing-lions@2x.png'),
        },
        DAZZLING_HOT: {
            '1X': require('../assets/images/VoidBridge/dazzling-hot.png'),
            '2X': require('../assets/images/VoidBridge/dazzling-hot@2x.png'),
        },
        DIAMOND_MINE_EXTRA: {
            '1X': require('../assets/images/VoidBridge/diamond-mine-extra.png'),
            '2X': require('../assets/images/VoidBridge/diamond-mine-extra@2x.png'),
        },

        DIAMOND_MINE_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/diamond-mine.png'),
            '2X': require('../assets/images/VoidBridge/diamond-mine@2x.png'),
        },
        DOUBLE_TRIPLE_8: {
            '1X': require('../assets/images/VoidBridge/double-triple-8.png'),
            '2X': require('../assets/images/VoidBridge/double-triple-8@2x.png'),
        },
        DRAGONFALL: {
            '1X': require('../assets/images/VoidBridge/dragonfall.png'),
            '2X': require('../assets/images/VoidBridge/dragonfall@2x.png'),
        },
        DRAGONS_WEALTH: require('../assets/images/VoidBridge/dragons-wealth.png'),
        DRAGON_AND_PEARLS: {
            '1X': require('../assets/images/VoidBridge/dragons-and-pearls.png'),
            '2X': require('../assets/images/VoidBridge/dragons-and-pearls@2x.png'),
        },
        DRAGON_KING: {
            '1X': require('../assets/images/VoidBridge/dragon-king.png'),
            '2X': require('../assets/images/VoidBridge/dragon-king@2x.png'),
        },
        FISH_O_MANIA: {
            '1X': require('../assets/images/VoidBridge/fish-o-mania.png'),
            '2X': require('../assets/images/VoidBridge/fish-o-mania@2x.png'),
        },
        FORTUNE_PANDA: {
            '1X': require('../assets/images/VoidBridge/fortune-panda.png'),
            '2X': require('../assets/images/VoidBridge/fortune-panda@2x.png'),
        },
        FUNNY_MONKEY: {
            '1X': require('../assets/images/VoidBridge/funny-monkey.png'),
            '2X': require('../assets/images/VoidBridge/funny-monkey@2x.png'),
        },
        GENIE_JACKPOT_WISHMAKER: require('../assets/images/VoidBridge/genie-jackpot-wishmaker.png'),
        GODS_OF_FORTUNE: {
            '1X': require('../assets/images/VoidBridge/gods-of-fortune.png'),
            '2X': require('../assets/images/VoidBridge/gods-of-fortune@2x.png'),
        },
        GODS_OF_OLYMPUS_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/gods-of olympus.png'),
            '2X': require('../assets/images/VoidBridge/gods-of olympus@2x.png'),
        },
        GOD_OF_FORTUNE_DELUXE: {
            '1X': require('../assets/images/VoidBridge/gods-of-fortune-deluxe.png'),
            '2X': require('../assets/images/VoidBridge/gods-of-fortune-deluxe@2x.png'),
        },
        GOD_OF_WEALTH: {
            '1X': require('../assets/images/VoidBridge/god-of-wealth.png'),
            '2X': require('../assets/images/VoidBridge/god-of-wealth@2x.png'),
        },
        GOLDBEARDS_TREASURE: {
            '1X': require('../assets/images/VoidBridge/goldbeards-treasure.png'),
            '2X': require('../assets/images/VoidBridge/goldbeards-treasure@2x.png'),
        },
        GOLDEN_KOI: {
            '1X': require('../assets/images/VoidBridge/golden-koi.png'),
            '2X': require('../assets/images/VoidBridge/golden-koi@2x.png'),
        },
        GOLD_88: {
            '1X': require('../assets/images/VoidBridge/gold-88.png'),
            '2X': require('../assets/images/VoidBridge/gold-88@2x.png'),
        },
        GOLD_DIAMONDS: {
            '1X': require('../assets/images/VoidBridge/gold-diamonds.png'),
            '2X': require('../assets/images/VoidBridge/gold-diamonds@2x.png'),
        },
        GOLD_OF_RA: {
            '1X': require('../assets/images/VoidBridge/gold-of-ra.png'),
            '2X': require('../assets/images/VoidBridge/gold-of-ra@2x.png'),
        },
        GOLF_TOUR: {
            '1X': require('../assets/images/VoidBridge/golf-tour.png'),
            '2X': require('../assets/images/VoidBridge/golf-tour@2x.png'),
        },
        GORILLA_GOLD_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/gorilla-gold.png'),
            '2X': require('../assets/images/VoidBridge/gorilla-gold@2x.png'),
        },
        GUARDIAN_LION: {
            '1X': require('../assets/images/VoidBridge/guardian-lion.png'),
            '2X': require('../assets/images/VoidBridge/guardian-lion@2x.png'),
        },
        Golden_Frog: require('../assets/images/VoidBridge/GoldenFrog.png'),
        Golden_Twins: require('../assets/images/VoidBridge/GoldenTwins.png'),
        HIGHWAY_CASH: {
            '1X': require('../assets/images/VoidBridge/highway-cash.png'),
            '2X': require('../assets/images/VoidBridge/highway-cash@2x.png'),
        },
        KING_OF_TIME: {
            '1X': require('../assets/images/VoidBridge/king-of-time.png'),
            '2X': require('../assets/images/VoidBridge/king-of-time@2x.png'),
        },
        LADY_LUCK: {
            '1X': require('../assets/images/VoidBridge/lady-luck.png'),
            '2X': require('../assets/images/VoidBridge/lady-luck@2x.png'),
        },
        LEGACY_OF_THE_GODS_MEGAWAYS: require('../assets/images/VoidBridge/legacy-of-the-gods-megaways.png'),
        LOGO: require('../assets/images/VoidBridge/logo.png'),
        LUCKY_8: {
            '1X': require('../assets/images/VoidBridge/lucky8s.png'),
            '2X': require('../assets/images/VoidBridge/lucky8s@2x.png'),
        },
        LUCKY_DRAGONS: {
            '1X': require('../assets/images/VoidBridge/lucky-dragons.png'),
            '2X': require('../assets/images/VoidBridge/lucky-dragons@2x.png'),
        },
        LUXUXY_LIFE: {
            '1X': require('../assets/images/VoidBridge/luxury-life.png'),
            '2X': require('../assets/images/VoidBridge/luxury-life@2x.png'),
        },
        MYSTIC_RICHES: {
            '1X': require('../assets/images/VoidBridge/mystic-riches.png'),
            '2X': require('../assets/images/VoidBridge/mystic-riches@2x.png'),
        },
        PHONENIX_PRINCESS: {
            '1X': require('../assets/images/VoidBridge/phoenix-princess.png'),
            '2X': require('../assets/images/VoidBridge/phoenix-princess@2x.png'),
        },
        RETURN_OF_KONG_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/return-of-kong-megaways.png'),
            '2X': require('../assets/images/VoidBridge/return-of-kong-megaways@2x.png'),
        },
        RICK_AND_MORTY_MEGAWAY: {
            '1X': require('../assets/images/VoidBridge/rick-and-morty.png'),
            '2X': require('../assets/images/VoidBridge/rick-and-morty@2x.png'),
        },
        SAFARI_GOLD_MEGAWAY: {
            '1X': require('../assets/images/VoidBridge/safari-gold-megaways.png'),
            '2X': require('../assets/images/VoidBridge/safari-gold-megaways@2x.png'),
        },
        SUPER_FORTUNE_DRAGON: {
            '1X': require('../assets/images/VoidBridge/super-fortune-dragon.png'),
            '2X': require('../assets/images/VoidBridge/super-fortune-dragon@2x.png'),
        },
        SWEET_SUCCESS_MEGAWAY: {
            '1X': require('../assets/images/VoidBridge/sweet-success.png'),
            '2X': require('../assets/images/VoidBridge/sweet-success@2x.png'),
        },
        TED_MEGAWAYS: require('../assets/images/VoidBridge/ted-megaways.png'),
        TEMPLE_OF_TREASURE_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/temple-of-treasure.png'),
            '2X': require('../assets/images/VoidBridge/temple-of-treasure@2x.png'),
        },
        TEMPLE_TREASURE: {
            '1X': require('../assets/images/VoidBridge/temple-treasures.png'),
            '2X': require('../assets/images/VoidBridge/temple-treasures@2x.png'),
        },
        THAI_DRAGON: {
            '1X': require('../assets/images/VoidBridge/thai-dragon.png'),
            '2X': require('../assets/images/VoidBridge/thai-dragon@2x.png'),
        },
        TREASURE_REEF: {
            '1X': require('../assets/images/VoidBridge/treasure-reef.png'),
            '2X': require('../assets/images/VoidBridge/treasure-reef@2x.png'),
        },
        TRIPLE_FORTUNE: {
            '1X': require('../assets/images/VoidBridge/triple-fortune.png'),
            '2X': require('../assets/images/VoidBridge/triple-fortune@2x.png'),
        },
        TRIPLE_LION: {
            '1X': require('../assets/images/VoidBridge/triple-lion.png'),
            '2X': require('../assets/images/VoidBridge/triple-lion@2x.png'),
        },
        TWIN_DRAGON: {
            '1X': require('../assets/images/VoidBridge/twin-dragon.png'),
            '2X': require('../assets/images/VoidBridge/twin-dragon@2x.png'),
        },
        VALLETTA_MEGAWAYS: {
            '1X': require('../assets/images/VoidBridge/valletta-megaways.png'),
            '2X': require('../assets/images/VoidBridge/valletta-megaways@2x.png'),
        },
        VENETIA: {
            '1X': require('../assets/images/VoidBridge/venetia.png'),
            '2X': require('../assets/images/VoidBridge/venetia@2x.png'),
        },
        VIKINGS_UNLEASHED: {
            '1X': require('../assets/images/VoidBridge/vikings-unleashed.png'),
            '2X': require('../assets/images/VoidBridge/vikings-unleashed@2x.png'),
        },
        WILD_DOLPHIN: {
            '1X': require('../assets/images/VoidBridge/wild-dolphin.png'),
            '2X': require('../assets/images/VoidBridge/wild-dolphin@2x.png'),
        },
        WOLF_LEGEND_MEGAWAY: {
            '1X': require('../assets/images/VoidBridge/wolf-legend-megaways.png'),
            '2X': require('../assets/images/VoidBridge/wolf-legend-megaways@2x.png'),
        },
        WOLF_QUEST: {
            '1X': require('../assets/images/VoidBridge/wolf-quest.png'),
            '2X': require('../assets/images/VoidBridge/wolf-quest@2x.png'),
        },
        Yellow_Emperor: require('../assets/images/VoidBridge/YellowEmperor.png'),
        Zodiac_Fortune: require('../assets/images/VoidBridge/ZodiacFortune.png'),
    },
    WALLET: require('../assets/images/Wallet/wallet.png'),
    WALLET_BALANCE: require('../assets/images/Wallet/wallet-balance.png'),
    WITHDRAWAL: {
        BTC: require('../assets/images/Wallet/btc-withdrawal.png'),
        NORMAL_WITHDRAW: require('../assets/images/Wallet/withdrawal.png'),
        USDT: require('../assets/images/Wallet/usdt-withdrawal.png'),
    },
};

export const paymentMethodsNameIconMap = new Map([
    ['AliPay', ICONS.PAYMENT_METHODS.ALI_PAY],
    ['WechatPay', ICONS.PAYMENT_METHODS.WECHAT_PAY],
    ['UnionPay', ICONS.PAYMENT_METHODS.UNION_PAY],
    ['BankToBank', ICONS.PAYMENT_METHODS.BANKTRANSFER_PAY],
    ['Cryptocurrency', ICONS.PAYMENT_METHODS.CRYPTOCURRENCY_PAY],
    ['QqPay', ICONS.PAYMENT_METHODS.QQ_PAY],
    ['jdPay', ICONS.PAYMENT_METHODS.JD_PAY],
    ['BTC', ICONS.PAYMENT_METHODS.JD_PAY],
    ['USDT', ICONS.PAYMENT_METHODS.JD_PAY],
    ['BankingIDR', ICONS.PAYMENT_METHODS.ONLINE_BANKING],
    ['BankingMYR', ICONS.PAYMENT_METHODS.ONLINE_BANKING],
    ['BankingTHB', ICONS.PAYMENT_METHODS.ONLINE_BANKING],
    ['BankingVND', ICONS.PAYMENT_METHODS.ONLINE_BANKING],
    ['paytrust88', ICONS.PAYMENT_METHODS.PAY_TRUST_88],
    ['help2pay', ICONS.PAYMENT_METHODS.HELP2PAY],
    ['mangopay', ICONS.PAYMENT_METHODS.MANGOPAY],
    ['mangopayqr', ICONS.PAYMENT_METHODS.MANGOPAY_QR],
    ['eeziepay', ICONS.PAYMENT_METHODS.EEZIE_PAY],
    ['E-Wallet', ICONS.PAYMENT_METHODS.E_WALLET],
]);

export const cryptocurrenciesIconMap = new Map([
    ['BTC', ICONS.CRYPTOCURRENCIES.BTC],
    ['USDT', ICONS.CRYPTOCURRENCIES.USDT],
    ['ETH', ICONS.CRYPTOCURRENCIES.ETH],
]);

export const paymentCorrectionIconMap = new Map([
    ['BalanceCorrection', ICONS.PAYMENT_CORRECTIONS.BALANCE_CORRECTION],
    ['CryptoCorrection', ICONS.PAYMENT_CORRECTIONS.CRYPTO_CORRECTION],
    ['DepositCorrection', ICONS.PAYMENT_CORRECTIONS.DEPOSIT_CORRECTION],
]);

export const withdrawalTransactionIconMap = new Map([
    ['BTC', ICONS.WITHDRAWAL.BTC],
    ['USDT', ICONS.WITHDRAWAL.USDT],
    ['NormalWithdrawal', ICONS.WITHDRAWAL.NORMAL_WITHDRAW],
]);
