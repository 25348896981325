export const PROMO_CARD_TYPES = {
    PROMO_DETAILS_CARD: 'Promo Details Card',
    PROMO_LIST_CARD: 'Promo List Card',
    PROMO_MODAL_CARD: 'Promo Modal Card',
};

export const REWARDS_PAGE_SIZE_DEFAULT = 5;
export const REWARDS_PAGE_NO_DEFAULT = 1;

export const REWARDS_ID_SALT = 'Promotion is the best!';

export const REWARD_STATUSES = {
    COMPLETED: 'Completed',
    EXPIRED: 'Expired',
    JOINED: 'Joined',
    RUNNING: 'Running',
};

export const REWARD_CATEGORY_ITEMS = [
    { id: 'All', name: 'All' },
    { id: 'Limited', name: 'Limited' },
    { id: 'Featured', name: 'Featured' },
    { id: 'New User', name: 'New User' },
    { id: 'Esports', name: 'Esports' },
    { id: 'Sports', name: 'Sports' },
    { id: 'Live', name: 'Live' },
    { id: 'Lottery', name: 'Lottery' },
    { id: 'RNG', name: 'RNG' },
    { id: 'Virtual', name: 'Virtual' },
];
