import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'antd';

import './ConfirmModal.less';
import { getTranslation } from '../../helpers/locale';

const CancelButton = ({ cancelText, onCancel }) => {
    return (
        <Button onClick={onCancel} className="confirm-modal__btn confirm-modal__btn--cancel">
            {getTranslation(cancelText)}
        </Button>
    );
};

CancelButton.defaultProps = {
    cancelText: 'Close',
};

CancelButton.propTypes = {
    cancelText: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
};

export default CancelButton;
