import * as Sentry from '@sentry/browser';

class ErrorLogger {
    constructor(config) {
        // config.init();
        const { init, logError, showReportDialog } = config;
        this.init = init;
        this.logError = logError;
        this.showReportDialog = showReportDialog;
    }
}

const SentryConfig = {
    init: () =>
        Sentry.init({
            dsn: 'https://b95f545b326e47259bd1fa4f04a27783@o406994.ingest.sentry.io/5275449',
            environment: process.env.NODE_ENV,
        }),

    logError: (error, errorInfo, exportErrorId) =>
        Sentry.withScope(function(scope) {
            scope.setExtras(errorInfo);
            const eventId = Sentry.captureException(error);
            exportErrorId(eventId);
        }),

    showReportDialog: (eventId) =>
        Sentry.showReportDialog({
            eventId,
        }),
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => new ErrorLogger(SentryConfig);
