import { useEffect } from 'react';
import _ from 'lodash';

import { getCurrentLanguage } from '../../common/helpers/api';
import { useHistory, useLocation } from 'react-router';

export default function useUrlLanguage() {
    const location = useLocation();
    const history = useHistory();
    const language = getCurrentLanguage();
    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        let queryParam = location.search;
        let newQueryParam = queryParam
            ? queryParam.includes('lang')
                ? _.map(queryParam.split('&'), (e) => {
                      return e.replace(/(lang=).*/, '$1' + language);
                  }).join('&')
                : `${queryParam}&lang=${language}`
            : `?lang=${language}`;
        history.replace({
            pathname: location.pathname,
            search: newQueryParam,
            state: location.state,
        });
    }, [history, language, location.pathname, location.search, location.state]);
}
