import React, { useState } from 'react';

import LiveChat from 'react-livechat';

import { ICONS } from '../constants/icons';
import { getCurrentUser, isUserLoggedIn } from '../helpers/api';
import { getStateProp } from '../helpers/misc';
import { getTranslation } from '../helpers/locale';
import { useStore } from '../../store/StateProvider';
import ImgIcon from './ImgIcon';
import SupportPopover from './SupportPopover';

const SupportButton = ({ size = 25, withText }) => {
    const [state] = useStore();

    const user = getCurrentUser();
    const userName = getStateProp(user, 'claims.userName', '');
    const userEmail = getStateProp(state, 'user.email', '');
    const geolocationCountryCode = getStateProp(state, 'country.geolocation.countryCode', '');
    const userMobile = getStateProp(state, 'user.mobileNo', '');
    const userRealName = getStateProp(state, 'user.realName', '');
    const [toggleSupportModal, setToggleSupportModal] = useState(false);
    //const [livechatGroup, setLivechatGroup] = useState(false);

    // ============================================
    // METHODS
    // ============================================

    /*     const openTawkChat = () => {
        try {
            const tawkChatApi = window.Tawk_API;
            if (isUserLoggedIn()) {
                tawkChatApi.visitor = {
                    mobile: userMobile,
                    name: userRealName,
                };
            }
            tawkChatApi.maximize();
        } catch (e) {
            console.log(e);
        }
    }; */

    const openLiveChat = () => {
        try {
            const liveChatApi = window.LC_API;
            if (isUserLoggedIn()) {
                const custom_variables = [
                    { name: 'Mobile', value: userMobile },
                    { name: 'Real Name', value: userRealName },
                ];
                liveChatApi.set_custom_variables(custom_variables);
                liveChatApi.set_visitor_name(userName);
                liveChatApi.set_visitor_email(userEmail);
            }
            liveChatApi.open_chat_window();
        } catch (e) {
            console.log(e);
        }
    };

    const toggleModal = () => {
        setToggleSupportModal(!toggleSupportModal);
    };

    /*     const getLivechatGroup = () => {
        if (TESTING_URL.includes(window.location.hostname)) {
            return LIVECHAT_GROUPS.TESTING;
        } else if (isChinaDomain()) {
            return LIVECHAT_GROUPS.GGBAO;
        } else if (['my', 'id', 'vn', 'th'].includes(geolocationCountryCode)) {
            return LIVECHAT_GROUPS_MAP.get(geolocationCountryCode);
        } else {
            return LIVECHAT_GROUPS.ALL;
        }
    }; */

    /*useEffect(() => {
        //only allow indonesia to access livechat
        if (geolocationCountryCode === 'id') {
            setLivechatGroup(getLivechatGroup());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geolocationCountryCode]); */

    // ============================================
    // RENDER
    // ============================================

    return (
        <div
            style={{ cursor: 'pointer', height: 'inherit', lineHeight: 'initial', zIndex: 200 }}
            onClick={toggleModal}
        >
            <ImgIcon icon={ICONS.HEADER.SUPPORT} size={size} />
            {withText && (
                <span style={{ paddingLeft: 6 }}>
                    {getTranslation('Contact us').toLocaleUpperCase()}
                </span>
            )}
            {geolocationCountryCode === 'id' && <LiveChat license={12496434} />}
            <SupportPopover
                visible={toggleSupportModal}
                onCancel={toggleModal}
                openLiveChat={openLiveChat}
                state={state}
            />
        </div>
    );
};

export default SupportButton;
