import { BrowserRouter } from 'react-router-dom';
import React from 'react';

import TagManager from 'react-gtm-module';
import initReactFastClick from 'react-fastclick';

import './App.less';
import { IntlProvider } from '..';
import { StateProvider } from '../../store/StateProvider';
import { isNonChinaDomain, isProductionEnvironment } from '../../common/helpers/misc';
import AppRoutes from './routes/AppRoutes';
import ErrorBoundary from '../../error-reporting/ErrorBoundary';
import appReducer from '../../store/appReducer';
import instantiateErrorLogger from '../../error-reporting/ErrorLogger';
import useCheckLocalStorage from '../../services/hooks/useCheckLocalStorage';

initReactFastClick();
const loggerInstance = instantiateErrorLogger();

// Only allow GA for domains used outside china
if (isNonChinaDomain() && isProductionEnvironment()) {
    const tagManagerArgs = {
        gtmId: 'GTM-MWG5WHL',
    };
    TagManager.initialize(tagManagerArgs);
}

const App = () => {
    // ============================================
    // HOOKS
    // ============================================

    useCheckLocalStorage();

    // ============================================
    // RENDER
    // ============================================

    return (
        <StateProvider reducer={appReducer} initialState={{}}>
            <BrowserRouter>
                <IntlProvider>
                    <ErrorBoundary logger={loggerInstance}>
                        <AppRoutes />
                    </ErrorBoundary>
                </IntlProvider>
            </BrowserRouter>
        </StateProvider>
    );
};

export default App;
