import PropTypes from 'prop-types';
import React from 'react';

import { Row } from 'antd';

import './ConfirmModal.less';
import { ICONS } from '../../constants/icons';
import ImgIcon from '../ImgIcon';

const ConfirmModalContent = ({ image, text }) => {
    return (
        <>
            <ImgIcon
                icon={image}
                className="confirm-modal__image"
                containerClassName="confirm-modal__image__container"
            />
            <Row className="confirm-modal__text">
                <span>{text}</span>
            </Row>
        </>
    );
};

ConfirmModalContent.defaultProps = {
    image: ICONS.MODAL.WARNING,
};

ConfirmModalContent.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string.isRequired,
};

export default ConfirmModalContent;
