export const ACTIONS = {
    NEWS_APPLY_GAME_FILTER: 'NEWS_APPLY_GAME_FILTER',
    NEWS_DETAILS_GET: 'NEWS_DETAILS_GET',
    NEWS_DETAILS_GET_FAILURE: 'NEWS_GET_DETAILS_FAILURE',
    NEWS_DETAILS_GET_SUCCESS: 'NEWS_GET_DETAILS_SUCCESS',
    NEWS_DROP_GAME_FILTER: 'NEWS_DROP_GAME_FILTER',
    NEWS_GET: 'NEWS_GET',
    NEWS_GET_FAILURE: 'NEWS_GET_FAILURE',
    NEWS_GET_SUCCESS: 'NEWS_GET_SUCCESS',
    PAGINATION: {
        PAGE_CHANGE: 'NEWS_PAGE_CHANGE',
        PAGE_SIZE_CHANGE: 'NEWS_PAGE_SIZE_CHANGE',
    },
};

/**
 * Apply news game filter (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Game category id
 */
export const applyNewsGameFilter = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_APPLY_GAME_FILTER,
    });

/**
 * Drop news game filter action
 * @param {Function} dispatch Action dispatcher from store
 */
export const dropNewsGameFilter = (dispatch) =>
    dispatch({
        type: ACTIONS.NEWS_DROP_GAME_FILTER,
    });

/**
 * Get news init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getNewsList = (dispatch) =>
    dispatch({
        type: ACTIONS.NEWS_GET,
    });

/**
 * Get news success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: News[], paging: Pagination}} payload Action payload
 */
export const getNewsListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_GET_SUCCESS,
    });

/**
 * Get news failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getNewsListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_GET_FAILURE,
    });

/**
 * Get news details init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getNewsDetailsById = (dispatch) =>
    dispatch({
        type: ACTIONS.NEWS_DETAILS_GET,
    });

/**
 * Get news details success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{newsDetails: News}} payload Action payload
 */
export const getNewsDetailsByIdSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_DETAILS_GET_SUCCESS,
    });

/**
 * Get news failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getNewsDetailsByIdFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.NEWS_DETAILS_GET_FAILURE,
    });

/**
 * Change page number action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page number
 */
export const changePageNumber = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_CHANGE,
    });

/**
 * Change page size action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Number} payload Page size
 */
export const changePageSize = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PAGINATION.PAGE_SIZE_CHANGE,
    });
