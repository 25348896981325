import PropTypes from 'prop-types';
import React from 'react';

import 'react-lazy-load-image-component/src/effects/opacity.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isRetinaDisplay } from '../helpers/misc';

const ImgIcon = ({
    lazy,
    size,
    height,
    maxHeight,
    width,
    icon,
    alt,
    onClick,
    children,
    imageClassName,
    containerClassName,
    ...rest
}) => {
    const styles = {
        cursor: 'pointer',
        height: size ? size : height,
        maxHeight: size ? size : maxHeight,
        objectFit: 'cover',
        width: size ? size : width,
    };

    const normalSize = (icon && icon['1X']) || icon || '';
    const retinaSize = icon && icon['2X'];

    let src = isRetinaDisplay() && retinaSize ? retinaSize : normalSize;

    return (
        <div onClick={onClick} style={{ background: 'transparent' }} className={containerClassName}>
            {lazy ? (
                <LazyLoadImage
                    effect="opacity"
                    style={styles}
                    src={src}
                    alt={alt}
                    className={imageClassName}
                    {...rest}
                />
            ) : (
                <img style={styles} src={src} alt={alt} className={imageClassName} {...rest} />
            )}
            {children}
        </div>
    );
};

ImgIcon.defaultProps = {
    alt: '',
    lazy: true,
};

ImgIcon.propTypes = {
    alt: PropTypes.string,
    containerClassName: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    imageClassName: PropTypes.string,
    lazy: PropTypes.bool,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ImgIcon;
