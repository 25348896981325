import PropTypes from 'prop-types';
import React from 'react';

import { Button } from 'antd';

import './ConfirmModal.less';
import { getTranslation } from '../../helpers/locale';

const SingleButton = ({ cancelText, onCancel }) => {
    return (
        <Button onClick={onCancel} className="confirm-modal__btn confirm-modal__btn--single">
            {getTranslation(cancelText)}
        </Button>
    );
};

SingleButton.defaultProps = {
    cancelText: 'Close',
};

SingleButton.propTypes = {
    cancelText: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
};

export default SingleButton;
