import { ACTIONS } from '../actions/sabaActions';

export default function sabaReducer(
    saba = {
        serviceUnderMaintenance: false,
    },
    { type, payload = null },
) {
    switch (type) {
        case ACTIONS.SET_SABA_UNDER_MAINTENANCE:
            return {
                ...saba,
                ...payload,
            };
        case ACTIONS.GET_SABA_PP_JACKPOT_SUCCESS:
            return {
                ...saba,
                ppJackpotAmount: payload,
            };
        case ACTIONS.GET_SABA_PP_JACKPOT:
        default:
            return saba;
    }
}
