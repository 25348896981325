import { Link, useHistory } from 'react-router-dom';
import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import './HeaderWithBackButton.less';
import { getTranslation } from '../../../common/helpers/locale';
import SupportButton from '../../../common/components/SupportButton';

const HeaderWithBackButton = ({ data }) => {
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const goBack = () => history.goBack();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="space-between" align="middle" className="layout__back-to-header">
            <Col>
                {data.path ? (
                    <Link to={data.path}>
                        <ArrowLeftOutlined className="navbar__icon--secondary" alt="back" />
                    </Link>
                ) : (
                    <ArrowLeftOutlined className="navbar__icon--secondary" onClick={goBack} />
                )}
            </Col>
            <Col className="layout__title">{getTranslation(data.title).toLocaleUpperCase()}</Col>
            <Col>
                <SupportButton />
            </Col>
        </Row>
    );
};

export default HeaderWithBackButton;
