import _ from 'lodash';
import intl from 'react-intl-universal';

import enUS from 'antd/es/locale/en_US';
import idID from 'antd/es/locale/id_ID';
import thTH from 'antd/es/locale/th_TH';
import viVN from 'antd/es/locale/vi_VN';
import zhCN from 'antd/es/locale/zh_CN';

import { BASE_LANGUAGE, LANGUAGES } from '../constants/localization';
import { COUNTRIES } from '../constants/countries';
import { getCurrentLanguage, isUserLoggedIn } from './api';
import { getStateProp, isChinaDomain } from './misc';
import { getUserCurrency } from './currency';

export const getCountryByLocale = (value) => {
    const foundCountry = isChinaDomain()
        ? { code: 'cn', name: 'China', prefix: '+86' }
        : _.find(COUNTRIES, (country) => value.indexOf(country.code) !== -1);

    return foundCountry || { code: 'my', name: 'Malaysia', prefix: '+60' };
};

export const getLanguageNameByValue = (value) => {
    const foundLanguage = _.find(LANGUAGES, (l) => l.value === value);
    return foundLanguage ? foundLanguage.name : foundLanguage;
};

export const getLanguageCodeByValue = (value) => {
    const foundLanguage = _.find(LANGUAGES, (l) => l.value === value);
    return foundLanguage ? foundLanguage.code : foundLanguage;
};

export const getSupportedLanguageLocaleByValue = (value) => {
    const foundLanguage = _.find(LANGUAGES, (l) => l.value === value);
    return foundLanguage ? foundLanguage.value : 'en-US';
};

export const getSupportedLocales = () => ({
    'en-US': require('../../locales/en_US'),
    'id-ID': require('../../locales/id_ID'),
    'tr-TR': require('../../locales/th_TH'), // instead of 'th-TH' fix for backend thai language issue
    'vi-VN': require('../../locales/vi'),
    'zh-CN': require('../../locales/zh_CN'),
    // 'ja-JP': require('../../locales/ja_JP.json'),
    // 'ko-KR': require('../../locales/ko_KR.json'),
    // 'zh-TW': require('../../locales/zh_TW.json'),
});

export const getTranslation = (sentence, params = {}) => {
    if (sentence) return intl.get(sentence, params).defaultMessage(sentence);
};

export const getCountryByCode = (countryCode) => _.find(COUNTRIES, (c) => c.code === countryCode);

export const getBrowserLocale = () => {
    let navigatorLanguage;
    if (navigator.languages) navigatorLanguage = navigator.languages[0];
    else if (navigator.language) navigatorLanguage = navigator.language;
    else navigatorLanguage = 'en-US';
    return navigatorLanguage;
};

export const determineClientLanguage = () => {
    if (getCurrentLanguage()) return getCurrentLanguage();
    else if (getBrowserLocale().indexOf('zh') !== -1) return 'zh-CN';
    else if (getBrowserLocale().indexOf('id') !== -1) return 'id-ID';
    // instead of 'th-TH' fix for backend thai language issue
    else if (getBrowserLocale().indexOf('th') !== -1) return 'tr-TR';
    else if (getBrowserLocale().indexOf('vi') !== -1) return 'vi-VN';
    else return 'en-US';
};

export const getAntDLocaleByCountry = (language) => {
    if (language === 'zh-CN') return zhCN;
    if (language === 'id-ID') return idID;
    if (language === 'tr-TR') return thTH; // instead of 'th-TH' fix for backend thai language issue
    if (language === 'vi-VN') return viVN;
    else return enUS;
};

export const getCurrencyByCountry = (countryCode) => {
    switch (countryCode) {
        case 'cn':
            return 'CNY';
        case 'my':
            return 'MYR';
        case 'th':
            return 'THB';
        case 'id':
            return 'IDR';
        case 'vn':
            return 'VND';
        default:
            return 'USD';
    }
};

export const getCountryByCurrency = (currency) => {
    switch (currency) {
        case 'CNY':
            return 'China';
        case 'MYR':
            return 'Malaysia';
        case 'THB':
            return 'Thailand';
        case 'IDR':
            return 'Indonesia';
        case 'VND':
            return 'Vietnam';
        default:
            return 'Undefined';
    }
};

export const getLanguageValueByCurrency = (currency) => {
    switch (currency) {
        case 'CNY':
            return 'zh-CN';
        case 'MYR':
            return 'ms-MS';
        case 'THB':
            return 'tr-TR'; // instead of 'th-TH' fix for backend thai language issue
        case 'IDR':
            return 'id-ID';
        case 'VND':
            return 'vi-VN';
        default:
            return 'en-US';
    }
};

export const getLanguageByCurrency = (currency) => {
    const convertedCurrency = currency === 'MYR' ? 'CNY' : currency;
    return _.find(
        LANGUAGES,
        (language) => language.value === getLanguageValueByCurrency(convertedCurrency),
    );
};

export const getCurrencyBasedLanguage = (currency) => {
    if (isUserLoggedIn()) return getLanguageValueByCurrency(currency);
    else return determineClientLanguage();
};

export const isSupportedCurrencyCountry = (country) => {
    return _.includes(['cn', 'th', 'id', 'vn', 'my'], country);
};

export const getFirstLanguage = (isFirstLoad, geolocationCountryCode, setIsFirstLoad) => {
    if (isFirstLoad) {
        if (geolocationCountryCode) {
            const lang = getLanguageValueByCurrency(getCurrencyByCountry(geolocationCountryCode));
            setIsFirstLoad(false);
            return getSupportedLanguageLocaleByValue(lang); //to handle unsupported language codes
        } else {
            return determineClientLanguage();
        }
    } else {
        return determineClientLanguage();
    }
};

export const isOTPDisabled = (state) => {
    //Disable otp for all country temporarily
    /*const countryCode = isUserLoggedIn()
        ? getStateProp(state, 'user.countryCode')
        : getStateProp(state, 'country.code');
    return countryCode === 'vn' || countryCode === 'id'; */
    return true;
};

export const isIndonesiaCountry = (state) => {
    const countryCode = getStateProp(state, 'country.geolocation.countryCode');
    return countryCode === 'id';
};

export const getAvailableLanguages = (state) => {
    const userCurrency = getUserCurrency(state);

    return userCurrency === 'USD'
        ? LANGUAGES
        : [BASE_LANGUAGE, getLanguageByCurrency(userCurrency)];
};
