export const ACTIONS = {
    SABA_TRANSACTIONS_GET: 'SABA_TRANSACTIONS_GET',
    SABA_TRANSACTIONS_GET_FAILURE: 'SABA_TRANSACTIONS_GET_FAILURE',
    SABA_TRANSACTIONS_GET_SUCCESS: 'SABA_TRANSACTIONS_GET_SUCCESS',
    TRANSACTIONS_GET: 'TRANSACTIONS_GET',
    TRANSACTIONS_GET_FAILURE: 'TRANSACTIONS_GET_FAILURE',
    TRANSACTIONS_GET_SUCCESS: 'TRANSACTIONS_GET_SUCCESS',
    TRANSACTIONS_PERIOD_CHANGE: 'TRANSACTIONS_PERIOD_CHANGE',
};

/**
 * Get transactions init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getTransactions = (dispatch) =>
    dispatch({
        type: ACTIONS.TRANSACTIONS_GET,
    });

/**
 * Get transactions success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Transaction[]} payload Action payload
 */
export const getTransactionsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTIONS_GET_SUCCESS,
    });

/**
 * Get transactions failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getTransactionsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTIONS_GET_FAILURE,
    });

/**
 * Change transaction period filter
 * @param {Function} dispatch Action dispatcher from store
 * @param {{dateFrom: String, dateTo: String}} payload Action payload
 */
export const changeTransactionsPeriod = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.TRANSACTIONS_PERIOD_CHANGE,
    });

/**
 * Get transactions init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getSabaTransactions = (dispatch) =>
    dispatch({
        type: ACTIONS.SABA_TRANSACTIONS_GET,
    });

/**
 * Get transactions success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Transaction[]} payload Action payload
 */
export const getSabaTransactionsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SABA_TRANSACTIONS_GET_SUCCESS,
    });

/**
 * Get transactions failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getSabaTransactionsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SABA_TRANSACTIONS_GET_FAILURE,
    });
