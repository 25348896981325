import { lazy } from 'react';

const AboutUs = lazy(() => import('./about-us/AboutUs'));
const AppVersion = lazy(() => import('./app-version/AppVersion'));
const FAQ = lazy(() => import('./faq/FAQ'));
const Rules = lazy(() => import('./rules/Rules'));
const Terms = lazy(() => import('./terms/Terms'));
const SponsorShip = lazy(() => import('./sponsor-ship/Sponsorship'));
const Triton = lazy(() => import('./sponsor-ship/Triton'));
const Aster = lazy(() => import('./sponsor-ship/Aster'));
const GeekFam = lazy(() => import('./sponsor-ship/GeekFam'));
const AppDownload = lazy(() => import('./app-download/AppDownload'));

export { AboutUs, AppVersion, FAQ, Rules, Terms, SponsorShip, Triton, GeekFam, Aster, AppDownload };
