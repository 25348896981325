import _ from 'lodash';

import {
    CSGO_MAP,
    ESPORTS_GAMES,
    MOBILE_PLATFORM,
    MOBILE_WEBVIEW,
    TF_GAMING_GAMES,
} from '../constants/misc';
import { ICONS } from '../constants/icons';
import { getCurrentLanguage, isChineseLanguage } from './api';
import { getLanguageCodeByValue, getTranslation } from './locale';

export const getStateProp = (state, path, defaultValue) => _.get(state, path, defaultValue);

export const getGameIconByName = (gameName) => {
    return _.get(
        _.find(ESPORTS_GAMES, (g) => g.searchName === gameName),
        'icon',
    );
};

export const getTFGameIconByName = (gameName) => {
    return _.get(
        _.find(TF_GAMING_GAMES, (g) => g.searchName === gameName),
        'icon',
    );
};

export const getGameIconByGameId = (gameId) => {
    let gameName = '';
    switch (gameId && gameId.toLowerCase()) {
        case 'csgo':
            gameName = 'CS:GO';
            break;
        case 'dota2':
            gameName = 'Dota 2';
            break;
        default:
            gameName = '';
    }
    return getGameIconByName(gameName);
};

export const getCurrentTimeOfDay = () => {
    const hours = new Date().getHours();
    if (hours >= 5 && hours < 12) return 'Morning';
    else if (hours >= 12 && hours < 18) return 'Afternoon';
    else return 'Evening';
};

export const getExpirationDate = () => {
    const date = new Date();
    return new Date(date.setMonth(date.getMonth() + 1));
};

export const detectMobile = () => {
    let isMobile = false;
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    toMatch.forEach((toMatchItem) => {
        if (navigator.userAgent.match(toMatchItem)) isMobile = true;
    });

    if (window.innerWidth <= 768) isMobile = true;

    return isMobile;
};

export const detectPWA = () => {
    return (
        (window.matchMedia('(display-mode: standalone)').matches ||
            window.matchMedia('(display-mode: fullscreen)').matches) &&
        detectMobile()
    );
};

export const detectWebView = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    //custom user agent injected into mobile app webview
    return /ggbookwebview/.test(userAgent);
};

export const detectIphone = () => {
    return /iPhone|iPod|iPad/.test(navigator.platform);
};

export const detectIphonePWA = () => {
    return detectPWA() && detectIphone();
};

export const detectUCBrowser = () => {
    return (
        navigator.userAgent.indexOf('UCBrowser/') >= 0 ||
        navigator.userAgent.indexOf('UBrowser/') >= 0
    );
};

export const detectQQBrowser = () => {
    return navigator.userAgent.indexOf('QQBrowser/') >= 0;
};

export const getClassNameForPWA = (className) => {
    return detectIphonePWA() ? `${className} ${className}__pwa-ios` : className;
};

export const getFirstActiveElement = (list) => {
    let result = {};
    for (let i = 0; i < list.length; i++) {
        if (list[i].active) {
            result = list[i];
            break;
        }
    }
    return result;
};

export const getImageByLanguage = (imageObj, locale) => {
    const languageCode = getLanguageCodeByValue(locale);
    return getStateProp(imageObj, languageCode.toUpperCase(), imageObj.US);
};

export const isTestEnvironment = () => {
    const currentAddress = window.location.host;
    return (
        (process.env.NODE_ENV !== 'production' && !currentAddress.includes('stg')) ||
        currentAddress.includes('dev') ||
        currentAddress.includes('sit') ||
        currentAddress.includes('uat')
    );
};

export const isProductionEnvironment = () => {
    const currentAddress = window.location.host;
    return (
        process.env.NODE_ENV === 'production' ||
        !currentAddress.includes('localhost') ||
        !currentAddress.includes('sit') ||
        !currentAddress.includes('uat') ||
        !currentAddress.includes('stg')
    );
};

export const enableLogging = () => {
    const currentAddress = window.location.host;
    return (
        process.env.NODE_ENV !== 'production' ||
        currentAddress.includes('dev') ||
        currentAddress.includes('sit') ||
        currentAddress.includes('uat') ||
        currentAddress.includes('stg')
    );
};

export const isChinaDomain = () => {
    const currentAddress = window.location.host;
    return currentAddress.includes('ggbao');
};

export const isNonChinaDomain = () => {
    const currentAddress = window.location.host;
    return currentAddress.includes('ggbook');
};

export const isRetinaDisplay = () => {
    return (
        (window.matchMedia &&
            (window.matchMedia(
                'only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)',
            ).matches ||
                window.matchMedia(
                    'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)',
                ).matches)) ||
        (window.devicePixelRatio && window.devicePixelRatio >= 2)
    );
};

export const getTeamImageUrl = (teamObj, isTFGamingPlatform) => {
    const defaultIcon = isTFGamingPlatform ? ICONS.DEFAULT_TEAM.TF_GAMING : ICONS.DEFAULT_TEAM.SABA;
    const teamImgUrl = isTFGamingPlatform
        ? getStateProp(teamObj, 'imageUrl', defaultIcon)
        : isChinaDomain()
        ? getStateProp(teamObj, 'imageUrlCN', defaultIcon)
        : getStateProp(teamObj, 'imageUrl', defaultIcon);

    return teamImgUrl ? teamImgUrl : defaultIcon;
};

export const convertPhoneNumber = (mobileNo) => {
    return !_.isObjectLike(mobileNo)
        ? {}
        : {
              ...mobileNo,
              phoneNumber: _.toNumber(mobileNo.phoneNumber),
          };
};

// add localized name property to list of possible values for Select
export const convertSelectValueList = (dataList) => {
    return _.map(dataList, (data) => {
        return {
            ...data,
            localeName: getTranslation(data.name),
        };
    });
};

export const roundDownMonetaryValue = (value) => {
    const [integerPart, decimalPart] = value.toString().split('.');

    const truncatedDecimalPart = decimalPart
        ? decimalPart.length <= 2
            ? decimalPart
            : decimalPart.slice(0, 2)
        : '0';

    return +[integerPart, truncatedDecimalPart].join('.');
};

export const redirectToCustomerService = () => {
    if (getCurrentLanguage() === 'zh-CN') {
        window.location.href = 'https://direct.lc.chat/11931459/2';
    } else if (getCurrentLanguage() === 'tr-TR') {
        window.location.href = 'https://direct.lc.chat/11931459/4';
    } else {
        window.location.href = 'https://direct.lc.chat/11931459/';
    }
};

export const getCustomerServicesUrl = () => {
    if (getCurrentLanguage() === 'zh-CN') {
        return 'https://direct.lc.chat/11931459/2';
    } else if (getCurrentLanguage() === 'tr-TR') {
        return 'https://direct.lc.chat/11931459/4';
    } else {
        return 'https://direct.lc.chat/11931459';
    }
};

export const getImageByDomain = (imageObj) => {
    return isChinaDomain() ? imageObj.CN : imageObj.US;
};

export const checkWebViewForUpdate = () => {
    const versionToCheck = parseFloat(MOBILE_WEBVIEW.VERSION);
    const currentVersion = getWebViewVersion();
    return versionToCheck > currentVersion;
};

export const getWebViewVersion = () => {
    const ggbookString = 'GGbookWebView/';
    const userAgentString = navigator.userAgent;
    let version = userAgentString.substring(
        userAgentString.indexOf(ggbookString) + ggbookString.length,
    );
    return parseFloat(version.substring(0, version.indexOf('/')));
};

export const getAppDownloadLink = (OS = null) => {
    if (OS) {
        if (OS === MOBILE_PLATFORM.IOS) {
            if (isChinaDomain()) return MOBILE_WEBVIEW.IOS.GGBBAO;
            else return MOBILE_WEBVIEW.IOS.GGBOOK;
        } else {
            if (isChinaDomain()) return MOBILE_WEBVIEW.ANDROID.GGBAO;
            else return MOBILE_WEBVIEW.ANDROID.GGBOOK;
        }
    } else {
        if (detectIphone()) {
            if (isChinaDomain()) return MOBILE_WEBVIEW.IOS.GGBBAO;
            else return MOBILE_WEBVIEW.IOS.GGBOOK;
        } else {
            if (isChinaDomain()) return MOBILE_WEBVIEW.ANDROID.GGBAO;
            else return MOBILE_WEBVIEW.ANDROID.GGBOOK;
        }
    }
};

export const getCopiedValue = () => navigator.clipboard.readText();

export const categoriseGames = (games, isProgressive) => {
    const lang = isChineseLanguage() ? 'zh' : 'en';
    const excludedLiveGame = _.filter(games, function(game) {
        return game.type !== 'Live Games';
    });
    let newGames = _.filter(excludedLiveGame, { isProgressiveJackpot: isProgressive }).sort(
        function(x, y) {
            return getTranslation(x.displayName).localeCompare(getTranslation(y.displayName), lang);
        },
    );

    newGames.sort(function(x, y) {
        return x.isNewGame === y.isNewGame ? 0 : x.isNewGame ? -1 : 1;
    });

    return newGames.sort(function(x, y) {
        return x.isFreeSpin === y.isFreeSpin ? 0 : x.isFreeSpin ? -1 : 1;
    });
};

export const isGameAccessRestricted = (state, gameCategory) => {
    const gameAccessArray = getStateProp(state, 'user.gameAccess[0].gameAccess');

    return (
        ((gameAccessArray && gameAccessArray.length) > 0 &&
            !gameAccessArray.includes(gameCategory)) ||
        (gameAccessArray && gameAccessArray.length) === 0
    );
};

export const isNullOrEmptyString = (value) => {
    if (!value) return true;
    else return !value.length;
};

export const getCsgoMapIcon = (mapName) => {
    return _.get(
        _.find(CSGO_MAP, (g) => g.name === mapName.toLowerCase()),
        'icon',
    );
};

export const getCsgoMapBackground = (mapName) => {
    return _.get(
        _.find(CSGO_MAP, (g) => g.name === mapName.toLowerCase()),
        'background',
    );
};

export const isValidFullUrl = (value) => {
    let url;

    try {
        url = new URL(value);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};
