export const ACTIONS = {
    GAME_EVENTS_APPLY_FILTER: 'GAME_EVENTS_APPLY_FILTER',
    GAME_EVENTS_APPLY_GAME_FILTER: 'GAME_EVENTS_APPLY_GAME_FILTER',
    GAME_EVENTS_DROP_FILTER: 'GAME_EVENTS_DROP_FILTER',
    GAME_EVENTS_DROP_GAME_FILTER: 'GAME_EVENTS_DROP_GAME_FILTER',
    GAME_EVENTS_GET: 'GAME_EVENTS_GET',
    GAME_EVENTS_GET_FAILURE: 'GAME_EVENTS_GET_FAILURE',
    GAME_EVENTS_GET_SUCCESS: 'GAME_EVENTS_GET_SUCCESS',
    GAME_EVENTS_MARKET_TYPE_CHANGE: 'GAME_EVENTS_MARKET_TYPE_CHANGE',
    GAME_EVENTS_SHOW_STREAM: 'GAME_EVENTS_SHOW_STREAM',
    GAME_EVENT_ADD_TO_FAVOURITES: 'GAME_EVENT_ADD_TO_FAVOURITES',
    GAME_EVENT_REMOVE_FROM_FAVOURITES: 'GAME_EVENT_REMOVE_FROM_FAVOURITES',
};

/**
 * Apply events filter action (frontend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {Filter} payload Action payload
 */
export const applyEventsFilter = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_APPLY_FILTER,
    });

/**
 * Apply events game filter (backend filter)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Game name
 */
export const applyEventsGameFilter = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_APPLY_GAME_FILTER,
    });

/**
 * Drop events filter action
 * @param {Function} dispatch Action dispatcher from store
 */
export const dropEventsFilter = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_EVENTS_DROP_FILTER,
    });

/**
 * Drop events game filter action
 * @param {Function} dispatch Action dispatcher from store
 */
export const dropEventsGameFilter = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_EVENTS_DROP_GAME_FILTER,
    });

/**
 * Get market events init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getEventsList = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_EVENTS_GET,
    });

/**
 * Get market events success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Events} payload Action payload
 */
export const getEventsListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_GET_SUCCESS,
    });

/**
 * Get market events failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getEventsListFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_GET_FAILURE,
    });

/**
 * Switch events market type action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Action payload (could be "t", "l" or "e")
 */
export const changeEventsMarketType = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_MARKET_TYPE_CHANGE,
    });

/**
 * Adds event id to array of favorities
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Action payload here is eventId
 */
export const addToFavorities = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENT_ADD_TO_FAVOURITES,
    });

/**
 * Removes event id from array of favorities
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Action payload here is eventId
 */
export const removeFromFavorities = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENT_REMOVE_FROM_FAVOURITES,
    });

/**
 * Show live stream action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Boolean} payload Action payload
 */
export const showLiveStream = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_EVENTS_SHOW_STREAM,
    });
