import { deleteStorageItem, getStorageItem } from '../../common/helpers/api';

const useCheckLocalStorage = () => {
    try {
        getStorageItem('test', 'test');
        deleteStorageItem('test');
    } catch (e) {
        alert('Please enable cookies in your browser settings to use our site!');
    }
};

export default useCheckLocalStorage;
