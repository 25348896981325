export const ACTIONS = {
    ADVANTPLAY_REDIRECT: 'ADVANTPLAY_REDIRECT',
    ADVANTPLAY_REDIRECT_FAILURE: 'ADVANTPLAY_REDIRECT_FAILURE',
    AFFILIATE_REGISTER: 'AFFILIATE_REGISTER',
    AFFILIATE_REGISTER_FAILURE: 'AFFILIATE_REGISTER_FAILURE',
    AFFILIATE_REGISTER_SUCCESS: 'AFFILIATE_REGISTER_SUCCESS',
    AFFILIATE_STATUS: 'AFFILIATE_STATUS',
    AFFILIATE_STATUS_FAILURE: 'AFFILIATE_STATUS_FAILURE',
    AFFILIATE_STATUS_SUCCESS: 'AFFILIATE_STATUS_SUCCESS',
    AG_REDIRECT_FAILURE: 'AG_REDIRECT_FAILURE',
    AG_REDIRECT_INIT: 'AG_REDIRECT_INIT',
    APP_LANGUAGE_UPDATE: 'APP_LANGUAGE_UPDATE',
    APP_LANGUAGE_UPDATE_FAILURE: 'APP_LANGUAGE_UPDATE_FAILURE',
    APP_LANGUAGE_UPDATE_SUCCESS: 'APP_LANGUAGE_UPDATE_SUCCESS',
    BET_SETTINGS_UPDATE: 'BET_SETTINGS_UPDATE',
    BET_SETTINGS_UPDATE_FAILURE: 'BET_SETTINGS_UPDATE_FAILURE',
    BET_SETTINGS_UPDATE_SUCCESS: 'BET_SETTINGS_UPDATE_SUCCESS',
    BTI_REDIRECT_FAILURE: 'BTI_REDIRECT_FAILURE',
    BTI_REDIRECT_INIT: 'BTI_REDIRECT_INIT',
    CARD_RACING_REDIRECT: 'CARD_RACING_REDIRECT',
    CARD_RACING_REDIRECT_FAILURE: 'CARD_RACING_REDIRECT_FAILURE',
    CRASH_REDIRECT: 'CRASH_REDIRECT',
    CRASH_REDIRECT_FAILURE: 'CRASH_REDIRECT_FAILURE',
    CRASH_WALLET_SETUP_GET: 'CRASH_WALLET_SETUP_GET',
    CRASH_WALLET_SETUP_GET_FAILURE: 'CRASH_WALLET_SETUP_GET_FAILURE',
    CRASH_WALLET_SETUP_GET_SUCCESS: 'CRASH_WALLET_SETUP_GET_SUCCESS',
    E_WALLET_ADD: 'E_WALLET_ADD',
    E_WALLET_ADD_FAILURE: 'E_WALLET_ADD_FAILURE',
    E_WALLET_ADD_SUCCESS: 'E_WALLET_ADD_SUCCESS',
    E_WALLET_DELETE: 'E_WALLET_DELETE',
    E_WALLET_DELETE_FAILURE: 'E_WALLET_DELETE_FAILURE',
    E_WALLET_DELETE_SUCCESS: 'E_WALLET_DELETE_SUCCESS',
    FIN_PIN_MODAL_CLOSE: 'FIN_PIN_MODAL_CLOSE',
    FIN_PIN_MODAL_OPEN: 'FIN_PIN_MODAL_OPEN',
    GAME_ACCESS_GET: 'GAME_ACCESS_GET',
    GAME_ACCESS_GET_FAILURE: 'GAME_ACCESS_GET_FAILURE',
    GAME_ACCESS_GET_SUCCESS: 'GAME_ACCESS_GET_SUCCESS',
    GET_SLOT88_GAMES_LIST: 'GET_SLOT88_GAMES_LIST',
    GET_SLOT88_GAMES_LIST_FAILURE: 'GET_SLOT88_GAMES_LIST_FAILURE',
    GET_SLOT88_GAMES_LIST_SUCCESS: 'GET_SLOT88_GAMES_LIST_SUCCESS',
    LOGIN_PROMPT_MODAL_CLOSE: 'LOGIN_PROMPT_MODAL_CLOSE',
    LOGIN_PROMPT_MODAL_OPEN: 'LOGIN_PROMPT_MODAL_OPEN',
    PLAYTECH_MEMBER_INFO_GET: 'PLAYTECH_MEMBER_INFO_GET',
    PLAYTECH_MEMBER_INFO_GET_FAILURE: 'PLAYTECH_MEMBER_INFO_GET_FAILURE',
    PLAYTECH_MEMBER_INFO_GET_SUCCESS: 'PLAYTECH_MEMBER_INFO_GET_SUCCESS',
    REFERRAL_GET: 'REFERRAL_GET',
    REFERRAL_GET_FAILURE: 'REFERRAL_GET_FAILURE',
    REFERRAL_GET_SUCCESS: 'REFERRAL_GET_SUCCESS',
    RNG_REDIRECT_FAILURE: 'RNG_REDIRECT_FAILURE',
    RNG_REDIRECT_INIT: 'RNG_REDIRECT_INIT',
    SABA_REDIRECT_FAILURE: 'SABA_REDIRECT_FAILURE',
    SABA_REDIRECT_INIT: 'SABA_REDIRECT_INIT',
    SET_ODDS_TYPE: 'SET_ODDS_TYPE',
    SLOT88_REDIRECT: 'SLOT88_REDIRECT',
    SLOT88_REDIRECT_FAILURE: 'SLOT88_REDIRECT_FAILURE',
    SOCIAL_PLATFORM_ACCESS_GET: 'SOCIAL_PLATFORM_ACCESS_GET',
    SOCIAL_PLATFORM_ACCESS_GET_FAILURE: 'SOCIAL_PLATFORM_ACCESS_GET_FAILURE',
    SOCIAL_PLATFORM_ACCESS_GET_SUCCESS: 'SOCIAL_PLATFORM_ACCESS_GET_SUCCESS',
    USER_BANK_CARD_ADD: 'USER_BANK_CARD_ADD',
    USER_BANK_CARD_ADD_FAILURE: 'USER_BANK_CARD_ADD_FAILURE',
    USER_BANK_CARD_ADD_SUCCESS: 'USER_BANK_CARD_ADD_SUCCESS',
    USER_BANK_CARD_DELETE: 'USER_BANK_CARD_DELETE',
    USER_BANK_CARD_DELETE_FAILURE: 'USER_BANK_CARD_DELETE_FAILURE',
    USER_BANK_CARD_DELETE_SUCCESS: 'USER_BANK_CARD_DELETE_SUCCESS',
    USER_CHANGE_FIN_PIN: 'USER_CHANGE_FIN_PIN',
    USER_CHANGE_FIN_PIN_FAILURE: 'USER_CHANGE_FIN_PIN_FAILURE',
    USER_CHANGE_FIN_PIN_SUCCESS: 'USER_CHANGE_FIN_PIN_SUCCESS',
    USER_CHANGE_PASSWORD: 'USER_CHANGE_PASSWORD',
    USER_CHANGE_PASSWORD_FAILURE: 'USER_CHANGE_PASSWORD_FAILURE',
    USER_CHANGE_PASSWORD_SUCCESS: 'USER_CHANGE_PASSWORD_SUCCESS',
    USER_CLAIM_PASS_CODE: 'USER_CLAIM_PASS_CODE',
    USER_CLAIM_PASS_CODE_FAILURE: 'USER_CLAIM_PASS_CODE_FAILURE',
    USER_CLAIM_PASS_CODE_SUCCESS: 'USER_CLAIM_PASS_CODE_SUCCESS',
    USER_CLEAR_INFO: 'USER_CLEAR_INFO',
    USER_CLOSE_DOWNLOAD_ALERT: 'USER_CLOSE_DOWNLOAD_ALERT',
    USER_DETAILS_GET: 'USER_DETAILS_GET',
    USER_DETAILS_GET_FAILURE: 'USER_DETAILS_GET_FAILURE',
    USER_DETAILS_GET_SUCCESS: 'USER_DETAILS_GET_SUCCESS',
    USER_DETAILS_SET: 'USER_DETAILS_SET',
    USER_DETAILS_UPDATE: 'USER_DETAILS_UPDATE',
    USER_DETAILS_UPDATE_FAILURE: 'USER_DETAILS_UPDATE_FAILURE',
    USER_DETAILS_UPDATE_SUCCESS: 'USER_DETAILS_UPDATE_SUCCESS',
    USER_LOGIN: 'USER_LOGIN',
    USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
    USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_MAINTENANCE_MODAL_VISIBLE: 'USER_MAINTENANCE_MODAL_VISIBLE',
    USER_ONBOARDING_MODAL_CLOSE: 'USER_ONBOARDING_MODAL_CLOSE',
    USER_ONBOARDING_MODAL_OPEN: 'USER_ONBOARDING_MODAL_OPEN',
    USER_ONBOARDING_MODAL_VISIBILITY_TRIGGER: 'USER_ONBOARDING_MODAL_VISIBILITY_TRIGGER',
    USER_REGISTER: 'USER_REGISTER',
    USER_REGISTER_FAILURE: 'USER_REGISTER_FAILURE',
    USER_REGISTER_SUCCESS: 'USER_REGISTER_SUCCESS',
    USER_REG_CODE_REQUEST: 'USER_REG_CODE_REQUEST',
    USER_REG_CODE_REQUEST_FAILURE: 'USER_REG_CODE_REQUEST_FAILURE',
    USER_REG_CODE_REQUEST_SUCCESS: 'USER_REG_CODE_REQUEST_SUCCESS',
    USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
    USER_RESET_PASSWORD_FAILURE: 'USER_RESET_PASSWORD_FAILURE',
    USER_RESET_PASSWORD_SUCCESS: 'USER_RESET_PASSWORD_SUCCESS',
    USER_SESSION_LOG: 'USER_SESSION_LOG',
    USER_SESSION_LOG_FAILURE: 'USER_SESSION_LOG_FAILURE',
    USER_SESSION_LOG_SUCCESS: 'USER_SESSION_LOG_SUCCESS',
    USER_VERIFY: 'USER_VERIFY',
    USER_VERIFY_FAILURE: 'USER_VERIFY_FAILURE',
    USER_VERIFY_FIN_PIN: 'USER_VERIFY_FIN_PIN',
    USER_VERIFY_FIN_PIN_FAILURE: 'USER_VERIFY_FIN_PIN_FAILURE',
    USER_VERIFY_FIN_PIN_SUCCESS: 'USER_VERIFY_FIN_PIN_SUCCESS',
    USER_VERIFY_SUCCESS: 'USER_VERIFY_SUCCESS',
    USER_VIP_LEVEL_DETAILS_GET: 'USER_VIP_LEVEL_DETAILS_GET',
    USER_VIP_LEVEL_DETAILS_GET_FAILURE: 'USER_VIP_LEVEL_DETAILS_GET_FAILURE',
    USER_VIP_LEVEL_DETAILS_GET_SUCCESS: 'USER_VIP_LEVEL_DETAILS_GET_SUCCESS',
};

/**
 * Add bank card init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const addBankCard = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_BANK_CARD_ADD,
    });

/**
 * Add bank card success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const addBankCardSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_BANK_CARD_ADD_SUCCESS,
    });

/**
 * Add bank card failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const addBankCardFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_BANK_CARD_ADD_FAILURE,
    });

/**
 * Delete bank card init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const deleteBankCard = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_BANK_CARD_DELETE,
    });

/**
 * Delete bank card success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Id of deleted card
 */
export const deleteBankCardSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_BANK_CARD_DELETE_SUCCESS,
    });

/**
 * Delete bank card failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const deleteBankCardFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_BANK_CARD_DELETE_FAILURE,
    });

/**
 * Change financial pin init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const changeFinPin = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CHANGE_FIN_PIN,
    });

/**
 * Change financial pin success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const changeFinPinSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CHANGE_FIN_PIN_SUCCESS,
    });

/**
 * Change financial pin failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const changeFinPinFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_CHANGE_FIN_PIN_FAILURE,
    });

/**
 * Change financial pin init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const verifyFinPin = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_VERIFY_FIN_PIN,
    });

/**
 * Change financial pin success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const verifyFinPinSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_VERIFY_FIN_PIN_SUCCESS,
    });

/**
 * Change financial pin failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const verifyFinPinFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_VERIFY_FIN_PIN_FAILURE,
    });

/**
 * Change password init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const changePassword = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CHANGE_PASSWORD,
    });

/**
 * Change password success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const changePasswordSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CHANGE_PASSWORD_SUCCESS,
    });

/**
 * Change password failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const changePasswordFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_CHANGE_PASSWORD_FAILURE,
    });

/**
 * Reset password init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const resetPassword = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_RESET_PASSWORD,
    });

/**
 * Reset password success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const resetPasswordSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_RESET_PASSWORD_SUCCESS,
    });

/**
 * Reset password failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const resetPasswordFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_RESET_PASSWORD_FAILURE,
    });

/**
 * Claim passcode init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const claimPasscode = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CLAIM_PASS_CODE,
    });

/**
 * Claim passcode success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const claimPasscodeSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CLAIM_PASS_CODE_SUCCESS,
    });

/**
 * Claim passcode failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const claimPasscodeFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_CLAIM_PASS_CODE_FAILURE,
    });

/**
 * Get user details init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getUserDetails = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_DETAILS_GET,
    });

/**
 * Get user details success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {User} payload Action payload
 */
export const getUserDetailsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_DETAILS_GET_SUCCESS,
    });

/**
 * Get user details failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getUserDetailsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_DETAILS_GET_FAILURE,
    });

/**
 * Update user details init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateUserDetails = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_DETAILS_UPDATE,
    });

/**
 * Update user details success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {*} payload Action payload (email or birthdate)
 */
export const updateUserDetailsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_DETAILS_UPDATE_SUCCESS,
    });

/**
 * Update user details failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const updateUserDetailsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_DETAILS_UPDATE_FAILURE,
    });

/**
 * Set user details action
 * @param {Function} dispatch Action dispatcher from store
 * @param {User} payload Action payload
 */
export const setUserDetails = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_DETAILS_SET,
    });

/**
 * Login init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const userLogin = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_LOGIN,
    });

/**
 * Login success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {LoginData} payload Action payload
 */
export const userLoginSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_LOGIN_SUCCESS,
    });

/**
 * Login failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const userLoginFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_LOGIN_FAILURE,
    });

/**
 * User logout action
 * @param {Function} dispatch Action dispatcher from store
 */
export const userLogout = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_LOGOUT,
    });

/**
 * Register init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const registerUser = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_REGISTER,
    });

/**
 * Register success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const registerUserSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_REGISTER_SUCCESS,
    });

/**
 * Register failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const registerUserFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_REGISTER_FAILURE,
    });

/**
 * Registration code request init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const regCodeRequest = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_REG_CODE_REQUEST,
    });

/**
 * Registration code request success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const regCodeRequestSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_REG_CODE_REQUEST_SUCCESS,
    });

/**
 * Registration code request failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const regCodeRequestFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_REG_CODE_REQUEST_FAILURE,
    });

/**
 * Close login prompt modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const closeLoginPromptModal = (dispatch) =>
    dispatch({
        type: ACTIONS.LOGIN_PROMPT_MODAL_CLOSE,
    });

/**
 * Open login prompt modal action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Route to redirect user after successful login
 */
export const openLoginPromptModal = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.LOGIN_PROMPT_MODAL_OPEN,
    });

/**
 * Saba redirect init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const sabaRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.SABA_REDIRECT_INIT,
    });

/**
 * Saba redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const sabaRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.SABA_REDIRECT_FAILURE,
    });

/**
 * Open onboarding modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const openOnBoardingModal = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_ONBOARDING_MODAL_OPEN,
    });

/**
 * Close onboarding modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const closeOnBoardingModal = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_ONBOARDING_MODAL_CLOSE,
    });

/**
 * Set onboarding visibility action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Boolean} payload Action payload
 */
export const setOnboardingVisibility = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_ONBOARDING_MODAL_VISIBILITY_TRIGGER,
    });

/**
 * Close financial pin modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const finPinModalClose = (dispatch) =>
    dispatch({
        type: ACTIONS.FIN_PIN_MODAL_CLOSE,
    });

/**
 * Open financial pin modal action
 * @param {Function} dispatch Action dispatcher from store
 */
export const finPinModalOpen = (dispatch) =>
    dispatch({
        type: ACTIONS.FIN_PIN_MODAL_OPEN,
    });

/**
 * Update bet settings init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateBetSettings = (dispatch) =>
    dispatch({
        type: ACTIONS.BET_SETTINGS_UPDATE,
    });

/**
 * Update bet settings success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {*} payload Action payload
 */
export const updateBetSettingsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SETTINGS_UPDATE_SUCCESS,
    });

/**
 * Update bet settings failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const updateBetSettingsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.BET_SETTINGS_UPDATE_FAILURE,
    });

/**
 * Set odds type locally (without request to server, for anonymous user)
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Odds type
 */
export const setOddsTypeLocally = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SET_ODDS_TYPE,
    });

/**
 * RNG redirect init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const rngRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.RNG_REDIRECT_INIT,
    });

/**
 * RNG redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const rngRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.RNG_REDIRECT_FAILURE,
    });

/**
 * Register Affiliate init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const registerAffiliate = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_REGISTER,
    });

/**
 * Register Affiliate success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const registerAffiliateSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_REGISTER_SUCCESS,
    });

/**
 * Register Affiliate failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const registerAffiliateFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_REGISTER_FAILURE,
    });

/**
 * Asia Gaming redirect init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const agRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.AG_REDIRECT_INIT,
    });

/**
 * Asia Gaming redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const agRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.AG_REDIRECT_FAILURE,
    });

/**
 * Get Referral init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getReferral = (dispatch) =>
    dispatch({
        type: ACTIONS.REFERRAL_GET,
    });

/**
 * Get Referral success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {ReferralInfo} payload Action payload
 */
export const getReferralSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_GET_SUCCESS,
    });

/**
 * Get Referral failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getReferralFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.REFERRAL_GET_FAILURE,
    });

/** BTi redirect init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const btiRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.BTI_REDIRECT_INIT,
    });

/**
 * BTi redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const btiRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.BTI_REDIRECT_FAILURE,
    });

/**
 * Clear User Info action
 * @param {Function} dispatch Action dispatcher from store
 */
export const clearUserInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CLEAR_INFO,
    });

/**
 * Get playtech member info init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getPlaytechMemberInfo = (dispatch) =>
    dispatch({
        type: ACTIONS.PLAYTECH_MEMBER_INFO_GET,
    });

/**
 * Get playtech member info success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {PlaytechInfo} payload Playtech member info
 */
export const getPlaytechMemberInfoSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYTECH_MEMBER_INFO_GET_SUCCESS,
    });

/**
 * Get playtech member info failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getPlaytechMemberInfoFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.PLAYTECH_MEMBER_INFO_GET_FAILURE,
    });

/**
 * Set maintenance visibility action
 * @param {Function} dispatch Action dispatcher from store
 */
export const setMaintenanceModalVisible = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_MAINTENANCE_MODAL_VISIBLE,
    });

/**
 * Verify account init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const verifyAccount = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_VERIFY,
    });

/**
 * Verify account success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {User} payload Additional user info
 */
export const verifyAccountSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_VERIFY_SUCCESS,
    });

/**
 * Verify account failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const verifyAccountFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_VERIFY_FAILURE,
    });

/**
 * Close download alert
 * @param {Function} dispatch Action dispatcher from store
 */
export const closeDownloadAlert = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_CLOSE_DOWNLOAD_ALERT,
    });

/**
 * Crash redirect action
 * @param {Function} dispatch Action dispatcher from store
 */
export const crashRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.CRASH_REDIRECT,
    });

/**
 * Crash redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const crashRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.CRASH_REDIRECT_FAILURE,
    });

/**
 * Advantplay redirect action
 * @param {Function} dispatch Action dispatcher from store
 */
export const advantplayRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.ADVANTPLAY_REDIRECT,
    });

/**
 * Advantplay redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const advantplayRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.ADVANTPLAY_REDIRECT_FAILURE,
    });

/**
 * Get  game access init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getGameAccess = (dispatch) =>
    dispatch({
        type: ACTIONS.GAME_ACCESS_GET,
    });

/**
 * Get game access success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {GameAccessInfo} payload Action payload
 */
export const getGameAccessSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_ACCESS_GET_SUCCESS,
    });

/**
 * Get game access failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getGameAccessFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GAME_ACCESS_GET_FAILURE,
    });

/**
 * Get  affiliate status init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getAffiliateStatus = (dispatch) =>
    dispatch({
        type: ACTIONS.AFFILIATE_STATUS,
    });

/**
 * Get affiliate status success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{affiliateStatus: String, getAffiliateStatus: Function}} payload Action payload
 */
export const getAffiliateStatusSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_STATUS_SUCCESS,
    });

/**
 * Get affiliate status failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getAffiliateStatusFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.AFFILIATE_STATUS_FAILURE,
    });

/**
 * Update app language init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateAppLanguage = (dispatch) =>
    dispatch({
        type: ACTIONS.APP_LANGUAGE_UPDATE,
    });

/**
 * Update app language success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const updateAppLanguageSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.APP_LANGUAGE_UPDATE_SUCCESS,
    });

/**
 * Update app language failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const updateAppLanguageFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.APP_LANGUAGE_UPDATE_FAILURE,
    });

/**
 * Get user vip level details init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getUserVipLevelDetails = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_VIP_LEVEL_DETAILS_GET,
    });

/**
 * Get user vip level details success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{accumulatedDeposit: Number, accumulatedETO: Number, lastLevelUpDate, TimeStamp}} payload Action payload
 */
export const getUserVipLevelDetailsSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_VIP_LEVEL_DETAILS_GET_SUCCESS,
    });

/**
 * Get user vip level details failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getUserVipLevelDetailsFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.USER_VIP_LEVEL_DETAILS_GET_FAILURE,
    });

/**
 * Get social platform URL init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getSocialPlatformAccess = (dispatch) =>
    dispatch({
        type: ACTIONS.USER_VIP_LEVEL_DETAILS_GET,
    });

/**
 * Get social platform URL success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: String}} payload Action payload
 */
export const getSocialPlatformAccessSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SOCIAL_PLATFORM_ACCESS_GET_SUCCESS,
    });

/**
 * Get social platform URL failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getSocialPlatformAccessFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.SOCIAL_PLATFORM_ACCESS_GET_FAILURE,
    });

/**
 * Get crash wallet setup init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getCrashWalletSetup = (dispatch) =>
    dispatch({
        type: ACTIONS.CRASH_WALLET_SETUP_GET,
    });

/**
 * Get crash wallet setup success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{data: String}} payload Action payload
 */
export const getCrashWalletSetupSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRASH_WALLET_SETUP_GET_SUCCESS,
    });

/**
 * Get crash wallet setup failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getCrashWalletSetupFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.CRASH_WALLET_SETUP_GET_FAILURE,
    });

/**
 * Card Racing redirect action
 * @param {Function} dispatch Action dispatcher from store
 */
export const cardRacingRedirect = (dispatch) =>
    dispatch({
        type: ACTIONS.CARD_RACING_REDIRECT,
    });

/**
 * Card Racing redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const cardRacingRedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.CARD_RACING_REDIRECT_FAILURE,
    });

/**
 * Add e-wallet init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const addEWallet = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_ADD,
    });

/**
 * Add e-wallet success action
 * @param {Function} dispatch Action dispatcher from store
 */
export const addEWalletSuccess = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_ADD_SUCCESS,
    });

/**
 * Add e-wallet failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const addEWalletFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_ADD_FAILURE,
    });

/**
 * Delete e-wallet init action
 * @param {Function} dispatch Action dispatcher from store
 */
export const deleteEWallet = (dispatch) =>
    dispatch({
        type: ACTIONS.E_WALLET_DELETE,
    });

/**
 * Delete e-wallet success action
 * @param {Function} dispatch Action dispatcher from store
 * @param {String} payload Id of deleted card
 */
export const deleteEWalletSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_DELETE_SUCCESS,
    });

/**
 * Delete e-wallet failure action
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const deleteEWalletFailure = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.E_WALLET_DELETE_FAILURE,
    });

/**
 * Slot88 get game list method
 * @param {Function} dispatch Action dispatcher from store
 * @param {{type: String, message: String}} payload Action payload
 */
export const getSlot88GamesList = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_SLOT88_GAMES_LIST,
    });

/**
 * Slot88 get game list method Succcess action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getSlot88GamesListSuccess = (dispatch, payload) =>
    dispatch({
        payload,
        type: ACTIONS.GET_SLOT88_GAMES_LIST_SUCCESS,
    });

/**
 * Slot88 get game list method failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const getSlot88GamesListFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.GET_SLOT88_GAMES_LIST_FAILURE,
    });

/**
 * Slot88 redirect action
 * @param {Function} dispatch Action dispatcher from store
 */
export const slot88Redirect = (dispatch) =>
    dispatch({
        type: ACTIONS.SLOT88_REDIRECT,
    });

/**
 * Slot88 redirect failure action
 * @param {Function} dispatch Action dispatcher from store
 */
export const slot88RedirectFailure = (dispatch) =>
    dispatch({
        type: ACTIONS.SLOT88_REDIRECT_FAILURE,
    });
