import { useHistory } from 'react-router-dom';
import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import ImgIcon from '../../common/components/ImgIcon';
import SupportButton from '../../common/components/SupportButton';

const AdvantplayNavBar = ({ icon }) => {
    const history = useHistory();

    // ============================================
    // METHODS
    // ============================================

    const goBack = () => history.goBack();

    // ============================================
    // RENDER
    // ============================================

    return (
        <Row justify="space-between" align="middle" className="navbar__container">
            <Col span={8}>
                <Row justify="start">
                    <ArrowLeftOutlined className="navbar__icon--secondary" onClick={goBack} />
                </Row>
            </Col>
            <Col span={8}>
                <Row justify="center">
                    <ImgIcon icon={icon} />
                </Row>
            </Col>
            <Col span={8}>
                <Row justify="end" align="middle">
                    <SupportButton />
                </Row>
            </Col>
        </Row>
    );
};

export default AdvantplayNavBar;
